import React, { useState } from 'react'
// COMPONENTS
import ModalInvite from './ModalInvite.jsx'
// DB
import { newInvite, checkInvite } from '../../../../services/db'

const ModalInviteContainer = ({ handleModal }) => {
  const [emailInvite, setEmailInvite] = useState('')
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleValue = (e) => setEmailInvite(e.target.value)

  const inviteProfile = async (e) => {
    e.preventDefault()
    if (!emailInvite) {
      setError(true)
    } else {
      setLoading(true)
      setError(false)
      await newInvite(emailInvite)
      await checkInvite(emailInvite)
      setSuccess(true)
      setLoading(false)
    }
  }

  return (
    <ModalInvite
      error={error}
      success={success}
      loading={loading}
      handleModal={handleModal}
      emailInvite={emailInvite}
      handleValue={handleValue}
      inviteProfile={inviteProfile}
    />
  )
}

export default ModalInviteContainer
