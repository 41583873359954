import { Link } from 'react-router-dom'
import { PrimaryButton, SecondaryButton } from './Buttons'
import { CommunityGridDropdown, UserInProjectDropdown } from './Selects'
import UserDefault from '../Images/UserDefault.png'

export const ProfileCard = ({ index, user, handler, singleUserSelection }) => (
  <div className="w-full rounded-md bg-white p-3 sm:p-6 border border-gray-300">
    <div className="w-full h-full flex flex-col justify-between items-baseline space-y-4">
      <div className="space-y-0 sm:space-y-2 w-full">
        <div className="w-full inline-flex justify-between">
          <div className="h-14 w-14 md:h-20 md:w-20 relative">
            <img
              className="rounded-full h-full w-full object-center object-cover  border border-gray-300"
              src={user.profileImage ? user.profileImage : UserDefault}
              alt="UImage"
            />
            <span
              className={
                (user.projects.active.length ? 'bg-red-500' : 'bg-green-500') +
                ' absolute rounded-full h-3 w-3 bottom-0 right-0'
              }
            ></span>
          </div>
          {singleUserSelection ? (
            <div onClick={(e) => singleUserSelection(e, index)}>
              <CommunityGridDropdown handler={handler} />
            </div>
          ) : null}
        </div>
        <div className="flex flex-col">
          <Link to={`/admin/comunidad/${user.id}`}>
            <p className="text-base md:text-xl text-gray-800 font-poppins">
              {user.personalData.fullName}
            </p>
          </Link>
          <p className="text-sm text-gray-500 font-poppins">
            {user.personalData.role}
          </p>
        </div>
      </div>
      <div className="w-full space-y-4 flex flex-col">
        <p className="text-xs sm:text-sm text-gray-400 font-poppins pb-4">
          <span className="text-base text-gray-800 font-poppins">
            {user.projects.finished.length}
          </span>{' '}
          <br className="sm:hidden" />
          proyectos terminados
        </p>
        <div
          className="max-w-min self-end hidden md:block"
          onClick={(e) => singleUserSelection(e, index)}
        >
          <SecondaryButton
            type="button"
            size="lg"
            children="Invitar"
            handler={() => handler('inviteToProject')}
          />
        </div>
        <div
          className="sm:max-w-min sm:self-end block md:hidden"
          onClick={(e) => singleUserSelection(e, index)}
        >
          <PrimaryButton
            type="button"
            size="sm"
            children="Invitar"
            handler={() => handler('inviteToProject')}
          />
        </div>
      </div>
    </div>
  </div>
)

export const ProfileCardInProject = ({
  user,
  isExtension,
  handleUserInProjectOptions,
  project,
}) => {
  const hasProjectContract = project.users.reduce((acc, projectUser) => {
    if (projectUser.user === user.id) {
      if (projectUser.contract) acc = true
      else acc = false
    }
    return acc
  }, false)
  
  let isNewInvite = user.projects.invites.includes(project.projectId)
  
  return (
    <div className="w-full rounded-md bg-white p-3 sm:p-6 border border-gray-300">
      <div className="w-full h-full flex flex-col justify-between items-baseline space-y-4">
        <div className="w-full inline-flex justify-between">
          <div className="h-14 w-14 md:h-20 md:w-20 relative">
            <img
              className="rounded-full h-full w-full object-center object-cover border border-gray-300"
              src={user.profileImage ? user.profileImage : UserDefault}
              alt="UImage"
            />
            {isNewInvite && (
              <span className="absolute -bottom-2 px-2 sm:px-4 py rounded-full text-xs sm:text-sm text-center min-w-max bg-gray-100">
                Pendiente
              </span>
            )}
            {isExtension && (
              <span className="absolute -bottom-2 px-2 sm:px-4 py rounded-full text-xs sm:text-sm text-center min-w-max bg-purple-lightest">
                Extensión
              </span>
            )}
          </div>
          <div>
            <UserInProjectDropdown
              user={user}
              handler={handleUserInProjectOptions}
              hasProjectContract={hasProjectContract}
              projectId={project.projectId}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <p className="text-base sm:text-xl text-gray-800 font-poppins">
            <Link to={`/admin/comunidad/${user.id}`}>
              {user.personalData.fullName}
            </Link>
          </p>
          <p className="text-sm text-gray-500 font-poppins">
            {user.personalData.role}
          </p>
        </div>
        <p className="text-xs sm:text-sm text-gray-400 font-poppins">
          <span className="text-base text-gray-800 font-poppins">
            {user.projects.finished.length}
          </span>{' '}
          proyectos terminados
        </p>
      </div>
    </div>
  )
}
