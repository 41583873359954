import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router'
// RECOIL
import { useRecoilValue } from 'recoil'
import { sessionUserState, userProjectsState } from '../../../atoms'
// SERVICES
import {
  acceptProjectInvitation,
  fetchUserPaymentsByProject,
  getUserPaymentsByProject,
  // validateUserPassword,
} from '../../../services/db'
import { signIn } from '../../../services/auth'
// COMPONENTS
import { FullPageLoaderComponent } from '../../Utils/UI/Loaders'
import AcceptProjectInvite from './AcceptProjectInvite.jsx'
// HELPERS
import { parseTimestampToDateLong, toPesos } from '../../Utils/helpers'
// CONTRACT
import { pdf } from '@react-pdf/renderer'
import ProjectContractPDF from '../../Utils/PDFS/ProjectContract'

const AcceptProjectInviteContainer = ({data,signature}) => {

  console.log('data',data)
  console.log('signature',signature)

  const history = useHistory()
  const params = useParams('projectId')
  const [position, setPosition] = useState(0)
  const [loading, setLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [showData, setShowData] = useState(false)
  const [success, setSuccess] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [userPassword, setUserPassword] = useState('')
  const [inputPassword, setInputPassword] = useState(0)
  const [projectToAccept, setProjectToAccept] = useState(null)
  const [budget, setBudget] = useState(0)
  const [errorPassword, setErrorPassword] = useState({
    invalid: false,
    message: '',
  })
  const sessionUser = useRecoilValue(sessionUserState)
  const userProjects = useRecoilValue(userProjectsState)
  const pd = sessionUser.personalData
  const defaultBankData = sessionUser.allBankData.filter(
    (bank) => bank.default === true
  )
  const bd = defaultBankData[0]
  console.log(bd)
  console.log(userProjects)
  // eslint-disable-next-line
  useEffect(() => {
    handleInitData()
  },[userProjects])

  const handleInitData = async () => {
    console.log('userProjects', userProjects)
    let projectFounded = userProjects.invites.find(
      (project) => project.id === params.projectId
    )
    if(projectFounded){
      setProjectToAccept(projectFounded)
      console.log('projectFounded', projectFounded)
      await getUserPayments(projectFounded.projectId, sessionUser.id)
      setLoading(false)
    }
  }

  const getUserPayments = async (project, user) => {
    console.log('project', project)
    console.log('user', user)
    const payments = await getUserPaymentsByProject(project, user)
    getAmount(payments)
  }

  const getAmount = (payments) => {
    let tpa = 0
    payments.forEach((payment) => (tpa += Number(payment.amount)))
    let curr = payments[0].currency
    return setBudget(curr + ' ' + tpa)
  }

  const dates = projectToAccept
    ? projectToAccept.users.reduce(
        (acc, user) => {
          if (user.user === sessionUser.id) {
            acc.startDate = parseTimestampToDateLong(user.startDate)
            acc.endDate = parseTimestampToDateLong(user.endDate)
          }
          return acc
        },
        { startDate: '', endDate: '' }
      )
    : {}

  const handleSubmit = async (e) => {
    e.preventDefault()
    setErrorPassword({
      invalid: false,
      message: '',
    })
    if (!userPassword)
      return setErrorPassword({
        invalid: true,
        message: 'Ingresa tu contraseña',
      })
    setUploading(true)
    // console.log('sessionUser',sessionUser)
    let validPassword = await signIn(
      sessionUser.personalData.email,
      userPassword,
      setIsLoading
    )
    // console.log('validPassword',validPassword.uid)

    if (validPassword.uid === sessionUser.id) {
      let pdfFile = pdf(
        <ProjectContractPDF
          project={projectToAccept}
          totalAmount={toPesos(budget)}
          userPd={pd}
          userBd={bd}
          userId={sessionUser.id}
          signature={sessionUser.signature}
          viewer={false}
        />
      )
      let blob = await pdfFile.toBlob()
      await acceptProjectInvitation(sessionUser, projectToAccept, blob)
      setSuccess(true)
      setUploading(false)
    } else {
      setErrorPassword({
        invalid: true,
        message: 'La contraseña no es correcta, ingrese de nuevo',
      })
      setUploading(false)
    }
  }

  const handlePassword = (e) => setUserPassword(e.target.value)
  let pdfFile = (
    <ProjectContractPDF
      project={projectToAccept}
      totalAmount={toPesos(budget)}
      userPd={pd}
      userBd={bd}
      userId={sessionUser.id}
      viewer={false}
    />
  )

  if (loading) {
    console.log('loading', loading)
    return <FullPageLoaderComponent />
  } else if (projectToAccept) {
    console.log('projectToAccept', projectToAccept)
    return (
      <AcceptProjectInvite
        pd={pd}
        bd={bd}
        dates={dates}
        budget={budget}
        pdfFile={pdfFile}
        history={history}
        success={success}
        position={position}
        showData={showData}
        uploading={uploading}
        setSuccess={setSuccess}
        setShowData={setShowData}
        setPosition={setPosition}
        sessionUser={sessionUser}
        handleSubmit={handleSubmit}
        userPassword={userPassword}
        inputPassword={inputPassword}
        errorPassword={errorPassword}
        handlePassword={handlePassword}
        projectToAccept={projectToAccept}
        setInputPassword={setInputPassword}
      />
    )
  } else {
    return history.goBack()
  }
}

export default AcceptProjectInviteContainer
