import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
// DB
import { extendUserProject } from '../../../../services/db'
// COMPONENTS
import ModalLayout from '../../UI/ModalLayout'
// UI
import { PrimaryButton, SecondaryButton } from '../../UI/Buttons'
import Success from '../../Images/InviteSent.png'
import To from '../../Images/To.png'
import NumberFormat from 'react-number-format'

const ModalExtendUserProjectContainer = ({ handleModals, user, project }) => {
  // eslint-disable-next-line
  const [step, setStep] = useState('details')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [data, setData] = useState({
    user: user,
    project: project,
    startDate: null,
    endDate: null,
    payments: [
      {
        paymentNumber: 1,
        amount: '',
        date: '',
      },
    ],
  })

  const handleDates = (key, date) => setData({ ...data, [key]: date })

  const addPayment = () => {
    setError(false)
    let newPayment = {
      paymentNumber: data.payments.length + 1,
      amount: '',
      date: '',
    }
    setData({ ...data, payments: [...data.payments, newPayment] })
  }

  const checkPayments = (payments) => {
    let errors = 0
    if (payments.length === 0) {
      errors++
    } else {
      payments.forEach((payment) => {
        if (!payment.amount) {
          errors++
        } else if (!payment.date) {
          errors++
        }
      })
    }
    return errors
  }

  const handleSubmit = async () => {
    setLoading(true)
    let errors = 0
    for (const key in data) {
      if (key === 'payments') {
        if (checkPayments(data.payments) > 0) errors++
      } else {
        if (!data[key]) errors++
      }
    }

    if (errors > 0) {
      setError(true)
    } else {
      setError(false)
      let res = await extendUserProject(data)
      setLoading(false)
      console.log('🚀 ➡️ res', res)
      // setStep('success')
    }
  }

  const deletePayment = (i) => {
    data.payments.splice(i, 1)
    setData({ ...data, payments: data.payments })
  }

  const handlePayment = (e) => {
    let i = e.target.name
    let value = e.target.value.replace(/\W/g, '')
    data.payments[i].amount = value
    setData({ ...data, payments: data.payments })
  }

  const handlePaymentDate = (date, i) => {
    data.payments[i].date = date
    setData({ ...data, payments: data.payments })
  }

  console.log('🚀 ➡️ data', data)

  const inputStyle = (error) =>
    error
      ? 'appearance-none block w-full px-3 py-2 border rounded-md sm:placeholder-transparent sm:bg-transparent font-poppins font-medium focus:outline-none bg-gray-50 sm:text-sm border-red-500 placeholder-gray-400 focus:ring-red-500 focus:border-red-500'
      : 'appearance-none block w-full px-3 py-2 border rounded-md sm:placeholder-transparent sm:bg-transparent font-poppins font-medium focus:outline-none bg-gray-50 sm:text-sm border-gray-300 placeholder-gray-400 focus:ring-purple focus:border-purple'

  const labelStyle = (error) =>
    error
      ? 'text-sm text-red-500 font-poppins font-medium'
      : 'text-sm text-gray-800 font-poppins font-medium'

  return (
    <ModalLayout
      handler={handleModals}
      modal="extendUserProject"
      title={
        step === 'success' ? 'Extensión enviada!' : 'Extender proyecto'
      }
      goBackText="Proyecto"
      regular={step === 'success'}
    >
      {step === 'details' ? (
        <div className="flex flex-col space-y-6">
          <div className="py-1 mb-4 font-poppins sm:text-base inline-flex items-center text-gray-800 space-x-2">
            <span>Perfil:</span>
            <span>{user && user.personalData.fullName}</span>
          </div>
          <div className="w-full inline-flex space-x-2 justify-between items-center">
            <div className="w-full flex flex-col items-baseline space-y-1">
              <label
                htmlFor="startDate"
                className={labelStyle(error && !data.startDate ? true : false)}
              >
                Inicio
              </label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selectsStart
                selected={data.startDate}
                startDate={data.startDate}
                endDate={data.endDate}
                minDate={new Date()}
                readonly
                popperPlacement="auto-start"
                onChange={(date) => handleDates('startDate', date)}
                className={inputStyle(error && !data.startDate ? true : false)}
              />
            </div>

            <div className="w-10 flex flex-col items-baseline space-y-1 pt-6">
              <img src={To} alt="to" />
            </div>

            <div className="w-full flex flex-col items-baseline space-y-1">
              <label
                htmlFor="dates"
                className={labelStyle(error && !data.endDate ? true : false)}
              >
                Finalización
              </label>
              <DatePicker
                selectsEnd
                dateFormat="dd/MM/yyyy"
                selected={data.endDate}
                startDate={data.startDate}
                endDate={data.endDate}
                minDate={new Date()}
                popperPlacement="auto-end"
                onChange={(date) => handleDates('endDate', date)}
                className={inputStyle(error && !data.endDate ? true : false)}
              />
            </div>
          </div>
          {data.payments.map((payment, i) => (
            <div className="flex flex-col space-y-2 items-baseline" key={i}>
              <div className="pt-4 space-x-2 font-poppins font-medium sm:text-sm  inline-flex justify-center group">
                <span className="h-5">Cuota {i + 1}</span>
                <button
                  onClick={() => deletePayment(i)}
                  className="h-max-content focus:outline-none group flex items-center"
                >
                  <svg
                    width="10"
                    height="15"
                    viewBox="0 0 11 14"
                    fill="none"
                    className="h-5 w-auto text-gray-500 group-hover:text-red-500"
                  >
                    <path
                      d="M0.777778 12.4444C0.777778 13.3 1.47778 14 2.33333 14H8.55556C9.41111 14 10.1111 13.3 10.1111 12.4444V3.11111H0.777778V12.4444ZM10.8889 0.777778H8.16667L7.38889 0H3.5L2.72222 0.777778H0V2.33333H10.8889V0.777778Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </div>
              <div className="w-full inline-flex space-x-4 justify-between items-center">
                <div className="w-full flex flex-col items-baseline space-y-1">
                  <label
                    htmlFor="amount"
                    className={labelStyle(
                      error && !payment.amount ? true : false
                    )}
                  >
                    Monto
                  </label>
                  <NumberFormat
                    type="text"
                    name={i}
                    value={payment.amount}
                    thousandSeparator={true}
                    prefix={'$'}
                    className={inputStyle(
                      error && !payment.amount ? true : false
                    )}
                    onChange={handlePayment}
                  />
                </div>
                <div className="w-full flex flex-col items-baseline space-y-1">
                  <label
                    htmlFor="paymentDate"
                    className={labelStyle(
                      error && !payment.date ? true : false
                    )}
                  >
                    Fecha
                  </label>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={payment.date}
                    minDate={data.startDate}
                    popperPlacement="top-end"
                    onChange={(date) => handlePaymentDate(date, i)}
                    className={inputStyle(
                      error && !payment.date ? true : false
                    )}
                  />
                </div>
              </div>
            </div>
          ))}

          {error && data.payments.length === 0 ? (
            <p className="font-poppins font-medium sm:text-sm text-red-500">
              Agrega al menos 1 cuota
            </p>
          ) : null}
          <SecondaryButton
            type="button"
            size="lg"
            children="+ Agregar cuota"
            handler={addPayment}
            error={error && data.payments.length === 0}
          />
          <div className="w-full mt-8 inline-flex justify-end">
            <div className="w-min-content">
              {data.payments ? (
                <PrimaryButton
                  type="button"
                  size="lg"
                  children="Extender"
                  handler={handleSubmit}
                  loading={loading}
                />
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
      {step === 'success' ? (
        <div className="w-full flex flex-col items-center space-y-8">
          <img
            src={Success}
            alt="Success!"
            className="max-h-20 sm:max-h-full w-auto"
          />
          <p className="text-sm sm:text-base md:text-lg text-center font-poppins text-gray-500">
            Se ha enviado la invitación para la extensión del {project.pname}.
          </p>
        </div>
      ) : null}
    </ModalLayout>
  )
}

export default ModalExtendUserProjectContainer
