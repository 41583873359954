import React, { useEffect, useState } from 'react'
import { Switch, Route, useHistory, useRouteMatch } from 'react-router-dom'
// HELPERS
import { cbu, cuit } from 'arg.js'
import { pdf } from '@react-pdf/renderer'
// FIREBASE
import { signUp } from '../../services/auth'
import { checkInvite, newUser } from '../../services/db'
import { useAuth } from '../../services/firebase-auth-context'
// RECOIL
import { useRecoilState } from 'recoil'
import { formDataState } from '../../atoms/index'
// COMPONENTS
import RegisterDatosPersonales from './RegisterDatosPersonales.jsx'
import RegisterDatosFiscales from './RegisterDatosFiscales.jsx'
import CContract from './ConfidentialityContract'
import CContractPDF from '../Utils/PDFS/ConfidentialityContract'
import BankAccountsContainer from '../User/BankAccounts/index.js'

const RegisterContainer = () => {
  let match = useRouteMatch()
  const history = useHistory()
  const sessionUser = useAuth()
  const [errorPD, setErrorPD] = useState(false)
  const [errorBD, setErrorBD] = useState(false)
  // PASAR INVALIDS A OBJETO
  const [invalidCuit, setInvalidCuit] = useState(false)
  const [invalidCbu, setInvalidCbu] = useState(false)
  const [invalidInvite, setInvalidInvite] = useState(false)
  const [invalidPassword, setInvalidPassword] = useState(false)
  const [invalidSignature, setInvalidSignature] = useState(false)
  const [formData, setFormData] = useRecoilState(formDataState)
  const [signature, setSignature] = useState('')

  const handleInputs = (e, option, data) => {
    e.preventDefault()
    let value = option ? option : e.target.value
    let name = data ? data : e.target.name
    console.log('name', name)
    console.log('value', value)
    if (name === 'cuit' && cuit.isValid(value.replace(/-/g, '')))
      setInvalidCuit(false)
    if (name === 'cbu' && cbu.isValid(value)) setInvalidCbu(false)
    if (name === 'password' && value.length > 6) setInvalidPassword(false)
    let newFormData = { ...formData }
    console.log('newFormData', newFormData)
    for (let key in newFormData) {
      if (newFormData[key].hasOwnProperty(name)) {
        let newObj = { ...newFormData[key] }
        newObj[name] = value
        newFormData[key] = newObj
      }
    }
    setFormData(newFormData)
  }

  const validateData = async (data, setError) => {
    let errors = 0
    for (const key in data) {
      if (!data[key]) errors++
      if (key === 'cuit' && !cuit.isValid(data[key].replace(/-/g, ''))) {
        errors++
        setInvalidCuit(true)
      } else if (key === 'cbu' && !cbu.isValid(data[key])) {
        errors++
        setInvalidCbu(true)
      } else if (key === 'password' && data[key].length < 6) {
        errors++
        setInvalidPassword(true)
      } else if (key === 'email') {
        let validateInvite = await checkInvite(data[key])
        if (!validateInvite.user) {
          errors++
          setInvalidInvite(true)
        }
      }
    }
    errors ? setError(true) : setError(false)
    return errors
  }

  const validateForm = async (e) => {
    e.preventDefault()
    if (match.url === '/register/datos-personales') {
      console.log('formData', formData)
      let errors = await validateData(formData.personalData, setErrorPD)
      if (!errors) {
        let pd = { ...formData.personalData }
        pd.fullName = pd.fullName = pd.fname + ' ' + pd.lname
        setFormData({ ...formData, personalData: pd })
        history.push('/register/datos-fiscales')
      }
    } else if (match.url === '/register/datos-fiscales') {
      let errors = await validateData(formData.bankData, setErrorBD)
      if (!errors) history.push('/register/c-contract')
    }
  }

  const submit = async () => {
    if (!signature) {
      setInvalidSignature(true)
    } else {
      console.log('formData', formData)
      let bd = formData.allBankData[0]
      let pd = formData.personalData
      let blob = pdf(
        <CContractPDF
          fname={pd.fname}
          lname={pd.lname}
          cuit={pd.cuit}
          address={pd.address}
          role={pd.role}
          signature={signature}
        />
      )
      let formDataUpdated = { ...formData }
      formDataUpdated.signature = signature
      let pdfFile = await blob.toBlob()
      let user = await signUp(pd.email, pd.password)
      if (user.uid) await newUser(user.uid, formDataUpdated, pdfFile)
    }
  }

  useEffect(() => {
    if (sessionUser) {
      if (sessionUser.uid === 'Wgi9MQzMQne1nrKQ7icJpscuqp12') {
        return history.push('/admin/home')
      } else {
        return history.push('/user/home')
      }
    }
  }, [sessionUser, history])

  const handleFormData = (data) => {
    let info = {...data}
    info.default = true
    console.log('data', info)
    setFormData({ ...formData, allBankData: [info] })
    history.push('/register/c-contract')
  }

  return (
    <Switch>
      <Route path="/register/datos-personales">
        <RegisterDatosPersonales
          personalData={formData.personalData}
          handleInputs={handleInputs}
          validateForm={validateForm}
          error={errorPD}
          invalidPassword={invalidPassword}
          invalidInvite={invalidInvite}
          invalidCuit={invalidCuit}
        />
      </Route>
      <Route path="/register/datos-fiscales">
        <BankAccountsContainer newUser={true} edit={false} setOpen={null} fetchUserData={null} handleFormData={handleFormData}/>
        {/* <RegisterDatosFiscales
          bankData={formData.bankData}
          handleInputs={handleInputs}
          validateForm={validateForm}
          error={errorBD}
          invalidCuit={invalidCuit}
          invalidCbu={invalidCbu}
        /> */}
      </Route>
      <Route path="/register/c-contract">
        <CContract
          submit={submit}
          formData={formData}
          signature={signature}
          invalidSignature={invalidSignature}
          setSignature={setSignature}
        />
      </Route>
    </Switch>
  )
}

export default RegisterContainer
