import React, { useEffect, useState, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
// DB
import {
  getProject,
  removeProject,
  changeProjectStatus,
  deleteUserFromProject,
  finishProjectForUser,
} from '../../../services/db'
// RECOIL
import { useRecoilValue } from 'recoil'
import { usersState } from '../../../atoms'
// COMPONENTS
import SingleProject from './SingleProject'
import ModalConfirmDelete from '../../Utils/Modals/ModalConfirmDelete'
import ModalInviteToProjectContainer from '../../Utils/Modals/ModalInviteToProject'
import ModalEditProjectContainer from '../../Utils/Modals/ModalEditProject'
import ModalViewPaymentsContainer from '../../Utils/Modals/ModalViewPayments'
import ModalExtendUserProjectContainer from '../../Utils/Modals/ModalExtendProject'
import ModalConfirmAction from '../../Utils/Modals/ModalConfirmAction'

const SingleProjectContainer = ({getProjects, getPayments, refreshData}) => {
  const history = useHistory()
  const { projectId } = useParams()
  const [modal, setModal] = useState('')
  const [project, setProject] = useState()
  const [payment, setPayment] = useState({})
  const [loading, setLoading] = useState(false)
  const [userSelected, setUserSelected] = useState({})
  const users = useRecoilValue(usersState)
  const [editPayment, setEditPayment] = useState(false)

  const fetchProject = useCallback(async () => {
    let projectFound = await getProject(projectId)
    !projectFound ? history.goBack() : setProject(projectFound)
  }, [projectId, history])

  const handleModals = async (option) => {
    console.log(option)
    if (option === 'changeToFinished')
      await changeProjectStatus(projectId, 'Finalizado')
    if (option === 'changeToOnDevelopment')
      await changeProjectStatus(projectId, 'En desarrollo')
    typeof option === 'string' ? setModal(option) : setModal('')
    return await fetchProject()
  }

  const deleteProject = async () => {
    await removeProject(projectId)
    return await fetchProject()
  }

  const handleEditPayment = (payment) => {
    setEditPayment(true)
    return setPayment(payment)
  }

  const handleNewPayment = () => {
    setEditPayment(true)
    return setPayment({ new: true, user: userSelected.id, project: projectId })
  }

  const removeUser = async () => {
    setLoading(true)
    await deleteUserFromProject(userSelected.id, projectId)
    setUserSelected({})
    setLoading(false)
  }

  const handleUserInProjectOptions = (user, option) => {
    setUserSelected(user)
    switch (option) {
      case 'openContract':
        let userP = project.users.find(
          (userProject) => userProject.user === user.id
        )
        return userP.contract ? window.open(userP.contract) : null
      default:
        setModal(option)
        break
    }
  }

  const handleFinishProjectForUser = async () => {
    setLoading(true)
    await finishProjectForUser(userSelected, project)
    setLoading(false)
  }

  useEffect(() => {
    fetchProject()
  }, [modal, fetchProject])

  useEffect(() => { 
    console.log('UserSelected:', userSelected)
  }, [userSelected])

  return (
    <>
      <SingleProject
        modal={modal}
        users={users}
        loading={loading}
        project={project}
        setModal={setModal}
        projectId={projectId}
        removeUser={removeUser}
        handleModals={handleModals}
        userSelected={userSelected}
        deleteProject={deleteProject}
        handleUserInProjectOptions={handleUserInProjectOptions}
        handleEditPayment={handleEditPayment}
      />
      {modal === 'deleteProject' && (
        <ModalConfirmDelete
          loading={loading}
          handleModals={handleModals}
          handleDelete={deleteProject}
          multiple={false}
          children="proyecto"
        />
      )}
      {modal === 'deleteUserFromProject' && (
        <ModalConfirmDelete
          loading={loading}
          handleModals={handleModals}
          handleDelete={removeUser}
          multiple={false}
          children="usuario"
        />
      )}
      {modal === 'inviteToProject' && (
        <ModalInviteToProjectContainer
          handleModals={handleModals}
          project={project}
        />
      )}
      {editPayment && (
        <ModalEditProjectContainer
          handleModals={handleModals}
          project={project}
          payment={payment}
          getPayments={getPayments} 
          getProjects={getProjects}
          fetchProject={fetchProject}
          refreshData={refreshData}
          closeEdit={() => {
            setEditPayment(false)
            setPayment({})
            setTimeout(() => setModal('openPayments'), 500)
            console.log('closeEdit')
          }}
        />
      )}
      {modal === 'editProject' && (
        <ModalEditProjectContainer
          handleModals={handleModals}
          project={project}
        />
      )}
      {modal === 'openPayments' && (
        <ModalViewPaymentsContainer
          admin
          handleModals={handleModals}
          payments={project.payments}
          userSelected={userSelected}
          handleEditPayment={handleEditPayment}
          handleNewPayment={handleNewPayment}
        />
      )}
      {modal === 'extendProject' && (
        <ModalExtendUserProjectContainer
          handleModals={handleModals}
          project={project}
          user={userSelected}
        />
      )}
      {modal === 'finishProjectForUser' && (
        <ModalConfirmAction
          loading={loading}
          handleModal={handleModals}
          handler={handleFinishProjectForUser}
          text={`¿Estás seguro de finalizar el proyecto para ${userSelected.personalData.fullName}?`}
          confirmationText={`Se ha finalizado el proyecto para ${userSelected.personalData.fullName}`}
        />
      )}
    </>
  )
}

export default SingleProjectContainer
