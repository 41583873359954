import React from 'react'
// COMPONENTS
import ModalConfirmDelete from '../../Utils/Modals/ModalConfirmDelete'
import ModalCreateProject from '../../Utils/Modals/ModalCreateProject'
import ModalInviteToProjectContainer from '../../Utils/Modals/ModalInviteToProject'
// UI
import { PrimaryButton } from '../../Utils/UI/Buttons'
import { ProjectsFilterBy } from '../../Utils/UI/Selects'
// IMAGES
import Search from '../../Utils/Images/Search.png'
import { useState } from 'react'
import SearchBlack from '../../Utils/Images/SearchBlack.png'
import Add from '../../Utils/Images/Add.png'
import GoBack from '../../Utils/Images/GoBack.png'

const ProjectsLayout = ({
  modal,
  filter,
  project,
  children,
  handleModals,
  handleFilter,
  handleSearch,
  deleteProjects,
  projectsSelected,
}) => {
  const [searchBar, setSearchBar] = useState(false)

  return (
    <div className="flex flex-col w-full h-full space-y-0 md:space-y-4 py-0 md:py-2 overflow-hidden">
      {/* TABLE HEADER ACTIONS */}
      <div className="block sm:hidden">
        <div
          className={
            (searchBar ? 'hidden' : '') +
            ' inline-flex -mt-11 md:hidden absolute space-x-6 w-full justify-end'
          }
        >
          <button onClick={() => setSearchBar(!searchBar)}>
            <img src={SearchBlack} alt="Search" />
          </button>
          <button onClick={() => handleModals('cproject')}>
            <img src={Add} alt="Add" />
          </button>
        </div>
        <div
          className={
            (searchBar ? '' : 'hidden') +
            ' absolute -mt-12 w-full rounded-md inline-flex items-center justify-between h-8 bg-gray-100 border border-gray-300'
          }
        >
          <img
            src={GoBack}
            alt="GoBack"
            onClick={() => setSearchBar(!searchBar)}
            className="h-auto min-w-max px-3"
          />
          <input
            type="text"
            onChange={handleSearch}
            className="appearance-none block w-full p-1 font-poppins border-transparent rounded-md focus:outline-none sm:text-sm bg-gray-100 focus:ring-0 h-full border-0"
          />
          <ProjectsFilterBy handler={handleFilter} filter={filter} />
        </div>
      </div>
      <div className="hidden sm:inline-flex justify-between items-center">
        {/* SEARCH USERS INPUT */}
        <div className="inline-flex space-x-4 md:space-x-8 w-auto">
          <div
            id="search"
            className="rounded-md inline-flex items-center h-8 bg-white border border-gray-300"
          >
            <img src={Search} alt="Search" className="w-auto px-3" />
            <input
              type="text"
              placeholder="Buscar"
              onChange={handleSearch}
              className="appearance-none block w-full p-1 font-poppins border-transparent rounded-md focus:outline-none sm:text-sm placeholder-gray-400 focus:ring-0 h-full border-0"
            />
          </div>
          <ProjectsFilterBy handler={handleFilter} filter={filter} />
        </div>
        <div className="hidden md:inline-flex items-center pl-4">
          <PrimaryButton
            type="button"
            size="lg"
            children="+ Crear proyecto"
            handler={() => handleModals('cproject')}
          />
        </div>
        <div className="inline-flex md:hidden items-center space-x-8 pl-4 min-w-max">
          <PrimaryButton
            type="button"
            size="sm"
            children="+ Crear proyecto"
            handler={() => handleModals('cproject')}
          />
        </div>
      </div>
      {children}
      {modal && modal === 'cproject' ? (
        <ModalCreateProject handleModal={handleModals} />
      ) : null}
      {modal && modal === 'delete' ? (
        <ModalConfirmDelete
          handleModals={handleModals}
          handleDelete={deleteProjects}
          multiple={projectsSelected.length > 1 ? true : false}
          children="proyecto"
        />
      ) : null}
      {modal && modal === 'inviteToProject' ? (
        <ModalInviteToProjectContainer
          handleModals={handleModals}
          project={project}
        />
      ) : null}
    </div>
  )
}

export default ProjectsLayout
