import { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { UploadPayment } from './UploadPayment'
// DB
import { uploadPayment } from '../../../services/db'
// RECOIL
import { useRecoilValue } from 'recoil'
import { userPaymentsState } from '../../../atoms'
// HELPERS
import { PdfToArrayBuffer } from '../../Utils/helpers'

const ModalUploadPayment = () => {
  const history = useHistory()
  const [file, setFile] = useState('')
  const paymentId = useParams().paymentId
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [paymentUpload, setPaymentUpload] = useState({})
  const userPayments = useRecoilValue(userPaymentsState)

  useEffect(() => {
    let payment = userPayments.find(
      (userPayment) => userPayment.id === paymentId
    )
    setPaymentUpload(payment)
    // eslint-disable-next-line
  }, [])

  const handlePdf = (file) => {
    return file ? setFile(file) : setFile(null)
  }

  const upload = async () => {
    setLoading(true)
    if (file.type === 'application/pdf') {
      let pdf = await PdfToArrayBuffer(file)
      await uploadPayment(pdf, paymentUpload)
      setLoading(false)
      setSuccess(true)
    } else {
      await uploadPayment(file, paymentUpload)
      setLoading(false)
      setSuccess(true)
    }
  }

  return (
    <UploadPayment
      file={file}
      upload={upload}
      history={history}
      loading={loading}
      success={success}
      handlePdf={handlePdf}
      paymentUpload={paymentUpload}
    />
  )
}

export default ModalUploadPayment
