export const FullPageLoaderComponent = () => (
  <div className="h-screen w-screen flex items-center justify-center bg-opacity-50">
    <div className="lds-ripple">
      <div></div>
      <div></div>
    </div>
  </div>
)

export const LoaderComponent = () => (
  <div className="h-full w-auto flex items-center justify-center bg-opacity-50">
    <div className="lds-ring lds-ring-big">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
)

export const LoaderSmall = () => (
  <div className="h-auto w-auto flex items-center justify-center bg-opacity-50 px-8">
    <div className="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
)