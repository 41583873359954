import { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
// RECOIL
import {
  sessionUserState,
  userPaymentsState,
  userProjectsState,
  paymentsLoadingState,
} from '../../../atoms'
import { useRecoilState, useRecoilValue } from 'recoil'
// UI
import Info from '../Images/Info.png'
import { PrimaryButton } from './Buttons'
import { LoaderComponent } from './Loaders'
import Calendar from '../Images/Calendar.png'
import NoProjects from '../Images/NoProjects.png'
import NoProjectsMobile from '../Images/NoProjectMobile.png'
import FullRigthArrow from '../Images/FullRigthArrow.png'
// HELPERS
import { parseDateFullMonth, toPesos } from '../helpers'
import { DateTime, Interval } from 'luxon'
import { getUserPaymentsByProject } from '../../../services/db'

export const AcceptProjectInviteCard = ({ projects, userId }) => {
  const history = useHistory()
  const [page, setPage] = useState(0)
  const [totalProjectAmount, setTotalProjectAmount] = useState()
  const [payments, setPayments] = useState([])

  const getUserProjectPayments = async (project, userId) => {
    console.log('project', project)
    console.log('userId', userId)
    const payments = await getUserPaymentsByProject(project.projectId, userId)
    setPayments(payments)
  }

  const getAmount = (payments) => {
    let tpa = 0
    payments.forEach((payment) => (tpa += Number(payment.amount)))
    let curr = payments[0].currency
    return curr + ' ' + tpa
  }

  useEffect(() => {
    console.log('projects', projects)
    console.log('page', page)
    if(projects.length > 0){
      getUserProjectPayments(projects[page], userId)
    }
  },[page])

  useEffect(() => {
    if(payments.length > 0) {
      setTotalProjectAmount(getAmount(payments))
    }
  },[payments])

  const projectInviteDetails = (project) => {
    return (
      <>
        <div className="space-y-2 sm:space-y-4 md:space-y-6 w-full">
          <p className="font-poppins w-full font-medium text-sm leading-5 text-green-500">
            ¡Nueva oferta de proyecto!
          </p>
          <p className="text-base sm:text-lg md:text-xl leading-10 font-normal font-poppins">
            {project.pname}
          </p>
          <p className="text-xl leading-10 font-normal font-poppins min-w-max">
            {totalProjectAmount}
          </p>
          <div className="flex flex-col lg:inline-flex lg:flex-row items-start space-y-2 sm:space-y-0 space-x-0 lg:space-x-2 w-full min-w-max">
            <div className="inline-flex space-x-2">
              <img src={Calendar} alt="to" />
              <span className="text-sm leading-4 font-medium text-gray-400 minw-max">
                {project.users.reduce((acc, user) => {
                  if (user.user === userId)
                    acc += parseDateFullMonth(user.startDate)
                  return acc
                }, '')}
              </span>
            </div>
            <div className="inline-flex space-x-2">
              <img src={FullRigthArrow} alt="to" />
              <span className="text-sm leading-4 font-medium text-gray-400 minw-max">
                {project.users.reduce((acc, user) => {
                  if (user.user === userId)
                    acc += parseDateFullMonth(user.endDate)
                  return acc
                }, '')}
              </span>
            </div>
          </div>
        </div>
        <div className="w-full inline-flex sm:justify-center md:justify-end mt-8">
          <div className="hidden sm:block max-w-max">
            <PrimaryButton
              type="button"
              size={window.innerWidth > 1048 ? 'xl' : 'md'}
              children="Firmar contrato"
              handler={(e) => history.push(`/user/invite/${project.id}`)}
            />
          </div>
          <div className="w-full sm:hidden">
            <PrimaryButton
              type="button"
              size="sm"
              children="Firmar contrato"
              handler={(e) => history.push(`/user/invite/${project.id}`)}
            />
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="flex flex-col border border-gray-300 rounded-md py-4 px-6 md:p-8 bg-white w-full h-full">
      {projects.length ? (
        <div className="space-y-4 sm:space-y-6 w-full">
          {projectInviteDetails(projects[page])}
          <div className="w-full inline-flex justify-around py-2">
            {projects.map((p, i) => (
              <button
                key={i}
                className={
                  (page === i ? 'ring-2 ' : '') +
                  'w-3 h-3 bg-purple rounded-full focus:outline-none focus:ring-2 ring-offset-2 ring-purple'
                }
                onClick={() => setPage(i)}
              ></button>
            ))}
          </div>
        </div>
      ) : (
        <div className="space-y-4 w-auto">
          <p className="text-sm min-w-max sm:min-w-min sm:text-base md:text-xl font-poppins text-gray-800">
            Ofertas de proyecto
          </p>
          <p className="text-sm hidden sm:block md:text-base font-poppins text-gray-500">
            Aún no tienes ofertas de proyecto. Espera a que Itesa te envíe una
            propuesta.
          </p>
          <p className="text-sm sm:hidden w-auto min-w-min md:text-base font-poppins text-gray-500">
            Aún no tienes ofertas de proyecto.
          </p>
          <img
            src={NoProjects}
            alt="Proyectos"
            className="hidden sm:block max-h-28 sm:max-h-32 md:max-h-40 w-auto mx-auto"
          />
          <img
            src={NoProjectsMobile}
            alt="Proyectos"
            className="sm:hidden max-h-28 max-w-min mx-auto"
          />
        </div>
      )}
    </div>
  )
}

export const UploadPaymentCard = () => {
  const history = useHistory()
  const [page, setPage] = useState(0)
  const [paymentsToUpload, setPaymentsToUpload] = useState([])
  const [paymentsLoading, setPaymentsLoading] =
    useRecoilState(paymentsLoadingState)
  const payments = useRecoilValue(userPaymentsState)
  const userProjects = useRecoilValue(userProjectsState)
  const [sessionUser, setSessionUser] = useRecoilState(sessionUserState)

  useEffect(
    () => {
      // eslint-disable-next-line
      let updatedPayments = []
      payments.forEach((payment) => {
        // eslint-disable-next-line
        if (payment.invoice) return
        let isValidPayment = true
        for (let inviteProject of userProjects.invites) {
          if (inviteProject.payments.includes(payment.id)) {
            isValidPayment = false
          }
        }
        if (isValidPayment) {
          let paymentDate = DateTime.fromSeconds(payment.date.seconds)
          let days = Interval.fromDateTimes(DateTime.now(), paymentDate)
          let remainingDays =
            days.length('days') > 0 ? Math.round(days.length('days')) : 0
          if (remainingDays <= 10 || !remainingDays) {
            payment = { ...payment, remainingDays: remainingDays }
            updatedPayments.push(payment)
            return payment
          }
        }
      })
      setPaymentsToUpload(updatedPayments)
      // setSessionUser({ ...sessionUser, paymentsToUpload: updatedPayments })
      return setPaymentsLoading(false)
    },
    // eslint-disable-next-line
    [userProjects]
  )

  const paymentDetails = (payment) => {
    return (
      <>
        <div className="space-y-2 sm:space-y-4 md:space-y-6 w-full sm:min-w-min md:min-w-max">
          <p className="font-poppins w-full font-medium text-sm leading-5 text-green-500 pr-8">
            Facturas pendientes
          </p>
          <p className="text-base md:text-lg lg:text-xl leading-10 font-normal font-poppins">
            {payment.project}
          </p>
          <p className="text-xl leading-10 font-normal font-poppins min-w-max text-center md:text-left">
            {(payment.currency)+' '+(payment.amount)}
          </p>
          <div
            className="
              flex flex-col items-center w-auto min-w-max space-x-0 space-y-2
              md:space-x-2 md:inline-flex md:flex-row md:space-y-0 md:min-w-full
            "
          >
            <div className="inline-flex justify-center md:justify-start items-center space-x-2 w-full">
              <img src={Calendar} alt="to" />
              <span className="text-sm leading-4 font-medium text-gray-400 min-w-max">
                {DateTime.fromSeconds(payment.date.seconds)
                  .setLocale('es')
                  .toLocaleString({
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric',
                  })}
              </span>
            </div>
            <span
              className={
                (payment.remainingDays > 0
                  ? 'text-gray-500 border-gray-300'
                  : 'text-red-500 border-red-500') +
                ' border px-2 py-1 rounded-full text-sm min-w-max'
              }
            >
              {payment.remainingDays > 0
                ? payment.remainingDays + ' días restantes'
                : 'Cargar factura'}
            </span>
          </div>
        </div>
        <div className="w-full inline-flex sm:justify-center md:justify-end mt-8">
          <div className="hidden sm:block max-w-max">
            <PrimaryButton
              type="button"
              size={window.innerWidth > 1048 ? 'xl' : 'md'}
              children="Subir factura"
              handler={(e) => history.push(`/user/upload/${payment.id}`)}
            />
          </div>
          <div className="w-full sm:hidden">
            <PrimaryButton
              type="button"
              size="sm"
              children="Subir factura"
              handler={(e) => history.push(`/user/upload/${payment.id}`)}
            />
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="relative flex flex-col border border-gray-300 rounded-md py-4 px-6 md:p-8 bg-white w-full h-full">
      {paymentsLoading ? (
        <LoaderComponent />
      ) : paymentsToUpload.length ? (
        <>
          <img
            src={Info}
            alt="Itesa Info"
            className="absolute right-6 cursor-pointer"
            onClick={() => history.push('/user/detalles-itesa')}
          />
          <div className="space-y-4 sm:space-y-6 w-full">
            {paymentDetails(paymentsToUpload[page])}
            <div className="w-full inline-flex justify-around py-2">
              {paymentsToUpload.map((p, i) => (
                <button
                  key={i}
                  className={
                    (page === i ? 'ring-2 ' : '') +
                    'w-3 h-3 bg-purple rounded-full focus:outline-none focus:ring-2 ring-offset-2 ring-purple'
                  }
                  onClick={() => setPage(i)}
                ></button>
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className="space-y-4 w-auto">
          <p className="text-sm min-w-max sm:min-w-min sm:text-base md:text-xl font-poppins text-gray-800">
            Facturas pendientes
          </p>
          <p className="text-sm w-auto min-w-min md:text-base font-poppins text-gray-500">
            No tienes facturas pendientes por cargar.
          </p>
          <img
            src={NoProjects}
            alt="Pagos"
            className="hidden sm:block max-h-28 sm:max-h-32 md:max-h-40 w-auto mx-auto"
          />
          <img
            src={NoProjectsMobile}
            alt="Pagos"
            className="sm:hidden max-h-28 max-w-min max-auto"
          />
        </div>
      )}
    </div>
  )
}
