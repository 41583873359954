import React, { useEffect, useState, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
// DB
import { getUser, removeUser } from '../../../services/db'
// RECOIL
import { useRecoilValue } from 'recoil'
import { projectsState } from '../../../atoms'
// COMPONENTS
import SingleUser from './SingleUser'

const SingleUserContainer = () => {
  const { userId } = useParams()
  const history = useHistory()
  const [user, setUser] = useState()
  const [modal, setModal] = useState('')
  const projects = useRecoilValue(projectsState)

  const fetchUser = useCallback(async () => {
    let userFound = (await getUser(userId)).user
    !userFound ? history.goBack() : setUser(userFound)
  }, [userId, history])

  const handleModals = async (option) =>
    option ? setModal(option) : setModal('')

  // eslint-disable-next-line
  const deleteUser = async () => await removeUser(userId)

  const userPayments = () => {
    let userPayments = []
    for (const key in user.payments) {
      userPayments = [...userPayments, ...user.payments[key]]
    }
    return userPayments
  }

  useEffect(() => fetchUser(), [modal, fetchUser])

  return <SingleUser
    user={user}
    modal={modal}
    userId={userId}
    setModal={setModal}
    projects={projects}
    deleteUser={deleteUser}
    userPayments={userPayments}
    handleModals={handleModals}
  />
}

export default SingleUserContainer
