import React, { useState } from 'react'
// RECOIL
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { projectsLoadingState, usersState } from '../../../../atoms/index'
// DB
import { newProject, updateProjectId } from '../../../../services/db'
// COMPONENTS
import ModalLayout from '../../UI/ModalLayout'
import ModalProjectDetails from './ModalProjectDetails.jsx'
import ModalAssignUser from './ModalAssignUser'

const ModalCProjectContainer = ({ handleModal }) => {
  const users = useRecoilValue(usersState)
  const [error, setError] = useState(false)
  const [step, setStep] = useState('details')
  const [loading, setLoading] = useState(false)
  const setProjectsLoading = useSetRecoilState(projectsLoadingState)
  const [usersToAssign, setUsersToAssign] = useState([])
  const [errorUsersProject, setErrorUsersProject] = useState(false)
  const [formData, setFormData] = useState({
    pname: '',
    description: '',
    projectId: '',
    pImage: '',
    startDate: new Date(),
  })

  const handleValue = async (e) => {
    let name = e.target.name
    let value = e.target.value
    setFormData({ ...formData, [name]: value })
  }

  const handleFile = async (file, key) => {
    setFormData({ ...formData, [key]: file })
  }

  const handleDates = (date, name) => {
    setFormData({
      ...formData,
      [name]: date == null ? '' : date,
    })
  }

  const confirmDetails = (create = false) => {
    let errors = 0
    for (const key in formData) {
      if (!formData[key]) errors++
    }
    if (errors > 0) {
      setError(true)
      return false
    } else {
      setError(false)
      if (!create) setStep('inviteUsers')
      return true
    }
  }

  const handleUserOption = (e, index, option) => {
    if (!option) return
    if (usersToAssign[index]) {
      usersToAssign.splice(index, 1)
      if (usersToAssign.length === 0) setErrorUsersProject(false)
      setUsersToAssign([...usersToAssign])
    } else {
      setUsersToAssign([...usersToAssign, { user: option }])
    }
  }

  const checkPayments = (payments) => {
    let errors = 0
    if (payments.length === 0) {
      errors++
    } else {
      payments.forEach((payment) => {
        if (!payment.amount) {
          errors++
        } else if (!payment.date) {
          errors++
        }
      })
    }
    return errors
  }

  const validateAssignments = () => {
    if (usersToAssign.length) {
      let errors = 0
      usersToAssign.forEach((user) => {
        if (Object.keys(user).length === 1) {
          errors++
        } else if (user.payments) {
          let invalid = checkPayments(user.payments)
          return invalid > 0 ? errors++ : null
        } else if (!user.startDate) {
          errors++
        } else if (!user.endDate) {
          errors++
        }
      })
      if (errors > 0) {
        setErrorUsersProject(true)
      } else {
        setErrorUsersProject(false)
        setFormData({ ...formData, users: usersToAssign })
        return createProject()
      }
    } else {
      setErrorUsersProject(false)
      setFormData({ ...formData, users: usersToAssign })
      return createProject()
    }
  }

  const createProject = async () => {
    let allowCreate = confirmDetails(true)
    if (allowCreate) {
      setLoading(true)
      setProjectsLoading(true)
      await newProject(formData, usersToAssign)
      updateProjectId()
      setStep('success')
      setProjectsLoading(false)
      setLoading(false)
    }
  }

  return (
    <ModalLayout
      handler={(e) => handleModal(e, 'cproject')}
      modal="cproject"
      extended={step === 'inviteUsers' ? true : false}
      regular={step === 'success'}
      goBackText="Volver"
      title={
        (loading && 'Creando proyecto') ||
        (step === 'details' && 'Crear proyecto') ||
        (step === 'inviteUsers' && 'Asignar perfiles') ||
        (step === 'success' && 'Proyecto creado')
      }
    >
      <div className="relative w-full h-full z-40">
        {step === 'details' ? (
          <ModalProjectDetails
            formData={formData}
            setFormData={setFormData}
            error={error}
            handleFile={handleFile}
            handleValue={handleValue}
            handleDates={handleDates}
            confirmDetails={confirmDetails}
            createProject={createProject}
          />
        ) : null}
        {step === 'inviteUsers' ? (
          <ModalAssignUser
            loading={loading}
            startDate={formData.startDate}
            usersToAssign={usersToAssign}
            handleUserOption={handleUserOption}
            setUsersToAssign={setUsersToAssign}
            validateAssignments={validateAssignments}
            errorUsersProject={errorUsersProject}
            users={users.filter((user) => {
              let includes
              usersToAssign.forEach((userToAssign) =>
                userToAssign.user === user.id ? (includes = userToAssign) : null
              )
              return includes ? false : true
            })}
          />
        ) : null}
        {step === 'success' ? (
          <div className="w-full flex flex-col items-center">
            <p className="text-base md:text-lg sm:dm-h3 w-full text-center">
              El proyecto ha sido creado. Los usuarios recibiran la invitación
              en su email.
            </p>
          </div>
        ) : null}
      </div>
    </ModalLayout>
  )
}

export default ModalCProjectContainer
