import ReactSlider from 'react-slider'
import { toPesos } from '../../Utils/helpers'
import { Input } from '../../Utils/UI/Inputs'
import ModalLayout from '../../Utils/UI/ModalLayout'
import { PrimaryButton, SecondaryButton } from '../../Utils/UI/Buttons'
import { LoaderComponent } from '../../Utils/UI/Loaders'
import InviteSent from '../../Utils/Images/InviteSent.png'
import RightArrow from '../../Utils/Images/FullRigthArrow.png'
import ProjectContractPDF from '../../Utils/PDFS/ProjectContract'
import GoBackContract from '../../Utils/Images/GoBackContract.png'
import { PDFDownloadLink } from '@react-pdf/renderer'

const AcceptProjectInvite = ({
  pd,
  bd,
  dates,
  budget,
  pdfFile,
  history,
  success,
  position,
  showData,
  uploading,
  setSuccess,
  setShowData,
  setPosition,
  sessionUser,
  handleSubmit,
  userPassword,
  inputPassword,
  errorPassword,
  handlePassword,
  projectToAccept,
  setInputPassword,
}) => {

  console.log('pd', pd)
  console.log('bd', bd)
  return (
    <div className="absolute">
      <div className="fixed inset-0 w-screen sm:min-h-screen h-auto sm:h-full flex flex-col sm:flex-row bg-white overflow-y-auto">
        <div className="h-full max-h-36 sm:max-h-full sm:h-full w-full sm:w-1/3 bg-white flex flex-col overflow-y-auto overflow-x-hidden">
          <div className="flex flex-col sm:flex-row sm:space-x-6 items-start sm:items-center mx-4 sm:mx-8 py-4">
            <div className="inline-flex items-center space-x-2">
              <img
                src={GoBackContract}
                alt="GoBack"
                className="h-3 sm:h-6 cursor-pointer"
                onClick={() => history.push('/user/home')}
              />
              <p
                className="text-sm sm:hidden cursor-pointer"
                onClick={() => history.push('/user/home')}
              >
                Home
              </p>
            </div>
            <p className="dm-h3">Contrato</p>
          </div>
          <div className="flex flex-col h-auto sm:h-auto mx-4 sm:mx-8">
            <div className="sm:hidden inline-flex w-full space-x-4 pb-4 h-full">
              <SecondaryButton
                type="button"
                size="sm"
                children="Revisar datos"
                handler={() => setShowData(!showData)}
              />

              <SecondaryButton
                type="button"
                size="sm"
                children={
                  <PDFDownloadLink
                    document={pdfFile}
                    fileName={
                      'Contract_' +
                      projectToAccept.pname +
                      '_' +
                      sessionUser.personalData.fullName
                    }
                  >
                    Descargar
                  </PDFDownloadLink>
                }
              />
            </div>
            <p className="hidden sm:block text-sm font-poppins text-gray-700">
              Revisá el contrato, que los datos sean los correctos y firmá en el
              recuadro para aceptar el proyecto.
            </p>
            {/* Review data for contract */}
            <div className="hidden sm:block w-full h-auto border border-gray-300 rounded-md p-4 my-4 space-y-2">
              <div>
                <p className="text-sm font-poppins text-gray-500 font-medium">
                  Nombre completo:
                  <span className="text-base font-poppins text-black mx-2">
                    {pd.fullName}
                  </span>
                </p>
              </div>
              <div>
                <p className="text-sm font-poppins text-gray-500 font-medium">
                  Domicilio:
                  <span className="text-base font-poppins text-black mx-2">
                    {pd.address}
                  </span>
                </p>
              </div>
              <div>
                <p className="text-sm font-poppins text-gray-500 font-medium">
                  Número de documento:
                  <span className="text-base font-poppins text-black mx-2">
                    {pd.cuit.substring(3, 11)}
                  </span>
                </p>
              </div>
              <div>
                <p className="text-sm font-poppins text-gray-500 font-medium">
                  Tarea a desarrollar:
                  <span className="text-base font-poppins text-black mx-2">
                    {pd.role}
                  </span>
                </p>
              </div>
              <div>
                <p className="text-sm font-poppins text-gray-500 font-medium">
                  Fecha:
                  <span className="text-base font-poppins text-black mx-2">
                    {dates.startDate} - {dates.endDate}
                  </span>
                </p>
              </div>
              <div>
                <p className="text-sm font-poppins text-gray-500 font-medium">
                  Presupuesto:
                  <span className="text-base font-poppins text-black mx-2">
                    {(budget)}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="hidden sm:flex mt-8 sm:mt-0 relative w-full flex-col items-center mx-auto h-auto space-y-2">
            {!inputPassword ? (
              <>
                <p className="text-sm font-poppins text-gray-500 font-medium w-full min-w-max text-center">
                  Deslizá para firmar el documento
                </p>
                <div className="flex flex-col items-center w-1/2 space-y-4">
                  <ReactSlider
                    defaultValue={0}
                    min={0}
                    max={230}
                    className="w-full h-8 rounded-full border border-gray-300 bg-green-300 flex items-center"
                    thumbClassName="w-auto h-8 px-4 rounded-full border border-gray-300 bg-white flex items-center focus:outline-none"
                    trackClassName="w-auto h-8 rounded-full"
                    onChange={(val) => {
                      setPosition(val)
                      val === 230
                        ? setInputPassword(true)
                        : setInputPassword(false)
                    }}
                    renderThumb={(props, state) => (
                      <div {...props}>
                        <img src={RightArrow} alt="SL" />
                      </div>
                    )}
                  />
                </div>
              </>
            ) : null}
            {/* SIGNATURE */}
            <div className="relative w-1/2 flex flex-col items-center justify-evenly py-2">
              {/* WHITE COVER IMAGE FOR SIGNATURE */}
              <div
                className={
                  (position === 230 ? 'hidden' : 'block') +
                  ' absolute h-full w-full bg-white'
                }
                style={{ left: position }}
              ></div>
              <img
                src={sessionUser.signature}
                alt="Signature"
                className="w-auto h-18 border border-gray-300 p-4 rounded-md"
              />
            </div>
            {inputPassword ? (
              <>
                <div className="text-center mx-auto px-4 py-2">
                  <Input
                    type="password"
                    label="Ingresá tu contraseña para dar tu consentimiento"
                    name="password"
                    value={userPassword}
                    handler={handlePassword}
                  />
                  {errorPassword.invalid ? (
                    <p className="text-sm font-poppins text-red-500 font-medium w-full pt-2">
                      {errorPassword.message}
                    </p>
                  ) : null}
                </div>
                <div className="mx-auto my-2">
                  {uploading ? (
                    <div className="pb-10">
                      <LoaderComponent />
                    </div>
                  ) : (
                    <PrimaryButton
                      type="button"
                      size="lg"
                      children="Aceptar y firmar"
                      handler={handleSubmit}
                    />
                  )}
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div className="h-full sm:h-full min-w-full sm:min-w-max w-full sm:w-2/3 bg-gray-100">
          <ProjectContractPDF
            viewer={true}
            project={projectToAccept}
            totalAmount={(budget)}
            userPd={pd}
            userBd={bd}
            userId={sessionUser.id}
            signature={inputPassword ? sessionUser.signature : null}
          />
        </div>
        {/* Signature and input password for mobile users */}
        <div className="flex sm:hidden mt-4 relative h-full w-full flex-col items-center mx-auto space-y-2">
          <div className="relative w-1/2 flex flex-col items-center justify-evenly">
            {/* WHITE COVER IMAGE FOR SIGNATURE */}
            <div
              className={
                (position === 230 ? 'hidden' : 'block') +
                ' absolute h-full w-full bg-white'
              }
              style={{ left: position }}
            ></div>
            <img
              src={sessionUser.signature}
              alt="Signature"
              className="w-auto h-24 border border-gray-300 p-4 rounded-md"
            />
          </div>
          {!inputPassword ? (
            <>
              <p className="text-sm font-poppins text-gray-500 font-medium w-full min-w-max text-center">
                Deslizá para firmar el documento
              </p>
              <div className="flex flex-col items-center w-1/2 space-y-2">
                <ReactSlider
                  defaultValue={0}
                  min={0}
                  max={230}
                  className="w-full h-8 rounded-full border border-gray-300 bg-green-300 flex items-center"
                  thumbClassName="w-auto h-8 px-4 rounded-full border border-gray-300 bg-white flex items-center focus:outline-none"
                  trackClassName="w-auto h-8 rounded-full"
                  onChange={(val) => {
                    setPosition(val)
                    val === 230 ? setInputPassword(true) : setInputPassword(false)
                  }}
                  renderThumb={(props, state) => (
                    <div {...props}>
                      <img src={RightArrow} alt="SL" />
                    </div>
                  )}
                />
              </div>
            </>
          ) : (
            <form className="mx-auto px-4 py-2">
              <div className="w-full flex flex-col items-center">
                <label
                  htmlFor="password"
                  className={
                    (errorPassword.invalid && !userPassword
                      ? 'text-red-500'
                      : 'text-gray-700') +
                    ' text-center text-sm font-medium font-poppins -mx-4'
                  }
                >
                  Ingresa tu contraseña para dar tu consentimiento
                </label>
                <div className="mt-2 w-2/3">
                  <input
                    name="password"
                    type="password"
                    autoComplete="password"
                    value={userPassword}
                    onChange={handlePassword}
                    className={
                      (errorPassword.invalid && !userPassword
                        ? 'border-red-600 placeholder-red-500 focus:ring-red-600 focus:border-red-600'
                        : 'border-gray-300 placeholder-gray-400 focus:ring-purple focus:border-purple') +
                      ' appearance-none block w-full px-3 py-1 border rounded-md sm:placeholder-transparent sm:bg-transparent font-poppins focus:outline-none bg-gray-50 text-sm'
                    }
                  />
                </div>
                {errorPassword.invalid ? (
                  <p className="text-sm text-center font-poppins text-red-500 font-medium w-full pt-2">
                    {errorPassword.message}
                  </p>
                ) : null}
              </div>
              <div className="mx-auto my-2 w-2/3">
                {uploading ? (
                  <div className="pb-10">
                    <LoaderComponent />
                  </div>
                ) : (
                  <PrimaryButton
                    type="button"
                    size={window.innerWidth > 640 ? 'lg' : 'sm'}
                    children="Aceptar y firmar"
                    handler={handleSubmit}
                  />
                )}
              </div>
            </form>
          )}
        </div>
      </div>
      {success || showData ? (
        <ModalLayout
          regular={success ? true : false}
          modal={success ? 'successInvitation' : 'reviewDataContract'}
          title={showData ? 'Revisar datos' : '¡Contrato firmado!'}
          goBackText={'Home'}
          handler={
            success
              ? (e) => {
                  setSuccess(false)
                  history.push('/user/home')
                }
              : () => setShowData(!showData)
          }
        >
          {success ? (
            <div className="w-full flex flex-col items-center space-y-8">
              <img src={InviteSent} alt="Signed!" />
              <p className="text-base text-center font-poppins text-gray-500">
                Recibirás un mail con la copia del contrato firmado.
              </p>
            </div>
          ) : (
            <>
              <div className="flex flex-col w-full h-full rounded-md space-y-6">
                <div className="space-y-2">
                  <p className="text-sm font-poppins text-gray-500 font-medium">
                    Nombre completo:
                  </p>
                  <span className="text-base font-poppins text-black">
                    {pd.fullName}
                  </span>
                </div>
                <div className="space-y-2">
                  <p className="text-sm font-poppins text-gray-500 font-medium">
                    Domicilio:
                  </p>
                  <span className="text-base font-poppins text-black">
                    {pd.address}
                  </span>
                </div>
                <div className="space-y-2">
                  <p className="text-sm font-poppins text-gray-500 font-medium">
                    Número de documento:
                  </p>
                  <span className="text-base font-poppins text-black">
                    {bd.cuit.substring(3, 11)}
                  </span>
                </div>
                <div className="space-y-2">
                  <p className="text-sm font-poppins text-gray-500 font-medium">
                    Tarea a desarrollar:
                  </p>
                  <span className="text-base font-poppins text-black">
                    {pd.role}
                  </span>
                </div>
                <div className="space-y-2">
                  <p className="text-sm font-poppins text-gray-500 font-medium">
                    Fecha:
                  </p>
                  <span className="text-base font-poppins text-black">
                    {dates.startDate} - {dates.endDate}
                  </span>
                </div>
                <div className="space-y-2">
                  <p className="text-sm font-poppins text-gray-500 font-medium">
                    Presupuesto:
                  </p>
                  <span className="text-base font-poppins text-black">
                    {(budget)}
                  </span>
                </div>
              </div>
            </>
          )}
        </ModalLayout>
      ) : null}
    </div>
  )
}

export default AcceptProjectInvite
