import React from 'react'
// UI
import {
  PaymentsFilterBy,
  // UserSinglePaymentDropdown,
} from '../../Utils/UI/Selects'
// IMAGES
import ArrowControlLeft from '../../Utils/Images/ArrowControlLeft.png'
import ArrowControlRight from '../../Utils/Images/ArrowControlRight.png'
import ArrowDownViolet from '../../Utils/Images/ArrowDownViolet.png'
import Search from '../../Utils/Images/Search.png'
import ViewInvoice from '../../Utils/Images/ViewInvoice.png'
// HELPERS
import { parseDate, toPesos } from '../../Utils/helpers.js'
import { Fragment } from 'react'
import { Dropdown } from '../../Utils/UI/Items'

const PaymentsTable = ({
  slice,
  filter,
  payments,
  handleSort,
  handleFilter,
  slicePayments,
  paymentsLength,
  setViewPayments,
  handleSearchInput,
  SinglePaymentDropdown,
}) => {
  console.log('PaymentsTable',payments)
  return (
    <div className="w-full rounded-md bg-white sm:pt-8 border border-gray-300 px-4 sm:px-6">
      {/* PAYMENTS TABLE ACTIONS INPUT */}
      <div className="inline-flex space-x-8 items-center justify-between w-full pt-2 sm:pb-4 md:border-b border-gray-300">
        <div className="inline-flex space-x-8 items-center max-w-max">
          <h3 className="text-sm md:text-base text-gray-800"> Ultimos pagos</h3>
          <div
            id="search"
            className="hidden md:inline-flex rounded-md items-center h-8 bg-white border border-gray-300"
          >
            <img src={Search} alt="Search" className="w-auto px-3" />
            <input
              type="text"
              placeholder="Buscar"
              onChange={handleSearchInput}
              className="appearance-none block w-full p-1 font-poppins border-transparent rounded-md focus:outline-none sm:text-sm placeholder-gray-400 focus:ring-0 h-full border-0"
            />
          </div>
          <div className="hidden md:block">
            <PaymentsFilterBy handler={handleFilter} filter={filter} />
          </div>
        </div>
        {/* PAGINATION */}
        <div className="hidden md:inline-flex items-center space-x-4">
          <p className="text-gray-800 font-poppins text-sm">
            {slice.start + 1}-{slice.end} de {paymentsLength}
          </p>
          <button
            onClick={() => slicePayments('prev')}
            type="button"
            className="h-full p-2 focus:outline-none"
          >
            <img src={ArrowControlLeft} alt="Previous" />
          </button>
          <button
            onClick={() => slicePayments('next')}
            type="button"
            className="h-full p-2 focus:outline-none"
          >
            <img src={ArrowControlRight} alt="Previous" />
          </button>
        </div>
      </div>
      {/* TABLE */}
      <table className="w-full">
        <thead>
          <tr className="hidden md:table-row">
            <th scope="col" className="px-2 py-6">
              <button
                onClick={(e) => handleSort(e, 'amount')}
                className="w-full inline-flex items-center space-x-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
              >
                <span>Pago</span>
                <img src={ArrowDownViolet} alt="Sort" />
              </button>
            </th>
            <th scope="col" className="px-2 py-6">
              <button
                onClick={(e) => handleSort(e, 'project')}
                className="w-full inline-flex items-center space-x-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
              >
                <span>Proyecto</span>
                <img src={ArrowDownViolet} alt="Sort" />
              </button>
            </th>
            <th scope="col" className="px-2 py-6">
              <button
                onClick={(e) => handleSort(e, 'paymentNumber')}
                className="w-full inline-flex items-center space-x-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
              >
                <span>Factura</span>
                <img src={ArrowDownViolet} alt="Sort" />
              </button>
            </th>
            <th scope="col" className="px-2 py-6">
              <button
                onClick={(e) => handleSort(e, 'date')}
                className="w-full inline-flex items-center space-x-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
              >
                <span className="hidden lg:block">Fecha de pago</span>
                <span className="lg:hidden">Fecha</span>
                <img src={ArrowDownViolet} alt="Sort" />
              </button>
            </th>
            <th scope="col" className="px-2 py-6">
              <button
                onClick={(e) => handleSort(e, 'status')}
                className="w-full inline-flex items-center space-x-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none cursor-pointer"
              >
                <span>Estado</span>
                <img src={ArrowDownViolet} alt="Sort" />
              </button>
            </th>
            <th scope="col" className="px-2 py-6">
              <div className="w-full inline-flex items-center space-x-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none cursor-default">
                <span>Comprobante</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white sm:divide-y divide-gray-200 w-full">
          {payments?.length ? (
            payments.map((payment, i) => {
              let options = [{ value: 'details', label: 'Ver detalles' }]
              if (payment.invoice)
                options = [
                  ...options,
                  {
                    value: 'invoice',
                    label: 'Descargar factura',
                  },
                ]
              if (payment.paymentSettlement)
                options = [
                  ...options,
                  {
                    value: 'paymentSettlement',
                    label: 'Descargar comprobante de pago',
                  },
                ]
              return (
                <Fragment key={i}>
                  {/* DESKTOP PAYMENTS TABLE */}
                  <tr className="hidden md:table-row">
                    <td className="px-2 py-2 font-poppins">
                      <div className="flex items-center">
                        {(payment.currency)+' '+(payment.amount)}
                      </div>
                    </td>
                    <td className="px-2 py-2 text-sm text-gray-800 font-poppins">
                      {payment.project && payment.project.id
                        ? payment.project.id
                        : null}
                    </td>
                    <td className="px-2 py-2 text-sm text-gray-800 font-poppins">
                      {payment.paymentNumber}
                      {payment.bono && ' Bono'}
                    </td>
                    <td className="px-2 py-2 text-sm text-gray-800 font-poppins">
                      {parseDate(payment.date)}
                    </td>
                    <td className="px-2 py-2 text-sm text-gray-800 font-poppins">
                      <span className={'px-4 py-1 rounded-full bg-gray-100'}>
                        {payment.status}
                      </span>
                    </td>
                    <td className="px-2 py-2 text-sm text-gray-800 font-poppins">
                      {payment.paymentSettlement ? (
                        <div className="w-full px-10">
                          <a href={payment.paymentSettlement} rel="noreferrer" target="_blank">
                            <img
                              src={ViewInvoice}
                              alt="Ver factura"
                              className="w-6 h-6"
                            />
                          </a>
                        </div>
                      ) : null}
                    </td>
                  </tr>
                  {/* MOBILE PAYMENTS TABLE */}
                  <tr className="table-row md:hidden w-full min-w-max border-b border-gray-300">
                    <td className="w-full p-0 h-auto">
                      <div className="w-full inline-flex justify-between items-center px-2">
                        <div className="w-full space-x-2 flex items-center">
                          <div className="relative h-14 w-14 my-2">
                            <img
                              src={
                                payment.project && payment.project.pImage
                                  ? payment.project.pImage
                                  : null
                              }
                              alt="USER"
                              className="rounded-full h-full w-full object-center object-cover border border-gray-300"
                            />
                          </div>
                          <div className="table-cell max-w-max text-sm text-gray-800 font-poppins">
                            <Dropdown
                              options={options}
                              handler={(value) =>
                                SinglePaymentDropdown(value, payment)
                              }
                              button={
                                <div className="flex flex-col items-start space-y-1">
                                  <span>
                                    {payment.project && payment.project.pname
                                      ? payment.project.pname
                                      : null}
                                  </span>
                                  <span className="text-gray-500">
                                    {parseDate(payment.date)}
                                  </span>
                                </div>
                              }
                            />
                          </div>
                        </div>
                        <div className="max-w-max text-sm text-gray-800 font-poppins">
                          <div className="flex flex-col items-end space-y-1">
                            <span>{toPesos(payment.amount)}</span>
                            <span className="px-2 py-1 rounded-full bg-gray-100 border border-gray-300 max-w-min">
                              {payment.status}
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </Fragment>
              )
            })
          ) : (
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td className="hidden md:block text-center px-2 py-6 text-sm text-gray-500 font-poppins w-max">
                No se encontraron pagos
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          )}
        </tbody>
      </table>
      <h3
        className="block md:hidden text-sm text-purple py-4 cursor-pointer"
        onClick={() => (payments.length ? setViewPayments(true) : null)}
      >
        {payments?.length ? 'Ver todos los pagos' : 'No se encontraron pagos'}
      </h3>
    </div>
  )
}

export default PaymentsTable
