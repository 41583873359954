import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../services/firebase-auth-context'
import { signIn, recoverPassword } from '../../services/auth'
import Login from './Login'

const { REACT_APP_ADMIN_UID } = process.env

const LoginContainer = () => {
  const [credentials, setCredentials] = useState({ email: '', password: '' })
  const [recoveryEmail, setRecoveryEmail] = useState('')
  const [resetPassword, setResetPassword] = useState(false)
  const [success, setSucces] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const sessionUser = useAuth()

  const handleInputs = (e) => {
    e.preventDefault()
    setError({ error: false, message: '' })
    let obj = { [e.target.name]: e.target.value }
    setCredentials({ ...credentials, ...obj })
  }

  const validateForm = useCallback(() => {
    credentials.email.length > 1 && credentials.email.includes('@')
      ? setError({ error: false, message: '' })
      : setError({ error: true, message: 'Ingrese un email válido' })
    credentials.password.length < 6
      ? setError({
          error: true,
          message: 'La contraseña debe tener al menos 6 caracteres',
        })
      : setError({ error: false, message: '' })
  }, [credentials])

  const validateRecoveryEmail = () => {
    console.log('🚀 ➡️ recoveryEmail', recoveryEmail)
    recoveryEmail.length > 1 && recoveryEmail.includes('@')
      ? setError({ error: false, message: '' })
      : setError({ error: true, message: 'Ingrese un email válido' })
  }

  const handleLogIn = async (e) => {
    setLoading(true)
    e.preventDefault()
    validateForm()
    if (!error.error) {
      try {
        await signIn(credentials.email, credentials.password)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        setError({
          error: true,
          message:
            'Los datos ingresados no corresponden a un usuario registrado. Intente nuevamente.',
        })
      }
    }
  }

  const handleRecoveryEmail = (e) => {
    setError({ error: false, message: '' })
    let value = e.target.value
    setRecoveryEmail(value)
  }

  const sendRequestRecovery = async () => {
    validateRecoveryEmail()
    if (!error.error) {
      setSucces(true)
      return recoverPassword(recoveryEmail)
    }
  }

  useEffect(() => {
    if (sessionUser) {
      if (sessionUser.uid === REACT_APP_ADMIN_UID) {
        console.log('Admin')
        return history.push('/admin/home')
      } else {
        console.log('User')
        return history.push('/user/home')
      }
    }
  }, [sessionUser])

  useEffect(() => {
    setError({ error: false, message: '' })
    setSucces(false)
  }, [resetPassword])

  return (
    <Login
      error={error}
      loading={loading}
      success={success}
      credentials={credentials}
      handleLogIn={handleLogIn}
      handleInputs={handleInputs}
      resetPassword={resetPassword}
      setResetPassword={setResetPassword}
      recoveryEmail={recoveryEmail}
      handleRecoveryEmail={handleRecoveryEmail}
      sendRequestRecovery={sendRequestRecovery}
    />
  )
}

export default LoginContainer
