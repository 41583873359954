const SVGList = () => (
	<svg width='16' height='15' viewBox='0 0 18 16' fill='none' className='h-full'>
		<path
			d='M18 4H0V0H18V4ZM18 6H0V10H18V6ZM18 12H0V16H18V12Z'
			fill='currentColor'
		/>
	</svg>
)

export default SVGList
