import { storage } from './firebase'

/* 
COMO CREAR REFERENCIAS DE NIVEL MAS ESPECIFICO
// Create a child reference
var imagesRef = storageRef.child('images');
// imagesRef now points to 'images'

// Child references can also take paths delimited by '/'
var spaceRef = storageRef.child('images/space.jpg');
// spaceRef now points to "images/space.jpg"
// imagesRef still points to "images"
*/

let storageRef = storage.ref()
let confidentialityContractsRef = storageRef.child('confidentiality-contracts')
let projectContractsRef = storageRef.child('project-contracts')
let paymentsInvoicesRef = storageRef.child('payments-invoices')
let paymentsSettlementRef = storageRef.child('payments-settlements')
let userResumesRef = storageRef.child('user-resumes')
let profileImagesRef = storageRef.child('profile-images')
let projectImagesRef = storageRef.child('project-images')

export const uploadCcontract = async (fileName, blob) => {
  let newCContractRef = confidentialityContractsRef.child(fileName)
  await newCContractRef.put(blob)
  return await newCContractRef.getDownloadURL()
}

export const uploadPcontract = async (fileName, projectId, blob) => {
  let newUploadRef = projectContractsRef.child(`${projectId}/${fileName}`)
  await newUploadRef.put(blob)
  return await newUploadRef.getDownloadURL()
}

export const uploadPaymentInvoice = async (fileData, paymentId) => {
  let newUploadRef = paymentsInvoicesRef.child(`${paymentId}.pdf`)
  await newUploadRef.put(fileData)
  return await newUploadRef.getDownloadURL()
}

export const uploadPaymentSettlement = async (fileData, paymentId) => {
  let newUploadRef = fileData.type
    ? paymentsSettlementRef.child(`${paymentId}`)
    : paymentsSettlementRef.child(`${paymentId}.pdf`)
  await newUploadRef.put(fileData)
  return await newUploadRef.getDownloadURL()
}

export const uploadUserResume = async (fileData, userId) => {
  let newUploadRef = userResumesRef.child(`${userId}.pdf`)
  await newUploadRef.put(fileData)
  return await newUploadRef.getDownloadURL()
}

export const uploadUserProfileImage = async (file, userId) => {
  let newUploadRef = await profileImagesRef.child(`${userId}-${file.name}`)
  await newUploadRef.put(file)
  return await newUploadRef.getDownloadURL()
}

export const uploadProjectImage = async (file, projectId) => {
  let newUploadRef = await projectImagesRef.child(`${projectId}-${file.name}`)
  await newUploadRef.put(file)
  return await newUploadRef.getDownloadURL()
}

export const listOfPaymentInvoices = async (paymentList) => {
  let filesForZip = await paymentList.map(
    async (payment) => await (await fetch(payment.url)).blob()
  )
  return Promise.all(filesForZip).then((values) => values)
}
