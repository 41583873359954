import React, { useState } from 'react'
// COMPONENTS
import ModalInviteContainer from '../../Utils/Modals/ModalInvite'
import ModalBankData from '../../Utils/Modals/ModalBankData'
import ModalContactData from '../../Utils/Modals/ModalContactDetails'
import ModalConfirmDelete from '../../Utils/Modals/ModalConfirmDelete'
import ModalViewPaymentsContainer from '../../Utils/Modals/ModalViewPayments'
// UI
import { PrimaryButton } from '../../Utils/UI/Buttons'
import {
  CommunityFilterAvailability,
  CommunityFilterBy,
  CommunityFilterBySkill,
} from '../../Utils/UI/Selects'
import { Transition } from '@headlessui/react'
// IMAGES
import Search from '../../Utils/Images/Search.png'

import SVGGrid from '../../Utils/SVGS/SVG-Grid'
import SVGList from '../../Utils/SVGS/SVG-List'
import GoBack from '../../Utils/Images/GoBack.png'
import SearchBlack from '../../Utils/Images/SearchBlack.png'
import Grid from '../../Utils/Images/GridBlack.png'
import ListBlack from '../../Utils/Images/ListBlack.png'
import Add from '../../Utils/Images/Add.png'
import { DropdownWithChips } from '../../Utils/UI/Items'
import Filter from '../../Utils/Images/Filter.png'

const CommunityLayout = ({
  users,
  modal,
  filter,
  children,
  listView,
  toggleView,
  deleteUsers,
  handleModals,
  handleFilter,
  handleSearch,
  selectedUser,
  skillsFilters,
  usersSelected,
  handleSkillFilter,
  availabilitySelected,
  selectedUserPayments,
}) => {
  const [skillFilter, setSkillFilter] = useState(false)
  const [searchBar, setSearchBar] = useState(false)
  return (
    <div className="flex flex-col w-full h-full space-y-0 md:space-y-4 py-0 md:py-2 overflow-hidden">
      {/* TABLE HEADER ACTIONS */}
      <div className="block sm:hidden">
        <div
          className={
            (searchBar ? 'hidden' : '') +
            ' inline-flex -mt-11 md:hidden absolute space-x-6 w-full justify-end'
          }
        >
          <button onClick={() => setSearchBar(!searchBar)}>
            <img src={SearchBlack} alt="Search" />
          </button>
          <button onClick={() => toggleView()}>
            <img src={listView ? ListBlack : Grid} alt="ListView" />
          </button>
          <button onClick={() => handleModals('invite')}>
            <img src={Add} alt="Add" />
          </button>
        </div>
        <div
          className={
            (searchBar ? '' : 'hidden') +
            ' absolute inline-flex space-x-8 justify-evenly -mt-14 bg-white'
          }
        >
          <div className="w-full rounded-md inline-flex items-center h-8 bg-gray-100 border border-gray-300">
            <img
              src={GoBack}
              alt="GoBack"
              onClick={() => setSearchBar(!searchBar)}
              className="h-auto min-w-max px-3"
            />
            <input
              type="text"
              onChange={handleSearch}
              className="appearance-none block w-full p-1 font-poppins border-transparent rounded-md focus:outline-none sm:text-sm bg-gray-100 focus:ring-0 h-full border-0"
            />
            <DropdownWithChips
              optionsSets={[
                {
                  title: 'Estado',
                  handler: handleFilter,
                  options: [
                    { value: 'all', label: 'Todos' },
                    { value: 'onProject', label: 'En proyecto' },
                    { value: 'free', label: 'Libre' },
                  ],
                },
                {
                  title: 'Disponibilidad',
                  handler: handleFilter,
                  options: [
                    { value: 'Full time', label: 'Full time' },
                    { value: 'Part time', label: 'Part time' },
                    { value: 'No disponible', label: 'No disponible' },
                  ],
                }
              ]}
              button={
                <div className="inline-flex md:hidden justify-center items-center bg-gray-100 px-2 pt-1">
                  <img src={Filter} className="w-auto h-3" alt="Filter" />
                </div>
              }
            />
          </div>
          <div className="min-w-max">
            <button
              onClick={() => setSkillFilter(!skillFilter)}
              className="rounded-md border appearance-none block min-w-max h-8 px-3 py-1 font-poppins focus:outline-none bg-white sm:text-sm border-gray-300 placeholder-gray-400 text-gray-800 focus:ring-0 focus:border-gray-300"
            >
              <span className="text-gray-300">Skills</span>
              {skillsFilters.length ? (
                <span className="text-sm font-bold text-purple px-2">
                  {skillsFilters.length}
                </span>
              ) : null}
            </button>
            <Transition
              as="div"
              show={skillFilter}
              onMouseLeave={() => setSkillFilter(false)}
              className="relative min-w-max"
              enter="transform transition ease-in-out duration-[200ms]"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transform duration-200 transition ease-in-out"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <CommunityFilterBySkill
                handler={handleSkillFilter}
                skillsSelected={skillsFilters}
              />
            </Transition>
          </div>
        </div>
      </div>
      <div className="hidden sm:inline-flex justify-between items-center">
        {/* SEARCH USERS INPUT */}
        <div className="inline-flex space-x-8 justify-evenly">
          <div
            id="search"
            className="rounded-md inline-flex items-center h-8 bg-white border border-gray-300"
          >
            <img src={Search} alt="Search" className="w-auto px-3" />
            <input
              type="text"
              placeholder="Buscar"
              onChange={handleSearch}
              className="appearance-none block w-full p-1 font-poppins border-transparent rounded-md focus:outline-none sm:text-sm placeholder-gray-400 focus:ring-0 h-full border-0"
            />
          </div>
          <CommunityFilterBy handler={handleFilter} filter={filter} />
          <CommunityFilterAvailability handler={handleFilter} filter={availabilitySelected} />
          <div className="min-w-max">
            <button
              onClick={() => setSkillFilter(!skillFilter)}
              className="rounded-md border appearance-none block min-w-max h-8 px-3 py-1 font-poppins focus:outline-none bg-white sm:text-sm border-gray-300 placeholder-gray-400 text-gray-800 focus:ring-0 focus:border-gray-300"
            >
              <span className="text-gray-300">Skills</span>
              {skillsFilters.length ? (
                <span className="text-sm font-bold text-purple px-2">
                  {skillsFilters.length}
                </span>
              ) : null}
            </button>
            <Transition
              as="div"
              show={skillFilter}
              onMouseLeave={() => setSkillFilter(false)}
              className="relative min-w-max"
              enter="transform transition ease-in-out duration-[200ms]"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transform duration-200 transition ease-in-out"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <CommunityFilterBySkill
                handler={handleSkillFilter}
                skillsSelected={skillsFilters}
              />
            </Transition>
          </div>
        </div>
        {/* VIEW CONTROLER BUTTONS */}
        <div className="inline-flex items-center space-x-8 pl-4">
          <div className="inline-flex items-stretch h-11">
            <button
              type="button"
              onClick={toggleView}
              className={
                'flex items-center h-full p-3 rounded-l-md border border-gray-300 focus:outline-none ' +
                (!listView
                  ? 'text-white bg-purple-light'
                  : 'text-gray-800 bg-white  focus:text-white  focus:bg-purple-light hover:text-white hover:bg-purple-light')
              }
            >
              <SVGGrid />
            </button>
            <button
              type="button"
              onClick={toggleView}
              className={
                'flex items-center h-full p-3 rounded-r-md border border-gray-300 focus:outline-none ' +
                (listView
                  ? 'text-white bg-purple-light'
                  : 'text-gray-800 bg-white  focus:text-white  focus:bg-purple-light hover:text-white hover:bg-purple-light')
              }
            >
              <SVGList />
            </button>
          </div>
          <PrimaryButton
            type="button"
            size="lg"
            children="Invitar nuevo miembro"
            handler={() => handleModals('invite')}
          />
        </div>
      </div>
      {/* COMMUNITY COMPONENTS */}
      {children}
      {/* MODALS BELOW */}
      {modal && modal === 'openBankData' ? (
        <ModalBankData
          handleModals={handleModals}
          data={{user: users[usersSelected[0]]}}
          allBankData={true}
        />
      ) : null}
      {modal && modal === 'invite' ? (
        <ModalInviteContainer handleModal={handleModals} />
      ) : null}
      {modal && modal === 'openUserData' ? (
        <ModalContactData
          handleModals={handleModals}
          data={users[usersSelected[0]].personalData}
          address={users[usersSelected[0]].bankData.address}
        />
      ) : null}
      {modal && modal === 'delete' ? (
        <ModalConfirmDelete
          handleModals={handleModals}
          handleDelete={deleteUsers}
          multiple={usersSelected.length > 1 ? true : false}
          children="usuario"
        />
      ) : null}
      {modal && modal === 'openPayments' ? (
        <ModalViewPaymentsContainer
          handleModals={handleModals}
          payments={selectedUserPayments}
          userSelected={selectedUser}
        />
      ) : null}
    </div>
  )
}

export default CommunityLayout
