import React, { createContext, useState, useEffect, useContext } from 'react'
import { auth } from './firebase'
import { FullPageLoaderComponent } from '../components/Utils/UI/Loaders.js'

export const AuthContext = createContext()
export const useAuth = () => useContext(AuthContext)
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setUser(user)
      setLoading(false)
    })
    return unsubscribe
  })

  return (
    <AuthContext.Provider value={user}>
      {loading ? <FullPageLoaderComponent /> : children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
