import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
// UI
import { ButtonSlector, PrimaryButton, SecondaryButton } from '../../UI/Buttons'

const inputStyle = (error) =>
  (error
    ? 'border-red-500 focus:ring-red-500 focus:border-red-500 '
    : 'border-gray-300 focus:ring-purple focus:border-purple ') +
  'appearance-none block w-full px-3 py-2 border rounded-md sm:placeholder-transparent sm:bg-transparent font-poppins focus:outline-none bg-gray-50 sm:text-sm placeholder-gray-400'

const labelStyle = (error) =>
  error
    ? 'text-sm text-red-500 font-poppins'
    : 'text-sm text-gray-800 font-poppins'

const ModalEditProject = ({
  formData,
  handleValue,
  handleDates,
  confirmDetails,
  error,
  payment,
  deletePayment,
  loading,
}) => {
  return (
    <form
      onSubmit={confirmDetails}
      className="w-full flex flex-col items-baseline px-1 space-y-8 overflow-y-auto"
    >
        {payment ? (
          <>
            <div className="w-full flex flex-col items-baseline space-y-2">
              <label
                htmlFor="currency"
                className={labelStyle(error && !formData.amount ? true : false)}
              >
                Moneda
              </label>
              <div className='flex w-full gap-5'>
                <ButtonSlector type="button" size="lg" children="ARS" isDisabled={!(formData.currency === "ARS")} name="currency" handler={()=>handleValue(0,"currency","ARS")}/>
                <ButtonSlector type="button" size="lg" children="USD" isDisabled={!(formData.currency === "USD")} name="currency" handler={()=>handleValue(0,"currency","USD")}/>
              </div>
            </div>
            <div className="w-full flex flex-col items-baseline space-y-2">
              <label
                htmlFor="amount"
                className={labelStyle(error && !formData.amount ? true : false)}
              >
                Monto en {formData.currency}
              </label>
              <input
                id="amount"
                name="amount"
                type="number"
                value={formData.amount}
                onChange={handleValue}
                className={inputStyle(error && !formData.pname ? true : false)}
              ></input>
            </div>
            <div className="w-full flex flex-col items-baseline space-y-1">
              <label
                htmlFor="dates"
                className={labelStyle(error && !formData.startDate ? true : false)}
              >
                Fecha 
              </label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={formData.date}
                startDate={formData.date}
                readonly
                popperPlacement="top-start"
                onChange={(date) => handleDates(date, 'date')}
                className={inputStyle(false)}
                />

            </div>
            <div className="w-full flex flex-col items-baseline space-y-2">
              <label htmlFor='bono' className='text-sm text-gray-800 font-poppins'>Bono</label>
              <input type='checkbox' name='bono' id='bono' onChange={handleValue} checked={formData.bono === 'true'}/>
            </div>
            <div className="w-full inline-flex gap-20">
              {payment.status === 'Pendiente' && (
                <SecondaryButton error={true} type="button" size="lg" children="Eliminar Pago" handler={deletePayment}/>
              )}
              <PrimaryButton loading={loading} type="submit" size="lg" children={payment.new ? `Crear ${formData.bono === 'true' ? 'bono':'pago'}`: "Editar pago"} />
            </div>
          </>
        ):(
          <>
            <div className="w-full flex flex-col items-baseline space-y-2">
              <label
                htmlFor="pname"
                className={labelStyle(error && !formData.pname ? true : false)}
              >
                Nombre del proyecto
              </label>
              <input
                id="pname"
                name="pname"
                type="text"
                value={formData.pname}
                onChange={handleValue}
                className={inputStyle(error && !formData.pname ? true : false)}
              ></input>
            </div>
            <div className="w-full flex flex-col items-baseline space-y-1">
              <label
                htmlFor="description"
                className={labelStyle(error && !formData.description ? true : false)}
              >
                Descripción
              </label>
              <textarea
                id="description"
                name="description"
                type="text"
                value={formData.description}
                onChange={handleValue}
                className={inputStyle(error && !formData.description ? true : false)}
              ></textarea>
            </div>
            <div className="w-full flex flex-col items-baseline space-y-1">
              <label
                htmlFor="dates"
                className={labelStyle(error && !formData.startDate ? true : false)}
              >
                Fecha de inicio
              </label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={formData.startDate}
                startDate={formData.startDate}
                readonly
                popperPlacement="top-start"
                onChange={(date) => handleDates(date, 'startDate')}
                className={inputStyle(false)}
              />
            </div>
            <div className="w-min-min inline-flex self-end">
              <PrimaryButton type="submit" size="lg" children="Editar proyecto" />
            </div>
          </>
        )}
      
    </form>
  )
}

export default ModalEditProject
