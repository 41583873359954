import React from 'react'
import { Link } from 'react-router-dom'
// UI
import { SingleUserDropdown } from '../../Utils/UI/Selects'
// IMAGES
import Down from '../../Utils/Images/Down.png'
import Trash from '../../Utils/Images/Trash.png'
import UserDefault from '../../Utils/Images/UserDefault.png'
import SelectedCheck from '../../Utils/Images/SelectedCheck.png'
import ArrowDownViolet from '../../Utils/Images/ArrowDownViolet.png'
import ArrowControlLeft from '../../Utils/Images/ArrowControlLeft.png'
import SelectAllDefault from '../../Utils/Images/SelectAllDefault.png'
import SelectAllSelected from '../../Utils/Images/SelectAllSelected.png'
import ArrowControlRight from '../../Utils/Images/ArrowControlRight.png'
import { PrimaryButton } from '../../Utils/UI/Buttons'

const CommunityList = ({
  users,
  slice,
  sliceUsers,
  handleSort,
  selectUsers,
  handleModals,
  curatedUsers,
  usersSelected,
  singleUserSelection,
}) => {
  return (
    <>
      <div className="hidden md:block w-full h-full rounded-md bg-white pt-8 border border-gray-300 px-6 py-4">
        {/* TABLE CONTROL ACTIONS */}
        <div className="inline-flex justify-between items-center w-full px-4 py-4 border-b border-gray-300">
          {/* USERS SELECTION OPTIONS */}
          <div className="inline-flex items-center space-x-6">
            <div
              className="min-h-max inline-flex items-center space-x-2"
              id="selectAll"
            >
              <button
                onClick={(e) => selectUsers(e, 'all')}
                type="button"
                className="h-full focus:outline-none"
              >
                <img
                  src={
                    usersSelected.length === curatedUsers.length
                      ? SelectAllSelected
                      : SelectAllDefault
                  }
                  alt="SelectAll"
                />
              </button>
              <img src={Down} alt="" />
            </div>
            {usersSelected && usersSelected.length > 1 ? (
              <button
                onClick={() => handleModals('delete')}
                id="delete"
                type="button"
                className="h-full p-1 focus:outline-none"
              >
                <img src={Trash} alt="Delete" />
              </button>
            ) : null}
            {usersSelected && usersSelected.length === 1 ? (
              <SingleUserDropdown
                handler={handleModals}
                admin={true}
                inverted
              />
            ) : null}
          </div>
          {/* PAGINATION */}
          <div className="inline-flex items-center space-x-4">
            <p className="text-gray-800 font-poppins text-sm">
              {slice.start + 1}-{slice.end} de {users.length}
            </p>
            <button
              onClick={() => sliceUsers('prev')}
              type="button"
              className="h-full p-2 focus:outline-none"
            >
              <img src={ArrowControlLeft} alt="Previous" />
            </button>
            <button
              onClick={() => sliceUsers('next')}
              type="button"
              className="h-full p-2 focus:outline-none"
            >
              <img src={ArrowControlRight} alt="Previous" />
            </button>
          </div>
        </div>

        {/* TABLE */}
        <table className="w-full">
          <thead>
            <tr>
              <th scope="col" className="px-0 py-6 w-20"></th>
              <th scope="col" className="px-2 py-6">
                <button
                  onClick={(e) => handleSort(e, 'fullName')}
                  className="w-full inline-flex items-center space-x-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                >
                  <span>Freelancer</span>
                  <img src={ArrowDownViolet} alt="Sort" />
                </button>
              </th>
              <th scope="col" className="px-2 py-6">
                <button
                  onClick={(e) => handleSort(e, 'role')}
                  className="w-full inline-flex items-center space-x-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                >
                  <span>Actividad</span>
                  <img src={ArrowDownViolet} alt="Sort" />
                </button>
              </th>
              <th scope="col" className="px-2 py-6">
                <button
                  onClick={(e) => handleSort(e, 'email')}
                  className="w-full inline-flex items-center space-x-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                >
                  <span>Email</span>
                  <img src={ArrowDownViolet} alt="Sort" />
                </button>
              </th>
              <th scope="col" className="px-2 py-6">
                <button className="w-full inline-flex items-center space-x-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider focus:outline-none">
                  <span>Skills</span>
                </button>
              </th>
              <th scope="col" className="px-2 py-6">
                <button
                  onClick={(e) => handleSort(e, 'availability')}
                  className="w-full inline-flex items-center space-x-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                >
                  <span>Disponibilidad</span>
                  <img src={ArrowDownViolet} alt="Sort" />
                </button>
              </th>
              <th scope="col" className="px-2 py-6">
                <button className="w-full inline-flex items-center space-x-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider focus:outline-none cursor-default">
                  <span>Estado</span>
                </button>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {curatedUsers.length ? (
              curatedUsers.map((user, i) => (
                <tr key={user.id}>
                  <td className="pl-4 py-2 font-poppins">
                    <button
                      onClick={(e) => selectUsers(e, i)}
                      type="button"
                      className="focus:outline-none h-full min-w-max"
                    >
                      <img
                        src={
                          usersSelected.includes(i)
                            ? SelectedCheck
                            : SelectAllDefault
                        }
                        alt="SelectAll"
                      />
                    </button>
                  </td>
                  <td className=" py-2 font-poppins">
                    <div className="flex items-center">
                      <div className="min-w-max">
                        <div className="h-8 w-8">
                          <img
                            className="rounded-full h-full w-full object-center object-cover border border-gray-300"
                            src={
                              user.profileImage
                                ? user.profileImage
                                : UserDefault
                            }
                            alt="UImage"
                          />
                        </div>
                      </div>
                      <Link to={`/admin/comunidad/${user.id}`}>
                        <p className="ml-4 text-sm font-medium text-gray-800">
                          {user.personalData.fullName}
                        </p>
                      </Link>
                    </div>
                  </td>
                  <td className="px-2 py-2 text-sm text-gray-800 font-poppins">
                    {user.personalData.role}
                  </td>
                  <td className="px-2 py-2 text-sm text-gray-800 font-poppins overflow-ellipsis w-auto">
                    <p className="w-auto break-all">
                      {user.personalData.email}
                    </p>
                  </td>
                  <td className="px-1 py-2 text-sm text-gray-800 font-poppins flex flex-wrap items-center">
                    {user.skills && (
                      <>
                        {user.skills
                          .map((skill) => (
                            <span
                              key={skill}
                              className="px-2 mb-1 mr-1 rounded-full min-w-max border border-gray-300 text-sm font-poppins"
                            >
                              {skill}
                            </span>
                          ))
                          .slice(0, 3)}

                        <span>
                          {user.skills.length > 3
                            ? '+ ' + parseInt(user.skills.length - 3)
                            : null}
                        </span>
                      </>
                    )}
                  </td>
                  <td className="px-2 py-2 text-sm text-gray-800 font-poppins overflow-ellipsis w-auto">
                    <p className="w-auto break-all">
                      {user.availability ? user.availability : null }
                    </p>
                  </td>
                  <td className="px-2 py-2 text-sm text-gray-800 font-poppins">
                    <div
                      className={
                        'px-8 py-1 rounded-full text-center min-w-max ' +
                        (user.projects.active.length >= 1
                          ? 'bg-gray-100'
                          : 'bg-green-400 text-white')
                      }
                    >
                      {user.projects.active.length >= 1
                        ? 'En proyecto'
                        : 'Libre'}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td></td>
                <td></td>
                <td className="text-center px-2 py-6 text-sm text-gray-500 font-poppins w-max">
                  No se encontraron freelancers
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="block md:hidden w-full h-full">
        {/* PAGINATION */}
        <div className="inline-flex justify-end items-center w-full px-4 py-2 space-x-4">
          <button
            onClick={() => sliceUsers('prev')}
            type="button"
            className="h-full p-2 focus:outline-none"
          >
            <img src={ArrowControlLeft} alt="Previous" />
          </button>
          <p className="text-gray-800 font-poppins text-sm">
            {slice.start + 1}-{slice.end} de {users.length}
          </p>
          <button
            onClick={() => sliceUsers('next')}
            type="button"
            className="h-full p-2 focus:outline-none"
          >
            <img src={ArrowControlRight} alt="Previous" />
          </button>
        </div>
        {/* TABLE */}
        <table className="w-full h-full">
          <tbody>
            {curatedUsers.length ? (
              curatedUsers.map((user, i) => (
                <tr
                  key={user.id}
                  className="table-row md:hidden w-full border-b border-t border-gray-300"
                >
                  <td className="w-full p-0 h-auto">
                    <div className="w-full inline-flex justify-between items-center">
                      <div className="w-full space-x-2 flex flex-1 items-start justify-start">
                        <div className="min-w-max">
                          <div className="relative max-h-14 h-14 w-14 my-2">
                            <img
                              className="relative rounded-full h-full w-full object-center object-cover border border-gray-300"
                              src={
                                user.profileImage
                                  ? user.profileImage
                                  : UserDefault
                              }
                              alt="UImage"
                            />
                            <span
                              className={
                                (user.projects.active.length
                                  ? 'bg-red-500'
                                  : 'bg-green-500') +
                                ' absolute rounded-full h-3 w-3 bottom-0 right-0'
                              }
                            ></span>
                          </div>
                        </div>
                        <div className="flex flex-col text-left h-full min-w-0 py-2 font-poppins">
                          <Link to={`/admin/comunidad/${user.id}`}>
                            <p className="text-sm font-medium text-gray-800">
                              {user.personalData.fullName}
                            </p>
                          </Link>
                          <p className="text-xs font-medium text-gray-500">
                            {user.personalData.role}
                          </p>
                        </div>
                      </div>
                      <div className="min-w-0">
                        <PrimaryButton
                          type="button"
                          size="sm"
                          children="Invitar"
                          handler={(e) => {
                            e.preventDefault()
                            singleUserSelection(e, i)
                            handleModals('inviteToProject')
                          }}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td></td>
                <td></td>
                <td className="text-center px-2 py-6 text-sm text-gray-500 font-poppins w-max">
                  No se encontraron freelancers
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default CommunityList
