import React, { useRef, useState } from 'react'
import Upload from '../Images/Upload.png'
import Trash from '../Images/Trash.png'
import File from '../Images/File.png'
import NumberFormat from 'react-number-format'

export const Input = ({ type, label, name, value, handler, error, disabled }) => (
  <div className="w-full">
    <label
      htmlFor={name}
      className={
        error && !value
          ? 'hidden sm:block text-sm font-medium font-poppins text-red-600'
          : 'hidden sm:block text-sm font-medium font-poppins text-gray-700'
      }
    >
      {label}
    </label>
    <div className="mt-1">
      <input
        id={name}
        name={name}
        type={type}
        autoComplete={name}
        placeholder={label}
        value={value}
        onChange={handler}
        disabled={disabled}
        className={
          error && !value
            ? 'appearance-none block w-full px-3 py-2 border rounded-md sm:placeholder-transparent sm:bg-transparent font-poppins focus:outline-none bg-gray-50 sm:text-sm border-red-600 placeholder-red-500 focus:ring-red-600 focus:border-red-600'
            : 'appearance-none block w-full px-3 py-2 border rounded-md sm:placeholder-transparent sm:bg-transparent font-poppins focus:outline-none bg-gray-50 sm:text-sm border-gray-300 placeholder-gray-400 focus:ring-purple focus:border-purple'
        }
      />
    </div>
  </div>
)

export const InputImg = ({ name, handleChange, img, error }) => {
  const hiddenFileInput = useRef()
  const [file, setFile] = useState()

  const handleFile = (e) => {
    setFile(e.target.files[0])
    handleChange(e.target.files[0], name)
  }
  const handleClick = (e) => {
    e.preventDefault()
    hiddenFileInput.current.click()
  }
  const removeFile = (e) => {
    setFile(null)
    hiddenFileInput.current.value = null
    handleChange(null, name, null)
  }
  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.dataTransfer.files[0].type.includes('image')) {
      setFile(e.dataTransfer.files[0])
      handleChange(e.dataTransfer.files[0], name)
    }
  }

  return (
    <>
      {file ? (
        <div className="w-full px-4 py-8 rounded-md border border-gray-300 inline-flex justify-between">
          <div className="inline-flex space-x-2 px-2 items-center font-poppins text-sm w-full">
            <span>
              <img src={File} alt="File" />
            </span>
            <span>{file.name}</span>
          </div>
          <div className="w-1/3 px-4 inline-flex items-center space-x-4">
            <div className="w-full border-2 border-purple"></div>
            <img
              src={Trash}
              alt="Remove File"
              className="h-4 cursor-pointer"
              onClick={removeFile}
            />
          </div>
        </div>
      ) : null}
      {!file && (
        <div
          className={
            (error && !img
              ? 'border border-red-500 text-red-500 '
              : 'border border-gray-300 ') +
            'w-full rounded-md border border-dashed border-gray-300 focus:outline-none appearance-none cursor-pointer'
          }
          onClick={handleClick}
          onDrop={(e) => handleDrop(e)}
          onDragOver={(e) => handleDrag(e)}
          onDragEnter={(e) => handleDrag(e)}
          onDragLeave={(e) => handleDrag(e)}
        >
          <div className="w-full py-6 px-4 flex flex-col items-center space-y-2 font-poppins">
            <>
              <img src={Upload} alt="Upload" />
              <span className="text-sm">Arrastra los archivos aquí o</span>
              <span className="text-base">Elige un archivo</span>
            </>
          </div>
        </div>
      )}
      <input
        id={name}
        name={name}
        type="file"
        accept="image/*"
        ref={hiddenFileInput}
        onChange={handleFile}
        style={{ display: 'none' }}
      />
    </>
  )
}

export const InputPdf = ({ name, handleChange, error }) => {
  const hiddenFileInput = useRef()
  const [file, setFile] = useState()

  const handleFile = (e) => {
    setFile(e.target.files[0])
    handleChange(e.target.files[0], name)
  }
  const handleClick = (e) => {
    e.preventDefault()
    hiddenFileInput.current.click()
  }
  const removeFile = (e) => {
    setFile(null)
    hiddenFileInput.current.value = null
    handleChange(null, name, null)
  }
  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.dataTransfer.files[0].type.includes('pdf')) {
      setFile(e.dataTransfer.files[0])
      handleChange(e.dataTransfer.files[0], name)
    }
  }

  return (
    <>
      {file ? (
        <div className="w-full px-4 py-8 rounded-md border border-gray-300 inline-flex justify-between">
          <div className="inline-flex space-x-2 px-2 items-center font-poppins text-sm w-full">
            <span>
              <img src={File} alt="File" />
            </span>
            <span>{file.name}</span>
          </div>
          <div className="w-1/3 px-4 inline-flex items-center space-x-4">
            <div className="w-full border-2 border-purple"></div>
            <img
              src={Trash}
              alt="Remove File"
              className="h-4 cursor-pointer"
              onClick={removeFile}
            />
          </div>
        </div>
      ) : null}
      {!file && (
        <div
          className={
            (error
              ? 'border border-red-500 text-red-500 '
              : 'border border-gray-300 ') +
            'w-full rounded-md border border-dashed border-gray-300 focus:outline-none appearance-none cursor-pointer'
          }
          onClick={handleClick}
          onDrop={(e) => handleDrop(e)}
          onDragOver={(e) => handleDrag(e)}
          onDragEnter={(e) => handleDrag(e)}
          onDragLeave={(e) => handleDrag(e)}
        >
          <div className="w-full py-6 px-4 flex flex-col items-center space-y-2 font-poppins">
            <>
              <img src={Upload} alt="Upload" />
              <span className="text-sm">Arrastra los archivos aquí o</span>
              <span className="text-base">Elige un archivo</span>
            </>
          </div>
        </div>
      )}
      <input
        id={name}
        name={name}
        type="file"
        accept=".pdf"
        ref={hiddenFileInput}
        onChange={handleFile}
        style={{ display: 'none' }}
      />
    </>
  )
}

export const InputMultipleTypeFile = ({ name, handleChange, error }) => {
  const hiddenFileInput = useRef()
  const [file, setFile] = useState()

  const handleFile = (e) => {
    setFile(e.target.files[0])
    handleChange(e.target.files[0], name)
  }
  const handleClick = (e) => {
    e.preventDefault()
    hiddenFileInput.current.click()
  }
  const removeFile = (e) => {
    setFile(null)
    hiddenFileInput.current.value = null
    handleChange(null, name, null)
  }
  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0])
      handleChange(e.dataTransfer.files[0], name)
    }
  }

  return (
    <>
      { file ? (
        <div className="w-full px-4 py-8 rounded-md border border-gray-300 inline-flex justify-between">
          <div className="inline-flex space-x-2 px-2 items-center font-poppins text-sm w-full">
            <span>
              <img src={ File } alt="File" />
            </span>
            <span>{ file.name }</span>
          </div>
          <div className="w-1/3 px-4 inline-flex items-center space-x-4">
            <div className="w-full border-2 border-purple"></div>
            <img
              src={ Trash }
              alt="Remove File"
              className="h-4 cursor-pointer"
              onClick={ removeFile }
            />
          </div>
        </div>
      ) : null }
      { !file && (
        <div
          className={
            (error
              ? 'border border-red-500 text-red-500 '
              : 'border border-gray-300 ') +
            'w-full rounded-md border border-dashed border-gray-300 focus:outline-none appearance-none cursor-pointer'
          }
          onClick={ handleClick }
          onDrop={ (e) => handleDrop(e) }
          onDragOver={ (e) => handleDrag(e) }
          onDragEnter={ (e) => handleDrag(e) }
          onDragLeave={ (e) => handleDrag(e) }
        >
          <div className="w-full py-6 px-4 flex flex-col items-center space-y-2 font-poppins">
            <>
              <img src={ Upload } alt="Upload" />
              <span className="text-sm">Arrastra los archivos aquí o</span>
              <span className="text-base">Elige un archivo</span>
            </>
          </div>
        </div>
      ) }
      <input
        id={ name }
        name={ name }
        type="file"
        accept=".pdf, image/*"
        ref={ hiddenFileInput }
        onChange={ handleFile }
        style={ { display: 'none' } }
      />
    </>
  )
}

export const InputPhone = ({ type, label, name, value, handler, error }) => (
  <div className="w-full">
    <label
      htmlFor={name}
      className={
        error && !value
          ? 'hidden sm:block text-sm font-medium font-poppins text-red-600'
          : 'hidden sm:block text-sm font-medium font-poppins text-gray-700'
      }
    >
      {label}
    </label>
    <div className="mt-1">
      <NumberFormat
        id={name}
        name={name}
        type={type}
        autoComplete={name}
        placeholder={label}
        value={value}
        onChange={handler}
        format="(##) ####-####"
        className={
          error && !value
            ? 'appearance-none block w-full px-3 py-2 border rounded-md sm:placeholder-transparent sm:bg-transparent font-poppins focus:outline-none bg-gray-50 sm:text-sm border-red-600 placeholder-red-500 focus:ring-red-600 focus:border-red-600'
            : 'appearance-none block w-full px-3 py-2 border rounded-md sm:placeholder-transparent sm:bg-transparent font-poppins focus:outline-none bg-gray-50 sm:text-sm border-gray-300 placeholder-gray-400 focus:ring-purple focus:border-purple'
        }
      />
    </div>
  </div>
)

export const InputDate = ({ type, label, name, value, handler, error }) => (
  <div className="w-full">
    <label
      htmlFor={name}
      className={
        error && !value
          ? 'hidden sm:block text-sm font-medium font-poppins text-red-600'
          : 'hidden sm:block text-sm font-medium font-poppins text-gray-700'
      }
    >
      {label}
    </label>
    <div className="mt-1">
      <NumberFormat
        id={name}
        name={name}
        type={type}
        autoComplete={name}
        placeholder={label}
        value={value}
        onChange={handler}
        format="##/##/####"
        mask={['D', 'D', 'M', 'M', 'A', 'A', 'A', 'A']}
        className={
          error && !value
            ? 'appearance-none block w-full px-3 py-2 border rounded-md sm:placeholder-transparent sm:bg-transparent font-poppins focus:outline-none bg-gray-50 sm:text-sm border-red-600 placeholder-red-500 focus:ring-red-600 focus:border-red-600'
            : 'appearance-none block w-full px-3 py-2 border rounded-md sm:placeholder-transparent sm:bg-transparent font-poppins focus:outline-none bg-gray-50 sm:text-sm border-gray-300 placeholder-gray-400 focus:ring-purple focus:border-purple'
        }
      />
    </div>
  </div>
)

export const InputCuit = ({ type, label, name, value, handler, error }) => (
  <div className="w-full">
    <label
      htmlFor={name}
      className={
        error && !value
          ? 'hidden sm:block text-sm font-medium font-poppins text-red-600'
          : 'hidden sm:block text-sm font-medium font-poppins text-gray-700'
      }
    >
      {label}
    </label>
    <div className="mt-1">
      <NumberFormat
        id={name}
        name={name}
        type={type}
        autoComplete={name}
        placeholder={label}
        value={value}
        onChange={handler}
        format="##-########-#"
        className={
          error && !value
            ? 'appearance-none block w-full px-3 py-2 border rounded-md sm:placeholder-transparent sm:bg-transparent font-poppins focus:outline-none bg-gray-50 sm:text-sm border-red-600 placeholder-red-500 focus:ring-red-600 focus:border-red-600'
            : 'appearance-none block w-full px-3 py-2 border rounded-md sm:placeholder-transparent sm:bg-transparent font-poppins focus:outline-none bg-gray-50 sm:text-sm border-gray-300 placeholder-gray-400 focus:ring-purple focus:border-purple'
        }
      />
    </div>
  </div>
)
