import React, { useState, useEffect } from 'react'
// RECOIL
import { useRecoilValue } from 'recoil'
import { paymentsState, projectsState } from '../../../../atoms/index'
// UI
import ModalViewPayments from './ModalViewPayments.jsx'

const ModalViewPaymentsContainer = ({
  payments,
  handleModals,
  userSelected,
  admin = false,
  handleEditPayment,
  handleNewPayment
}) => {
  const [slice, setSlice] = useState({})
  const allPayments = useRecoilValue(paymentsState)
  const allProjects = useRecoilValue(projectsState)

  const curatedPayments = payments.reduce((acc, payment) => {
    for (const key in userSelected.payments) {
      if (userSelected.payments[key].includes(payment)) {
        let paymentData = allPayments.find(
          (originPayment) => originPayment.id === payment
        )
        if (paymentData) {
          let projectPayment = allProjects.find(
            (project) => project.projectId === paymentData.project
          )
          paymentData = {
            ...paymentData,
            project: projectPayment,
            user: userSelected,
          }
          acc.push(paymentData)
        }
      }
    }
    return acc
  }, [])

  const slicePayments = (action) => {
    if (action === 'next') {
      if (slice.start + 5 > curatedPayments.length) {
        setSlice({
          start: slice.start,
          end: curatedPayments.length,
        })
      } else if (slice.end + 5 > curatedPayments.length) {
        setSlice({
          start: curatedPayments.length > 5 ? slice.end : 0,
          end: curatedPayments.length,
        })
      } else {
        setSlice({ start: slice.start + 5, end: slice.end + 5 })
      }
    }
    if (action === 'prev') {
      if (slice.start - 5 < 0) {
        setSlice({ start: 0, end: payments.length > 5 ? 5 : payments.length })
      } else {
        setSlice({ start: slice.start - 5, end: slice.start })
      }
    }
  }

  useEffect(() => {
    setSlice({
      start: 0,
      end: curatedPayments.length > 5 ? 5 : curatedPayments.length,
    })
    // eslint-disable-next-line
  }, [])

  return (
    <ModalViewPayments
      slice={slice}
      admin={admin}
      handleModals={handleModals}
      userSelected={userSelected}
      slicePayments={slicePayments}
      paymentsLength={curatedPayments.length}
      payments={curatedPayments.slice(slice.start, slice.end)}
      handleEditPayment={handleEditPayment}
      handleNewPayment={handleNewPayment}
    />
  )
}

export default ModalViewPaymentsContainer
