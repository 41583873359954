import { useEffect, useState } from 'react'
// RECOIL
import { sessionUserState } from '../../../atoms'
import { useRecoilValue } from 'recoil'
// HELPERS
import { cbu, cuit } from 'arg.js'
// DB
import { updateBankDetails } from '../../../services/db'
import ModalEditBankDetails from '../../Utils/Modals/ModalEditBankDetails/ModalEditBankDetails'
import ModalCreateBank from './ModalCreateBank'

const BankAccountsContainer = ({ newUser, edit, bankData, setOpen, fetchUserData, handleFormData, onlyView}) => {
  const sessionUser = useRecoilValue(sessionUserState)
  useEffect(() => {
    if (bankData) {
      const type = bankData.type
      console.log('type', type)
      if(type === 'zelle' || type === 'wise') {
        console.log('zelle or wise',bankData)
        setFormData({
          name: bankData.name,
          type: bankData.type,
          email: bankData.email,
          id: bankData.id,
          invoice: bankData.invoice,
        })
      }
      if(type === 'arg') {
        console.log('ars',bankData)
        setFormData({
          bank: bankData.bank,
          accountOwner: bankData.accountOwner,
          cuit: bankData.cuit,
          cbu: bankData.cbu,
          address: bankData.address,
          invoice: bankData.invoice,
          id: bankData.id,
          name: bankData.name,
          invoice: bankData.invoice,
          type: bankData.type,
        })
      }
      if(type === 'ach'){
        console.log('ach',bankData)
        setFormData({
          bankName: bankData.bankName,
          beneficiaryName: bankData.beneficiaryName,
          routingNumber: bankData.routingNumber,
          accountNumber: bankData.accountNumber,
          accountType: bankData.accountType,
          invoice: bankData.invoice,
          id: bankData.id,
          name: bankData.name,
          invoice: bankData.invoice,
          type: bankData.type,
        })
      }
      if(type === 'usdt'){
        setFormData({
          walletAddress: bankData.walletAddress,
          invoice: bankData.invoice,
          id: bankData.id,
          name: bankData.name,
          invoice: bankData.invoice,
          type: bankData.type
        })
      }
    }else{
      console.log('no bank data')
    }
  }, [bankData])

  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [invalidCuit, setInvalidCuit] = useState(false)
  const [invalidCbu, setInvalidCbu] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({})

  console.log('bankData', bankData)
  console.log('formData', formData)

  const handleInputs = (e, option, data) => {
    let key = data ? data : e.target.name
    let value = option ? option : e.target.value
    if (key === 'cuit' && cuit.isValid(value)) {
      setInvalidCuit(false)
    }
    if (key === 'cbu' && cbu.isValid(value)) setInvalidCbu(false)
    setFormData({ ...formData, [key]: value })
  }

  const validateData = (data, setError) => {
    let errors = 0
    for (const key in data) {
      if (!data[key]) errors++
      if (key === 'cuit' && !cuit.isValid(data[key].replace(/-/g, ''))) {
        errors++
        setInvalidCuit(true)
      } else if (key === 'cbu' && !cbu.isValid(data[key])) {
        errors++
        setInvalidCbu(true)
      }
    }
    errors ? setError(true) : setError(false)
    return errors
  }

  const validateForm = async (e) => {
    e.preventDefault()
    let errors = validateData(formData, setError)
    if (!errors) {
      setLoading(true)
      await updateBankDetails(sessionUser.id, formData)
      setLoading(false)
      setSuccess(true)
      fetchUserData()
    }
  }

  const handleModals = (option) => {
    if(newUser){
      console('newUser', newUser)
    }else{
      console.log('option', option)
      setOpen(false)
    }
  }

  return (
    <>
        {edit && (
            <ModalEditBankDetails
                error={error}
                loading={loading}
                success={success}
                formData={formData}
                invalidCbu={invalidCbu}
                invalidCuit={invalidCuit}
                validateForm={validateForm}
                handleModals={handleModals}
                handleInputs={handleInputs}
                sessionUser={sessionUser}
                fetchUserData={fetchUserData}
                onlyView={onlyView}
            />
        )}
        {!edit && (
          <ModalCreateBank
              error={error}
              loading={loading}
              success={success}
              formData={formData}
              invalidCbu={invalidCbu}
              invalidCuit={invalidCuit}
              validateForm={validateForm}
              handleModals={handleModals}
              handleInputs={handleInputs}
              fetchUserData={fetchUserData}
              newUser={newUser}
              handleFormData={handleFormData}
          />
        )}
    </>
    
  )
}

export default BankAccountsContainer