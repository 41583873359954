import React, { useRef, Fragment } from 'react'
import GoBackContract from '../Images/GoBackContract.png'
import { Listbox, Transition, Dialog } from '@headlessui/react'
import { parseTimestampToDateLong, toPesos } from '../helpers'
import { useState } from 'react'

export const DivResumen = ({ amount, children }) => (
  <div className="flex flex-col items-center md:p-2 text-center">
    <p className="text-base lg:text-xl font-poppins font-medium min-w-max">
      {amount}
    </p>
    <p className="text-xs sm:text-sm text-gray-500 w-auto">{children}</p>
  </div>
)

export const ModalHeader = ({ title, goBackText, handler, modal, regular }) => (
  <div className="w-full mb-8">
    <div className={(regular ? 'block' : 'hidden sm:block') + ' w-full'}>
      <div className="w-full inline-flex justify-end">
        <button
          className="focus:outline-none"
          onClick={(e) => handler(e, modal)}
        >
          <svg width="21" height="20" viewBox="0 0 21 20" fill="none">
            <path
              d="M0.755742 0.528328L0.882035 0.382035C1.34511 -0.0810376 2.06974 -0.123135 2.58037 0.255742L2.72666 0.382035L10.5 8.15478L18.2733 0.382035C18.7827 -0.127345 19.6086 -0.127345 20.118 0.382035C20.6273 0.891414 20.6273 1.71728 20.118 2.22666L12.3452 10L20.118 17.7733C20.581 18.2364 20.6231 18.961 20.2443 19.4717L20.118 19.618C19.6549 20.081 18.9303 20.1231 18.4196 19.7443L18.2733 19.618L10.5 11.8452L2.72666 19.618C2.21728 20.1273 1.39141 20.1273 0.882035 19.618C0.372655 19.1086 0.372655 18.2827 0.882035 17.7733L8.65478 10L0.882035 2.22666C0.418962 1.76359 0.376865 1.03896 0.755742 0.528328L0.882035 0.382035L0.755742 0.528328Z"
              fill="#666666"
            />
          </svg>
        </button>
      </div>
      <h3 className='text-center text-lg md:dm-h3 w-full mt-4'>
        {title}
      </h3>
    </div>
    <div className={regular ? 'hidden' : 'sm:hidden flex flex-col'}>
      <button
        className="inline-flex items-center space-x-2 focus:outline-none"
        onClick={handler}
      >
        <img src={GoBackContract} alt="GoBack" className="h-3 cursor-pointer" />
        <p className="text-sm cursor-pointer">{goBackText}</p>
      </button>
      <h3 className="text-xl text-left">{title}</h3>
    </div>
  </div>
)

export const Dropdown = ({ handler, options, button, inverted }) => {
  console.log(options)
  
  return (
    <div className="h-full w-full">
      <div className="block sm:hidden h-full w-full">
        <Listbox
          as="div"
          className="relative focus:outline-none h-full"
          onChange={handler}
        >
          {({ open }) => (
            <div className="relative">
              <Listbox.Button className="focus:outline-none h-full w-full">
                {button && button}
              </Listbox.Button>
              <Transition
                show={open}
                as="div"
                leave="transition ease-in duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog
                  open={open}
                  onClose={() => null}
                  className="block sm:hidden fixed z-30 inset-0 m-0 overflow-y-auto overflow-x-hidden h-auto"
                >
                  <Dialog.Overlay className="fixed z-30 inset-0 h-full bg-gray-500 bg-opacity-75" />
                  <Listbox.Options
                    static
                    className="absolute z-30 w-full right-0 bottom-0 font-poppins bg-white rounded-md text-sm border border-gray-300 focus:outline-none py-6"
                  >
                    {options.map((option) => (
                      <Listbox.Option
                        key={option.value}
                        value={option.value}
                        className="text-gray-900 cursor-default select-none relative py-4 px-4 focus:outline-none hover:bg-purple-lightest"
                      >
                        <div>{option.label}</div>
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Dialog>
              </Transition>
            </div>
          )}
        </Listbox>
      </div>
      <div className="hidden sm:block">
        <Listbox
          as="div"
          className="relative focus:outline-none"
          onChange={handler}
        >
          {({ open }) => (
            <>
              <Listbox.Button className="focus:outline-none">
                {button}
              </Listbox.Button>
              <Transition
                show={open}
                leave="transition-all ease-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className={
                  (inverted ? '' : 'right-0') +
                  ' absolute z-10 font-poppins bg-white rounded-md text-sm border border-gray-300 focus:outline-none min-w-max'
                }
              >
                <Listbox.Options
                  static
                  className="relative mt-1 overflow-auto focus:outline-none z-30"
                >
                  {options.map((option) => (
                    <Listbox.Option
                      key={option.value}
                      value={option.value}
                      className="text-gray-900 cursor-default select-none relative py-2 px-2 focus:outline-none hover:bg-purple-lightest"
                    >
                      <div>{option.label}</div>
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </>
          )}
        </Listbox>
      </div>
    </div>
  )
}

export const DropdownWithChips = ({ optionsSets, button }) => {
  const [open, setOpen] = useState(false)
  return (
    <div className="min-w-max">
      <button onClick={() => setOpen(!open)}>{button && button}</button>
      <Transition
        show={open}
        as="div"
        leave="transition ease-in duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Dialog
          open={open}
          onClose={() => setOpen(!open)}
          className="block sm:hidden fixed z-30 inset-0 m-0 overflow-y-auto overflow-x-hidden h-auto"
        >
          <Dialog.Overlay className="fixed z-30 inset-0 h-full bg-gray-500 bg-opacity-75" />
          <div className="absolute z-30 w-full bottom-0 font-poppins bg-white rounded-md text-sm border border-gray-300 focus:outline-none py-6">
            <button />
            {optionsSets.map((set, i) => (
              <div
                key={i}
                static
                className="flex flex-col px-4 focus:outline-none space-y-1 pb-4"
              >
                <span className="text-sm text-gray-500">{set.title}</span>
                <div className="inline-flex space-x-2">
                  {set.options.map((option, i) => (
                    <span
                      key={i}
                      onClick={() => {
                        set.handler(option.value)
                        setOpen(!open)
                      }}
                      className="text-xs px-2 py-1 border border-gray-300 rounded-full cursor-pointer hover:border-purple hover:text-purple"
                    >
                      {option.label}
                    </span>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}

export const PaymentDetailMobile = ({ paymentDetail, onClose }) => {
  const payment = paymentDetail.payment
  const focusRef = useRef()
  return (
    <Dialog
      static="true"
      open={true}
      initialFocus={focusRef}
      onClose={() => onClose({ open: false, payment: null })}
      className="block md:hidden fixed z-30 inset-0 m-0 overflow-y-auto overflow-x-hidden h-full"
    >
      <Dialog.Overlay className="fixed z-30 inset-0 h-full bg-gray-500 bg-opacity-75" />
      <div className="absolute z-30 w-full bottom-0 font-poppins bg-white rounded-md text-sm border border-gray-300 focus:outline-none py-6">
        <div className="relative py-4 px-4 text-gray-800 font-poppins text-base">
          Detalles de pago
        </div>
        <div className="flex flex-col font-poppins text-sm">
          <div className="py-2 px-4">
            <div className="flex flex-col">
              <span className="text-gray-500">Proyecto</span>
              <span className="text-gray-800">{payment.project.pname}</span>
            </div>
          </div>
          <div className="py-2 px-4">
            <div className="flex flex-col">
              <span className="text-gray-500">
                Cuota Nro. {payment.paymentNumber}
                {payment.bono && ' Bono'}
              </span>
              <span className="text-gray-800">{toPesos(payment.amount)}</span>
            </div>
          </div>
          <div className="py-2 px-4">
            <div className="flex flex-col">
              <span className="text-gray-500">Fecha</span>
              <span className="text-gray-800">
                {parseTimestampToDateLong(payment.date)}
              </span>
            </div>
          </div>
          <div className="py-2 px-4">
            <div className="flex flex-col">
              <span className="text-gray-500">Estatus</span>
              <span className="text-gray-800">{payment.status}</span>
            </div>
          </div>
        </div>
        <button onClick={() => null} className="hidden" ref={focusRef} />
      </div>
    </Dialog>
  )
}
