import React from 'react'
// COMPONENTS
import ModalLayout from '../../UI/ModalLayout'
import ModalAssignUser from '../ModalCreateProject/ModalAssignUser'

const ModalInviteToProject = ({
  step,
  project,
  loading,
  curatedUsers,
  handleModals,
  usersToAssign,
  handleUserOption,
  setUsersToAssign,
  errorUsersProject,
  validateAssignments,
}) => {
  return (
    <ModalLayout
      modal="cproject"
      handler={handleModals}
      extended={step === 'assign' ? true : false}
      goBackText="Proyecto"
      title={step === 'assign' ? 'Asignar proyecto' : 'Usuarios invitados'}
      regular={step === 'success'}
    >
      {step === 'assign' ? (
        <ModalAssignUser
          project={project}
          loading={loading}
          users={curatedUsers}
          usersToAssign={usersToAssign}
          handleUserOption={handleUserOption}
          setUsersToAssign={setUsersToAssign}
          errorUsersProject={errorUsersProject}
          startDate={project.startDate.toDate()}
          validateAssignments={validateAssignments}
        />
      ) : null}
      {step === 'success' ? (
        <div className="w-full flex flex-col items-center">
          <p className="text-sm sm:text-base md:text-lg text-center font-poppins text-gray-500">
            Los usuarios han sido invitados a unirse al proyecto {project.pname}
            .
          </p>
        </div>
      ) : null}
    </ModalLayout>
  )
}

export default ModalInviteToProject
