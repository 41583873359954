import React from 'react'
// UI
import Calendar from '../../Utils/Images/Calendar.png'
import { SingleProjectOptions } from '../../Utils/UI/Selects'
import { ProfileCardInProject } from '../../Utils/UI/ProfileCard'
import UserDefault from '../../Utils/Images/UserDefault.png'
// HELPERS
import { parseDateFullMonth } from '../../Utils/helpers'
import { SecondaryButton } from '../../Utils/UI/Buttons'

const SingleProject = ({
  users,
  project,
  setModal,
  handleModals,
  handleUserInProjectOptions,
  handleEditPayment
}) => {
  return (
    <>
      {project && (
        <div className="w-full">
          <div className="w-full flex space-x-4 md:space-x-10 mb-10">
            {/* PROJECT IMAGE */}
            <div className="w-auto">
              <div className="h-24 w-24 md:h-56 md:w-56">
                <img
                  src={project.pImage ? project.pImage : UserDefault}
                  alt="ProfileImage"
                  className="rounded-full h-full w-full object-center object-cover border border-gray-300"
                />
              </div>
            </div>
            {/* PROJECT DATA */}
            <div className="relative w-full sm:w-auto flex flex-col md:flex-row md:space-y-0 md:space-x-10">
              <div className="flex flex-col space-y-2 w-full">
                <div className="inline-flex items-center justify-between space-x-6">
                  <div className="inline-flex space-x-4 items-center">
                    <p className="font-poppins text-base sm:text-lg md:text-xl text-gray-800">
                      {project.pname}
                    </p>
                    <span
                      className={
                        (project.status === 'Finalizado'
                          ? 'bg-green-500 text-white '
                          : 'text-gray-800 bg-gray-300 ') +
                        ' hidden sm:block font-poppins text-base rounded-full px-2 py-1 min-w-max'
                      }
                    >
                      {project.status}
                    </span>
                  </div>
                  {/* PROJECT ACTIONS */}
                  <div className="hidden md:inline-flex items-center space-x-4 w-auto">
                    <div className="w-full sm:max-w-min">
                      <SecondaryButton
                        size="xl"
                        children="Invitar"
                        handler={() => setModal('inviteToProject')}
                      />
                    </div>
                    <div className="min-w-max pt-1">
                      <SingleProjectOptions handler={handleModals} />
                    </div>
                  </div>
                </div>
                <p className="font-poppins text-xs sm:text-base md:text-lg text-gray-500 m-0 w-full">
                  {project.description}
                </p>
                <span
                  className={
                    (project.status === 'Finalizado'
                      ? 'bg-green-500 text-white '
                      : 'text-gray-800 bg-white border border-gray-300 ') +
                    ' block sm:hidden font-poppins text-xs rounded-full px-2 py-1 self-start'
                  }
                >
                  {project.status}
                </span>
                <div className="inline-flex justify-start pb-4 font-poppins items-center space-x-2">
                  <img src={Calendar} alt="Date" />
                  <span className="text-sm sm:text-base text-gray-500">
                    {parseDateFullMonth(project.startDate)}
                  </span>
                </div>
              </div>
              {/* PROJECT ACTIONS */}
              <div className="inline-flex md:hidden items-center space-x-4 w-auto">
                <div className="w-full sm:max-w-min">
                  <SecondaryButton
                    size="sm"
                    children="Invitar"
                    handler={() => setModal('inviteToProject')}
                  />
                </div>
                <div className="min-w-max pt-1">
                  <SingleProjectOptions handler={handleModals} />
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-8">
            {project.users?.map((user, i) => (
              <ProfileCardInProject
                key={i}
                isExtension={user.isExtension}
                user={users.find((u) => u.id === user.user)}
                handleUserInProjectOptions={handleUserInProjectOptions}
                project={project}
                handleEditPayment={handleEditPayment}
              />
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default SingleProject
