import { atom } from 'recoil'

export const layoutState = atom({
  key: 'layoutOptions',
  default: {
    admin: ['home', 'proyectos', 'comunidad', 'pagos'],
    user: ['home', 'perfil'],
  },
})

/* export const modalState = atom({
  key: 'activeModal',
  default: {
    invite: false,
    cproject: false,
    addPayment: false,
    openBankData: false,
  },
}) */

export const formDataState = atom({
  key: 'formData',
  default: {
    personalData: {
      fname: '',
      lname: '',
      email: '',
      phone: '',
      birthdate: '',
      role: '',
      password: '',
      cuit: '',
      address: '',
    },
    isNewUser: true,
    signature: '',
  },
})

export const sessionUserState = atom({
  key: 'user',
  default: {},
})

export const usersState = atom({
  key: 'users',
  default: [],
})

export const projectsState = atom({
  key: 'projects',
  default: [],
})

export const paymentsState = atom({
  key: 'payments',
  default: [],
})

export const pastPaymentsState = atom({
  key: 'pastPayments',
  default: [],
})

export const futurePaymentsState = atom({
  key: 'futurePayments',
  default: [],
})

export const userProjectsState = atom({
  key: 'userProjects',
  default: {
    active: [],
    finished: [],
    invites: [],
  },
})

export const userPaymentsState = atom({
  key: 'userPayments',
  default: [],
})

export const sessionUserLoadingState = atom({
  key: 'sessionUserLoading',
  default: true,
})

export const usersLoadingState = atom({
  key: 'usersLoading',
  default: true,
})

export const projectsLoadingState = atom({
  key: 'projectsLoading',
  default: true,
})

export const paymentsLoadingState = atom({
  key: 'paymentsLoading',
  default: true,
})
