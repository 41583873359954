import React, { useState, useEffect, useCallback } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import NumberFormat from 'react-number-format'
// UI
import To from '../../Images/To.png'
import { ButtonSlector, PrimaryButton, SecondaryButton } from '../../UI/Buttons.js'
import { getUser } from '../../../../services/db'
import { Dropdown, DropdownWithChips } from '../../UI/Items.js'

const inputStyle = (error) =>
  error
    ? 'appearance-none block w-full px-3 py-2 border rounded-md sm:placeholder-transparent sm:bg-transparent font-poppins font-medium focus:outline-none bg-gray-50 sm:text-sm border-red-500 placeholder-gray-400 focus:ring-red-500 focus:border-red-500'
    : 'appearance-none block w-full px-3 py-2 border rounded-md sm:placeholder-transparent sm:bg-transparent font-poppins font-medium focus:outline-none bg-gray-50 sm:text-sm border-gray-300 placeholder-gray-400 focus:ring-purple focus:border-purple'

const labelStyle = (error) =>
  error
    ? 'text-sm text-red-500 font-poppins font-medium'
    : 'text-sm text-gray-800 font-poppins font-medium'

const ShareAssignment = ({
  user,
  index,
  startDate,
  usersToAssign,
  setUsersToAssign,
  handleUserOption,
  errorUsersProject,
}) => {
  const [data, setData] = useState({
    user: user,
    startDate: '',
    endDate: '',
    payments: [],
    currency: 'ARS',
  })
  const [userData, setUserData] = useState(null)
  const [pesos, setPesos] = useState(true)

  const getUserData = useCallback(async () => {
    let fetchUserData = await getUser(user)
    setUserData(fetchUserData.user)
  }, [user])

  useEffect(() => getUserData(), [getUserData])

  const handleDates = (key, date) => setData({ ...data, [key]: date })

  const handlePayment = (e) => {
    let i = e.target.name
    let value = e.target.value.replace(/\W/g, '')
    //add space after currency symbol
    data.payments[i].amount = value.slice(3)
    console.log('data', data)
    setData({ ...data, payments: data.payments })
    usersToAssign[index] = data
    setUsersToAssign([...usersToAssign])
  }

  const handlePaymentDate = (date, i) => {
    data.payments[i].date = date
    setData({ ...data, payments: data.payments })
    usersToAssign[index] = data
    setUsersToAssign([...usersToAssign])
  }

  const addPayment = () => {
    let newPayment = {
      paymentNumber: data.payments.length + 1,
      amount: '',
      date: '',
    }
    setData({ ...data, payments: [...data.payments, newPayment] })
    usersToAssign[index] = data
    setUsersToAssign([...usersToAssign])
  }

  const deletePayment = (i) => {
    data.payments.splice(i, 1)
    setData({ ...data, payments: data.payments })
    usersToAssign[index] = data
    setUsersToAssign([...usersToAssign])
  }

  const updatePaymentsCurrency = (curr) => {
    console.log('curr', curr)
    console.log('data', data)
    const paymentUpdated = data.payments.map((paym)=>{
      if(!paym.amount) return paym
      const amount = paym.amount
      return { ...paym, amount }
    })
    console.log('paymentUpdated', paymentUpdated)
    setData({ ...data, payments: paymentUpdated, currency: curr })
    usersToAssign[index] = { ...data, payments: paymentUpdated, currency: curr }
    setUsersToAssign([...usersToAssign])
  }

  const handleCoinToggle = () => {
    console.log('pesos', pesos)
    setPesos(!pesos)
    pesos ? updatePaymentsCurrency('USD') : updatePaymentsCurrency('ARS')
  }

  return (
    <div className="flex flex-col space-y-4">
      <div className="py-1 mb-4 font-poppins sm:text-base inline-flex items-center text-gray-800 space-x-2">
        <span>Perfil:</span>
        <span>{userData && userData.personalData.fullName}</span>
        <button
          onClick={() => handleUserOption('event', index, user)}
          className="h-full focus:outline-none bg-transparent sm:text-sm text-gray-500 hover:text-red-500 inline-flex items-center"
        >
          <svg
            width="36"
            height="35"
            viewBox="0 0 36 35"
            fill="none"
            className="h-6 w-auto"
          >
            <path
              d="M17.8874 2.90208C9.86313 2.90208 3.37695 9.38826 3.37695 17.4125C3.37695 25.4368 9.86313 31.923 17.8874 31.923C25.9117 31.923 32.3979 25.4368 32.3979 17.4125C32.3979 9.38826 25.9117 2.90208 17.8874 2.90208ZM25.1426 22.6218L23.0967 24.6678L17.8874 19.4585L12.6782 24.6678L10.6322 22.6218L15.8414 17.4125L10.6322 12.2033L12.6782 10.1573L17.8874 15.3666L23.0967 10.1573L25.1426 12.2033L19.9334 17.4125L25.1426 22.6218Z"
              fill="currentColor"
            />
          </svg>
        </button>
      </div>

      <div className="w-full inline-flex space-x-2 justify-between items-center">
        <div className="w-full flex flex-col items-baseline space-y-1">
          <label
            htmlFor="startDate"
            className={labelStyle(
              errorUsersProject && !data.startDate ? true : false
            )}
          >
            Inicio
          </label>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selectsStart
            selected={data.startDate}
            startDate={data.startDate}
            endDate={data.endDate}
            minDate={startDate}
            readonly
            popperPlacement="top-start"
            onChange={(date) => handleDates('startDate', date)}
            className={inputStyle(
              errorUsersProject && !data.startDate ? true : false
            )}
          />
        </div>

        <div className="w-10 flex flex-col items-baseline space-y-1 pt-6">
          <img src={To} alt="to" />
        </div>

        <div className="w-full flex flex-col items-baseline space-y-1">
          <label
            htmlFor="dates"
            className={labelStyle(
              errorUsersProject && !data.endDate ? true : false
            )}
          >
            Finalización
          </label>
          <DatePicker
            selectsEnd
            dateFormat="dd/MM/yyyy"
            selected={data.endDate}
            startDate={data.startDate}
            endDate={data.endDate}
            minDate={startDate}
            popperPlacement="top-end"
            onChange={(date) => handleDates('endDate', date)}
            className={inputStyle(
              errorUsersProject && !data.endDate ? true : false
            )}
          />
        </div>
      </div>
      <div className="w-full inline-flex space-x-4 justify-between items-center">
        <div className="w-full flex flex-col items-baseline space-y-1">
          <label>Moneda</label>
          <ButtonSlector type="button" size="lg" children="ARS" isDisabled={!pesos} handler={handleCoinToggle}/>
        </div>
        <div className="w-full flex flex-col items-baseline space-y-1 mt-7">
          <ButtonSlector type="button" size="lg" children="USD" isDisabled={pesos} handler={handleCoinToggle}/>
        </div>
      </div>
      {data.payments.map((payment, i) => (
        <div className="flex flex-col space-y-2 items-baseline" key={i}>
          <div className="pt-4 space-x-2 font-poppins font-medium sm:text-sm  inline-flex justify-center group">
            <span className="h-5">Cuota {i + 1}</span>
            <button
              onClick={() => deletePayment(i)}
              className="h-max-content focus:outline-none group flex items-center"
            >
              <svg
                width="10"
                height="15"
                viewBox="0 0 11 14"
                fill="none"
                className="h-5 w-auto text-gray-500 group-hover:text-red-500"
              >
                <path
                  d="M0.777778 12.4444C0.777778 13.3 1.47778 14 2.33333 14H8.55556C9.41111 14 10.1111 13.3 10.1111 12.4444V3.11111H0.777778V12.4444ZM10.8889 0.777778H8.16667L7.38889 0H3.5L2.72222 0.777778H0V2.33333H10.8889V0.777778Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          </div>
          <div className="w-full inline-flex space-x-4 justify-between items-center">
            <div className="w-full flex flex-col items-baseline space-y-1">
              <label
                htmlFor="amount"
                className={labelStyle(
                  errorUsersProject && !payment.amount ? true : false
                )}
              >
                Monto
              </label>
              <NumberFormat
                type="text"
                name={i}
                value={payment.amount}
                thousandSeparator={true}
                prefix={pesos ? 'ARS $ ' : 'USD $ '}
                className={inputStyle(
                  errorUsersProject && !payment.amount ? true : false
                )}
                onChange={handlePayment}
              />
            </div>
            <div className="w-full flex flex-col items-baseline space-y-1">
              <label
                htmlFor={`paymentDate${i}`}
                className={labelStyle(
                  errorUsersProject && !payment.date ? true : false
                )}
              >
                Fecha
              </label>
              <DatePicker
                id={`paymentDate${i}`}
                dateFormat="dd/MM/yyyy"
                selected={payment.date}
                minDate={startDate}
                popperPlacement="top-end"
                onChange={(date) => handlePaymentDate(date, i)}
                className={inputStyle(
                  errorUsersProject && !payment.date ? true : false
                )}
              />
            </div>
          </div>
        </div>
      ))}
      {errorUsersProject && data.payments.length === 0 ? (
        <p className="font-poppins font-medium sm:text-sm text-red-500">
          Agrega al menos 1 cuota
        </p>
      ) : null}
      <SecondaryButton
        type="button"
        size="lg"
        children="+ Agregar cuota"
        handler={addPayment}
        error={errorUsersProject}
      />
    </div>
  )
}

export default ShareAssignment
