import { useState } from 'react'
import ModalLayout from '../UI/ModalLayout'
import { PrimaryButton, SecondaryButton } from '../UI/Buttons'

const ModalConfirmAction = ({
  text,
  loading,
  handler,
  handleModal,
  confirmationText,
}) => {
  const [success, setSuccess] = useState(false)
  
  return (
    <ModalLayout 
    regular 
    title={success ? '' : text}
    handler={handleModal} 
    modal="confirmAction"
    goBackText='Proyecto'
    >
      {!success ? (
        <>
          <div className="w-full inline-flex justify-end">
            <div className="flex flex-col space-y-4 w-full sm:w-min sm:space-y-0 sm:flex-row sm:inline-flex sm:space-x-4">
              <SecondaryButton
                loading={loading}
                disabled={loading}
                type="button"
                size="md"
                children="Confirmar"
                handler={async () => {
                  await handler()
                  setSuccess(true)
                }}
              />
              <PrimaryButton
                disabled={loading}
                type="button"
                size="md"
                children="Cancelar"
                handler={handleModal}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="w-full flex flex-col items-center space-y-8">
          <h3 className="text-base sm:text-lg sm:dm-h3 w-full mb-8 text-center">
            {confirmationText}
          </h3>
        </div>
      )}
    </ModalLayout>
  )
}

export default ModalConfirmAction
