import React from 'react'
import { PrimaryButton } from '../UI/Buttons'
import Logo from '../Images/Logo.png'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: '', info: '' }
    this.componentDidCatch = this.componentDidCatch.bind(this)
  }

  static getDerivedStateFromError = (error) => {
    return { hasError: true }
  }

  componentDidCatch = (error, info) => {
    this.setState({ ...this.state, error, info })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="bg-white h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8 font-poppins">
          <div className="max-w-max mx-auto">
            <div className="w-full flex justify-center py-8">
              <img src={Logo} alt="Itesa" />
            </div>
            <main className="sm:flex px-4 sm:max-w-4xl">
              <p className="text-xl font-extrabold text-purple">
                404
              </p>
              <div className="sm:ml-6">
                <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                  <h1 className="text-xl font-extrabold text-gray-900 tracking-tight">
                    Internal error 😞
                  </h1>
                  <p className="mt-1 text-base text-gray-500">
                    {this.state.error.message ? this.state.error.message : 'Something went wrong...'}
                  </p>
                </div>
                <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                  <a href="/login">
                    <PrimaryButton
                      size="md"
                      children="Volver al inicio"
                      handler={null}
                    />
                  </a>
                </div>
              </div>
            </main>
          </div>
        </div>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
