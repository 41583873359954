import JSZip from 'jszip'
import { DateTime } from 'luxon'
import { listOfPaymentInvoices } from '../../services/storage'

export const fileToUrl = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

export const PdfToArrayBuffer = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsArrayBuffer(file)
  })
}
export const oldSkillsList = [
  'Node JS',
  'React',
  'React Native',
  'Firebase',
  'AWS',
  'MongoDB',
  'SQL',
  'MySQL',
  'PHP',
  'Laravel',
  'AR.JS',
  'Next.js',
  'Gatsby',
  '.NET',
  'Kotlin',
  'Swift',
  'Java',
  'C++',
  'C',
  'C *',
  'Python',
  'Django',
  'Azure',
  'Bootstrap',
  'Ant Design',
  'Truffle',
  'Web3',
  'Solidity',
  'Sass',
]

const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
]

export const monthsShort = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sep',
  'Oct',
  'Nov',
  'Dic',
]

export const parseDateFullMonth = (date) =>
  date.toDate().getDate() +
  ' ' +
  months[date.toDate().getMonth()] +
  ' ' +
  date.toDate().getFullYear()

  export const parseDate = (timestamp) => {
    //convert date firestore timestamp to date
    const date =new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
    console.log('date', date);
    
    // Check if date is a Firestore Timestamp
    if (date && typeof date.toDate === 'function') {
      date = date.toDate();
    }else{
      console.log('no es un timestamp',date);
    }
    
    // Check if date is a valid Date object
    if (!(date instanceof Date) || isNaN(date)) {
      console.log('no es una fecha',date);
      return 'Fecha no válida';
    }
    let year = date.getFullYear();

    return (
      date.getDate() +
      '/' +
      (date.getMonth() + 1) +
      '/' +
      year.toString().substring(2, 4)
    );
  };
  

export const parseTimestampToDateLong = (date) =>
  DateTime.fromSeconds(date.seconds).setLocale('es-AR').toLocaleString({
    month: 'long',
    day: 'numeric',
    // year: '2-digit',
  })

export const toPesos = (number) => {
  let pesos = new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })
  return pesos.format(number)
}

export const toDollars = (number) => {
  let dollars = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })
  return dollars.format(number)
}

export const JSONDownloadPayments = (approvedPayments) => {
  // FORMAT THE DATA PROPERLY FOR THE JSON FILE
  const data = () =>
    approvedPayments.map((payment) => {
      let formattedDate = payment.date.toDate()

      let month =
        formattedDate.getMonth() + 1 < 10
          ? '0' + (formattedDate.getMonth() + 1)
          : formattedDate.getMonth() + 1

      let day =
        formattedDate.getDate() < 10
          ? '0' + formattedDate.getDate()
          : formattedDate.getDate()

      let paymentDate = formattedDate.getFullYear() + month + day + ''

      let cuit = payment.user.bankData.cuit
      let formattedCuit = cuit.replace(/-/g, '')

      return {
        referencia: payment.project.toUpperCase(),
        importe: payment.amount,
        fecha_de_pago: paymentDate.toUpperCase(),
        beneficiario: {
          cbu: payment.user.bankData.cbu,
          cuix: formattedCuit,
          denominacion: payment.user.personalData.role.toUpperCase(),
          email: payment.user.personalData.email.toUpperCase(),
        },
      }
    })

  // FUNCTION THAT GENERATES AND DOWNLOAD THE JSON FILE WITH THE APPROVED PAYMENTS
  const downloadData = async () => {
    let date = new Date()
    const myData = {
      version: '2.0',
      transferencias: data(),
    }

    let month =
      date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1

    let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()

    const fileName =
      'SPV_Transferencias_Masivas_' + date.getFullYear() + month + day

    const json = JSON.stringify(myData)
    const blob = new Blob([json], { type: 'application/json' })
    const href = await URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = href
    link.download = fileName + '.json'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return downloadData()
}

export const donwloadZipFile = async (invoiceZipPayments) => {
  let listOfFiles = await listOfPaymentInvoices(invoiceZipPayments)
  var zip = new JSZip()
  let counter = 0
  for (let file of listOfFiles) {
    zip.file(`Invoice${counter}.pdf`, file)
    counter++
  }
  try {
    let zipFile = await zip.generateAsync({ type: 'blob' })
    if (zipFile) {
      let month = DateTime.now().monthLong
      let year = DateTime.now().year
      const href = await URL.createObjectURL(zipFile)
      const link = document.createElement('a')
      link.href = href
      link.download = 'Facturas' + month + year + '.zip'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  } catch (error) {
    console.error(error)
  }
}
