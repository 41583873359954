import ModalLayout from '../../UI/ModalLayout'
import { Input, InputCuit } from '../../UI/Inputs'
import { PrimaryButton, SecondaryButton } from '../../UI/Buttons'
import { SelectRegister } from '../../UI/Selects'
import { LoaderComponent } from '../../UI/Loaders'
import { deleteBankDetails } from '../../../../services/db'
import { useEffect, useState } from 'react'

const ModalEditBankDetails = ({
  error,
  loading,
  success,
  formData,
  invalidCbu,
  invalidCuit,
  validateForm,
  handleModals,
  handleInputs,
  sessionUser,
  fetchUserData,
  onlyView
}) => {

  const deleteBank = async () => {
    console.log("deleteBank")
    await deleteBankDetails(sessionUser.id, formData.id)
    fetchUserData()
    handleModals()
  }

  const [type, setType] = useState('')

  useEffect(() => {
    if(formData){
      setType(formData.type)
    }
  }, [formData])

  return (
    <ModalLayout
      goBackText="Home"
      handler={handleModals}
      modal="editBankDetails"
      title={success ? 'Datos Bancarios' : 'Cuenta bancaria'}
      regular={success ? true : false}
    >
      {type === 'arg' && (
          <div className="w-full flex flex-col space-y-4">
            <Input
              disabled={onlyView}
              type="text"
              label="Nombre"
              name="name"
              value={formData.name}
              handler={handleInputs}
              error={error}
            />
            <Input
              disabled={onlyView}
              type="text"
              label="Banco"
              name="bank"
              value={formData.bank}
              handler={handleInputs}
              error={error}
            />
            <Input
              disabled={onlyView}
              type="text"
              label="Titular de la cuenta"
              name="accountOwner"
              value={formData.accountOwner}
              handler={handleInputs}
              error={error}
            />
            <div>
              <Input
                disabled={onlyView}
                type="number"
                label="CBU"
                name="cbu"
                value={formData.cbu}
                handler={handleInputs}
                error={error}
              />
              {invalidCbu ? (
                <p className="text-sm font-medium font-poppins text-red-600 mt-2">
                  Ingrese un CBU válido
                </p>
              ) : null}
            </div>
            <div className="w-full space-y-2">
              <Input
                disabled={onlyView}Cuit
                type="text"
                label="Cuit"
                name="cuit"
                value={formData.cuit}
                handler={handleInputs}
                error={error}
              />
              {invalidCuit ? (
                <p className="text-sm font-medium font-poppins text-red-600">
                  Ingrese un Cuit válido
                </p>
              ) : null}
            </div>
            <Input
              disabled={onlyView}
              type="text"
              label="Domicilio fiscal"
              name="address"
              value={formData.address}
              handler={handleInputs}
              error={error}
            />
            <SelectRegister
              disabled={onlyView}
              label="Tipo de factura a emitir"
              options={['A', 'C']}
              value={formData.invoice}
              handleInputs={handleInputs}
              data="invoice"
              error={error}
            />
          </div>
      )}
      {(type === 'wise' || type === 'zelle') && (
        <div className="w-full flex flex-col space-y-4">
          <Input
            disabled={onlyView}
            type="text"
            label="Nombre"
            name="name"
            value={formData.name}
            handler={handleInputs}
            error={error}
          />
          <Input
            disabled={onlyView}
            type="text"
            label="Email"
            name="email"
            value={formData.email}
            handler={handleInputs}
            error={error}
          />
          <SelectRegister
            disabled={onlyView}
            label="Tipo de factura a emitir"
            options={['A', 'C', 'Invoice']}
            value={formData.invoice}
            handleInputs={handleInputs}
            data="invoice"
            error={error}
          />
         
        </div>
      )}
      {type === 'ach' && (
        <div className="w-full flex flex-col space-y-4">
          <Input
            disabled={onlyView}
            type="text"
            label="Nombre"
            name="name"
            value={formData.name}
            handler={handleInputs}
            error={error}
          />
          <Input
            disabled={onlyView}
            type="text"
            label="Routing Number"
            name="routingNumber"
            value={formData.routingNumber}
            handler={handleInputs}
            error={error}
          />
          <Input
            disabled={onlyView}
            type="text"
            label="Account Number"
            name="accountNumber"
            value={formData.accountNumber}
            handler={handleInputs}
            error={error}
          />
          <Input
            disabled={onlyView}
            type="text"
            label="Beneficiary Name"
            name="beneficiaryName"
            value={formData.beneficiaryName}
            handler={handleInputs}
            error={error}
          />
          <Input
            disabled={onlyView}
            type="text"
            label="Bank Name"
            name="bankName"
            value={formData.bankName}
            handler={handleInputs}
            error={error}
          />
          <SelectRegister
            disabled={onlyView}
            label={'Account Type'}
            options={['Checking', 'Savings']}
            value={formData.accountType}
            handleInputs={handleInputs}
            data="accountType"
            error={error}
          />
          <SelectRegister
            disabled={onlyView}
            label="Tipo de factura a emitir"
            options={['A', 'C', 'Invoice']}
            value={formData.invoice}
            handleInputs={handleInputs}
            data="invoice"
            error={error}
          />
         
        </div>
      )}
      {type === 'usdt' && (
        <div className="w-full flex flex-col space-y-4">
          <Input
            disabled={onlyView}
            type="text"
            label="Nombre"
            name="name"
            value={formData.name}
            handler={handleInputs}
            error={error}
          />
          <Input
            disabled={onlyView}
            type="text"
            label="Wallet Address"
            name="walletAddress"
            value={formData.walletAddress}
            handler={handleInputs}
            error={error}
          />
          <SelectRegister
            disabled={onlyView}
            label="Tipo de factura a emitir"
            options={['A', 'C', 'Invoice']}
            value={formData.invoice}
            handleInputs={handleInputs}
            data="invoice"
            error={error}
          />
         
        </div>
      )}
      {(!success && !onlyView) && (
         <div className="py-6">
         {loading ? (
           <LoaderComponent />
         ) : (
           <div className='flex gap-3 flex-col'> 
             <PrimaryButton
               type="submit"
               size="lg"
               children="Confirmar"
               handler={validateForm}
               className="mb-4"
             />
             <SecondaryButton
               size="lg"
               children="Eliminar"
               handler={deleteBank}
               data="deleteBankDetails"
             />
           </div>
         )}
       </div>
      )}
      {success && !loading ? (
        <div className="w-full">
          <p className="text-lg w-full text-center font-poppins text-gray-500">
            Los datos bancarios han sido editados correctamente.
          </p>
        </div>
      ) : null}
    </ModalLayout>
  )
}

export default ModalEditBankDetails
