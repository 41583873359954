import React, { useEffect, useState } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
// FIREBASE
import { db } from '../../services/firebase'
import { useAuth } from '../../services/firebase-auth-context'
// RECOIL
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil'
import {
  usersState,
  projectsState,
  paymentsState,
  sessionUserState,
  sessionUserLoadingState,
  usersLoadingState,
  projectsLoadingState,
  paymentsLoadingState,
  futurePaymentsState,
  pastPaymentsState,
} from '../../atoms/index'
// UI
import DashboardLayout from '../Utils/UI/DashboardLayout'
// COMPONENTS
import AdminHome from './AdminHome'
import CommunityContainer from './Community'
import ProjectsContainer from './Projects'
import SingleUserContainer from './SingleUser'
import SingleProjectContainer from './SingleProject'
import { FullPageLoaderComponent } from '../Utils/UI/Loaders'
import ModalInviteContainer from '../Utils/Modals/ModalInvite'
import ModalCProjectContainer from '../Utils/Modals/ModalCreateProject'
import ModalAddPayment from '../Utils/Modals/ModalAddPayment'
import Payments from './Payments'

const { REACT_APP_ADMIN_UID } = process.env

const AdminContainer = () => {
  const user = useAuth()
  const history = useHistory()
  const [sessionUserLoading, setSessionUserLoading] = useRecoilState(
    sessionUserLoadingState
  )
  const setSessionUser = useSetRecoilState(sessionUserState)
  const setUsers = useSetRecoilState(usersState)
  const setProjects = useSetRecoilState(projectsState)
  const setPayments = useSetRecoilState(paymentsState)
  const setPastPayments = useSetRecoilState(pastPaymentsState)
  const setFuturePayments = useSetRecoilState(futurePaymentsState)
  const setUsersLoading = useSetRecoilState(usersLoadingState)
  const setProjectsLoading = useSetRecoilState(projectsLoadingState)
  const setPaymentsLoading = useSetRecoilState(paymentsLoadingState)
  const [modals, setModals] = useState({
    invite: false,
    cproject: false,
    addPayment: false,
  })
  const [paymentToUpload, setPaymentToUpload] = useState(null)

  const handleModal = (e, modal) => {
    if(modal === 'addPayment') {
      if(modals.addPayment) {
        setPaymentToUpload(null)
      }
    }
    return modals[modal]
      ? setModals({ ...modals, [modal]: false })
      : setModals({ ...modals, [modal]: true })
  }

  useEffect(() => {
    if (user) {
      setSessionUserLoading(false)
      if (user.uid === REACT_APP_ADMIN_UID) {
        setSessionUser({
          personalData: { fullName: 'Admin', role: 'Admin' },
          isAdmin: true,
        })
      } else {
        history.push('/user/home')
      }
    } else {
      return history.push('/login')
    }
    // eslint-disable-next-line
  }, [user])

  function deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }
  
  const getUsers = async () => {
    return new Promise((resolve, reject) => {
      const unsubscribe = db.collection('users').onSnapshot(
        (query) => {
          setUsersLoading(true);
          let updatedUsers = [];
          query.forEach((doc) => updatedUsers.push(doc.data()));
          setUsers(updatedUsers);
          setUsersLoading(false);
          unsubscribe(); // Unsubscribe from the snapshot listener
          resolve(); // Resolve the promise once all operations are done
        },
        (error) => {
          console.error('Error fetching users data:', error);
          unsubscribe();
          reject(error); // Reject the promise in case of error
        }
      );
    });
  };

  const getProjects = async () => {
    console.log('Fetching Projects data 🚀',users.length);
    if(users.length === 0) return
    return new Promise((resolve, reject) => {
      db.collection('projects').onSnapshot(
        (query) => {
          setProjectsLoading(true);
          let updatedProjects = [];
          query.forEach((doc) => {
            // Deep clone the project object
            let project = deepClone(doc.data());
            if (!project.users) {
              project.users = [];
            } else {
              project.users = project.users.map((user) => {
                // Find the user in the users array
                let foundUser = users.find((u) => u.id === user.user);
                return ({ ...user, ...foundUser });
              }); // Initialize profileImage
            }
            updatedProjects.push(project);
          });
          console.log('Updated Projects:', updatedProjects.length);
          setProjects(updatedProjects);
          setProjectsLoading(false);
          resolve();
        },
        (error) => {
          console.error('Error fetching projects data:', error);
          reject(error);
        }
      );
    });
  };
  
  const getPayments = async () => {
    console.log('Fetching Payments data 🚀');
    return new Promise((resolve, reject) => {
      const unsubscribe = db.collection('payments').onSnapshot(
        (query) => {
          setPaymentsLoading(true);
          let updatedPayments = [];
          query.forEach((doc) => { 
            let data = doc.data();
            const userId = data.user;
            const user = users.find((user) => user.id === userId);
            console.log('User:', user);
            data.user = user;
            return updatedPayments.push(data);
          });
          const sortedPayments = updatedPayments.sort((a, b) => b.date.seconds - a.date.seconds);
          const updatedPaymentsWithNumber = addPaymentNumber(sortedPayments);
          setPayments(updatedPaymentsWithNumber);
          console.log('Updated Payments:', updatedPaymentsWithNumber)
          let now = new Date();
          const updatedPastPayments = updatedPaymentsWithNumber.filter(
            (payment) => now > payment.date.toDate()
          );
          setPastPayments(updatedPastPayments);
          const updatedFuturePayments = updatedPaymentsWithNumber.filter(
            (payment) => now < payment.date.toDate()
          );
          setFuturePayments(updatedFuturePayments);
          setPaymentsLoading(false);
          console.log('Payments data fetched 🚀');
          unsubscribe(); // Unsubscribe from the snapshot listener
          resolve(); // Resolve the promise once all operations are done
        },
        (error) => {
          console.error('Error fetching payments data:', error);
          unsubscribe();
          reject(error); // Reject the promise in case of error
        }
      );
    });
  };
  
  
  const addPaymentNumber = (payments) => {
    // Group payments by user ID and project
    const groupedPayments = payments.reduce((acc, payment) => {
      const key = `${payment.user.id}-${payment.project}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(payment);
      return acc;
    }, {});
  
    // Sort each group by date and assign paymentNumber
    Object.values(groupedPayments).forEach(group => {
      group.sort((a, b) => a.date.seconds - b.date.seconds); // Sort by date
      group.forEach((payment, index) => {
        payment.paymentNumber = index + 1; // Assign paymentNumber
      });
    });
  
    return payments;
  };

  useEffect(() => {
    fetchData()
  }, [])

  const users = useRecoilValue(usersState)
  const projects = useRecoilValue(projectsState)

  const fetchData = async () => {
    console.log('Fetching data 🚀')
    await getUsers()
  }

  useEffect(() => {
    console.log('Users:', users.length)
    if(users.length > 0) {
      getProjects(users)
    }
  }, [users])

  useEffect(() => {
    console.log('Projects:', projects)
    if(projects.length > 0) {
      getPayments()
    }
  }, [projects])

  const handleUploadPayment = async (payment) => {
    console.log('Uploading payment:', payment)
    setPaymentToUpload(payment)
    setModals({ ...modals, addPayment: true })
  }

  if (sessionUserLoading) {
    return <FullPageLoaderComponent />
  } else {
    return (
      <Switch>
        <DashboardLayout>
          {modals.invite && <ModalInviteContainer handleModal={handleModal} />}
          {modals.cproject && (
            <ModalCProjectContainer handleModal={handleModal} />
          )}
          {modals.addPayment && <ModalAddPayment handleModal={handleModal} fetchData={fetchData} payment={paymentToUpload}/>}

          <Route path="/admin/home">
            <AdminHome modals={modals} handleModal={handleModal} fetchData={fetchData}/>
          </Route>
          <Route exact path="/admin/proyectos">
            <ProjectsContainer getPayments={getPayments} getProjects={getProjects}/>
          </Route>
          <Route exact path="/admin/comunidad">
            <CommunityContainer />
          </Route>
          <Route path="/admin/comunidad/:userId">
            <SingleUserContainer />
          </Route>
          <Route path="/admin/proyectos/:projectId">
            <SingleProjectContainer getPayments={getPayments} getProjects={getProjects} refreshData={fetchData}/>
          </Route>
          <Route path="/admin/pagos">
            <Payments fetchData={getPayments} handleUploadPayment={handleUploadPayment}/>
          </Route>
          <Redirect from="/" to="/admin/home" />
        </DashboardLayout>
      </Switch>
    )
  }
}

export default AdminContainer


