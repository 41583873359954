import React, { useState } from 'react'
// UI
import ModalLayout from '../../UI/ModalLayout'
// IMAGES
import ViewInvoice from '../../Images/ViewInvoice.png'
import ArrowControlLeft from '../../Images/ArrowControlLeft.png'
import ArrowControlRight from '../../Images/ArrowControlRight.png'
import Search from '../../Images/SearchBlack.png'
import GoBack from '../../Images/GoBack.png'
// HELPERS
import { parseDate, toPesos } from '../../helpers.js'
import ModalPaymentsMobile from './ModalPaymentsMobile'
import { PaymentDetailMobile } from '../../UI/Items'
import {
  PaymentsDownloadOptions,
  PaymentsFilterBy,
  PaymentsFilterByMobile,
  PaymentsFilterByRange,
} from '../../UI/Selects'
import { deletePayment } from '../../../../services/db'
import ModalConfirmDelete from '../ModalConfirmDelete'
import { PrimaryButton, SecondaryButton } from '../../UI/Buttons.js'

const ModalViewPayments = ({
  slice,
  filter,
  payments,
  setSearch,
  handleModals,
  handleFilter,
  userSelected,
  excelPayments,
  slicePayments,
  paymentsLength,
  handleDownloads,
  handlePaymentsRange,
  paymentsRangeSelected,
  admin = false,
  handleEditPayment,
  handleNewPayment
}) => {
  const [searchBar, setSearchBar] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [paymentToDelete, setPaymentToDelete] = useState({})
  const [loading, setLoading] = useState(false)
  const [paymentDetail, setPaymentDetail] = useState({
    open: false,
    payment: null,
  })

  const handleEdit = (payment) => {
    handleEditPayment(payment)
  }

  const handleDelete = async () => {
    setLoading(true)
    await deletePayment(paymentToDelete.id)
    setPaymentToDelete({})
    setLoading(false)
  }

  const SinglePaymentDropdown = async (option, payment) => {
    if (option === 'invoice') {
      window.open(payment.invoice, '_blank')
    }
    if (option === 'paymentSettlement') {
      window.open(payment.paymentSettlement, '_blank')
    }
    if (option === 'details') {
      setPaymentDetail({
        open: true,
        payment: payment,
      })
    }
    if (option === 'delete') {
      console.log('Delete payment - ', payment)
      // setPaymentToDelete(payment)
      // setModalDelete(true)
    }
  }

  if (modalDelete) {
    return (
      <ModalConfirmDelete
        handleModals={() => setModalDelete(false)}
        handleDelete={handleDelete}
        loading={loading}
        children={'Payment delete'}
      />
    )
  }

  return (
    <ModalLayout
      handler={handleModals}
      extended='extended'
      modal="viewPayments"
      goBackText="Home"
      title={
        window.innerWidth > 648 ? (
          `Pagos de ${userSelected.personalData.fullName}`
        ) : (
          <div
            className={
              (searchBar ? 'hidden' : '') +
              ' w-full inline-flex items-center justify-between'
            }
          >
            <span>Ultimos pagos</span>
            {filter ? (
              <span className="inline-flex space-x-4">
                <div
                  className={
                    (searchBar ? 'hidden' : 'block') +
                    ' block sm:hidden focus:outline-none'
                  }
                  onClick={() => {
                    setSearchBar(!searchBar)
                    setSearch('')
                  }}
                >
                  <img src={Search} alt="Search" className="w-auto h-4" />
                </div>
                <div>
                  {admin ? (
                    <PaymentsDownloadOptions handler={handleDownloads} />
                  ) : null}
                </div>
              </span>
            ) : null}
          </div>
        )
      }
    >
      {paymentDetail.open && (
        <PaymentDetailMobile
          paymentDetail={paymentDetail}
          onClose={setPaymentDetail}
        />
      )}
      {filter ? (
        <div
          className={
            (searchBar
              ? 'w-full flex flex-col space-x-0'
              : 'hidden sm:inline-flex') +
            ' -mt-14 space-x-4 sm:space-x-8 mb-4 px-3'
          }
        >
          <div className="w-full rounded-md inline-flex justify-between items-center h-8 bg-white border border-gray-300">
            <div className="inline-flex items-center w-full h-full bg-gray-100">
              <img
                src={Search}
                alt="Search"
                className="hidden sm:block w-auto px-3"
              />
              <img
                src={GoBack}
                alt="Search"
                className="block sm:hidden h-4 w-auto px-3 cursor-pointer"
                onClick={() => setSearchBar(!searchBar)}
              />
              <input
                type="text"
                onChange={(e) => setSearch(e.target.value.toLowerCase())}
                className="appearance-none block w-full p-1 font-poppins border-transparent rounded-md focus:outline-none sm:text-sm placeholder-gray-400 focus:ring-0 h-full border-0 bg-transparent"
              />
            </div>
            <div className="z-30 w-auto block sm:hidden pt-1 px-2 bg-gray-100 rounded-md">
              <PaymentsFilterByMobile
                handleFilter={handleFilter}
                handlePaymentsRange={handlePaymentsRange}
              />
            </div>
          </div>
          <div className="hidden sm:inline-flex relative space-x-4">
            <PaymentsFilterBy handler={handleFilter} filter={filter} />
            <PaymentsFilterByRange
              handler={handlePaymentsRange}
              filter={paymentsRangeSelected}
            />
          </div>
        </div>
      ) : null}
      <div className="inline-flex justify-end items-center w-full px-4">
        <div className="inline-flex justify-center items-center space-x-4 pb-4">
          <button
            onClick={() => slicePayments('prev')}
            type="button"
            className="focus:outline-none"
          >
            <img
              src={ArrowControlLeft}
              alt="Previous"
              className="h-full w-auto"
            />
          </button>
          <p className="text-gray-800 font-poppins text-sm min-w-max">
            {slice.start + 1}-{slice.end} de {paymentsLength}
          </p>
          <button
            onClick={() => slicePayments('next')}
            type="button"
            className=" focus:outline-none"
          >
            <img src={ArrowControlRight} alt="Next" className="h-full w-auto" />
          </button>
        </div>
      </div>
      <div className="md:hidden relative w-full">
        <ModalPaymentsMobile
          admin={admin}
          payments={payments}
          SinglePaymentDropdown={SinglePaymentDropdown}
        />
      </div>
      <div className="hidden md:block w-full rounded-md border-2 border-gray-300">
        <table className="w-full bg-gray-300">
          <thead>
            <tr>
              <th scope="col" className="p-6">
                <span className="w-full inline-flex items-center space-x-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider focus:outline-none">
                  Pago
                </span>
              </th>
              {/* {userSelected && (
                <th scope="col" className="p-6">
                  <span className="w-full inline-flex items-center space-x-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider focus:outline-none">
                    Freelancer
                  </span>
                </th>
              )} */}
              {/* <th scope="col" className="p-6">
                <span className="w-full inline-flex items-center space-x-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider focus:outline-none">
                  Proyecto
                </span>
              </th> */}
              <th scope="col" className="p-6">
                <span className="w-auto inline-flex items-center space-x-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider focus:outline-none">
                  Factura
                </span>
              </th>
              <th scope="col" className="p-6">
                <span className="hidden lg:inline-flex w-auto items-center space-x-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider focus:outline-none">
                  Fecha de pago
                </span>
                <span className="lg:hidden inline-flex w-auto items-center space-x-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider focus:outline-none">
                  Fecha
                </span>
              </th>
              <th scope="col" className="p-6">
                <span className="w-auto inline-flex items-center space-x-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider focus:outline-none cursor-pointer">
                  Estado
                </span>
              </th>
              <th scope="col" className="p-6">
                <div className="w-auto inline-flex items-center space-x-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider focus:outline-none cursor-default">
                  Factura
                </div>
              </th>
              <th scope="col" className="p-6">
                <div className="w-auto inline-flex items-center space-x-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider focus:outline-none cursor-default">
                  Action
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-300 w-full">
            {payments.length ? (
              payments.map((payment, i) => (
                <tr key={i}>
                  {payment && (
                    <>
                      <td className="px-6 py-4 font-poppins">
                        <div className="flex items-center no-wrap">
                          {payment.currency + ' ' +(payment.amount)}
                        </div>
                      </td>
                      {/* {userSelected && (
                        <td className="px-6 py-4 text-sm text-gray-800 font-poppins">
                          {userSelected.personalData.fullName}
                        </td>
                      )} */}
                      {/* <td className="px-6 py-4 text-sm text-gray-800 font-poppins">
                        {payment.project?.pname
                          ? payment.project.pname
                          : payment.project}
                      </td> */}
                      <td className="px-6 py-4 text-sm text-center text-gray-800 font-poppins">
                        {payment.paymentNumber}
                        {payment.bono && ' Bono'}
                      </td>
                      <td className="px-6 py-4 text-sm text-center text-gray-800 font-poppins">
                        {parseDate(payment.date)}
                      </td>
                      <td className="px-6 py-4 text-sm text-center text-gray-800 font-poppins">
                        <span className={'px-4 py-1 rounded-full bg-gray-100'}>
                          {payment.status}
                        </span>
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 font-poppins">
                        <div className="inline-flex space-x-2 min-w-max justify-center w-full">
                          {payment.invoice && (
                            <a href={payment.invoice}>
                              <img
                                src={ViewInvoice}
                                alt="Ver factura"
                                className="w-6 h-6"
                              />
                            </a>
                          )}
                        </div>
                      </td>
                      {payment.status === 'Pendiente' ? (
                        <td className="px-6 py-4 text-sm text-gray-800 font-poppins">
                          <div className="inline-flex space-x-2 min-w-max justify-center w-full">
                            {admin ? (
                              <button
                                onClick={() =>
                                  handleEditPayment(payment)
                                }
                                className="text-red-500 hover:text-red-700"
                              >
                                Edit
                              </button>
                            ) : null}
                          </div>
                        </td>
                      ) : (
                        <td className="px-6 py-4 text-sm text-gray-800 font-poppins">
                          <div className="inline-flex space-x-2 min-w-max justify-center w-full">
                          </div>
                        </td>
                      )}
                    </>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td className="text-center p-6 text-sm text-gray-500 font-poppins w-max">
                  No se encontraron pagos
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <br />
      <SecondaryButton size="xl" children="+ Agregar Pago | Bono" handler={handleNewPayment} />
    </ModalLayout>
  )
}

export default ModalViewPayments
