import { InputPdf } from '../../Utils/UI/Inputs'
import ModalLayout from '../../Utils/UI/ModalLayout'
import { PrimaryButton } from '../../Utils/UI/Buttons'
import { LoaderComponent } from '../../Utils/UI/Loaders'

export const UploadPayment = ({
  file,
  upload,
  history,
  loading,
  success,
  handlePdf,
  paymentUpload,
}) => (
  <ModalLayout
    goBackText="Home"
    regular={success ? true : false}
    handler={() => history.push('/user/home')}
    title={!success ? 'Sube tu factura' : '¡La factura ha sido cargada!'}
  >
    <>
      {!success ? (
        <>
          <div className="inline-flex justify-between font-normal font-poppins text-sm md:text-base w-full py-2">
            <p>
              Proyecto:{' '}
              <span className="text-gray-500">{paymentUpload.project}</span>
            </p>
            <p className="lg:min-w-max self-end">
              Número de cuota:{' '}
              <span className="text-gray-500">
                {paymentUpload.paymentNumber}
              </span>
            </p>
          </div>
          <div className="w-full flex flex-col space-y-4 items-center">
            <InputPdf name="invoice" handleChange={handlePdf} />
            <div className="w-1/2 pt-6">
              {loading ? (
                <LoaderComponent />
              ) : (
                file && (
                  <PrimaryButton
                    type="button"
                    size="lg"
                    children="Cargar factura"
                    handler={upload}
                  />
                )
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="w-full flex flex-col items-center">
          <p className="text-base md:text-lg text-center font-poppins text-gray-500">
            Has cargado la factura correctamente. Procesaremos tu factura para
            gestionar el pago.
          </p>
        </div>
      )}
    </>
  </ModalLayout>
)
