import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
// UI
import { SelectUsers } from '../../UI/Selects'
import { PrimaryButton } from '../../UI/Buttons'
// COMPONENTS
import ShareAssignment from './ShareAssignment'

const labelStyle = (error) =>
  error
    ? 'text-sm text-red-500 font-poppins'
    : 'text-sm text-gray-800 font-poppins'

const ModalAssignUser = ({
  users,
  loading,
  project,
  startDate,
  usersToAssign,
  setUsersToAssign,
  handleUserOption,
  errorUsersProject,
  validateAssignments,
}) => (
  <>
    <div className="flex flex-col justify-center items-center">
      {project && (
        <p className="text-gray-800 font-poppins text-base pb-4">
          Asignar perfiles a: {project.pname}
        </p>
      )}
      <div className="w-full sm:w-1/2">
        {!loading && (
          <>
            <label htmlFor="user" className={labelStyle(false)}>
              Añadir perfiles
            </label>
            <SelectUsers options={users} handleOption={handleUserOption} />
          </>
        )}
      </div>
    </div>
    <div className="w-full mt-8 grid grid-cols-1 sm:grid-cols-2 gap-8">
      {usersToAssign.map((userToAssign, i) => (
        <ShareAssignment
          startDate={startDate}
          key={userToAssign.user+i}
          user={userToAssign.user}
          index={i}
          usersToAssign={usersToAssign}
          setUsersToAssign={setUsersToAssign}
          handleUserOption={handleUserOption}
          errorUsersProject={errorUsersProject}
        />
      ))}
    </div>
    <div className="w-full mt-8 inline-flex justify-end">
      {usersToAssign.length ? (
        <div className="w-min-content">
          <PrimaryButton
            loading={loading}
            type="button"
            size="lg"
            children="Invitar"
            handler={validateAssignments}
          />
        </div>
      ) : null}
    </div>
  </>
)

export default ModalAssignUser
