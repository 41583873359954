import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
// UI
import { LoaderComponent } from '../../Utils/UI/Loaders'
import {
  PaymentsDownloadOptions,
  PaymentsFilterBy,
  PaymentsFilterByRange,
} from '../../Utils/UI/Selects'
// IMAGES
import Down from '../../Utils/Images/Down.png'
import Trash from '../../Utils/Images/Trash.png'
import Search from '../../Utils/Images/Search.png'
import ViewInvoice from '../../Utils/Images/ViewInvoice.png'
import SelectedCheck from '../../Utils/Images/SelectedCheck.png'
import ArrowDownViolet from '../../Utils/Images/ArrowDownViolet.png'
import SelectAllDefault from '../../Utils/Images/SelectAllDefault.png'
import ArrowControlLeft from '../../Utils/Images/ArrowControlLeft.png'
import ArrowControlRight from '../../Utils/Images/ArrowControlRight.png'
import SelectAllSelected from '../../Utils/Images/SelectAllSelected.png'
// HELPERS
import { parseDate, toPesos } from '../../Utils/helpers'
import { Dropdown } from '../../Utils/UI/Items'

const PaymentsTable = ({
  slice,
  filter,
  payments,
  setModal,
  setBankData,
  setSearch,
  handleSort,
  handleFilter,
  slicePayments,
  deletePayments,
  selectPayments,
  paymentsLength,
  downloadLoading,
  handleDownloads,
  paymentsLoading,
  paymentsSelected,
  handleExtendedView,
  handlePaymentsRange,
  paymentsRangeSelected,
  SinglePaymentDropdown,
  handleChangePaymentStatus,
  handleUploadPayment
}) => {
  return (
    <div className="w-full rounded-md bg-white pt-2 sm:pt-8 border border-gray-300 px-1 sm:px-6">
      <div className="inline-flex justify-between items-center w-full pt-2 pb-4">
        {/* SEARCH PAYMENTS INPUT */}
        <div className="inline-flex md:space-x-4">
          <h3 className="hidden md:block text-base text-gray-800 min-w-max">
            Ultimos pagos
          </h3>
          <h3 className="md:hidden text-sm text-gray-800 px-4">Tus pagos</h3>
          <div
            id="search"
            className="hidden md:inline-flex rounded-md items-center h-8 bg-white border border-gray-300 w-auto"
          >
            <img src={Search} alt="Search" className="w-auto px-3" />
            <input
              type="text"
              placeholder="Buscar"
              onChange={(e) => setSearch(e.target.value)}
              className="appearance-none block w-full p-1 font-poppins border-transparent rounded-md focus:outline-none sm:text-sm placeholder-gray-400 focus:ring-0 h-full border-0"
            />
          </div>
          <div className="hidden md:inline-flex relative space-x-4">
            <PaymentsFilterBy handler={handleFilter} filter={filter} />
            <PaymentsFilterByRange handler={handlePaymentsRange} filter={paymentsRangeSelected} />
          </div>
        </div>
        {/* VIEW CONTROLER BUTTONS */}
        <div className="hidden md:inline-flex items-center space-x-4 pl-4">
          <PaymentsDownloadOptions
            handler={handleDownloads}
            downloadLoading={downloadLoading}
          />
        </div>
      </div>
      {/* TABLE CONTROL ACTIONS */}
      <div className="hidden md:inline-flex justify-between items-center w-full px-4 py-4 border-b border-t border-gray-300">
        {/* PAYMENTS SELECTION OPTIONS */}
        <div className="inline-flex items-center space-x-6">
          <div
            className="min-h-max inline-flex items-center space-x-2"
            id="selectAll"
          >
            <button
              onClick={(e) => selectPayments(e, 'all')}
              type="button"
              className="h-full focus:outline-none"
            >
              <img
                src={
                  paymentsSelected.length === payments.length
                    ? SelectAllSelected
                    : SelectAllDefault
                }
                alt="SelectAll"
              />
            </button>
            <img src={Down} alt="" />
          </div>
          {paymentsSelected.length ? (
            <button
              onClick={deletePayments}
              id="delete"
              type="button"
              className="h-full p-1 focus:outline-none"
            >
              <img src={Trash} alt="Delete" />
            </button>
          ) : null}
        </div>
        {/* PAGINATION */}
        <div className="inline-flex items-center space-x-4">
          <p className="text-gray-800 font-poppins text-sm">
            {slice.start + 1}-{slice.end} de {paymentsLength}
          </p>
          <button
            onClick={() => slicePayments('prev')}
            type="button"
            className="h-full p-2 focus:outline-none"
          >
            <img src={ArrowControlLeft} alt="Previous" />
          </button>
          <button
            onClick={() => slicePayments('next')}
            type="button"
            className="h-full p-2 focus:outline-none"
          >
            <img src={ArrowControlRight} alt="Previous" />
          </button>
        </div>
      </div>
      {/* TABLE */}
      {paymentsLoading ? (
        <div className="p-6">
          <LoaderComponent />
        </div>
      ) : (
        <table className="w-full">
          <thead>
            <tr className="hidden md:table-row">
              <th scope="col" className="px-0 py-6 w-15"></th>
              <th scope="col" className="px-2 py-6">
                <button
                  onClick={(e) => handleSort(e, 'amount')}
                  className="w-full inline-flex items-center space-x-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                >
                  <span>Pago</span>
                  <img src={ArrowDownViolet} alt="Sort" />
                </button>
              </th>
              <th scope="col" className="px-2 py-6">
                <button
                  onClick={(e) => handleSort(e, 'user')}
                  className="w-full inline-flex items-center space-x-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                >
                  <span>Freelancer</span>
                  <img src={ArrowDownViolet} alt="Sort" />
                </button>
              </th>
              <th scope="col" className="px-2 py-6">
                <button
                  onClick={(e) => handleSort(e, 'project')}
                  className="w-full inline-flex items-center space-x-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                >
                  <span>Proyecto</span>
                  <img src={ArrowDownViolet} alt="Sort" />
                </button>
              </th>
              <th scope="col" className="px-2 py-6">
                <button
                  onClick={(e) => handleSort(e, 'paymentNumber')}
                  className="w-full inline-flex items-center space-x-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                >
                  <span>Factura</span>
                  <img src={ArrowDownViolet} alt="Sort" />
                </button>
              </th>
              <th scope="col" className="px-2 py-6">
                <button
                  onClick={(e) => handleSort(e, 'date')}
                  className="w-full inline-flex items-center space-x-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                >
                  <span className="hidden lg:block">Fecha de pago</span>
                  <span className="lg:hidden">Fecha</span>
                  <img src={ArrowDownViolet} alt="Sort" />
                </button>
              </th>
              <th scope="col" className="px-2 py-6">
                <button
                  onClick={(e) => handleSort(e, 'status')}
                  className="w-full inline-flex items-center space-x-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider focus:outline-none cursor-pointer"
                >
                  <span>Estado</span>
                  <img src={ArrowDownViolet} alt="Sort" />
                </button>
              </th>
              <th scope="col" className="px-2 py-6">
                <div className="w-full inline-flex items-center space-x-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider focus:outline-none cursor-default">
                  <span>Factura</span>
                </div>
              </th>
              <th scope="col" className="px-2 py-6">
                <div className="w-full inline-flex items-center space-x-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider focus:outline-none cursor-default">
                  <span>CBU</span>
                </div>
              </th>
              <th scope="col" className="px-2 py-6">
                <div className="w-full flex justify-center space-x-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider focus:outline-none cursor-default">
                  <span>PAGO</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="bg-transparent md:bg-white sm:divide-y divide-gray-200 w-full">
            {payments.length ? (
              payments.map((payment, i) => {
                console.log('🚀 ➡️ payment: ', payment)
                // console.log('🚀 ➡️ payment user: ', payment.user.personalData.fullName + ' 🚀 ➡️ payment id: ' + payment.id)
                if(payment.project?.status === 'Eliminado') return null  
                let options = [{ value: 'details', label: 'Ver detalles' }]
                if (payment.invoice)
                  options = [
                    ...options,
                    {
                      value: 'invoice',
                      label: 'Descargar factura',
                    },
                  ]
                if (payment.paymentSettlement)
                  options = [
                    ...options,
                    {
                      value: 'paymentSettlement',
                      label: 'Descargar comprobante de pago',
                    },
                  ]
                let hasAllBankData = payment.user?.allBankData?.length > 0 
                let defaultBankData = hasAllBankData ? payment.user?.allBankData?.filter(bd => bd.default)[0] : {...payment.user?.bankData, default: true, type: 'arg'}
                let statusBg = payment.status === 'Pendiente' ? 'bg-gray-100' : payment.status === 'Aprobado' ? 'bg-blue-200' : payment.status === 'Rechazado' ? 'bg-red-300' : 'bg-green-300'
                return (
                  <Fragment key={i}>
                    <tr className="hidden md:table-row">
                      <td className="px-4 py-2 font-poppins">
                        <button
                          onClick={(e) => selectPayments(e, i)}
                          type="button"
                          className="focus:outline-none h-full"
                        >
                          <img
                            src={
                              paymentsSelected.includes(i)
                                ? SelectedCheck
                                : SelectAllDefault
                            }
                            alt="SelectAll"
                          />
                        </button>
                      </td>
                      <td className="px-2 py-2 font-poppins">
                        <div className="flex items-center">
                          {payment.currency + ' ' + payment.amount}
                        </div>
                      </td>
                      <td className="px-2 py-2 text-sm text-gray-800 font-poppins">
                        {payment.user?.id && (
                          <Link
                            to={`/admin/comunidad/${payment.user.id}`}
                            key={payment.user.id}
                          >
                            <div className="flex items-center">
                            <div className="min-w-max">
                             <div className="h-8 w-8">
                              <img
                              className="rounded-full h-full w-full object-center object-cover border border-gray-300"
                              src={
                                payment.user.profileImage
                                  ? payment.user.profileImage
                                  : null
                              }
                              alt="UImage"
                            />
                             </div>
                            </div>
                            <span className='ml-2'>{payment.user.personalData.fullName}</span>
                            </div>
                          </Link>
                        )}
                      </td>
                      <td className="px-2 py-2 text-sm text-gray-800 font-poppins">
                        {payment.project?.pname}
                      </td>
                      <td className="px-2 py-2 text-sm text-gray-800 font-poppins">
                        {payment.paymentNumber}
                        {payment.bono && ' Bono'}
                      </td>
                      <td className="px-2 py-2 text-sm text-gray-800 font-poppins">
                        {parseDate(payment.date)}
                      </td>
                      <td className="px-2 py-2 text-sm text-gray-800 font-poppins">
                        <span className={`px-4 py-1 rounded-full ${statusBg}`}>
                          {payment.status}
                        </span>
                      </td>
                      <td className="px-2 py-2 text-sm text-gray-800 font-poppins">
                        {payment.invoice ? (
                          <div className="inline-flex space-x-2 min-w-max">
                            {payment.status === 'Pendiente' ? (
                              <span
                                className="group cursor-pointer"
                                onClick={() =>
                                  handleChangePaymentStatus(payment, 'Aprobado')
                                }
                              >
                                <svg
                                  width="36"
                                  height="35"
                                  viewBox="0 0 36 35"
                                  fill="none"
                                  className="w-6 h-6 group group-hover:text-green-500"
                                >
                                  <path
                                    d="M13.8842 23.5069L7.78976 17.4125L5.7583 19.444L13.8842 27.5698L31.2967 10.1573L29.2652 8.12582L13.8842 23.5069Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            ) : null}
                            <span>
                              <a
                                href={payment.invoice}
                                rel="noreferrer"
                                target="blank"
                              >
                                <img
                                  src={ViewInvoice}
                                  alt="Ver factura"
                                  className="w-6 h-6"
                                />
                              </a>
                            </span>
                            {payment.status === 'Pendiente' ? (
                              <span
                                className="group cursor-pointer"
                                onClick={() =>
                                  handleChangePaymentStatus(
                                    payment,
                                    'Rechazado'
                                  )
                                }
                              >
                                <svg
                                  width="36"
                                  height="35"
                                  viewBox="0 0 36 35"
                                  fill="none"
                                  className="w-6 h-6 group group-hover:text-red-500"
                                >
                                  <path
                                    d="M27.8698 9.3012L25.8238 7.25523L17.7125 15.3666L9.60115 7.25523L7.55518 9.3012L15.6665 17.4125L7.55518 25.5239L9.60115 27.5699L17.7125 19.4585L25.8238 27.5699L27.8698 25.5239L19.7585 17.4125L27.8698 9.3012Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            ) : null}
                          </div>
                        ) : null}
                      </td>
                      <td className="px-2 py-2 text-sm text-white font-poppins">
                        <span
                          className="px-2 py-1 rounded-full bg-purple cursor-pointer"
                          onClick={(e) => {
                            selectPayments(e, i)
                            setModal('openBankData')
                            setBankData({...defaultBankData, user: payment.user})
                          }}
                        >
                          Ver
                        </span>
                      </td>
                      <td className="px-2 py-2 text-sm text-gray-800 font-poppins">
                        {payment.status === "Aprobado" && (payment.paymentSettlement ? (
                          <div className="flex justify-center space-x-2 min-w-max">
                            <span>
                              <a
                                href={payment.paymentSettlement}
                                rel="noreferrer"
                                target="blank"
                              >
                                <img
                                  src={ViewInvoice}
                                  alt="Ver pago"
                                  className="w-6 h-6"
                                />
                              </a>
                            </span>
                          </div>
                        ) : (
                          <div className="flex justify-center space-x-2 min-w-max">
                            <span
                              className="px-2 py-1 rounded-full bg-gray-100 cursor-pointer"
                              onClick={(e) => {
                                handleUploadPayment(payment)
                              }}
                            >
                              Cargar
                            </span>
                          </div>
                        ))}
                      </td>
                    </tr>
                    <tr className="table-row md:hidden w-full min-w-max border-b border-t border-gray-300">
                      <td className="w-full p-0 h-auto">
                        <div className="w-full inline-flex justify-between items-center px-2">
                          <div className="w-full space-x-2 flex flex-1 items-center">
                            <div className="min-w-max">
                              <div className="relative max-h-14 h-14 w-14 my-2">
                                <img
                                  src={payment.user.profileImage}
                                  alt="USER"
                                  className="rounded-full h-full w-full object-center object-cover border border-gray-300"
                                />
                              </div>
                            </div>
                            <div className="table-cell text-sm text-gray-800 font-poppins">
                              <Dropdown
                                options={options}
                                handler={(value) =>
                                  SinglePaymentDropdown(value, payment)
                                }
                                button={
                                  <div className="flex flex-col justify-center text-left h-full min-w-0">
                                    <p>{payment.user.personalData.fullName}</p>
                                    <p className="text-gray-500 text-xs">
                                      {payment.project?.pname}
                                    </p>
                                  </div>
                                }
                              />
                            </div>
                          </div>
                          <div className="min-w-0 text-sm text-gray-800 font-poppins">
                            <div className="flex flex-col items-end space-y-1 text-base">
                              <span>{toPesos(payment.amount)}</span>
                              <span
                                className={
                                  (payment.status === 'Pendiente' &&
                                    ' text-red-500 border-red-500 ') +
                                  (payment.status === 'Aprobado' &&
                                    ' text-green-500 border-green-500 ') +
                                  (payment.status === 'Rechazado' &&
                                    ' text-gray-400 border-gray-400 ') +
                                  (payment.status === 'Realizado' &&
                                    ' text-gray-700 border-gray-400 ') +
                                  ' px-2 rounded-full text-xs border max-w-min'
                                }
                              >
                                {payment.status}
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </Fragment>
                )
              })
            ) : (
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td className="hidden md:block text-center px-2 py-6 text-sm text-gray-500 font-poppins w-max">
                  No se encontraron pagos
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      <h3
        className="block md:hidden text-sm text-purple px-4 py-4 cursor-pointer"
        onClick={() => (payments.length ? handleExtendedView() : null)}
      >
        {payments.length ? 'Ver todos los pagos' : 'No se encontraron pagos'}
      </h3>
    </div>
  )
}

export default PaymentsTable
