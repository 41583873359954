import React, { useState, useEffect } from 'react'
import UserPaymentsTable from './UserPaymentsTable.jsx'
// RECOIL
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  paymentsLoadingState,
  sessionUserState,
  userPaymentsState,
  userProjectsState,
} from '../../../atoms/index'
import { PaymentDetailMobile } from '../../Utils/UI/Items.js'
import { DateTime, Interval } from 'luxon'
import ModalViewPayments from '../../Utils/Modals/ModalViewPayments/ModalViewPayments.jsx'

const UserPayments = () => {
  const [search, setSearch] = useState('')
  const [order, setOrder] = useState('des')
  const [filter, setFilter] = useState('all')
  const [paymentDetail, setPaymentDetail] = useState({
    open: false,
    payment: null,
  })
  const [viewPayments, setViewPayments] = useState(false)
  const payments = useRecoilValue(userPaymentsState)
  const userProjects = useRecoilValue(userProjectsState)
  const setPaymentsLoading = useSetRecoilState(paymentsLoadingState)
  const [sessionUser, setSessionUser] = useRecoilState(sessionUserState)
  const [curatedPayments, setCuratedPayments] = useState(
    payments?.map((payment) => {
      let projectPayment = [
        ...userProjects?.active,
        ...userProjects?.invites,
        ...userProjects?.finished,
      ].find((userProject) => userProject.id === payment.project)
      payment = { ...payment, project: projectPayment }
      console.log(payment)
      return payment
    })
  )
  const [slice, setSlice] = useState({
    start: 0,
    end: 0,
  })
  const paymentsRange = window.innerWidth > 1048 ? 10 : 5

  // Validate if payments is on an accepted project
  const validatePayment = (payment) => {
    let isInvited = false
    // CHECK IF THE PAYMENT IS NOT IN A PROJECT THAT THE USER IS INVITED
    for (let project of userProjects.invites) {
      if (project.projectId === payment.project) {
        isInvited = true
        break
      }
    }

    if (filter === 'pending') {
      return payment.status === 'Pendiente' && !isInvited
    } else if (filter === 'approved') {
      return payment.status === 'Aprobado' && !isInvited
    } else if (filter === 'settled') {
      return payment.status === 'Realizado' && !isInvited
    } else if (filter === 'rejected') {
      return payment.status === 'Rechazado' && !isInvited
    } else {
      return true && !isInvited
    }
  }

  // Handles the payments with search input and filters
  const buildPayments = () => {
    console.log(payments)
    let updatePayments = payments?.filter((payment) => validatePayment(payment))
      .reduce((acc, payment) => {
        console.log(payment)
        let projectPayment = [
          ...userProjects.active,
          ...userProjects.invites,
          ...userProjects.finished,
        ].find((userProject) => userProject.id === payment.project)
        payment = { ...payment, project: projectPayment, user: sessionUser }
        if (search.length) {
          if (
            payment.project.pname.toLowerCase().includes(search.toLowerCase())
          )
            acc.push(payment)
          return acc
        } else {
          acc.push(payment)
          return acc
        }
      }, [])
    setCuratedPayments(sortFunction(updatePayments, 'date', 'des'))
  }

  // Mobile dropdown to see payments options
  const SinglePaymentDropdown = (option, payment) => {
    if (option === 'invoice') {
      window.open(payment.invoice, '_blank')
    }
    if (option === 'paymentSettlement') {
      window.open(payment.paymentSettlement, '_blank')
    }
    if (option === 'details') {
      setPaymentDetail({
        open: true,
        payment: payment,
      })
    }
  }

  // Handles the selected filter
  const handleFilter = (filter) =>
    filter ? setFilter(filter) : setFilter('all')

  // Handles the search input for payments table
  const handleSearchInput = (e) => {
    let value = e.target.value.toLowerCase()
    setSearch(value)
  }

  // Aux function to sort the payments table
  const sortFunction = (array, key, order) => {
    if (!array) return
    let copy = [...array]
    if (key === 'amount') {
      order === 'des'
        ? copy.sort((a, b) => a[key] - b[key])
        : copy.sort((a, b) => b[key] - a[key])
    } else {
      order === 'des'
        ? copy.sort((a, b) => (a[key] > b[key] ? 1 : -1))
        : copy.sort((a, b) => (a[key] > b[key] ? -1 : 1))
    }
    return copy
  }

  // Handles the sort actions for payments table
  const handleSort = (e, sort) => {
    setCuratedPayments(sortFunction(curatedPayments, sort, order))
    order === 'des' ? setOrder('asc') : setOrder('des')
  }

  // Slice the payments array for pagination of table
  const slicePayments = (action) => {
    if (action === 'next') {
      if (slice.start + paymentsRange > curatedPayments.length) {
        setSlice({
          start: slice.start,
          end: curatedPayments.length,
        })
      } else if (slice.end + paymentsRange > curatedPayments.length) {
        setSlice({
          start: curatedPayments.length > paymentsRange ? slice.end : 0,
          end: curatedPayments.length,
        })
      } else {
        setSlice({
          start: slice.start + paymentsRange,
          end: slice.end + paymentsRange,
        })
      }
    }
    if (action === 'prev') {
      if (slice.start - paymentsRange < 0) {
        setSlice({
          start: 0,
          end:
            curatedPayments.length > paymentsRange
              ? paymentsRange
              : curatedPayments.length,
        })
      } else {
        setSlice({ start: slice.start - paymentsRange, end: slice.start })
      }
    }
  }

  //Sets the pending payments to upload
  useEffect(
    () => {
      // eslint-disable-next-line
      let updatedPayments = []
      if(!payments) return
      payments.forEach((payment) => {
        console.log(payment)
        // eslint-disable-next-line
        if (payment.invoice) return
        let isValidPayment = true
        for (let inviteProject of userProjects.invites) {
          if (inviteProject.payments.includes(payment.id)) {
            isValidPayment = false
          }
        }
        if (isValidPayment) {
          let paymentDate = DateTime.fromSeconds(payment.date.seconds)
          let days = Interval.fromDateTimes(DateTime.now(), paymentDate)
          let remainingDays =
            days.length('days') > 0 ? Math.round(days.length('days')) : 0
          if (remainingDays < 15 || !remainingDays) {
            payment = { ...payment, remainingDays: remainingDays }
            updatedPayments.push(payment)
            return payment
          }
        }
      })
      // setSessionUser({ ...sessionUser, paymentsToUpload: updatedPayments })
      return setPaymentsLoading(false)
    },
    // eslint-disable-next-line
    [userProjects]
  )

  useEffect(() => {
    buildPayments()
    // eslint-disable-next-line
  }, [userProjects, filter, search])

  useEffect(() => {
    setSlice({
      start: 0,
      end:
        curatedPayments?.length > paymentsRange
          ? paymentsRange
          : curatedPayments?.length,
    })
    // eslint-disable-next-line
  }, [curatedPayments])

  return (
    <>
      {paymentDetail.open && (
        <PaymentDetailMobile
          paymentDetail={paymentDetail}
          onClose={setPaymentDetail}
        />
      )}
      {viewPayments && (
        <ModalViewPayments
          slice={slice}
          filter={filter}
          setSearch={setSearch}
          handleFilter={handleFilter}
          slicePayments={slicePayments}
          paymentsLength={payments.length}
          handleModals={() => setViewPayments(false)}
          payments={curatedPayments.slice(slice.start, slice.end)}
        />
      )}
      <UserPaymentsTable
        slice={slice}
        filter={filter}
        handleSort={handleSort}
        handleFilter={handleFilter}
        slicePayments={slicePayments}
        paymentsLength={payments?.length}
        setViewPayments={setViewPayments}
        handleSearchInput={handleSearchInput}
        SinglePaymentDropdown={SinglePaymentDropdown}
        payments={curatedPayments?.slice(slice.start, slice.end)}
      />
    </>
  )
}

export default UserPayments
