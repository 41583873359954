import React, { useState } from 'react'
// RECOIL
import { useRecoilValue } from 'recoil'
import { usersState } from '../../../../atoms/index'
// DB
import { inviteUsersToProject } from '../../../../services/db'
// COMPONENTS
import ModalInviteToProject from './ModalInviteToProject.jsx'

const ModalInviteToProjectContainer = ({ handleModals, project }) => {
  const users = useRecoilValue(usersState)
  const [usersToAssign, setUsersToAssign] = useState([])
  const [errorUsersProject, setErrorUsersProject] = useState(false)
  const [step, setStep] = useState('assign')
  const [loading, setLoading] = useState(false)

  const handleUserOption = (e, index, option) => {
    if (!option) return
    if (usersToAssign[index]) {
      usersToAssign.splice(index, 1)
      if (usersToAssign.length === 0) setErrorUsersProject(false)
      setUsersToAssign([...usersToAssign])
    } else {
      setUsersToAssign([...usersToAssign, { user: option }])
    }
  }

  const checkPayments = (payments) => {
    let errors = 0
    if (payments.length === 0) {
      errors++
    } else {
      payments.forEach((payment) => {
        if (!payment.amount) {
          errors++
        } else if (!payment.date) {
          errors++
        }
      })
    }
    return errors
  }

  const validateAssignments = async () => {
    let errors = 0
    usersToAssign.forEach((user) => {
      if (Object.keys(user).length === 1) {
        errors++
      } else if (user.payments) {
        let invalid = checkPayments(user.payments)
        return invalid > 0 ? errors++ : null
      } else if (!user.startDate) {
        errors++
      } else if (!user.endDate) {
        errors++
      }
    })
    if (errors > 0) {
      setErrorUsersProject(true)
    } else {
      setErrorUsersProject(false)
      await inviteUsers()
    }
  }

  const inviteUsers = async () => {
    try{
      setLoading(true)
      await inviteUsersToProject(project.projectId, usersToAssign)
      setLoading(false)
      setStep('success')
    }catch(err){
      console.error('Error: ', err)
      setLoading(false)
    }
  }

  const curatedUsers = users.filter((user) => {
    let projectUsers = project ? project.users : usersToAssign
    let includes
    includes = projectUsers?.find((element) =>
      element.user === user.id ? (includes = element) : null
    )
    let hasBeenSelected = usersToAssign?.find(
      (userAssigned) => userAssigned.user === user.id
    )
    return includes || hasBeenSelected ? false : true
  })

  return (
    <ModalInviteToProject
      step={step}
      project={project}
      loading={false}
      curatedUsers={curatedUsers}
      handleModals={handleModals}
      usersToAssign={usersToAssign}
      handleUserOption={handleUserOption}
      setUsersToAssign={setUsersToAssign}
      errorUsersProject={errorUsersProject}
      validateAssignments={validateAssignments}
    />
  )
}

export default ModalInviteToProjectContainer
