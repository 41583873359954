import React from 'react'
// UI
import { ProfileProjectCard } from '../../Utils/UI/ProjectCard'
import UserDefault from '../../Utils/Images/UserDefault.png'
import GoBack from '../../Utils/Images/GoBack.png'
import {
  UserProjectsFilterBy,
  SingleUserDropdown,
  ChangeAvailability,
} from '../../Utils/UI/Selects'
import Search from '../../Utils/Images/Search.png'
// COMPONENTS
import EditProfileContainer from '../EditProfile'
import ModalBankData from '../../Utils/Modals/ModalBankData.jsx'
import ModalContactData from '../../Utils/Modals/ModalContactDetails'
import { PrimaryButton, SecondaryButton } from '../../Utils/UI/Buttons'

const Profile = ({
  user,
  modal,
  filter,
  setModal,
  searchBar,
  setSearch,
  setSearchBar,
  handleModals,
  handleFilter,
  curatedProjects,
  handleAvailability
}) => {
  return (
    <div className="relative w-full">
      <button
        className={
          (searchBar ? 'hidden' : 'block') +
          ' absolute -top-11 right-1 block sm:hidden focus:outline-none'
        }
        onClick={() => setSearchBar(!searchBar)}
      >
        <img src={Search} alt="Search" className="w-auto h-4" />
      </button>
      <div
        className={
          (searchBar ? 'hidden' : 'flex') +
          ' w-full space-x-4 sm:space-x-10 mb-10'
        }
      >
        {/* PROFILE IMAGE */}
        <div className="w-auto">
          <div className="h-24 w-24 sm:h-56 sm:w-56">
            <img
              src={user.profileImage ? user.profileImage : UserDefault}
              alt="ProfileImage"
              className="rounded-full h-full w-full object-center object-cover border border-gray-300"
            />
          </div>
        </div>
        <div className="relative w-full sm:w-auto flex flex-col md:flex-row space-x-0 space-y-4 md:space-y-0 md:space-x-10">
          {/* PROFILE DATA */}
          <div className="flex flex-col justify-between w-full md:min-w-max">
            <div>
              <div className="inline-flex space-x-4 items-center">
                <p className="font-poppins text-base sm:text-lg md:text-xl text-gray-800">
                  {user.personalData.fullName}
                </p>
                <div className="hidden sm:block">
                  {user.projects.active.length ? (
                    <span className="hidden sm:block font-poppins text-sm md:text-base text-gray-800 rounded-full bg-gray-300 px-2 py-1">
                      En proyecto
                    </span>
                  ) : (
                    <span className="font-poppins text-sm md:text-base text-white rounded-full bg-green-500 px-2 py-1">
                      Libre
                    </span>
                  )}
                </div>
              </div>
              <p className="font-poppins text-sm sm:text-base md:text-lg text-gray-500 m-0">
                {user.personalData.role}
              </p>
              <div className="sm:hidden h-4 inline-flex justify-center items-center min-w-max">
                <span
                  className={
                    (user.projects.active.length
                      ? 'bg-red-500'
                      : 'bg-green-500') + ' h-3 w-3 mr-2 rounded-full'
                  }
                />
                <span className="sm:hidden font-poppins text-sm text-gray-800 min-w-max">
                  {user.projects.active.length ? 'En proyecto' : 'Libre'}
                </span>
              </div>
              {user.portfolio && (
                <p className="break-all my-2 text-sm sm:text-base md:text-lg text-purple">
                  {user.portfolio}
                </p>
              )}
              <p className="font-poppins text-xs sm:text-sm md:text-base text-gray-800 m-0">
                <span className="text-gray-500">Disponibilidad:</span> {user.availability ? user.availability : null}
              </p>
            </div>
            <div className="font-poppins text-sm sm:text-base md:text-lg text-gray-800 inline-flex items-center">
              <span className="text-base sm:text-lg md:text-xl mr-2">
                {user.projects.finished.length}
              </span>{' '}
              proyectos realizados
            </div>
          </div>
          {/* PROFILE ACTIONS */}
          <div className="inline-flex justify-end sm:justify-start space-x-0 sm:space-x-4 w-full">
            <div className="hidden sm:inline-flex items-start space-x-1 md:space-x-4 w-full flex-wrap sm:flex-nowrap">
              <div className="w-full">
                <SecondaryButton
                  type="button"
                  size="sm"
                  children="Editar perfil"
                  handler={() => setModal('editProfile')}
                />
              </div>
              <div className="w-full">
                <ChangeAvailability handler={handleAvailability} />
              </div>
            </div>
            <div className="inline-flex sm:hidden w-full items-baseline space-y-2 flex-wrap">
              <PrimaryButton
                type="button"
                size="sm"
                children="Editar perfil"
                handler={() => setModal('editProfile')}
              />
              <div className="w-full">
                <ChangeAvailability handler={handleAvailability} />
              </div>
            </div>
            <div className="absolute top-0 right-0 sm:relative pt-0 sm:pt-2">
              <SingleUserDropdown handler={handleModals} />
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          (searchBar ? 'w-full inline-flex' : 'hidden sm:inline-flex') +
          ' space-x-4 sm:space-x-8 mb-4'
        }
      >
        <div
          id="search"
          className="w-full rounded-md inline-flex items-center h-8 bg-white border border-gray-300"
        >
          <img
            src={Search}
            alt="Search"
            className="hidden sm:block w-auto px-3"
          />
          <img
            src={GoBack}
            alt="Search"
            className="block sm:hidden h-4 w-auto px-3 cursor-pointer"
            onClick={() => setSearchBar(!searchBar)}
          />
          <input
            type="text"
            placeholder="Buscar"
            onChange={(e) => setSearch(e.target.value.toLowerCase())}
            className="appearance-none block w-full p-1 font-poppins border-transparent rounded-md focus:outline-none sm:text-sm placeholder-gray-400 focus:ring-0 h-full border-0"
          />
        </div>
        <UserProjectsFilterBy handler={handleFilter} filter={filter} />
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
        {curatedProjects.length ? (
          curatedProjects.map((project) => (
            <ProfileProjectCard
              key={project.projectId}
              project={project}
              user={user}
            />
          ))
        ) : (
          <div className="text-center px-2 py-6 text-sm text-gray-500 font-poppins w-max">
            No se encontraron proyectos
          </div>
        )}
      </div>
      {modal === 'editProfile' && (
        <EditProfileContainer handleModal={handleModals} />
      )}
      {modal === 'openBankData' && (
        <ModalBankData handleModals={handleModals} data={user.bankData} />
      )}
      {modal === 'openUserData' && (
        <ModalContactData
          handleModals={handleModals}
          data={user.personalData}
          address={user.bankData.address}
        />
      )}
    </div>
  )
}

export default Profile
