import React, { useState } from 'react'
import AddPayment from './AddPayment.jsx'
// RECOIL
import { useRecoilValue } from 'recoil'
import { usersState } from '../../../../atoms/index'
import { projectsState, paymentsState } from '../../../../atoms/index'
// UI
import ModalLayout from '../../UI/ModalLayout'
import { PdfToArrayBuffer } from '../../helpers'
import { uploadSettlementPayment } from '../../../../services/db.js'
import { LoaderComponent } from '../../UI/Loaders.js'

const ModalAddPayment = ({ handleModal,fetchData, payment }) => {
  console.log(payment)
  const users = useRecoilValue(usersState)
  const projects = useRecoilValue(projectsState)
  const payments = useRecoilValue(paymentsState)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [projectOptions, setProjectOptions] = useState([])
  const [paymentOptions, setPaymentOptions] = useState([])
  const [errorProjects, setErrorProjects] = useState(false)
  const [errorPayments, setErrorPayments] = useState(false)
  const [paymentToUpload, setPaymentToUpload] = useState({
    user: payment ? payment.user : '',
    project: payment ? payment.project : '',
    payment: payment ? payment : '',
    paymentSettlement: '',
  })

  const handleUserSelection = (e, selection, option) => {
    setPaymentToUpload({ ...paymentToUpload, user: option })
    console.log(option)
    if (option) {
      let userProjects = projects.filter((project) => {
        let isUserProject = false
        console.log(option.projects)
        if (!option.projects.invites.includes(project.projectId)) {
          if(!project.users){
            return false
          }
          for (const user of project.users) {
            if (user.user === option.id) {
              isUserProject = true
              break
            }
          }
        }
        console.log(isUserProject)
        return isUserProject
      })
      setProjectOptions(userProjects)
      return !userProjects.length ? setErrorProjects(true) : null
    } else {
      setPaymentToUpload({ user: '', project: '', payment: '' })
      setErrorProjects(false)
      setErrorPayments(false)
      setProjectOptions([])
      setPaymentOptions([])
    }
  }

  const handleProjectSelection = (option) => {
    setErrorProjects(false)
    setErrorPayments(false)
    setPaymentToUpload({ ...paymentToUpload, project: option, payment: null })
    let paymentsPending = []
    console.log(option.payments)
    for (const payment of option.payments) {
      let paymentData = payments.find((p) => p.id === payment)
      console.log(paymentData)
      console.log(paymentData.status)
      console.log(paymentData.user.id)
      if (
        paymentData &&
        paymentData.status === 'Aprobado' &&
        paymentData.user.id === paymentToUpload.user.id
      ) {
        paymentsPending.push(paymentData)
      }
    }
    if (paymentsPending.length) {
      setPaymentOptions(paymentsPending)
    } else {
      setPaymentOptions([])
      setErrorPayments(true)
    }
  }

  const handlePaymentSelection = (option) => {
    return setPaymentToUpload({ ...paymentToUpload, payment: option })
  }

  const handlePaymentSettlementDocument = async (inputFile) => {
    let file = inputFile ? inputFile : null
    let converted
    if (file) {
      converted =
        file.type === 'application/pdf' ? await PdfToArrayBuffer(file) : file
    } else {
      converted = null
    }
    return converted
      ? setPaymentToUpload({
          ...paymentToUpload,
          paymentSettlement: converted,
        })
      : setPaymentToUpload({
          ...paymentToUpload,
          paymentSettlement: null,
        })
  }

  const handleSubmit = async () => {
    console.log('Uploading payment:', paymentToUpload)
    setLoading(true)
    await uploadSettlementPayment(paymentToUpload)
    setLoading(false)
    setSuccess(true)
    await fetchData()
  }

  return (
    <ModalLayout
      goBackText="Home"
      handler={(e) => handleModal(e, 'addPayment')}
      modal="addPayment"
      title={success ? '¡Comprobante cargado!' : 'Ingresar un pago para'}
    >
     <div className="w-full flex items-center justify-center mb-5">
        <div className="min-w-max">
          <div className="h-8 w-8">
            <img
              className="rounded-full h-full w-full object-center object-cover border border-gray-300"
              src={
                payment.user.profileImage
                  ? payment.user.profileImage
                  : null
              }
              alt="UImage"
            />
          </div>
        </div>
        <span className='ml-2'>{payment.user.personalData.fullName}</span>
      </div>

      {loading ? (
        <div className="py-14">
          <LoaderComponent />
        </div>
      ) : null}
      {!loading && !success ? (
        <AddPayment
          loading={loading}
          paymentToUpload={paymentToUpload}
          handleUserSelection={handleUserSelection}
          projectOptions={projectOptions}
          handleProjectSelection={handleProjectSelection}
          paymentOptions={paymentOptions}
          handlePaymentSelection={handlePaymentSelection}
          handlePaymentSettlementDocument={handlePaymentSettlementDocument}
          handleSubmit={handleSubmit}
          errorProjects={errorProjects}
          errorPayments={errorPayments}
          users={users.filter((user) =>
            user.payments.pending.length ? true : false
          )}
        />
      ) : null}
      {success ? (
        <div className="w-full flex flex-col items-center space-y-8">
          <p className="text-sm sm:text-base md:text-lg text-center font-poppins text-gray-500">
            El perfil podra ver el comprobante en su perfil.
          </p>
        </div>
      ) : null}
    </ModalLayout>
  )
}

export default ModalAddPayment
