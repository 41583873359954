import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { RecoilRoot } from 'recoil'
import AuthProvider from './services/firebase-auth-context'
import LoginContainer from './components/Login'
import RegisterContainer from './components/Register'
import AdminContainer from './components/Admin'
import UserContainer from './components/User'
import ErrorBoundary from './components/Utils/UI/ErrorHandler'

const queryClient = new QueryClient()

const App = () => (
  <RecoilRoot>
    <AuthProvider>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <Router>
            <Switch>
              <Route path="/login" component={LoginContainer} />
              <Route
                path="/register/:component"
                component={RegisterContainer}
              />
              <Route path="/admin/:component" component={AdminContainer} />
              <Route path="/user/:component" component={UserContainer} />
              <Redirect from="/" to="/login" />
            </Switch>
          </Router>
        </QueryClientProvider>
      </ErrorBoundary>
    </AuthProvider>
  </RecoilRoot>
)

export default App
