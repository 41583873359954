import React from 'react'
import { Link } from 'react-router-dom'
// UI
import { PrimaryButton } from '../Utils/UI/Buttons'
import { RecoverPassword } from './RecoveryPassword'

const Login = ({
  handleLogIn,
  handleInputs,
  credentials,
  success,
  error,
  loading,
  resetPassword,
  recoveryEmail,
  setResetPassword,
  handleRecoveryEmail,
  sendRequestRecovery,
}) => (
  <div className="min-h-screen bg-white sm:bg-gray-50 flex flex-col justify-center sm:px-6 lg:px-8">
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-sm sm:space-y-6">
      <div className="bg-white py-8 px-4 sm:border sm:rounded-lg sm:px-10 space-y-10">
        <div className="flex w-full justify-center p-2">
          <img
            src="/images/logo-full.png"
            alt="ITESA INNOVATION HUB"
            className="h-14 w-auto"
          />
        </div>
        {resetPassword ? (
          <RecoverPassword
            error={error}
            success={success}
            recoveryEmail={recoveryEmail}
            setResetPassword={setResetPassword}
            handleRecoveryEmail={handleRecoveryEmail}
            sendRequestRecovery={sendRequestRecovery}
          />
        ) : (
          <>
            <form className="space-y-6" onSubmit={handleLogIn}>
              <div>
                <label
                  htmlFor="email"
                  className="hidden sm:block text-sm font-medium text-gray-700 font-poppins"
                >
                  Email
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    placeholder="Email"
                    value={credentials.email}
                    onChange={handleInputs}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 sm:placeholder-transparent bg-gray-50 sm:bg-transparent font-poppins focus:outline-none focus:ring-purple focus:border-purple sm:text-sm"
                  />
                </div>
                {error.error && credentials.email.length < 3 ? (
                  <p className="text-sm font-medium font-poppins text-red-600 pt-2">
                    {error.message}
                  </p>
                ) : null}
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="hidden sm:block text-sm font-medium text-gray-700 font-poppins"
                >
                  Contraseña
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    placeholder="Contraseña"
                    value={credentials.password}
                    onChange={handleInputs}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 sm:placeholder-transparent bg-gray-50 sm:bg-transparent font-poppins focus:outline-none focus:ring-purple focus:border-purple sm:text-sm"
                  />
                </div>
                {error.error ? (
                  <p className="text-sm font-medium font-poppins text-red-600 pt-2">
                    {error.message}
                  </p>
                ) : null}
              </div>
              <div>
                <PrimaryButton
                  type="submit"
                  size="lg"
                  children="Log in"
                  loading={loading}
                />
              </div>
            </form>
            
            <div className="text-base w-full text-center">
              <p
                className="font-medium text-gray-700 text-sm sm:text-base font-poppins hover:text-purple-dark focus:text-purple-dark active:text-purple cursor-pointer"
                onClick={() => setResetPassword(true)}
              >
                ¿Olvidaste la contraseña?
              </p>
            </div>
          </>
        )}
      </div>
      <div className="bg-white py-6 px-4 sm:border sm:rounded-lg sm:px-6 border-t">
        <div className="w-full inline-flex justify-evenly sm:justify-between items-center">
          <p className="text-center text-sm sm:text-lg text-gray-600 font-poppins">
            ¿No tenés una cuenta?
          </p>
          <Link
            to="/register/datos-personales"
            className="text-purple text-sm sm:text-base text-center font-poppins hover:text-purple-darkest focus:text-purple-dark"
          >
            Sign up
          </Link>
        </div>
      </div>
    </div>
  </div>
)

export default Login
