import { Dialog } from '@headlessui/react'
import React, { useEffect, useState, Fragment } from 'react'
import { DateTime, Interval } from 'luxon'
import useGetNotifications from '../../../hooks/useGetNotifications'
import { useRecoilValue } from 'recoil'
import { usersState } from '../../../atoms'
// UI
import { Transition } from '@headlessui/react'
import { ModalHeader } from '../../Utils/UI/Items'
import { LoaderComponent } from '../../Utils/UI/Loaders'

const NotificationsPanel = ({ closePanel, notificationPanel }) => {
  const [oldNotifications, setOldNotifications] = useState([])
  const [todayNotifications, setTodayNotifications] = useState([])
  const users = useRecoilValue(usersState)
  let { isLoading, data: notifications /* error */ } = useGetNotifications()

  useEffect(() => {
    if (notifications) {
      let todayNotificationsUpdated = []
      let oldNotificationsUpdated = []
      for (const notification of notifications) {
        let now = DateTime.now()
        let notificationDate = DateTime.fromSeconds(notification.date.seconds)
        let interval = Interval.fromDateTimes(notificationDate, now)
        let intervalDuration = interval
          .toDuration(['days', 'hours', 'minutes'])
          .toObject()
        console.log(users)
        if(!users.length) return console.log('No users')
        let userData = users?.find((user) => user.id === notification.user)
        console.log('userData', userData)
        if(!userData) return console.log('No user data')
        if (intervalDuration.days) {
          let oneOrMoreDays =
            intervalDuration.days > 0
              ? `Hace ${intervalDuration.days} días.`
              : 'Hace 1 día.'
          oldNotificationsUpdated.push({
            name: notification.name,
            message: notification.message,
            when: oneOrMoreDays,
            interval: interval.count('minutes'),
            userImage: userData?.profileImage,
          })
        } else if (intervalDuration.hours > 0) {
          let amountOfHoursElapsed =
            intervalDuration.hours > 1
              ? `Hace ${intervalDuration.hours} horas.`
              : 'Hace 1 hora.'
          todayNotificationsUpdated.push({
            name: notification.name,
            message: notification.message,
            when: amountOfHoursElapsed,
            interval: interval.count('minutes'),
            userImage: userData.profileImage,
          })
        } else {
          todayNotificationsUpdated.push({
            name: notification.name,
            message: notification.message,
            when: `Hace ${Math.floor(intervalDuration.minutes)} minutos.`,
            interval: interval.count('minutes'),
            userImage: userData.profileImage,
          })
        }
        setTodayNotifications(
          todayNotificationsUpdated.sort((a, b) => a.interval - b.interval)
        )
        setOldNotifications(
          oldNotificationsUpdated.sort((a, b) => a.interval - b.interval)
        )
      }
    }
    // eslint-disable-next-line
  }, [notifications])

  return (
    <Transition
      appear
      as={Fragment}
      show={notificationPanel}
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Dialog
        open
        onClose={closePanel}
        className="fixed z-30 inset-0 m-0 overflow-y-auto overflow-x-hidden h-auto"
      >
        <Dialog.Overlay className="fixed z-30 inset-0 h-full bg-gray-500 bg-opacity-75 backdrop-filter backdrop-blur-sm" />
        <div className="flex items-center justify-end w-full min-h-screen h-auto z-30">
          <div className="min-h-screen w-full sm:w-1/2 md:w-1/4 h-auto sm:max-w-5xl md:max-w-5xl notifications-bg z-40 rounded-md sm:mx-0 px-6 sm:px-10 py-8 overflow-auto">
            <ModalHeader
              title="Notificaciones"
              handler={closePanel}
              modal="notificationsPanel"
              goBackText="Dashboard"
            />
            <div className="w-full h-full">
              <div className="py-4">
                <h3 className="py-1 my-4 border-b border-gray-400 text-gray-400">
                  Hoy
                </h3>
                {isLoading ? (
                  <div className="p-6">
                    <LoaderComponent />
                  </div>
                ) : (
                  <>
                    {todayNotifications.map((notification, i) => (
                      <div
                        id={i}
                        className="w-full py-1 inline-flex items-start"
                      >
                        <div className="max-h-8 h-8 w-8 mr-2 rounded-full">
                          <img
                            className="rounded-full h-8 w-full object-center object-fill border border-gray-300"
                            src={notification.userImage}
                            alt="UImage"
                          />
                        </div>
                        <div className="w-full">
                          <p className="text-sm font-poppins">
                            <strong>{notification.name}</strong>
                            {notification.message}
                          </p>
                          <p className="text-xs">{notification.when}</p>
                        </div>
                      </div>
                    ))}
                    {!todayNotifications.length && (
                      <p className="text-base">
                        No hay notificaciones pendientes.
                      </p>
                    )}
                  </>
                )}
              </div>
              <div className="py-4">
                <h3 className="py-1 my-4 border-b border-gray-400 text-gray-400">
                  Pasadas
                </h3>
                {isLoading ? (
                  <div className="p-6">
                    <LoaderComponent />
                  </div>
                ) : (
                  <>
                    {oldNotifications.map((notification, i) => (
                      <div
                        key={i}
                        className="w-full mb-6 inline-flex items-start"
                      >
                        <div className="max-h-8 h-8 w-8 mr-2 rounded-full">
                          <img
                            className="rounded-full h-8 w-full object-center object-fill border border-gray-300"
                            src={notification.userImage}
                            alt="UImage"
                          />
                        </div>
                        <div className="w-full">
                          <p className="text-sm font-poppins">
                            <strong>{notification.name}</strong>
                            {notification.message}
                          </p>
                          <p className="text-xs">{notification.when}</p>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default NotificationsPanel
