import React from 'react'
// UI
import { ProfileCard } from '../../Utils/UI/ProfileCard'
import ArrowControlLeft from '../../Utils/Images/ArrowControlLeft.png'
import ArrowControlRight from '../../Utils/Images/ArrowControlRight.png'

const Grid = ({
  users,
  slice,
  sliceUsers,
  handleModals,
  singleUserSelection,
}) => (
  <div className="w-full">
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8">
      {users.map((user, i) => (
        <ProfileCard
          index={i}
          user={user}
          key={user.id}
          handler={handleModals}
          singleUserSelection={singleUserSelection}
        />
      ))}
    </div>
    <div className="w-full py-6 inline-flex justify-center items-center space-x-4">
      <button
        onClick={() => sliceUsers('prev')}
        type="button"
        className="h-full p-2 focus:outline-none"
      >
        <img src={ArrowControlLeft} alt="Previous" />
      </button>
      <p className="text-gray-800 font-poppins text-sm">
        {slice.start + 1}-{slice.end} de {users.length}
      </p>
      <button
        onClick={() => sliceUsers('next')}
        type="button"
        className="h-full p-2 focus:outline-none"
      >
        <img src={ArrowControlRight} alt="Previous" />
      </button>
    </div>
  </div>
)

export default Grid
