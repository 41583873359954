// UI
import ModalLayout from '../../Utils/UI/ModalLayout'
import { PrimaryButton } from '../../Utils/UI/Buttons'
import { Input, InputImg, InputPdf } from '../../Utils/UI/Inputs'
import { useHistory } from 'react-router'
import Remove from '../../Utils/Images/Remove.png'
import Trash from '../../Utils/Images/Trash.png'
// RECOIL
import { LoaderComponent } from '../../Utils/UI/Loaders'
import { SelectSkills } from '../../Utils/UI/Selects'

const labelStyle = (error) =>
  error
    ? 'text-sm md:text-lg text-red-500 font-poppins'
    : 'text-sm md:text-lg text-gray-800 font-poppins'

const EditProfile = ({
  step,
  loading,
  formData,
  errorForm,
  handleStep,
  skillsList,
  handleFiles,
  removeSkill,
  setFormData,
  handleModal,
  handleSubmit,
  previewProfileImage,
  handleSkillSelection,
}) => {
  const history = useHistory()
  return (
    <ModalLayout
      handler={handleModal ? handleModal : () => null}
      modal="editProfileDetails"
      goBackText="Perfil"
      title={step === 'success' ? '¡Datos actualizados!' : 'Editar perfil'}
      regular={step === 'success' ? true : false}
    >
      {loading ? (
        <div className="py-20">
          <LoaderComponent />
        </div>
      ) : (
        <>
          {step === 'profileDetails' && (
            <form className="w-full flex flex-col items-baseline pt-8 px-1 space-y-8 overflow-y-auto">
              <div className="w-full flex flex-col items-baseline space-y-2">
                {!formData.profileImage && (
                  <label
                    htmlFor="profileImage"
                    className={labelStyle(
                      errorForm && !formData.profileImage ? true : false
                    )}
                  >
                    Imagen de perfil
                  </label>
                )}
                {formData.profileImage ? (
                  <div className="relative h-36 w-36 sm:h-48 sm:w-48 mx-auto">
                    <img
                      src={Trash}
                      alt="Change"
                      className="absolute ml-32 sm:ml-44 cursor-pointer px-2"
                      onClick={(e) => handleFiles(null, 'profileImage')}
                    />
                    <img
                      src={
                        previewProfileImage
                          ? previewProfileImage
                          : formData.profileImage
                      }
                      alt="USER"
                      className="rounded-full h-full w-full object-center object-cover border border-gray-300"
                    />
                  </div>
                ) : (
                  <InputImg
                    name="profileImage"
                    handleChange={handleFiles}
                    img={formData.profileImage}
                    error={errorForm}
                    className={labelStyle(errorForm)}
                  />
                )}
              </div>
              <div className="w-full flex flex-col items-baseline space-y-1">
                <div className="inline-flex space-x-2 justify-between w-full">
                  <label
                    htmlFor="cv"
                    className={labelStyle(
                      errorForm && !formData.cv ? true : false
                    )}
                  >
                    CV
                  </label>
                  <div>
                    <PrimaryButton
                      size="sm"
                      children={
                        <a href={formData.cv} rel="noreferrer" target="_blank">
                          Ver CV
                        </a>
                      }
                    />
                  </div>
                </div>
                <InputPdf
                  name="cv"
                  handleChange={handleFiles}
                  error={errorForm}
                  className={labelStyle(false)}
                />
              </div>
              <div className="w-full flex flex-col items-baseline space-y-1">
                <label
                  htmlFor="portfolio"
                  className={labelStyle(
                    errorForm && !formData.portfolio ? true : false
                  )}
                >
                  Link al portfolio
                </label>
                <Input
                  value={formData.portfolio}
                  handler={(e) =>
                    setFormData({ ...formData, portfolio: e.target.value })
                  }
                  error={errorForm}
                  name="portfolio"
                  type="text"
                />
              </div>
              <div className="w-min-min inline-flex self-end">
                <PrimaryButton
                  type="submit"
                  size="lg"
                  children="Continuar"
                  handler={handleStep}
                />
              </div>
            </form>
          )}
          {step === 'selectSkills' && (
            <>
              <div className="inline-flex space-x-4 w-full">
                {/* SKILLS SELECTOR */}
                <SelectSkills
                  skillsSelected={formData.skills}
                  handleSelection={handleSkillSelection}
                  skillsList={skillsList}
                />
              </div>
              <div className="w-full flex flex-wrap py-4">
                {formData?.skills?.map((skill, i) => (
                  <span
                    key={i}
                    onClick={() => removeSkill(i)}
                    className="px-2 py-1 mx-2 my-1 rounded-full border border-gray-300 text-sm font-poppins flex cursor-pointer hover:border-red-500 hover:text-red-500"
                  >
                    {skill}
                    <img
                      src={Remove}
                      className="h-5 w-auto ml-2"
                      alt="Remove"
                    />
                  </span>
                ))}
              </div>
              {formData.skills.length ? (
                <div className="w-1/2 pt-8 mx-auto">
                  <PrimaryButton
                    type="button"
                    size="lg"
                    children="Completar"
                    handler={handleSubmit}
                  />
                </div>
              ) : null}
            </>
          )}
          {step === 'success' ? (
            <div className="w-full flex flex-col items-center space-y-8">
              <p className="text-lg text-center font-poppins text-gray-500">
                Has cargado tus datos correctamente y se ha completado tu
                perfil.
              </p>
              <div className="w-1/2 pt-8 mx-auto">
                <PrimaryButton
                  type="button"
                  size="lg"
                  children="Continuar"
                  handler={() => history.push('/user/home')}
                />
              </div>
            </div>
          ) : null}
        </>
      )}
    </ModalLayout>
  )
}

export default EditProfile
