import React from 'react'
import { useHistory } from 'react-router-dom'
// RECOIL
import { useRecoilValue } from 'recoil'
import {
  paymentsLoadingState,
  projectsLoadingState,
  // userPaymentsState,
  userProjectsState,
  sessionUserState,
} from '../../../atoms'
// COMPONENTS
import UserPayments from '../Payments'
import { LoaderComponent } from '../../Utils/UI/Loaders'
// UI
import Edit from '../../Utils/Images/Edit.png'
import UnavailablePendings from '../../Utils/Images/UnavailablePendings.png'
import NoProjectMobile from '../../Utils/Images/NoProjectMobile.png'
import {
  AcceptProjectInviteCard,
  UploadPaymentCard,
} from '../../Utils/UI/UserHomeCards'
import { useEffect } from 'react'
import { useState } from 'react'
import BankAccountsContainer from '../BankAccounts'
import { updateBankDataDefault } from '../../../services/db'

const UserHome = ({fetchUserData}) => {
  const projectsLoading = useRecoilValue(projectsLoadingState)
  const paymentsLoading = useRecoilValue(paymentsLoadingState)
  // const paymentsUser = useRecoilValue(userPaymentsState)
  const sessionUser = useRecoilValue(sessionUserState)
  const userProjects = useRecoilValue(userProjectsState)
  const [hasPendingRequest, setHasPendingRequest] = useState(false)
  const [hasActiveProjects, setHasActiveProjects] = useState(false)

  /* Verifies if theres is any projects invites or invoice pending available */
  const pendingPaymentsOrProjects = () => {
    if ('invites' in userProjects ) {
      return (
        userProjects?.invites.length > 0 ||
        sessionUser.paymentsToUpload?.length > 0
      )
    } else {
      return false
    }
  }

  useEffect(() => {
    const pendingRequest = pendingPaymentsOrProjects()
    const activeProjects = userProjects?.active?.length > 0
    setHasPendingRequest(pendingRequest)
    setHasActiveProjects(activeProjects)
    // eslint-disable-next-line
  }, [userProjects, sessionUser])

  const handleAsDefault = async (id) => {
    let res = await updateBankDataDefault(sessionUser.id, id)
    console.log(res)
    if(res.error){
      alert(res.error)
    }else{
      await fetchUserData()
    }
  }

  const BankCard = ({ bankData }) => {
    const isDefault = bankData.default;
    const [open, setOpen] = useState(false);
    let image 
    let dataToShow
    if(bankData.type === 'arg'){
      image = '/images/arg.png'
      dataToShow = '...' + bankData.cbu.slice(-16)
    }
    if(bankData.type === 'wise'){
      image = '/images/wise.png'
      dataToShow = bankData.email
    }
    if(bankData.type === 'zelle'){
      image = '/images/zelle.png'
      dataToShow = bankData.email
    }
    if(bankData.type === 'ach'){
      image = '/images/ach.svg'
      dataToShow = bankData.routingNumber
    }
    if(bankData.type === 'usdt'){
      image = '/images/usdt.svg'
      let address = bankData.walletAddress
      dataToShow = address.slice(0, 6) + '...' + address.slice(-6)
    }
    return (
      // Create cards for each bank account only with the bank name
      <div className="flex flex-row items-center flex-start p-3 border border-gray-500 rounded-lg cursor-pointer bg-gray-100 gap-3">
        <div className=''>
           <img src={image} alt='Arg' className='w-14 h-auto' />
        </div>
        <div className={`flex flex-col justify-between w-full`}>
          <div className='flex flex-row w-full align-center justify-between'>
            <p>{bankData.name}</p>
            <img
              src={Edit}
              alt="Edit"
              className="h-3 max-w-min cursor-pointer"
              onClick={() =>setOpen(true)}
            />
            {open && <BankAccountsContainer bankData={bankData} edit={true} setOpen={setOpen} fetchUserData={fetchUserData}/>}
          </div>
            <span className="text-xs text-gray-500">{dataToShow}</span> 
          {isDefault && <p className="text-xs p-0 m-0 text-green-600">Default</p>}
          {!isDefault && <span className="text-xs p-0 m-0 ml-auto text-gray-500 cursor-pointer" onClick={()=>handleAsDefault(bankData.id)}>Convertir en Default</span>}
        </div>
      </div>
    );
  }

  const CreateBankModal = () => {
    const [open, setOpen] = useState(false);
    return (
      <div className="flex flex-col items-center justify-center p-4 border border-gray-500 rounded-lg cursor-pointer bg-gray-100" onClick={()=>setOpen(true)}>
        <div className='flex flex-row w-full align-center justify-center' >
          <p>+ Agregar</p>
        </div>
        {open && <BankAccountsContainer edit={false} setOpen={setOpen} fetchUserData={fetchUserData}/>}
      </div>
    );
  }


  return (
    <div className="flex flex-col space-y-10 h-full w-full">
      
      {/* HOME USER CARDS */}
      <div className="grid grid-cols-1 sm:inline-flex items-stretch w-full space-y-6 sm:space-y-0 sm:space-x-8 h-auto">
        {/* BANK DETAILS */}
        <div className="border border-gray-300 rounded-md py-4 px-6 sm:p-6 md:p-8 bg-white w-1/2 sm:w-1/2 h-auto">
          <div className="text-sm text-gray-800 mb-4 sm:mb-6 font-poppins inline-flex justify-between w-full">
            <span>Cuentas Bancarias</span>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {sessionUser && sessionUser.allBankData.map((bankData, i) => {
              return <BankCard key={i} bankData={bankData}/>
            })}
            <CreateBankModal />
          </div>
        </div>
        {hasPendingRequest ? (
          <div className="w-1/2 sm:w-1/2 h-auto flex self-stretch space-x-6 md:space-x-8 overflow-x-scroll sm:overflow-x-auto">
            {/* PROJECTS INVITATIONS */}
            <div className="w-auto lg:w-full">
              {projectsLoading ? (
                <LoaderComponent />
              ) : (
                <AcceptProjectInviteCard
                  projects={userProjects.invites}
                  userId={sessionUser.id}
                />
              )}
            </div>
          </div>
        ) : (
          hasActiveProjects ? (
            <div className="w-1/2 lg:w-1/2">
              {paymentsLoading ? <LoaderComponent /> : <UploadPaymentCard />}
            </div>
          ):(
            <div className="w-1/2 bg-transparent flex flex-col-reverse sm:flex-col items-center sm:items-start font-poppins sm:w-1/2">
              <div className="pt-6 sm:pt-0">
                <p className="hidden sm:block text-base md:text-xl text-gray-800">
                  Ofertas de proyecto
                </p>
                <p className="block sm:hidden text-sm text-center sm:text-left md:text-base text-gray-500">
                  Aún no tienes ofertas de proyecto ni pagos pendientes.
                </p>
                <p className="hidden sm:block text-sm text-center sm:text-left md:text-base text-gray-500">
                  Aún no tienes ofertas de proyecto. Espera a que Itesa te envíe
                  una propuesta.
                </p>
              </div>
              <div className="w-full md:w-1/2 flex justify-center">
                <img
                  src={UnavailablePendings}
                  alt="Unavailable"
                  className="max-w-min hidden sm:block"
                />
                <img
                  src={NoProjectMobile}
                  alt="Unavailable"
                  className="max-w-min sm:hidden"
                />
              </div>
            </div>
          )
        )}
      </div>
      {/* USER PAYMENTS TABLE */}
      <div className="h-full w-full">
        {paymentsLoading ? <LoaderComponent /> : <UserPayments />}
      </div>
    </div>
  )
}

export default UserHome
