const SVGGrid = () => (
	<svg width='16' height='15' viewBox='0 0 21 16' fill='none' className='h-full'>
		<path
			d='M0 7.38462H6.17647V0H0V7.38462ZM0 16H6.17647V8.61539H0V16ZM7.41176 16H13.5882V8.61539H7.41176V16ZM14.8235 16H21V8.61539H14.8235V16ZM7.41176 7.38462H13.5882V0H7.41176V7.38462ZM14.8235 0V7.38462H21V0H14.8235Z'
			fill='currentColor'
		/>
	</svg>
)

export default SVGGrid
