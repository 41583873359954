import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import SignatureCanvas from 'react-signature-canvas'
// COMPONENTS AND UI
import CContractPDF from '../Utils/PDFS/ConfidentialityContract'
import { PrimaryButton } from '../Utils/UI/Buttons'
import ArrowLeftRegister from '../Utils/Images/ArrowLeftRegister.png'

const CContract = ({
  submit,
  formData,
  signature,
  setSignature,
  invalidSignature,
}) => {
  const pd = formData.personalData
  const canvas = useRef({})

  console.log("pd", pd)

  return (
    <div className="min-h-screen h-auto bg-gray-50 w-full flex flex-col items-center">
      <div className="w-full sm:w-3/4 px-4 sm:px-0 py-6 sm:py-10 flex flex-col items-baseline space-y-6">
        <Link to="/register/datos-fiscales">
          <div className="inline-flex items-center">
            <img src={ArrowLeftRegister} alt="Back" />
            <p className="text-sm sm:text-base text-gray-500 pl-2 font-poppins">
              Registro de datos fiscales
            </p>
          </div>
        </Link>
        <div>
          <img
            src="/images/logo-full.png"
            alt="ITESA INNOVATION HUB"
            className="h-10 sm:h-14 w-auto"
          />
        </div>
        <h3 className="dm-h3">Firma de contrato de confidencialidad</h3>
        <p className="text-sm sm:text-lg text-gray-500 font-poppins">
          Este PDF estará disponible en tu cuenta para poder ser descargado
          previamente.
        </p>
      </div>
      <div className="w-full sm:w-3/4 px-4 sm:px-0 py-6 sm:py-10 flex flex-col items-center space-y-20">
        <div className="h-96 sm:h-screen w-full">
          <CContractPDF
            fname={pd.fname}
            lname={pd.lname}
            cuit={pd.cuit}
            address={pd.address}
            role={pd.role}
            signature={signature ? signature : null}
            viewer={true}
          />
        </div>
        <div className="w-full sm:w-3/5 flex flex-col items-center space-y-2">
          <div className="px-1 sm:px-0 inline-flex justify-between items-center w-full">
            <p className="text-base text-gray-800">Firma digital</p>
            <button
              className="text-sm text-gray-800 focus:outline-none p-0 m-0"
              onClick={() => {
                canvas.current.clear()
                setSignature(null)
              }}
            >
              Reset
            </button>
          </div>
          <SignatureCanvas
            ref={canvas}
            penColor="black"
            canvasProps={{
              className: `${
                invalidSignature && canvas.current.isEmpty()
                  ? 'border border-red-600 rounded w-full h-48 bg-gray-50'
                  : 'border border-gray-400 rounded w-full h-48 bg-gray-50'
              }`,
            }}
            onEnd={
              () =>
                setSignature(
                  canvas.current.getTrimmedCanvas().toDataURL('image/jpg')
                ) //base64
            }
          />
          {invalidSignature && canvas.current.isEmpty() ? (
            <p className="text-base text-red-600">
              Firma y acepta el contrato para poder registrate
            </p>
          ) : null}
          <div className="md:w-1/2 lg:w-2/5 pt-4">
            <PrimaryButton
              type="button"
              size="lg"
              children="Aceptar y firmar"
              handler={submit}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CContract
