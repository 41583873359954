import React from 'react'

const SidebarButton = ({ button, active = false, profileImage }) => {
  return (
    <div
      className={
        'flex justify-center py-3 w-full h-auto ' +
        (active ? 'bg-purple-lightest group' : 'hover:bg-purple-lightest group')
      }
    >
      {button === 'home' ? (
        <svg
          width="36"
          height="35"
          viewBox="0 0 36 35"
          fill="none"
          className={
            active
              ? 'text-purple h-9'
              : 'text-gray-800 h-9 group-hover:text-purple'
          }
        >
          <path
            d="M15.2861 29.0209V20.3146H21.0903V29.0209H28.3455V17.4125H32.6987L18.1882 4.35313L3.67773 17.4125H8.03087V29.0209H15.2861Z"
            fill="currentColor"
          />
        </svg>
      ) : null}
      {button === 'proyectos' ? (
        <svg
          width="27"
          height="15"
          viewBox="0 0 27 15"
          fill="none"
          className={
            active
              ? 'text-purple h-9'
              : 'text-gray-800 h-9 group-hover:text-purple'
          }
        >
          <path
            d="M0 9H3V6H0V9ZM0 15H3V12H0V15ZM0 3H3V0H0V3ZM6 9H27V6H6V9ZM6 15H27V12H6V15ZM6 0V3H27V0H6Z"
            fill="currentColor"
          />
        </svg>
      ) : null}
      {button === 'comunidad' ? (
        <svg
          width="27"
          height="19"
          viewBox="0 0 27 19"
          fill="none"
          className={
            active
              ? 'text-purple h-9'
              : 'text-gray-800 h-9 group-hover:text-purple'
          }
        >
          <path
            d="M19.575 9.50005C21.438 9.50005 22.9365 7.98805 22.9365 6.12505C22.9365 4.26205 21.438 2.75005 19.575 2.75005C17.712 2.75005 16.2 4.26205 16.2 6.12505C16.2 7.98805 17.712 9.50005 19.575 9.50005ZM9.45 8.15005C11.691 8.15005 13.4865 6.34105 13.4865 4.10005C13.4865 1.85905 11.691 0.0500488 9.45 0.0500488C7.209 0.0500488 5.4 1.85905 5.4 4.10005C5.4 6.34105 7.209 8.15005 9.45 8.15005ZM19.575 12.2C17.1045 12.2 12.15 13.442 12.15 15.9125V18.95H27V15.9125C27 13.442 22.0455 12.2 19.575 12.2ZM9.45 10.85C6.3045 10.85 0 12.4295 0 15.575V18.95H9.45V15.9125C9.45 14.765 9.8955 12.7535 12.6495 11.228C11.475 10.985 10.341 10.85 9.45 10.85Z"
            fill="currentColor"
          />
        </svg>
      ) : null}
      {button === 'perfil' ? (
        <>
          <div className="block sm:hidden h-9 w-9">
            <img
              src={profileImage ? profileImage : null}
              alt="USER"
              className="rounded-full h-full w-full object-center object-cover border-2 border-purple"
            />
          </div>
          <svg
            width="27"
            height="28"
            viewBox="0 0 27 28"
            fill="none"
            className={
              (active
                ? 'text-purple h-9'
                : 'text-gray-800 h-9 group-hover:text-purple') +
              ' hidden sm:block'
            }
          >
            <path
              d="M13.5 0.5C6.048 0.5 0 6.548 0 14C0 21.452 6.048 27.5 13.5 27.5C20.952 27.5 27 21.452 27 14C27 6.548 20.952 0.5 13.5 0.5ZM13.5 4.55C15.741 4.55 17.55 6.359 17.55 8.6C17.55 10.841 15.741 12.65 13.5 12.65C11.259 12.65 9.45 10.841 9.45 8.6C9.45 6.359 11.259 4.55 13.5 4.55ZM13.5 23.72C10.125 23.72 7.1415 21.992 5.4 19.373C5.4405 16.6865 10.8 15.215 13.5 15.215C16.1865 15.215 21.5595 16.6865 21.6 19.373C19.8585 21.992 16.875 23.72 13.5 23.72Z"
              fill="currentColor"
            />
          </svg>
        </>
      ) : null}
      {button === 'logout' ? (
        <svg
          width="33"
          height="31"
          viewBox="0 0 33 31"
          fill="none"
          className="text-gray-800 h-9 group-hover:text-purple"
        >
          <path
            d="M12.2333 18.3843C13.117 18.3843 13.8333 17.668 13.8333 16.7843C13.8333 15.9007 13.117 15.1843 12.2333 15.1843C11.3497 15.1843 10.6333 15.9007 10.6333 16.7843C10.6333 17.668 11.3497 18.3843 12.2333 18.3843ZM17.5667 1.85098C17.5667 1.53994 17.4309 1.24439 17.1949 1.04175C16.959 0.839107 16.6463 0.749558 16.3388 0.796557L1.40549 3.07922C0.884674 3.15883 0.5 3.60678 0.5 4.13364V27.3016C0.5 27.8285 0.884592 28.2764 1.40534 28.356L16.3387 30.6409C16.6462 30.6879 16.9589 30.5984 17.1949 30.3958C17.4309 30.1931 17.5667 29.8976 17.5667 29.5865V15.7177L28.6008 15.7177L26.4741 17.5816C26.0314 17.9695 25.9866 18.6434 26.3739 19.0867C26.7612 19.5301 27.434 19.575 27.8766 19.1871L32.1363 15.4537C32.3674 15.2512 32.5 14.9586 32.5 14.651C32.5 14.3434 32.3674 14.0508 32.1363 13.8482L27.8766 10.1149C27.434 9.72698 26.7612 9.77191 26.3739 10.2153C25.9866 10.6586 26.0314 11.3325 26.4741 11.7204L28.6008 13.5843L17.5667 13.5843V1.85098ZM15.4333 3.09308V28.3442L2.63333 26.3858V5.04965L15.4333 3.09308ZM20.7667 28.5176H19.7V17.851H21.8333V27.451C21.8333 28.0401 21.3558 28.5176 20.7667 28.5176ZM19.7 11.451V2.91764H20.7667C21.3558 2.91764 21.8333 3.39521 21.8333 3.98431V11.451H19.7Z"
            fill="currentColor"
          />
        </svg>
      ) : null}
      {button === 'pagos' && (
        <svg
          fill="currentColor"
          viewBox="0 0 20 20"
          className={
            (active
              ? 'text-purple h-9'
              : 'text-gray-800 h-9 group-hover:text-purple')
          }
        >
          <path
            fillRule="evenodd"
            d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
            clipRule="evenodd"
          />
        </svg>
      )}
    </div>
  )
}

export default SidebarButton
