import React from 'react'
import { Link } from 'react-router-dom'
// UI
import { PrimaryButton } from '../Utils/UI/Buttons'
import { SelectRegister } from '../Utils/UI/Selects'
import { Input, InputCuit, InputDate, InputPhone } from '../Utils/UI/Inputs'

const RegisterDatosPersonales = ({
  personalData,
  handleInputs,
  validateForm,
  error,
  invalidPassword,
  invalidInvite,
  invalidCuit
}) => {

  return (
    <>
      <div className="min-h-screen bg-white sm:bg-gray-50 flex flex-col justify-center sm:px-6 lg:px-8 pb-10">
        <div className="mt-8 xl:mx-auto xl:w-full xl:max-w-xl xl:space-y-6">
          <div className="w-full bg-white py-8 px-4 sm:border sm:rounded-lg sm:px-10 space-y-4">
            <div className="flex flex-col w-full justify-center items-center p-2">
              <img
                src="/images/logo-full.png"
                alt="ITESA INNOVATION HUB"
                className="h-14 w-auto hidden sm:block"
              />
            </div>
            <h3 className="dm-h3 text-center text-gray-800 pb-2">
              Creá tu cuenta
            </h3>
            <form className="space-y-3" onSubmit={(e) => validateForm(e)}>
              {/* //agregar cuit y domicilio para el contrato de confidencialidad */}
              <div className='flex gap-4'>
                <Input
                  type="text"
                  label="Nombre"
                  name="fname"
                  value={personalData.fname}
                  handler={handleInputs}
                  error={error}
                />
                <Input
                  type="text"
                  label="Apellido"
                  name="lname"
                  value={personalData.lname}
                  handler={handleInputs}
                  error={error}
                />
              </div>
                <Input
                  type="email"
                  label="Email"
                  name="email"
                  value={personalData.email}
                  handler={handleInputs}
                  error={error}
                />
              <div className='flex w-full gap-4'>
                <div className='flex w-full'>
                  {invalidInvite ? (
                    <p className="text-sm font-medium font-poppins text-red-600">
                      El email introducido no ha sido invitado a unirse.
                    </p>
                  ) : null}
                  <InputPhone
                    type="text"
                    label="Numero de teléfono"
                    name="phone"
                    value={personalData.phone}
                    handler={handleInputs}
                    error={error}
                  />
                </div>
                <div className='flex w-full'>
                  <InputCuit
                      type="text"
                      label="Cuit"
                      name="cuit"
                      value={personalData.cuit}
                      handler={handleInputs}
                      error={error}
                    />
                    {invalidCuit ? (
                      <p className="text-sm font-medium font-poppins text-red-600">
                        Ingrese un Cuit válido
                      </p>
                    ) : null}
                </div>
              </div>
              <Input
                type="text"
                label="Domicilio fiscal"
                name="address"
                value={personalData.address}
                handler={handleInputs}
                error={error}
              />
              <div className='flex gap-4 w-full'>
                <div className='flex w-full'>
                  <InputDate
                    type="text"
                    label="Fecha de nacimiento"
                    name="birthdate"
                    value={personalData.birthdate}
                    handler={handleInputs}
                    error={error}
                  />
                </div>
                <div className='w-full'>
                  <SelectRegister
                    label="Profesión"
                    options={['Diseñador', 'Developer', 'Product Manager', 'Marketing']}
                    value={personalData.role}
                    handleInputs={handleInputs}
                    data="role"
                    error={error}
                  />
                </div>
              </div>
              <Input
                type="password"
                label="Contraseña"
                name="password"
                value={personalData.password}
                handler={handleInputs}
                error={error}
              />
              {invalidPassword ? (
                <p className="text-sm font-medium font-poppins text-red-600">
                  La contraseña debe tener al menos 6 caracteres
                </p>
              ) : null}
              <br />
              <PrimaryButton type="submit" size="lg" children="Confirmar" />
            </form>
          </div>
          <div className="bg-white py-6 px-4 sm:border sm:rounded-lg sm:px-6 border-t w-full inline-flex justify-evenly sm:justify-between items-center">
            <p className="text-center text-sm sm:text-lg text-gray-600 font-poppins">
              ¿Ya tenés una cuenta?
            </p>
            <Link
              to="/login"
              className="text-purple text-sm sm:text-base text-center font-poppins hover:text-purple-darkest focus:text-purple-dark"
            >
              Log in
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default RegisterDatosPersonales
