
import { Input, InputCuit } from '../../Utils/UI/Inputs'
import { PrimaryButton } from '../../Utils/UI/Buttons'
import { SelectRegister } from '../../Utils/UI/Selects'
import { LoaderComponent } from '../../Utils/UI/Loaders'
import ModalLayout from '../../Utils/UI/ModalLayout'
import { useState } from 'react'
import { cbu, cuit } from 'arg.js'
import { createBankData, updateBankDetails } from '../../../services/db'
import { useRecoilValue } from 'recoil'
import { sessionUserState } from '../../../atoms'

const ModalCreateBank = ({
  formData,
  handleModals,
  fetchUserData,
  newUser,
  handleFormData
}) => {

    const sessionUser = useRecoilValue(sessionUserState)

    const [selected, setSelected] = useState(null)
    const [newBankData, setNewBankData] = useState({})
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [invalidCbu, setInvalidCbu] = useState(false)
    const [invalidCuit, setInvalidCuit] = useState(false)

    const validateArgData = (data, setError) => {
        let errors = 0
        for (const key in data) {
          if (!data[key]) errors++
          if (key === 'cuit' && !cuit.isValid(data[key].replace(/-/g, ''))) {
            errors++
            setInvalidCuit(true)
          } else if (key === 'cbu' && !cbu.isValid(data[key])) {
            errors++
            setInvalidCbu(true)
          }
        }
        errors ? setError(true) : setError(false)
        return errors
    }

    const validateEmail = (data, setError) => {
        let email = data.email
        let errors = 0
        if (!email) {
            errors++
            return errors
        }
        if (email && !email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)) {
            errors++
            return errors
        }
        return errors
    }

    const validateAddress = (data, setError) => {
        let address = data.walletAddress
        let errors = 0
        if (!address) {
            errors++
            return errors
        }
    }

    const validateAch = (data, setError) => {
        let errors = 0
       //check if has routing number, account number, beneficiary name, account type, bank name, bank address
        let routingNumber = data.routingNumber
        let accountNumber = data.accountNumber
        let beneficiaryName = data.beneficiaryName
        let accountType = data.accountType
        let bankName = data.bankName
        let bankAddress = data.bankAddress
        if (!routingNumber) {
            errors++
            return errors
        }
        if (!accountNumber) {
            errors++
            return errors
        }
        if (!beneficiaryName) {
            errors++
            return errors
        }
        if (!accountType) {
            errors++
            return errors
        }
        if (!bankName) {
            errors++
            return errors
        }
        if (!bankAddress) {
            errors++
            return errors
        }
        return errors
    }
    
    const validateForm = async (e) => {
        e.preventDefault()
        console.log('newBankData', newBankData)
        let errors = 0
        if(selected <= 2){
            errors = validateEmail(newBankData, setError)
        }
        if(selected === 3){
            errors = validateAch(newBankData, setError)
        }
        if(selected === 4){
            errors = validateArgData(newBankData, setError)
        }
        if(selected === 5){
            errors = validateAddress(newBankData, setError)
        }
        let invoice = newBankData.invoice
        if (!invoice) {
            errors++
        }
        if(formData.name === ''){
            errors++
        }
        console.log('errors', errors)
        console.log('newBankData', newBankData)
        if (!errors) {
            console.log('newBankData', newBankData)
            if(newUser){
                handleFormData(newBankData)
            }else{
                setLoading(true)
                await createBankData(sessionUser.id, newBankData)
                setLoading(false)
                setSuccess(true)
                fetchUserData()
            }
        }else{
            setError(true)
            setLoading(false)
        }
    }

    const paymentMethods =[{
        id: 1,
        name: "Wise",
        image: "/images/wise.png",
        description: "Transferencia internacional",
    },{
        id: 2,
        name: "Zelle",
        image: "/images/zelle.png",
        description: "Transferencia internacional",
    },{
        id: 3,
        name: "US Bank (ACH)",
        image: "/images/ach.svg",
        description: "Transferencia internacional",
    },{
        id: 4,
        name: "Banco Argentina",
        image: "/images/arg.png",
        description: "Transferencia internacional",
    },{
        id: 5,
        name: "USDT (Tether)",
        image: "/images/usdt.svg",
        description: "Transferencia internacional",
    }]

    const handleSelected = (id) => {
        setSelected(id)
        if(id === 1){
            setNewBankData({
                type: 'wise',
            })
        }
        if(id === 2){
            setNewBankData({
                type: 'zelle',
            })
        }
        if(id === 3){
            setNewBankData({
                type: 'ach',
            })
        }
        if(id === 4){
            setNewBankData({
                type: 'arg',
            })
        }
        if(id === 5){
            setNewBankData({
                type: 'usdt',
            })
        }
    }

    const handleNewUserClose = () => {
        setSelected(null)
        setNewBankData({})
    }

    return (
        <ModalLayout
            goBackText="Home"
            handler={newUser ? handleNewUserClose : handleModals}
            modal="createBank"
            title={selected ? 'Complete los datos' : 'Seleccione un método de cobro'}
            regular={success ? true : false}
        >   
            {selected ? (
                <>
                    {!success ? (
                        <>
                        <div className="w-full flex flex-col space-y-4 mb-3">
                            <Input 
                                type="text"
                                label="Agendar Como..."
                                name="accountOwner"
                                value={formData.name}
                                handler={(e)=> setNewBankData({...newBankData, name: e.target.value})}
                                error={error}
                            />
                        </div>
                           {selected <= 2 && (
                                <div className="w-full flex flex-col space-y-4">
                                    <Input
                                        type="text"
                                        label="Email"
                                        name="email"
                                        value={newBankData.email}
                                        handler={(e) => setNewBankData({...newBankData, email: e.target.value})}
                                        error={error}
                                    />                                   
                                </div>
                            )}
                            {selected === 3 && (
                                 <div className="w-full flex flex-col space-y-4">
                                    <Input
                                        type="text"
                                        label="Routing Number"
                                        name="routingNumber"
                                        value={newBankData.routingNumber}
                                        handler={e => setNewBankData({...newBankData, routingNumber: e.target.value})}
                                        error={error}
                                    />
                                    <Input
                                        type="text"
                                        label="Account Number"
                                        name="accountNumber"
                                        value={newBankData.accountNumber}
                                        handler={e => setNewBankData({...newBankData, accountNumber: e.target.value})}
                                        error={error}
                                    />
                                    <Input
                                        type="text"
                                        label="Beneficiary Name"
                                        name="beneficiaryName"
                                        value={newBankData.beneficiaryName}
                                        handler={e => setNewBankData({...newBankData, beneficiaryName: e.target.value})}
                                        error={error}
                                    />
                                    <SelectRegister
                                        label={'Account Type'}
                                        options={['Checking', 'Savings']}
                                        value={newBankData.accountType}
                                        handleInputs={(e,o) => setNewBankData({...newBankData, accountType: o})}
                                        data="accountType"
                                        error={error}
                                    />
                                    <Input
                                        type="text"
                                        label="Bank Name"
                                        name="bankName"
                                        value={newBankData.bankName}
                                        handler={e => setNewBankData({...newBankData, bankName: e.target.value})}
                                        error={error}
                                    />
                                    <Input
                                        type="text"
                                        label="Bank Address"
                                        name="bankAddress"
                                        value={newBankData.bankAddress}
                                        handler={e => setNewBankData({...newBankData, bankAddress: e.target.value})}
                                        error={error}
                                    />
                                </div>
                            )}
                            {selected === 4 && (
                                <div className="w-full flex flex-col space-y-4">
                                    <Input
                                        type="text"
                                        label="Banco"
                                        name="bank"
                                        value={newBankData.bank}
                                        handler={(e)=> setNewBankData({...newBankData, bank: e.target.value})}
                                        error={error}
                                    />
                                    <Input
                                        type="text"
                                        label="Titular de la cuenta"
                                        name="accountOwner"
                                        value={newBankData.accountOwner}
                                        handler={(e)=> setNewBankData({...newBankData, accountOwner: e.target.value})}
                                        error={error}
                                    />
                                    <div>
                                        <Input
                                        type="number"
                                        label="CBU"
                                        name="cbu"
                                        value={newBankData.cbu}
                                        handler={(e)=> setNewBankData({...newBankData, cbu: e.target.value})}
                                        error={error}
                                        />
                                        {invalidCbu ? (
                                        <p className="text-sm font-medium font-poppins text-red-600 mt-2">
                                            Ingrese un CBU válido
                                        </p>
                                        ) : null}
                                    </div>
                                    <Input
                                        type="text"
                                        label="Domicilio fiscal"
                                        name="address"
                                        value={newBankData.address}
                                        handler={(e)=> setNewBankData({...newBankData, address: e.target.value})}
                                        error={error}
                                    />
                                </div>
                            )}
                            {selected === 5 && (
                                <div className="w-full flex flex-col space-y-4">
                                    <p className="text-lg font-medium font-poppins text-gray-500">
                                        Binance Smart Chain - BEP20
                                    </p>
                                    <Input
                                        type="text"
                                        label="Dirección de la billetera"
                                        name="walletAddress"
                                        value={newBankData.walletAddress}
                                        handler={e => setNewBankData({...newBankData, walletAddress: e.target.value})}
                                        error={error}
                                    />
                                </div>
                            )}
                            <div className='mt-5'>
                                <SelectRegister
                                    label="Tipo de factura a emitir"
                                    options={['A', 'C','Invoice']}
                                    value={newBankData.invoice}
                                    handleInputs={(e,o)=> {
                                        setNewBankData({...newBankData, invoice: o})
                                    }}
                                    data="invoice"
                                    error={error}
                                />
                            </div>
                            {newBankData.invoice && newBankData.invoice!== 'Invoice' && (
                                <div className="w-full mt-4">
                                    <InputCuit
                                    type="text"
                                    label="Cuit"
                                    name="cuit"
                                    value={newBankData.cuit}
                                    handler={(e)=> setNewBankData({...newBankData, cuit: e.target.value})}
                                    error={error}
                                    />
                                    {invalidCuit ? (
                                    <p className="text-sm font-medium font-poppins text-red-600">
                                        Ingrese un Cuit válido
                                    </p>
                                    ) : null}
                                </div>
                            )}
                            <div className="py-6">
                                        {loading ? (
                                        <LoaderComponent />
                                        ) : (
                                        <PrimaryButton
                                            type="submit"
                                            size="lg"
                                            children="Confirmar"
                                            handler={validateForm}
                                        />
                                        )}
                                    </div>
                        </>
                    ) : null}
                    {success && !loading ? (
                    <div className="w-full">
                        <p className="text-lg w-full text-center font-poppins text-gray-500">
                        Los datos bancarios han sido editados correctamente.
                        </p>
                    </div>
                    ) : null}
                </>
            ):(
                <div className="grid grid-cols-3 gap-4">
                    {paymentMethods.map((method) => (
                        <div key={method.id} className="flex flex-col items-center justify-center p-4 border border-gray-200 rounded-lg cursor-pointer" onClick={() =>handleSelected(method.id)}>
                            <img src={method.image} alt={method.name} className="w-auto h-16" />
                            <p className="text-sm font-medium font-poppins text-gray-500 mt-2">{method.name}</p>
                        </div>
                    ))}
                </div>
            )}
        </ModalLayout>
    )
}

export default ModalCreateBank
