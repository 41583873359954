import React from 'react'
// UI
import { PrimaryButton } from '../../UI/Buttons'
import { InputMultipleTypeFile } from '../../UI/Inputs'
import {
  SelectUsersPaymentToUpload,
  SelectProjectPaymentToUpload,
  SelectPaymentNumberToUpload,
} from '../../UI/Selects'

const AddPayment = ({
  handleUserSelection,
  users,
  errorProjects,
  errorPayments,
  handleSubmit,
  projectOptions,
  paymentOptions,
  paymentToUpload,
  handleProjectSelection,
  handlePaymentSelection,
  handlePaymentSettlementDocument,
}) => (
  <form className="w-full flex flex-col items-baseline pb-14 space-y-8">
    {/* <div className="w-full space-y-2">
      <SelectUsersPaymentToUpload
        options={users}
        handleOption={handleUserSelection}
      />
      {errorProjects && !projectOptions.length ? (
        <p className="font-poppins w-full text-sm">
          * No hay proyectos pendientes de pago para este usuario{' '}
          {paymentToUpload.project.pname}
        </p>
      ) : null}
    </div>
    {!errorProjects && projectOptions.length ? (
      <div className="w-full space-y-2">
        <SelectProjectPaymentToUpload
          options={projectOptions}
          handleOption={handleProjectSelection}
          projectSelected={paymentToUpload.project.pname}
        />
        {errorPayments && !paymentOptions.length ? (
          <p className="font-poppins w-full text-sm">
            * No hay pagos pendientes para este usuario en el proyecto{' '}
            {paymentToUpload.project.pname}
          </p>
        ) : null}
      </div>
    ) : null}
    {!errorPayments && paymentOptions.length ? (
      <SelectPaymentNumberToUpload
        options={paymentOptions}
        handleOption={handlePaymentSelection}
        paymentSelected={paymentToUpload.payment}
      />
    ) : null} */}
    {paymentToUpload.payment ? (
      <InputMultipleTypeFile name="paymentSettlement" handleChange={handlePaymentSettlementDocument} />
    ) : null}
    {paymentToUpload.paymentSettlement ? (
      <div className="w-min-min  inline-flex self-end">
        <PrimaryButton
          type="button"
          size="lg"
          children="Cargar comprobante"
          handler={handleSubmit}
        />
      </div>
    ) : null}
  </form>
)

export default AddPayment
