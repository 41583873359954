import { useQuery } from 'react-query'
import { db } from '../services/firebase'

const useGetNotifications = () => {
  
  const getNotifications = async () => {
    let query = await db.collection('notifications').get()
    let notifications = query?.docs.map(notification => notification.data())
    return notifications
  }

  const { isLoading, error, data } = useQuery(
    'notifications',
    getNotifications
  )

  return { isLoading, error, data }
}

export default useGetNotifications
