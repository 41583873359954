import React from 'react'
// LIBRARY
import {
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
  LeadingActions,
  Type,
} from 'react-swipeable-list'
import 'react-swipeable-list/dist/styles.css'
// HELPERS
import { toPesos } from '../../helpers.js'
import { Dropdown } from '../../UI/Items'

const ModalPaymentsMobile = ({
  payments,
  SinglePaymentDropdown,
  admin = false,
}) => {
  const swipeRight = (payment) => (
    <LeadingActions className="h-full">
      <SwipeAction
        onClick={() => SinglePaymentDropdown('delete', payment)}
        className="h-full px-6 bg-gray-200 w-20"
      >
        <div className="flex justify-center items-center h-full w-full">
          <span className="flex justify-center items-center h-14 w-14 p-4 rounded-full bg-gray-100 border border-gray-300">
            <svg
              width="15"
              height="19"
              viewBox="0 0 15 19"
              fill="none"
              className="h-full min-w-min w-10"
            >
              <path
                d="M1.50049 16.25C1.50049 17.35 2.40049 18.25 3.50049 18.25H11.5005C12.6005 18.25 13.5005 17.35 13.5005 16.25V4.25H1.50049V16.25ZM14.5005 1.25H11.0005L10.0005 0.25H5.00049L4.00049 1.25H0.500488V3.25H14.5005V1.25Z"
                fill="#FF3434"
              />
            </svg>
          </span>
        </div>
      </SwipeAction>
    </LeadingActions>
  )

  const swipeLeft = (payment) => (
    <TrailingActions className="h-full">
      <SwipeAction
        onClick={() => SinglePaymentDropdown('invoice', payment)}
        className="h-full px-6 bg-gray-200 w-20"
      >
        <div className="flex justify-center items-center h-full w-full">
          <span className="flex justify-center items-center h-14 w-14 p-4 rounded-full bg-gray-100 border border-gray-300">
            <svg
              width="15"
              height="19"
              viewBox="0 0 15 19"
              fill="none"
              className="h-full min-w-min w-10 text-gray-300"
            >
              <path
                d="M14.9127 6.92647H10.7951V0.75H4.61862V6.92647H0.500977L7.70686 14.1324L14.9127 6.92647ZM0.500977 16.1912V18.25H14.9127V16.1912H0.500977Z"
                fill="currentColor"
              />
            </svg>
          </span>
        </div>
      </SwipeAction>
    </TrailingActions>
  )

  return (
    <table className="relative w-full">
      <tbody className="bg-white w-full">
        {payments.length ? (
          payments.map((payment, i) => {
            let options = [{ value: 'details', label: 'Ver detalles' }]
            if (payment.invoice)
              options = [
                ...options,
                {
                  value: 'invoice',
                  label: 'Descargar factura',
                },
              ]
            if (payment.paymentSettlement)
              options = [
                ...options,
                {
                  value: 'paymentSettlement',
                  label: 'Descargar comprobante de pago',
                },
              ]
            return (
              <tr
                key={i}
                className="table-row md:hidden h-full w-full min-w-max border-b border-gray-300"
              >
                <td className="w-full p-0 h-auto">
                  <SwipeableList
                    threshold={0.25}
                    type={Type.ANDROID}
                    style={{ height: '100%', width: '100%' }}
                  >
                    <SwipeableListItem
                      leadingActions={admin ? swipeRight(payment) : null}
                      trailingActions={payment.invoice && swipeLeft(payment)}
                      // onSwipeEnd={() => console.log('END')}
                      // onSwipeStart={() => console.log('START')}
                    >
                      {payment && (
                        <div className="w-full inline-flex justify-between items-center px-2">
                          <div className="w-full space-x-2 flex flex-1 items-center">
                            <div className="min-w-max">
                              <div className="relative max-h-14 h-14 w-14 my-2">
                                <img
                                  src={payment.user.profileImage}
                                  alt="USER"
                                  className="rounded-full h-full w-full object-center object-cover border border-gray-300"
                                />
                              </div>
                            </div>
                            <div className="table-cell text-sm text-gray-800 font-poppins">
                              <Dropdown
                                options={options}
                                handler={(value) =>
                                  SinglePaymentDropdown(value, payment)
                                }
                                button={
                                  <div className="flex flex-col justify-center text-left h-full min-w-0">
                                    <p>{payment.user.personalData.fullName}</p>
                                    <p className="text-gray-500 text-xs">
                                      {payment.project?.pname}
                                    </p>
                                  </div>
                                }
                              />
                            </div>
                          </div>
                          <div className="min-w-0 text-sm text-gray-800 font-poppins">
                            <div className="flex flex-col items-end space-y-1 text-base">
                              <span>{toPesos(payment.amount)}</span>
                              <span
                                className={
                                  (payment.status === 'Pendiente' &&
                                    ' text-red-500 border-red-500 ') +
                                  (payment.status === 'Aprobado' &&
                                    ' text-green-500 border-green-500 ') +
                                  (payment.status === 'Rechazado' &&
                                    ' text-gray-400 border-gray-400 ') +
                                  (payment.status === 'Realizado' &&
                                    ' text-gray-700 border-gray-400 ') +
                                  ' px-2 rounded-full text-xs border max-w-min'
                                }
                              >
                                {payment.status}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </SwipeableListItem>
                  </SwipeableList>
                </td>
              </tr>
            )
          })
        ) : (
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td className="text-center p-6 text-sm text-gray-500 font-poppins w-max">
              No se encontraron pagos
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default ModalPaymentsMobile
