import React, {Fragment} from 'react'
import { ModalHeader } from './Items'
import { Dialog, Transition } from '@headlessui/react'

const ModalLayout = ({
  modal,
  title,
  handler,
  children,
  extended,
  goBackText,
  regular = false,
}) => {
  const cancelRef = React.useRef()
  console.log('🚀 ➡️ modal', modal)
  console.log('🚀 ➡️ title', title)
  console.log('🚀 ➡️ handler', handler)
  return (
    <Transition
      appear
      as={Fragment}
      show={true}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
    <Dialog
      open
      onClose={handler}
      className="fixed z-30 inset-0 m-0 overflow-y-auto overflow-x-hidden h-auto"
      initialFocus={cancelRef}
    >
      <Dialog.Overlay className="fixed z-30 inset-0 h-full bg-gray-500 bg-opacity-75 backdrop-filter" />
      <div className="flex items-center justify-center w-full min-h-screen h-auto z-30">
        <div
          className={
            (extended ? ` w-full ` : ' w-full sm:w-2/3 md:w-1/3 ') +
            (regular ? ' mx-6 min-h-0 ' : ' min-h-screen ') +
            (modal === 'viewPayments' ? ' px-0 ' : ' px-6 ') +
            ' h-full sm:min-h-0 sm:h-auto sm:max-w-2xl md:max-w-5xl bg-white z-40 rounded-md sm:mx-0 sm:px-10 sm:my-8 py-8 overflow-auto'
          }
        >
          <div
            className={modal === 'viewPayments' ? ' px-6 sm:px-0 ' : ' px-0 '}
            ref={cancelRef}
          >
            <ModalHeader
              title={title}
              handler={handler}
              regular={regular}
              modal={modal && modal}
              goBackText={goBackText && goBackText}
            />
          </div>
          <div className="w-full h-full">{children}</div>
        </div>
      </div>
    </Dialog>
    </Transition>
  )
}

export default ModalLayout
