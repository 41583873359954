import React from 'react'
// RECOIL
import { useRecoilValue } from 'recoil'
import {
  projectsState,
  paymentsState,
  paymentsLoadingState,
  projectsLoadingState,
} from '../../../atoms'
// COMPONENTS
import PaymentsHome from '../PaymentsHome'
// UI
import { DivResumen } from '../../Utils/UI/Items'
import { AdminHomeButton } from '../../Utils/UI/Buttons'
import { LoaderComponent } from '../../Utils/UI/Loaders'
// HELPERS
import { toDollars, toPesos } from '../../Utils/helpers'
import { setCuitToPersonalData, updateAllPaymentsCurrency, updateAllUsersAddress, updateAllUsersBankData } from '../../../services/db'

const AdminHome = ({ handleModal,fetchData }) => {
  const projects = useRecoilValue(projectsState)
  const payments = useRecoilValue(paymentsState)
  const paymentsLoading = useRecoilValue(paymentsLoadingState)
  const projectsLoading = useRecoilValue(projectsLoadingState)

  const updateData = async() => {
    //need to update all users data, add a field called newBankData that is an array with an object that contains bankData
    console.log('updating data')
    // await updateAllUsersBankData()
    // await updateAllPaymentsCurrency()
    // await setCuitToPersonalData()
    await updateAllUsersAddress()

  }

  return (
    <div className="w-full sm:w-screen h-full">
      <div className="w-full flex flex-col md:flex-row justify-center items-center md:justify-between space-x-0 xl:space-x-20 space-y-4 md:space-y-0">
        <div className="flex flex-col items-center sm:items-baseline justify-items-stretch space-y-4 lg:space-y-6 w-full md:w-2/3 lg:w-full">
          <p className="hidden sm:block text-base font-poppins">Tu resumen</p>
          {/* <button className=" sm:block text-2xl font-poppins text-gray-800" onClick={updateData}>Updateee</button> */}
          <div className="flex flex-row items-center justify-around lg:space-x-8 h-full w-full md:w-auto">
            {paymentsLoading || projectsLoading ? (
              <LoaderComponent />
            ) : (
              <>
                <DivResumen
                  amount={toPesos(
                    payments.reduce(
                      (acc, payment) => {
                        if (payment.status === 'Aprobado') acc += payment.currency === 'ARS' && parseInt(payment.amount)
                        return acc
                      },
                      0
                    )
                  )}
                  children="Pagos aprobados"
                />
                <DivResumen
                  amount={"u"+toPesos(
                    payments.reduce(
                      (acc, payment) => {
                        if (payment.status === 'Aprobado') acc += payment.currency === 'USD' && parseInt(payment.amount)
                        return acc
                      },
                      0
                    )
                  )}
                  children="Pagos aprobados"
                />
                <DivResumen
                  amount={
                    projects.filter((project) =>
                      project.status === 'En desarrollo' ? true : false
                    ).length
                  }
                  children="Proyectos on going"
                />
                <DivResumen
                  amount={projects.length}
                  children="Proyectos totales"
                />
              </>
            )}
          </div>
        </div>
        <div className="flex flex-row items-center justify-end w-full space-x-2 lg:space-x-6">
          <AdminHomeButton
            img="invite"
            children="Invitar perfiles"
            handler={handleModal}
          />
          <AdminHomeButton
            img="cproject"
            children="Crear proyecto"
            handler={handleModal}
          />
          {/* <AdminHomeButton
            img="addPayment"
            children="Ingresar un pago"
            handler={handleModal}
          /> */}
        </div>
      </div>
      <div className="mt-4 md:mt-10">
        <PaymentsHome fetchData={fetchData}/>
      </div>
    </div>
  )
}

export default AdminHome
