import { PrimaryButton } from '../Utils/UI/Buttons'

export const RecoverPassword = ({
  error,
  success,
  recoveryEmail,
  setResetPassword,
  handleRecoveryEmail,
  sendRequestRecovery,
}) => {
  return (
  <>
    {success ? (
      <div className="w-full flex flex-col items-center space-y-8">
        <p className="text-gray-800 py-4 mx-auto text-sm sm:text-base text-center font-poppins">
          Te enviamos un email para restablecer tu contraseña, desde ahí podras
          recuperarla para volver a ingresar a nuestra plataforma.
        </p>
      </div>
    ) : (
      <>
        <div>
          <label
            htmlFor="email"
            className="hidden sm:block text-sm font-medium text-gray-700 font-poppins"
          >
            Email
          </label>
          <div className="mt-1">
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              placeholder="Email"
              value={recoveryEmail}
              onChange={handleRecoveryEmail}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 sm:placeholder-transparent bg-gray-50 sm:bg-transparent font-poppins focus:outline-none focus:ring-purple focus:border-purple sm:text-sm"
            />
            {error.error ? (
              <p className="text-sm font-medium font-poppins text-red-600 pt-2">
                {error.message}
              </p>
            ) : null}
          </div>
        </div>
        <div>
          <PrimaryButton
            type="button"
            size="lg"
            children="Restablecer constraseña"
            handler={sendRequestRecovery}
          />
        </div>
      </>
    )}

    <div className="sm:rounded-lg sm:px-6 w-full inline-flex justify-center items-center text-sm sm:text-base text-center font-poppins text-gray-800">
      Volver al
      <br />
      <span
        onClick={() => setResetPassword(false)}
        className="ml-2 text-purple hover:text-purple-darkest focus:text-purple-dark cursor-pointer"
      >
        Log in
      </span>
    </div>
  </>
)}
