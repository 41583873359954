import React, { useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
// IMAGES
import ThreeDotsVer from '../Images/ThreeDotsVer.png'
import ThreeDotsHor from '../Images/ThreeDotsHor.png'
import Down from '../Images/Down.png'
import Filter from '../Images/Filter.png'
import Trash from '../Images/Trash.png'
import Remove from '../Images/Remove.png'
import Download from '../Images/DownloadBlack.png'
import { Input } from './Inputs'
import { oldSkillsList } from '../helpers'
import { Dropdown, DropdownWithChips } from './Items'
import { LoaderSmall } from './Loaders'
import { SecondaryButton } from './Buttons'

export const SelectRegister = ({
  label,
  options,
  value,
  handleInputs,
  data,
  error,
  disabled
}) => {
  const [active, setActive] = useState(false)
  const handleDropdown = () => (disabled ? (null) : (active ? setActive(false) : setActive(true)))

  return (
    <div>
      <label
        htmlFor="role"
        className={
          error && !value
            ? 'hidden sm:block text-sm font-medium text-red-600 font-poppins'
            : 'hidden sm:block text-sm font-medium text-gray-700 font-poppins'
        }
      >
        {label}
      </label>
      <div className="mt-1 relative">
        <div
          type="button"
          className={
            error && !value
              ? 'relative appearance-none block w-full px-3 py-2 border rounded-md shadow-sm bg-gray-50 sm:bg-transparent text-left font-poppins focus:outline-none border-red-600 focus:ring-red-600 focus:border-red-600 sm:text-sm'
              : 'relative appearance-none block w-full px-3 py-2 border rounded-md shadow-sm bg-gray-50 sm:bg-transparent text-left font-poppins focus:outline-none border-gray-300 focus:ring-purple focus:border-purple sm:text-sm'
          }
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
          onClick={handleDropdown}
        >
          {value ? (
            <span className="block truncate text-gray-800">{value}</span>
          ) : (
            <span
              className={
                error && !value
                  ? 'block truncate sm:invisible text-red-500'
                  : 'block truncate sm:invisible text-gray-400'
              }
            >
              {label}
            </span>
          )}
          <span className="block truncate text-gray-400 sm:invisible"></span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.41 0.294922L6 4.87492L10.59 0.294922L12 1.70492L6 7.70492L0 1.70492L1.41 0.294922Z"
                fill="#666666"
              />
            </svg>
          </span>
        </div>
        {active ? (
          <ul
            className="absolute bottom-10 mb-1 w-full bg-gray-50 sm:bg-white shadow-sm max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
            tabIndex="-1"
            role="listbox"
            aria-labelledby="listbox-label"
            aria-activedescendant="listbox-option-3"
          >
            {options.map((option) => (
              <li
                className="text-gray-800 cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-purple-lightest"
                key={option}
                id={option.toLowerCase()}
                role="option"
                aria-selected="false"
                onClick={(e) => {
                  handleDropdown()
                  handleInputs(e, option, data)
                }}
              >
                <span className="font-normal block truncate">{option}</span>
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </div>
  )
}

export const SelectUsers = ({ options, handleOption }) => {
  const [listItems, setListItems] = useState([])
  const [inputValue, setInputValue] = useState('')

  const handleChange = (e) => {
    setInputValue(e.target.value)
    setListItems(
      options
        .filter((user) => {
          return e.target.value
            ? user.personalData.fullName
                .toLowerCase()
                .includes(e.target.value.toLowerCase())
            : null
        })
        .slice(0, 10)
    )
  }
  return (
    <div className="w-full h-full relative">
      <Listbox
        as="div"
        className="focus:outline-none"
        onChange={(option) => {
          setInputValue('')
          handleOption('event', 'users', option.id)
        }}
      >
        <input
          type="text"
          onChange={handleChange}
          value={inputValue}
          className="appearance-none block w-full px-3 py-2 border rounded-md sm:placeholder-transparent sm:bg-transparent font-poppins focus:outline-none bg-gray-50 sm:text-sm border-gray-300 placeholder-gray-400 focus:ring-purple focus:border-purple"
        />
        {inputValue ? (
          <Listbox.Options
            static
            className="relative mt-1 overflow-auto max-h-32 block font-poppins bg-white rounded-md text-sm border border-gray-300 focus:outline-none w-full"
          >
            {listItems.length === 0 ? (
              <Listbox.Option className="text-gray-900  select-none relative py-2 px-2 focus:outline-none">
                No se encontro usuario
              </Listbox.Option>
            ) : (
              listItems.map((user) => (
                <Listbox.Option
                  key={user.id}
                  value={user}
                  className="text-gray-900 cursor-default select-none block relative py-2 px-2 focus:outline-none bg-white hover:bg-purple-lightest"
                >
                  {user.personalData.fullName}
                </Listbox.Option>
              ))
            )}
          </Listbox.Options>
        ) : null}
      </Listbox>
    </div>
  )
}

export const SelectUsersPaymentToUpload = ({ options, handleOption }) => {
  const [listItems, setListItems] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [userSelected, setUserSelected] = useState('')

  const handleChangeInput = (e) => {
    setInputValue(e.target.value)
    setListItems(
      options
        .filter((user) => {
          return e.target.value
            ? user.personalData.fullName
                .toLowerCase()
                .includes(e.target.value.toLowerCase())
            : null
        })
        .slice(0, 10)
    )
  }

  const handleChangeOption = (option) => {
    let verifiedOption = option.hasOwnProperty('personalData') ? option : null
    if (verifiedOption === null) setInputValue('')
    setUserSelected(verifiedOption)
    handleOption('event', 'users', verifiedOption)
  }

  return (
    <div className="w-full h-full relative ">
      <Listbox
        className="focus:outline-none"
        as="div"
        onChange={(option) => handleChangeOption(option)}
      >
        <label
          htmlFor="userFinder"
          className="mb-1 hidden sm:block text-sm font-medium font-poppins text-gray-700"
        >
          Perfil del freelancer
        </label>
        <div className="w-full h-full inline-flex items-center justify-between border rounded-md border-gray-300 p-0 m-0">
          <input
            name="userFinder"
            type="text"
            placeholder="Busca usuarios"
            onChange={handleChangeInput}
            value={
              userSelected ? userSelected.personalData.fullName : inputValue
            }
            disabled={userSelected ? true : false}
            className={
              (userSelected ? 'border-0' : 'border') +
              ' appearance-none h-full w-full px-3 py-2 sm:bg-transparent font-poppins focus:outline-none bg-gray-50 sm:text-sm placeholder-gray-400 rounded-md border-transparent focus:border-purple focus:ring-purple'
            }
          />
          {userSelected ? (
            <img
              src={Remove}
              alt="Remove"
              className="h-auto w-6 mx-2 cursor-pointer"
              onClick={handleChangeOption}
            />
          ) : null}
        </div>
        {inputValue && !userSelected ? (
          <Listbox.Options
            static
            className="relative mt-1 overflow-auto max-h-32 block font-poppins bg-white rounded-md text-sm border border-gray-300 focus:outline-none w-full z-40"
          >
            {listItems.length === 0 ? (
              <Listbox.Option className="text-gray-900  select-none relative py-2 px-2 focus:outline-none">
                No se encontro usuario
              </Listbox.Option>
            ) : (
              listItems.map((user) => (
                <Listbox.Option
                  key={user.id}
                  value={user}
                  className="text-gray-900 cursor-default select-none block relative py-2 px-2 focus:outline-none bg-white hover:bg-purple-lightest"
                >
                  {user.personalData.fullName}
                </Listbox.Option>
              ))
            )}
          </Listbox.Options>
        ) : null}
      </Listbox>
    </div>
  )
}

export const SelectProjectPaymentToUpload = ({
  options,
  handleOption,
  projectSelected,
}) => {
  return (
    <div className="w-full h-full relative">
      <Listbox
        as="div"
        className="relative focus:outline-none"
        onChange={(option) => {
          handleOption(option)
        }}
      >
        <label
          htmlFor="projectFinder"
          className="mb-1 hidden sm:block text-sm font-medium font-poppins text-gray-700"
        >
          Proyecto
        </label>
        <Listbox.Button
          className={
            (projectSelected ? 'text-gray-800' : 'text-gray-400') +
            ' relative z-0 inline-flex justify-between items-center border border-gray-300 appearance-none w-full px-3 py-2 sm:placeholder-transparent sm:bg-transparent font-poppins focus:outline-none sm:text-sm rounded-md'
          }
        >
          {projectSelected ? projectSelected : 'Selecciona un proyecto'}
          <span>
            <img src={Down} alt="Select" />
          </span>
        </Listbox.Button>
        {options.length ? (
          <Listbox.Options className="absolute mt-1 overflow-auto max-h-32 block font-poppins bg-white rounded-md text-sm border border-gray-300 focus:outline-none w-full z-10">
            {options.map((project) => (
              <Listbox.Option
                key={project.projectId}
                value={project}
                className="text-gray-900 cursor-default select-none block relative py-2 px-2 focus:outline-none bg-white hover:bg-purple-lightest"
              >
                {project.pname}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        ) : null}
      </Listbox>
    </div>
  )
}

export const SelectPaymentNumberToUpload = ({
  options,
  handleOption,
  paymentSelected,
}) => {
  return (
    <div className="w-full h-full relative ">
      <Listbox
        as="div"
        className="relative focus:outline-none"
        onChange={(option) => {
          handleOption(option)
        }}
      >
        <label
          htmlFor="projectFinder"
          className="mb-1 hidden sm:block text-sm font-medium font-poppins text-gray-700"
        >
          Cuota
        </label>
        <Listbox.Button
          className={
            (paymentSelected ? 'text-gray-800' : 'text-gray-400') +
            ' relative z-0 inline-flex justify-between items-center border border-gray-300 appearance-none w-full px-3 py-2 sm:placeholder-transparent sm:bg-transparent font-poppins focus:outline-none sm:text-sm rounded-md'
          }
        >
          {paymentSelected
            ? 'Cuota ' + paymentSelected.paymentNumber
            : 'Selecciona cuota'}
          <span>
            <img src={Down} alt="Select" />
          </span>
        </Listbox.Button>
        {options.length ? (
          <Listbox.Options className="absolute mt-1 overflow-auto max-h-14 block font-poppins bg-white rounded-md text-sm border border-gray-300 focus:outline-none w-full z-10">
            {options.map((payment) => (
              <Listbox.Option
                key={payment.id}
                value={payment}
                className="text-gray-900 cursor-default select-none block relative py-2 px-2 focus:outline-none bg-white hover:bg-purple-lightest"
              >
                Cuota {payment.paymentNumber}
                {payment.bono && ' Bono'}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        ) : null}
      </Listbox>
    </div>
  )
}

export const SelectProjects = ({ options, projectSelected, handleOption }) => {
  const [listItems, setListItems] = useState([])
  const [inputValue, setInputValue] = useState('')

  const handleChange = (e) => {
    handleOption('event', 'projects', null)
    setInputValue(e.target.value)
    setListItems(
      options
        .filter((project) => {
          return e.target.value
            ? project.pname.toLowerCase().includes(e.target.value.toLowerCase())
            : null
        })
        .slice(0, 10)
    )
  }
  return (
    <div className="w-full h-full relative">
      <Listbox
        as="div"
        className="focus:outline-none"
        onChange={(option) => {
          setInputValue('')
          handleOption('event', 'projects', option.projectId)
        }}
      >
        <div className="w-full flex flex-col items-center">
          <label
            htmlFor="user"
            className="text-base text-gray-800 font-poppins"
          >
            Seleccionar proyecto
          </label>
          {projectSelected ? (
            <div className="flex items-center my-2 px-4 py-1 text-sm sm:text-base text-gray-800 font-poppins border border-gray-300 rounded-full w-auto">
              <span className="w-full">
                {projectSelected ? projectSelected.pname : null}
              </span>
              <img
                src={Trash}
                alt="Remove project"
                className="px-2 cursor-pointer"
                onClick={() => handleOption('event', 'projects', null)}
              />
            </div>
          ) : (
            <input
              type="search"
              onChange={handleChange}
              value={inputValue}
              className="mt-2 appearance-none block w-full px-3 py-2 border rounded-md sm:placeholder-transparent sm:bg-transparent font-poppins focus:outline-none bg-gray-50 sm:text-sm border-gray-300 placeholder-gray-400 focus:ring-purple focus:border-purple"
            />
          )}
        </div>

        {inputValue ? (
          <Listbox.Options
            static
            className="mt-1 overflow-auto relative block font-poppins bg-white rounded-md text-sm border border-gray-300 focus:outline-none w-full z-40"
          >
            {listItems.length === 0 ? (
              <Listbox.Option className="text-gray-900  select-none relative py-2 px-2 focus:outline-none">
                No se encontraron proyectos
              </Listbox.Option>
            ) : (
              listItems.map((project) => (
                <Listbox.Option
                  key={project.projectId}
                  value={project}
                  className="text-gray-900 cursor-default select-none block relative py-2 px-2 focus:outline-none bg-white hover:bg-purple-lightest"
                >
                  {project.pname}
                </Listbox.Option>
              ))
            )}
          </Listbox.Options>
        ) : null}
      </Listbox>
    </div>
  )
}

export const SingleUserDropdown = ({ handler, admin, inverted = false }) => {
  const options = [
    { value: 'openUserData', label: 'Ver datos de contacto' },
    { value: 'openBankData', label: 'Ver datos de cuenta bancaria' },
    {value: 'openCv', label: 'Ver CV'}
  ]
  return (
    <div>
      <Dropdown
        inverted={inverted}
        handler={handler}
        options={
          admin
            ? [
                ...options,
                { value: 'openPayments', label: 'Ver pagos' },
                { value: 'delete', label: 'Eliminar' },
              ]
            : [
                {
                  value: 'openBankData',
                  label: 'Ver datos de cuenta bancaria',
                },
                { value: 'openUserData', label: 'Ver datos de contacto' },
                {
                  value: 'changeAvailability',
                  label: 'Cambiar disponibilidad',
                },
              ]
        }
        button={
          <div className="px-2 py-1 text-gray-400 cursor-pointer focus:outline-none min-w-max">
            <img src={ThreeDotsVer} alt="Options" />
          </div>
        }
      />
    </div>
  )
}

export const CommunityGridDropdown = ({ handler }) => {
  const options = [
    { value: 'openBankData', label: 'Ver datos de cuenta bancaria' },
    { value: 'openUserData', label: 'Ver datos de contacto' },
    { value: 'openPayments', label: 'Ver pagos' },
    { value: 'delete', label: 'Eliminar' },
  ]

  return (
    <div>
      <Dropdown
        handler={handler}
        options={options}
        button={
          <>
            <img src={ThreeDotsHor} alt="Options" className="hidden md:block" />
            <img src={ThreeDotsVer} alt="Options" className="block md:hidden" />
          </>
        }
      />
    </div>
  )
}

export const UserInProjectDropdown = ({
  user,
  handler,
  hasProjectContract,
  projectId,
}) => {
  let options = [{ value: 'openPayments', label: 'Ver pagos' }]
  if (hasProjectContract)
    options.push({ value: 'openContract', label: 'Ver contrato' })
  if (user.projects.active.includes(projectId))
    options.push({ value: 'finishProjectForUser', label: 'Finalizar proyecto' })
  /* if (user.projects.finished.includes(projectId))
    options.push({ value: 'extendProject', label: 'Extender proyecto' }) */
  options.push({ value: 'deleteUserFromProject', label: 'Eliminar' })

  return (
    <Dropdown
      handler={(option) => handler(user, option)}
      options={options}
      button={
        <div className="px-2 py-1 text-gray-400 cursor-pointer focus:outline-none min-w-min">
          <img src={ThreeDotsHor} alt="Options" className="hidden sm:block" />
          <img src={ThreeDotsVer} alt="Options" className="block sm:hidden" />
        </div>
      }
    />
  )
}

export const CommunityFilterBy = ({ handler, filter }) => {
  const options = [
    { value: 'all', label: 'Todos' },
    { value: 'onProject', label: 'En proyecto' },
    { value: 'free', label: 'Libre' },
  ]
  return (
    <div className="min-w-max">
      <Dropdown
        handler={handler}
        options={options}
        button={
          <>
            <div className="hidden sm:block z-0 rounded-md border appearance-none min-w-max h-8 px-3 py-1 font-poppins focus:outline-none bg-white sm:text-sm border-gray-300 placeholder-gray-400 text-gray-800 focus:ring-0 focus:border-gray-300">
              <span className="text-gray-300 pr-4">Estado:</span>
              {filter === 'all'
                ? 'Todos'
                : filter === 'onProject'
                ? 'En proyecto'
                : 'Libre'}
            </div>
            <div className="inline-flex sm:hidden md:hidden justify-center items-center bg-gray-100 px-2 pt-1">
              <img src={Filter} className="w-auto h-3" alt="Filter" />
            </div>
          </>
        }
      />
    </div>
  )
}

export const CommunityFilterBySkill = ({ handler, skillsSelected }) => (
  <div className="absolute z-10 w-72 h-64 mt-1 flex py-4 flex-wrap bg-white p-4 border border-gray-300 rounded max-w-sm overflow-y-auto right-0">
    {skillsSelected.length ? (
      <div className="flex flex-col">
        <p className="text-sm text-gray-800 font-poppins mb-2">
          Seleccionadas:
        </p>
        <div className="w-full flex flex-wrap">
          {skillsSelected.map((skill) => (
            <span
              key={skill}
              onClick={() => handler(skill)}
              className="px-2 py-1 mr-1 my-1 rounded-full border border-gray-300 text-sm font-poppins flex cursor-pointer hover:border-red-500 hover:text-red-500"
            >
              {skill}
              <img src={Remove} className="h-5 w-auto ml-2" alt="Remove" />
            </span>
          ))}
        </div>
        <div className="border border-gray-300 my-4"></div>
      </div>
    ) : null}
    <div className="w-full flex flex-wrap">
      {oldSkillsList.map((skill) =>
        skillsSelected.includes(skill) ? null : (
          <span
            key={skill}
            onClick={() => handler(skill)}
            className="px-2 py-1 mr-1 my-1  rounded-full min-w-max border border-gray-300 text-sm font-poppins flex cursor-pointer hover:border-purple hover:text-purple"
          >
            {skill}
          </span>
        )
      )}
    </div>
  </div>
)

export const CommunityFilterAvailability = ({ handler, filter }) => {
  const options = [
    { value: 'allAvailability', label: 'Todos' },
    { value: 'Full time', label: 'Full time' },
    { value: 'Part time', label: 'Part time' },
    { value: 'No disponible', label: 'No disponible' },
  ]
  return (
    <div className="min-w-max px-2 md:px-0 sm:max-w-min bg-transparent">
      <Dropdown
        handler={handler}
        options={options}
        button={
          <>
            <div className="inline-flex sm:hidden justify-center items-center bg-gray-100 px-2 pt-1">
              <img src={Filter} className="w-auto h-3" alt="Filter" />
            </div>
            <span className="hidden sm:block rounded-md border appearance-none min-w-max h-8 px-3 py-1 font-poppins focus:outline-none bg-white sm:text-sm border-gray-300 placeholder-gray-400 text-gray-800 focus:ring-0 focus:border-gray-300">
              <span className="text-gray-300 pr-4 h-full">Disponibilidad:</span>
              {filter === 'allAvailability' && 'Todos'}
              {filter === 'Full time' && 'Full time'}
              {filter === 'Part time' && 'Part time'}
              {filter === 'No disponible' && 'No disponible'}
            </span>
          </>
        }
      />
    </div>
  )
}

export const ProjectsFilterBy = ({ handler, filter }) => {
  const options = [
    { value: 'all', label: 'Todos' },
    { value: 'onDevelop', label: 'En desarrolo' },
    { value: 'onDesign', label: 'En diseño' },
    { value: 'finished', label: 'Terminados' },
  ]
  return (
    <div className="min-w-max px-2 md:px-0 sm:max-w-min bg-transparent">
      <Dropdown
        handler={handler}
        options={options}
        button={
          <>
            <div className="inline-flex sm:hidden justify-center items-center bg-gray-100 px-2 pt-1">
              <img src={Filter} className="w-auto h-3" alt="Filter" />
            </div>
            <span className="hidden sm:block rounded-md border appearance-none min-w-max h-8 px-3 py-1 font-poppins focus:outline-none bg-white sm:text-sm border-gray-300 placeholder-gray-400 text-gray-800 focus:ring-0 focus:border-gray-300">
              <span className="text-gray-300 pr-4 h-full">Ordenar por:</span>
              {filter === 'all' && 'Todos'}
              {filter === 'onDevelop' && 'En desarrollo'}
              {filter === 'finished' && 'Terminados'}
              {filter === 'onDesign' && 'En diseño'}
            </span>
          </>
        }
      />
    </div>
  )
}

export const UserProjectsFilterBy = ({ handler, filter }) => {
  const options = [
    { value: 'all', label: 'Todos' },
    { value: 'invitations', label: 'Invitaciones' },
    { value: 'onDevelop', label: 'En desarrollo' },
    { value: 'finished', label: 'Terminados' },
    { value: 'onDesign', label: 'En diseño' },
  ]
  return (
    <div className="min-w-max h-auto">
      <Dropdown
        handler={handler}
        options={options}
        button={
          <div className="inline-flex items-center rounded-md border appearance-none min-w-max w-auto h-8 px-3 font-poppins focus:outline-none bg-white sm:text-sm border-gray-300 placeholder-gray-400 text-gray-800 focus:ring-0 focus:border-gray-300">
            <span>
              {filter === 'all' && 'Todos'}
              {filter === 'onDesign' && 'En diseño'}
              {filter === 'finished' && 'Terminados'}
              {filter === 'onDevelop' && 'En desarrollo'}
              {filter === 'invitations' && 'Invitaciones'}
            </span>
            <span className="ml-2">
              <img src={Down} alt="Filter by:" />
            </span>
          </div>
        }
      />
    </div>
  )
}

export const ProjectsOptionsDropdown = ({ handler }) => (
  <div>
    <Listbox
      as="div"
      className="relative focus:outline-none"
      onChange={handler}
    >
      {({ open }) => (
        <>
          <Listbox.Button className="px-2 py-1 text-gray-400 cursor-pointer focus:outline-none min-w-min">
            <img src={ThreeDotsVer} alt="Options" />
          </Listbox.Button>
          <Transition
            show={open}
            as="div"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="absolute font-poppins bg-white rounded-md text-sm border border-gray-300 focus:outline-none min-w-max"
          >
            <Listbox.Options
              static
              className="relative mt-1 overflow-auto focus:outline-none"
            >
              <Listbox.Option
                value="inviteToProject"
                className="text-gray-900 cursor-default select-none relative py-2 px-2 focus:outline-none hover:bg-purple-lightest"
              >
                <div>Invitar freelancer</div>
              </Listbox.Option>
              <Listbox.Option
                value="changeToFinished"
                className="text-gray-900 cursor-default select-none relative py-2 px-2 focus:outline-none hover:bg-purple-lightest"
              >
                <div>Marcar como finalizado</div>
              </Listbox.Option>
              <Listbox.Option
                value="changeToOnDevelopment"
                className="text-gray-900 cursor-default select-none relative py-2 px-2 focus:outline-none hover:bg-purple-lightest"
              >
                <div>Marcar en desarrollo</div>
              </Listbox.Option>
              <Listbox.Option
                value="changeToOnDesign"
                className="text-gray-900 cursor-default select-none relative py-2 px-2 focus:outline-none hover:bg-purple-lightest"
              >
                <div>Marcar como en Diseño</div>
              </Listbox.Option>
            </Listbox.Options>
          </Transition>
        </>
      )}
    </Listbox>
  </div>
)

export const SingleProjectOptions = ({ handler, inverted = false }) => {
  const options = [
    { value: 'editProject', label: 'Editar' },
    { value: 'changeToFinished', label: 'Marcar como finalizado' },
    { value: 'changeToOnDevelopment', label: 'Marcar en desarrollo' },
    { value: 'deleteProject', label: 'Eliminar' },
  ]
  return (
    <Dropdown
      handler={handler}
      inverted={inverted}
      options={options}
      button={
        <div className="px-2 py-1 text-gray-400 cursor-pointer focus:outline-none min-w-max">
          <img src={ThreeDotsVer} alt="Options" />
        </div>
      }
    />
  )
}

export const UserOnProjectOptions = ({ handler }) => (
  <div>
    <Listbox
      as="div"
      className="relative focus:outline-none"
      onChange={handler}
    >
      {({ open }) => (
        <>
          <Listbox.Button className="px-2 py-1 text-gray-400 cursor-pointer focus:outline-none min-w-min">
            <img src={ThreeDotsHor} alt="Options" />
          </Listbox.Button>
          <Transition
            show={open}
            as="div"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="absolute font-poppins bg-white rounded-md text-sm border border-gray-300 focus:outline-none w-48 -ml-40"
          >
            <Listbox.Options
              static
              className="relative mt-1 overflow-auto focus:outline-none"
            >
              <Listbox.Option
                value="openContracts"
                className="text-gray-900 cursor-default select-none relative py-2 px-2 focus:outline-none hover:bg-purple-lightest"
              >
                <div>Ver contratos</div>
              </Listbox.Option>
              <Listbox.Option
                value="openPayments"
                className="text-gray-900 cursor-default select-none relative py-2 px-2 focus:outline-none hover:bg-purple-lightest"
              >
                <div>Ver pagos</div>
              </Listbox.Option>
              <Listbox.Option
                value="deleteUserInProject"
                className="text-red-500 cursor-default select-none relative py-2 px-2 focus:outline-none hover:bg-purple-lightest"
              >
                <div>Eliminar del proyecto</div>
              </Listbox.Option>
            </Listbox.Options>
          </Transition>
        </>
      )}
    </Listbox>
  </div>
)

export const PaymentsFilterBy = ({ handler, filter }) => {
  const options = [
    { value: 'all', label: 'Todos' },
    { value: 'approved', label: 'Aprobados' },
    { value: 'settled', label: 'Realizados' },
    { value: 'pending', label: 'Pendientes' },
    { value: 'rejected', label: 'Rechazados' },
  ]

  return (
    <div className="min-w-max w-full px-2 md:px-0 sm:max-w-min bg-transparent">
      <Dropdown
        handler={handler}
        options={options}
        button={
          <>
            <div className="inline-flex sm:hidden justify-center items-center bg-gray-100">
              <img src={Filter} className="w-auto h-3" alt="Filter" />
            </div>
            <div className="hidden sm:block rounded-md border appearance-none min-w-max h-8 px-3 py-1 font-poppins focus:outline-none bg-white sm:text-sm border-gray-300 placeholder-gray-400 text-gray-800 focus:ring-0 focus:border-gray-300">
              <span className="text-gray-300 pr-4">Ordenar por:</span>
              {filter === 'all' ? 'Todos' : null}
              {filter === 'approved' ? 'Aprobados' : null}
              {filter === 'settled' ? 'Realizados' : null}
              {filter === 'pending' ? 'Pendientes' : null}
              {filter === 'rejected' ? 'Rechazados' : null}
            </div>
          </>
        }
      />
    </div>
  )
}

export const PaymentsFilterByRange = ({ handler, filter }) => {
  const options = [
    { value: 'all', label: 'Todos' },
    { value: 'past', label: 'Históricos' },
    { value: 'future', label: 'Próximos' },
  ]

  return (
    <div className="min-w-max w-full px-2 md:px-0 sm:max-w-min bg-transparent">
      <Dropdown
        handler={handler}
        options={options}
        button={
          <>
            <div className="inline-flex sm:hidden justify-center items-center bg-gray-100">
              <img src={Filter} className="w-auto h-3" alt="Filter" />
            </div>
            <div className="hidden sm:block rounded-md border appearance-none min-w-max h-8 px-3 py-1 font-poppins focus:outline-none bg-white sm:text-sm border-gray-300 placeholder-gray-400 text-gray-800 focus:ring-0 focus:border-gray-300">
              <span className="text-gray-300 pr-4">Rango:</span>
              {filter === 'all' && 'Todos'}
              {filter === 'past' && 'Históricos'}
              {filter === 'future' && 'Próximos'}
            </div>
          </>
        }
      />
    </div>
  )
}

export const PaymentsFilterByMobile = ({
  handleFilter,
  handlePaymentsRange,
}) => {
  const options = [
    {
      title: 'Estado',
      handler: handleFilter,
      options: [
        { value: 'all', label: 'Todos' },
        { value: 'approved', label: 'Aprobados' },
        { value: 'settled', label: 'Realizados' },
        { value: 'pending', label: 'Pendientes' },
        { value: 'rejected', label: 'Rechazados' },
      ],
    },
    {
      title: 'Rango',
      handler: handlePaymentsRange,
      options: [
        { value: 'all', label: 'Todos' },
        { value: 'past', label: 'Históricos' },
        { value: 'future', label: 'Próximos' },
      ],
    },
  ]

  return (
    <div className="min-w-max w-full px-2 md:px-0 sm:max-w-min bg-transparent">
      <DropdownWithChips
        optionsSets={options}
        button={
          <div className="inline-flex md:hidden justify-center items-center bg-gray-100 px-2 pt-1">
            <img src={Filter} className="w-auto h-3" alt="Filter" />
          </div>
        }
      />
    </div>
  )
}

export const PaymentsDownloadOptions = ({ handler, downloadLoading }) => {
  const options = [
    { value: 'all', label: 'Descargar facturas' },
    { value: 'approved', label: 'Descargar pagos aprobados' },
  ]

  return (
    <div className="min-w-max w-full px-2 md:px-0 sm:max-w-min bg-transparent">
      <Dropdown
        handler={handler}
        options={options}
        button={
          <>
            <div className="relative inline-flex md:hidden justify-center items-center bg-gray-100 bottom-0.5">
              <img src={Download} alt="Download" />
            </div>
            <div className="hidden md:block w-full">
              {downloadLoading ? (
                <LoaderSmall />
              ) : (
                <div
                  type="button"
                  className="py-1 px-2 w-full text-center text-sm 2xl:text-base font-medium rounded text-white bg-purple border border-purple hover:bg-purple-dark focus:bg-purple-dark active:bg-purple-light focus:outline-none font-poppins"
                >
                  Descargar
                </div>
              )}
            </div>
          </>
        }
      />
    </div>
  )
}

export const ChangeAvailability = ({ handler }) => {
  const options = [
    { value: 'Full time', label: 'Full time' },
    { value: 'Part time', label: 'Part time' },
    { value: 'No disponible', label: 'No disponible' },
  ]

  return (
    <div className="min-w-max w-full sm:max-w-min bg-transparent">
      <Dropdown
        handler={handler}
        options={options}
        button={
          <SecondaryButton type="button" children="Disponibilidad" size="sm" />
        }
      />
    </div>
  )
}

export const SelectSkills = ({
  skillsSelected,
  handleSelection,
  skillsList,
}) => {
  const [listItems, setListItems] = useState(skillsList)
  const [inputValue, setInputValue] = useState('')

  const handleChange = (e) => {
    setInputValue(e.target.value)
  }

  useEffect(() => {
    if (skillsList) {
      setListItems(
        skillsList
          // eslint-disable-next-line
          .filter((skill) => {
            if (inputValue) {
              if (skill.toLowerCase().includes(inputValue.toLowerCase())) {
                return skillsSelected.includes(skill) ? false : true
              }
            } else {
              return skillsSelected.includes(skill) ? false : true
            }
          })
          .slice(0, 10)
      )
    }
  }, [inputValue])

  return (
    <div className="w-full h-full relative">
      <Listbox
        as="div"
        className="focus:outline-none"
        onChange={(option) => {
          setInputValue('')
          handleSelection(option)
        }}
      >
        <div className="inline-flex w-full">
          <Input
            type="search"
            label="Selecciona los skills que dominas"
            name="skills"
            value={inputValue}
            handler={handleChange}
          />
        </div>
        {inputValue ? (
          <Listbox.Options
            static
            className="mt-1 overflow-auto relative block font-poppins bg-white rounded-md text-sm border border-gray-300 focus:outline-none w-full z-40"
          >
            {listItems.length === 0 ? (
              <>
                <Listbox.Option className="text-gray-900  select-none relative py-2 px-2 focus:outline-none">
                  No se encontro skill.
                </Listbox.Option>
                <Listbox.Option
                  value={inputValue}
                  className="text-gray-900 select-none relative py-2 px-2 focus:outline-none hover:bg-purple-lightest cursor-pointer"
                >
                  Agregar: <strong>{inputValue}</strong>
                </Listbox.Option>
              </>
            ) : (
              listItems.map((skill) => (
                <Listbox.Option
                  key={skill}
                  value={skill}
                  className="text-gray-900 cursor-default select-none block relative py-2 px-2 focus:outline-none bg-white hover:bg-purple-lightest"
                >
                  {skill}
                </Listbox.Option>
              ))
            )}
          </Listbox.Options>
        ) : null}
      </Listbox>
    </div>
  )
}
