import { useState } from 'react'
import ModalLayout from '../UI/ModalLayout'
import { PrimaryButton, SecondaryButton } from '../UI/Buttons'

const ModalConfirmDelete = ({
  handleModals,
  handleDelete,
  loading,
  multiple,
  children,
}) => {
  const [success, setSuccess] = useState(false)
  return (
    <ModalLayout regular handler={handleModals} modal="delete">
      {!success ? (
        <>
          <h3 className="text-base sm:text-lg md:dm-h3 w-full mb-8 text-center">
            ¿Estás seguro que querés eliminar{' '}
            {multiple ? `estos ${children}s` : `este ${children}`}?
          </h3>
          <div className="w-full inline-flex justify-end">
            <div className="flex flex-col space-y-4 w-full sm:w-min sm:space-y-0 sm:flex-row sm:inline-flex sm:space-x-4">
              <SecondaryButton
                loading={loading}
                disabled={loading}
                type="button"
                size="md"
                children="Eliminar"
                handler={() => {
                  handleDelete()
                  return setSuccess(true)
                }}
              />
              <PrimaryButton
                disabled={loading}
                type="button"
                size="md"
                children="Cancelar"
                handler={handleModals}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="w-full flex flex-col items-center space-y-8">
          {/* <img src={Success} alt='Success!' /> */}
          <h3 className="text-base sm:text-lg sm:dm-h3 w-full mb-8 text-center">
            {multiple
              ? `¡Los ${children}s han sido eliminados`
              : `¡El ${children} ha sido eliminado!`}
          </h3>
        </div>
      )}
    </ModalLayout>
  )
}

export default ModalConfirmDelete
