import { auth } from './firebase'

export const subscription = () =>
  auth.onAuthStateChanged((user) =>
    user ? user : console.log('No user is loged in')
  )

export const signUp = async (email, password) => {
  return await auth
    .createUserWithEmailAndPassword(email, password)
    .then((userCredential) => {
      console.log('🚀 ➡️ User created:', userCredential.user.uid)
      return userCredential.user
    })
    .catch((error) =>
      console.log('Error: ' + error.code + '. Message: ' + error.message)
    )
}

export const signIn = async (email, password, setLoading) => {
  return await auth.signInWithEmailAndPassword(email, password).then((res) => {
    if(setLoading)setLoading(false)
    return res.user
  })
}

export const logOut = async () =>
  await auth
    .signOut()
    .then(() => console.log('🚀 ➡️ Logout successfully.'))
    .catch((error) =>
      console.log('Error: ' + error.code + '. Message: ' + error.message)
    )

export const recoverPassword = async (email) => {
  try {
    await auth.sendPasswordResetEmail(email)
  } catch (error) {
    console.log(error)
  }
}
