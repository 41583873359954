import { db } from './firebase'
import firebase from 'firebase/app'
import {
  uploadPcontract,
  uploadPaymentInvoice,
  uploadUserResume,
  uploadPaymentSettlement,
  uploadCcontract,
  uploadUserProfileImage,
  uploadProjectImage,
} from './storage'
import { oldSkillsList } from '../components/Utils/helpers'

// USERS
export const newUser = async (uid, userData, pdfFile) => {
  try {
    let linkCContract = await uploadCcontract(uid, pdfFile)
    await db
      .collection('users')
      .doc(uid)
      .set({
        id: uid,
        personalData: userData.personalData,
        allBankData: userData.allBankData,
        signature: userData.signature,
        projects: {
          invites: [],
          finished: [],
          active: [],
          extensions: [],
          deleted: [],
        },
        payments: {
          received: [],
          pending: [],
        },
        confidentialityContract: linkCContract,
      })
    console.log('🚀 ➡️ User data uploaded.')
    // Upload notification
    await db.collection('notifications').doc().set({
      type: 'newUser',
      date: new Date(),
      user: uid,
      name: userData.personalData.fullName,
      message: ` se ha unido a la comunidad de Itesa.`,
    })
    console.log('🚀 ➡️ Notification uploaded.')
    return
  } catch (err) {
    if (err) throw new Error('🚀 ➡️ Error uploading user data: ', err)
  } finally {
    return console.log('🚀 ➡️ New user created successfuly.')
  }
}
export const newInvite = async (email) => {
  return await db
    .collection('invites')
    .doc(email)
    .set({})
    .then(() => console.log('🚀 ➡️ User invited successfully.'))
    .catch((err) => console.log('🚀 ➡️ Error inviting user: ', err))
}
export const checkInvite = async (email) => {
  return await db
    .collection('invites')
    .doc(email)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return { user: doc.id, message: 'Invitacion válida' }
      } else {
        return { user: null, message: 'Invitacion inválida' }
      }
    })
    .catch((err) => ({
      user: null,
      message: 'Error fetching invitation:' + err,
    }))
}
export const removeUser = async (id) => {
  let userRef = db.collection('users').doc(id)
  // let userData = (await getUser(id)).user
  //set only deleted true
  if(!userRef) return
  return await userRef.update({ deleted: true })
  
  // for (const key in userData.projects) {
  //   userData.projects[key].reduce(async (acc, project) => {
  //     await acc
  //     let projectRef = db.collection('projects').doc(project)
  //     let projectData = (await projectRef.get()).data()
  //     let userProjectsUpdated = projectData.users.filter(
  //       (user) => user.user !== id
  //     )
  //     return await projectRef.update({ users: userProjectsUpdated })
  //   }, null)
  // }
  // if (userData.payments.pending.length) {
  //   await userData.payments.pending.reduce(async (acc, payment) => {
  //     await acc
  //     await deletePayment(payment.id)
  //   }, null)
  // }
  // return await userRef.delete()
}
export const getUser = async (id) => {
  return await db
    .collection('users')
    .doc(id)
    .get()
    .then((doc) => ({ user: doc.data(), message: 'User founded succesfully' }))
    .catch((err) => ({ user: null, message: 'Error getting user: ' + err }))
}

export const updateAllBankData = async (user) => {
  console.log("Updating all bank data")
  let userRef = db.collection('users').doc(user.id)
  // get user.bankData and create allBankData array with bankData on user
  let bankData = user.bankData
  if(!bankData) return
  console.log('🚀 ➡️ Bank Data: ', bankData,user.id)
  bankData.name = user.bankData.bank
  bankData.default = true
  bankData.type = 'arg'
  let randomId = Math.floor(Math.random() * 1000000)
  bankData.id = randomId
  let allBankData = [bankData]
  console.log('🚀 ➡️ All Bank Data: ', allBankData)
  await userRef.update({ allBankData: allBankData })
  return { ...user, allBankData: allBankData }
}

export const fetchUsers = async () => {
  return await db
    .collection('users')
    .get()
    .then((query) => {
      let users = []
      query.forEach((user) => {
        let id = user.id
        let fullUser = { id: id, ...user.data() }
        users.push(fullUser)
      })
      return users
    })
    .catch((err) => console.log('🚀 ➡️ Error getting users: ', err))
}
export const fetchUserProjects = async (userId) => {
  console.log('🚀 ➡️ Fetching user projects...',userId)
  let userData = (await getUser(userId)).user
  try{
    return await db
      .collection('projects')
      .get()
      .then((query) => {
        console.log('🚀 ➡️ User Data: ', userData)
        let deleted = userData.projects.deleted || []
        let invites = userData.projects.invites || []
        let active = userData.projects.active || []
        let finished = userData.projects.finished || []
        let extensions = userData.projects.extensions || []
        let projects = { active: [], invites: [], finished: [], extensions: [], deleted: []}
        query.forEach((project) => {
          let id = project.id
          // let extension = project.extension || null
          let fullProject = { id: id, ...project.data() }
          if (deleted.includes(id)) projects.deleted.push(fullProject)
          if (invites.includes(id)) projects.invites.push(fullProject)
          if (active.includes(id)) projects.active.push(fullProject)
          if (finished.includes(id)) projects.finished.push(fullProject)
          if (extensions.includes(id)) projects.extensions.push(fullProject)
        })
        console.log('🚀 ➡️ Projects: ', projects)
        return projects
      })
      .catch((err) => ({ error: true ,res: err, message: 'Error getting user projects' }))

  }catch(err){
    console.log('🚀 ➡️ Error fetching user projects: ', err)
  }
}

export const fetchUserPayments = async (userId) => {
  return await db
    .collection('payments')
    .where('user', '==', userId)
    .get()
    .then((query) => {
      let payments = []
      query.forEach((payment) => {
        let id = payment.id
        let fullPayment = { id: id, ...payment.data() }
        payments.push(fullPayment)
      })
      console.log('🚀 ➡️ Payments: ', payments)
      // RETURNS PAYMENTS THAT ARE ONLY ON ACCEPTED PROJECTS
      return payments
    })
    .catch((err) => ({ res: err, message: 'Error getting user payments' }))
}
export const acceptProjectInvitation = async (user, project, blob) => {
  let userRef = db.collection('users').doc(user.id)
  let projectRef = db.collection('projects').doc(project.projectId)
  let url = await uploadPcontract(user.id, project.projectId, blob)
  let userProjectsCopy = { ...user.projects }
  userProjectsCopy.invites = userProjectsCopy.invites.filter(
    (userProject) => userProject !== project.projectId
  )
  userProjectsCopy.active = [...userProjectsCopy.active, project.projectId]
  project.users.forEach((pUser, i) => {
    let copyProjectUsers = [...project.users]
    if (user.id === pUser.user) {
      pUser = { ...pUser, contract: url }
      copyProjectUsers[i] = pUser
      project = { ...project, users: copyProjectUsers }
    }
  })
  try {
    await projectRef.update({ users: project.users })
    await userRef.update({ projects: { ...userProjectsCopy } })
    await db
      .collection('notifications')
      .doc()
      .set({
        type: 'acceptProject',
        date: new Date(),
        user: user.id,
        project: project.id,
        name: user.personalData.fullName,
        message: ` ha firmado el contrato para el proyecto ${project.pname}.`,
      })
    console.log('🚀 ➡️ Notification uploaded.')
    return
  } catch (err) {
    if (err) throw new Error('🚀 ➡️ Error accepting project: ', err)
  } finally {
    console.log('🚀 ➡️ Project accepted successfuly.')
    return
  }
}
export const validateUserPassword = async (user, password) => {
  let userData = (await getUser(user.id)).user
  return userData.personalData.password === password ? true : false
}
export const updateProfile = async (userId, data) => {
  return new Promise(async (resolve, reject) => {
    let skillsListRef = db.collection('skills')
    let skillsListQuery = await skillsListRef.get()
    let skillsList = skillsListQuery.docs.map((skill) => skill.data().name)
    for (let i = 0; i < data.skills.length; i++) {
      const element = data.skills[i]
      if (!skillsList.includes(element)) {
        await skillsListRef.doc(element).set({ name: element })
      }
    }
    let userToUpdateRef = db.collection('users').doc(userId)
    let userData = (await userToUpdateRef.get()).data()
    let cvUrl =
      userData.cv === data.cv
        ? userData.cv
        : await uploadUserResume(data.cv, userId)
    let imageUrl =
      userData.profileImage === data.profileImage
        ? userData.profileImage
        : await uploadUserProfileImage(data.profileImage, userId)
    data = { ...data, cv: cvUrl, profileImage: imageUrl }
    await userToUpdateRef.update({ ...data })
    resolve('User data updated successfully')
  })
}
export const updateSkills = async () => {
  return new Promise(async (resolve, reject) => {
    for (let i = 0; i < oldSkillsList.length; i++) {
      const element = oldSkillsList[i]
      let newSkill = { name: element }
      let newSkillRef = db.collection('skills').doc(element)
      await newSkillRef.set(newSkill)
      if (i === oldSkillsList.length) {
      }
    }
    resolve('Resolved')
  })
}
export const updateBankDetails = async (userId, data) => {
  let userToUpdateRef = db.collection('users').doc(userId)
  const doc = await userToUpdateRef.get()
  let userData = doc.data()
  let allBankData = userData.allBankData
  const updatedBankData = allBankData.map((bankData) => {
    if(bankData.id === data.id){
      return {...bankData, ...data}
    }else{
      return bankData
    }
  })
  await userToUpdateRef.update({ allBankData: updatedBankData })
}

export const updateBankDataDefault = async (userId, bankId) => {
  const userPayments = await fetchUserPayments(userId)
  let pendingPayments = userPayments.filter((payment) => payment.status === 'Pendiente' || payment.status === 'Aprobado')
  console.log('🚀 ➡️ Pending Payments: ', pendingPayments)
  let today = new Date()
  let pendingPaymentsFiltered = pendingPayments.filter((payment) => {
    let paymentDate = payment.date.toDate()
    //sub 2 days
    paymentDate.setDate(paymentDate.getDate() - 3)
    return today >= paymentDate
  })
  if(pendingPaymentsFiltered.length > 0){
    return {error: 'No se puede cambiar el banco por que tiene pagos pendientes'}
  }
  let userToUpdateRef = db.collection('users').doc(userId)
  const doc = await userToUpdateRef.get()
  let userData = doc.data()
  let allBankData = userData.allBankData
  const updatedBankData = allBankData.map((bankData) => {
    if(bankData.id === bankId){
      return {...bankData, default: true}
    }else{
      return {...bankData, default: false}
    }
  })
  await userToUpdateRef.update({ allBankData: updatedBankData })
  return {message: 'Banco predeterminado actualizado'}
}

export const createBankData = async (userId, data) => {
  let userToUpdateRef = db.collection('users').doc(userId)
  const doc = await userToUpdateRef.get()
  let userData = doc.data()
  let allBankData = userData.allBankData
  data.default = false
  let randomId = Math.floor(Math.random() * 1000000)
  data.id = randomId
  allBankData.push(data)
  await userToUpdateRef.update({ allBankData: allBankData })
}

export const deleteBankDetails = async (userId, bankId) => {
  let userToUpdateRef = db.collection('users').doc(userId)
  const doc = await userToUpdateRef.get()
  let userData = doc.data()
  let allBankData = userData.allBankData
  // if only has one bank data, can't delete it
  if(allBankData.length === 1) return {error: 'No se puede eliminar el único banco registrado'}
  const updatedBankData = allBankData.filter((bankData) => bankData.id !== bankId)
  await userToUpdateRef.update({ allBankData: updatedBankData })
}

export const changeAvailability = async (user, option) => {
  let userRef = db.collection('users').doc(user.id)
  await userRef.set({ availability: option }, { merge: true })
}
// PROJECTS
export const lastProjectId = () => {
  return db.collection('projectIds').doc('count').get()
}

export const updateProjectId = () => {
  return db
    .collection('projectIds')
    .doc('count')
    .get()
    .then((res) => {
      let projectId = res.data()
      console.log('Project ID: ', projectId)
      db.collection('projectIds')
        .doc('count')
        .update({ number: projectId.number + 1 })
    })
}

export const newProject = async (data, projectUsers) => {
  let newProjectRef = db.collection('projects').doc(data.projectId)
  let projectImage = await uploadProjectImage(data.pImage, data.projectId)
  await newProjectRef.set({
    pname: data.pname,
    projectId: data.projectId,
    description: data.description,
    startDate: data.startDate,
    pImage: projectImage,
    status: 'Iniciado',
  })
  if (projectUsers.length)
    await inviteUsersToProject(newProjectRef.id, projectUsers)
  return {
    doc: data.projectId,
    message: 'Project created successfully',
  }
}
export const fetchProjects = async () => {
  return await db
    .collection('projects')
    .get()
    .then((query) => {
      let projects = []
      query.forEach((project) => {
        let id = project.id
        let fullProject = { id: id, ...project.data() }
        projects.push(fullProject)
      })
      return projects
    })
    .catch((err) => ({ res: err, message: 'Error getting projects' }))
}

export const updatePayment = async (payment) => {
  let paymentRef = db.collection('payments').doc(payment.id)
  return await paymentRef.update({ currency: payment.currency, amount: payment.amount, date: payment.date, bono: payment.bono === 'true' ? true : false })
}

export const removeProject = async (id) => {
  console.log('🚀 ➡️ Project ID: ', id)
  let projectRef = db.collection('projects').doc(id)
  //set status deleted
  await projectRef.update({ status: 'Eliminado' })
  const users = await projectRef.get().then((doc) => doc.data().users)
  //update user project from active or invites to deleted
  users.reduce(async (acc, user) => {
    await acc
    let userRef = db.collection('users').doc(user.user)
    let userData = (await userRef.get()).data()
    let userProjects = userData.projects
    console.log('🚀 ➡️ User Projects: ', userProjects)
    for (const key in userProjects) {
      if (userProjects[key].includes(id)) {
        userProjects[key] = userProjects[key].filter(
          (projectId) => projectId !== id
        )
      }
    }
    userProjects.deleted.push(id)
    await userRef.update({ projects: {...userProjects} })
  }, null)

  //remove payments from project and user
  const payments = await projectRef.get().then((doc) => doc.data().payments)
  payments.reduce(async (acc, payment) => {
    await acc
    await deletePayment(payment)
  }, null)
  await projectRef.update({ payments: [] })


  // // // let projectData = (await projectRef.get()).data()
  // // // // Removing the project from each user projects data
  // // // projectData?.users?.reduce(async (acc, userProject) => {
  // // //   await acc
  // // //   let userData = (await getUser(userProject.user)).user
  // // //   for (const key in userData.projects) {
  // // //     if (userData.projects[key].includes(id)) {
  // // //       let userProjectsUpdated = userData.projects[key].filter(
  // // //         (project) => project !== id
  // // //       )
  // // //       let str = `projects.${key}`
  // // //       await db
  // // //         .collection('users')
  // // //         .doc(userProject.user)
  // // //         .update({ [str]: [...userProjectsUpdated] })
  // // //     }
  // // //   }
  // // // }, null)
  // // // // Removing payments that are not settled from payments and users
  // // // projectData?.payments?.reduce(async (acc, payment) => {
  // // //   await acc
  // // //   let paymentRef = db.collection('payments').doc(payment)
  // // //   let paymentData = (await paymentRef.get()).data()
  // // //   if (paymentData.status !== 'Realizado') {
  // // //     let userRef = db.collection('users').doc(paymentData.user)
  // // //     let userData = (await userRef.get()).data()
  // // //     for (const key in userData.payments) {
  // // //       if (userData.payments[key].includes(payment)) {
  // // //         let paymentsUpdated = userData.payments[key].filter(
  // // //           (paymentInObject) => payment !== paymentInObject
  // // //         )
  // // //         let str = `payments.${key}`
  // // //         await userRef.update({ [str]: [...paymentsUpdated] })
  // // //       }
  // // //     }
  // // //     await paymentRef.delete()
  // // //   }
  // // // }, null)
  // // // return projectRef.delete()
}
export const inviteUsersToProject = async (projectId, usersToAssign) => {
  await usersToAssign.reduce(async (acc, projectUser) => {
    console.log('🚀 ➡️ Project User: ', projectUser)
    await acc
    let userRef = db.collection('users').doc(projectUser.user)
    let projectRef = db.collection('projects').doc(projectId)
    let paymentsIds = []
    // Creating the payments of the users invited to the project
    await projectUser.payments.reduce(async (acc, payment) => {
      console.log('🚀 ➡️ Payment: ', payment)
      await acc
      let newPaymentRef = db.collection('payments').doc()
      await newPaymentRef.set({
        id: newPaymentRef.id,
        project: projectId,
        user: projectUser.user, // USER ID
        date: payment.date,
        amount: payment.amount,
        currency: projectUser.currency,
        paymentNumber: payment.paymentNumber,
        status: 'Pendiente',
        invoice: null,
      })
      // Adding the new payment created to the user
      await userRef.update({
        'payments.pending': firebase.firestore.FieldValue.arrayUnion(
          newPaymentRef.id
        ),
      })
      // Adding the new payment created to payments of the project
      await projectRef.update({
        payments: firebase.firestore.FieldValue.arrayUnion(newPaymentRef.id),
      })
      paymentsIds.push(newPaymentRef.id)
    }, null)

    let newUserProject = {
      endDate: projectUser.endDate,
      startDate: projectUser.startDate,
      user: projectUser.user,
      payments: paymentsIds,
    }
    // Adding the user to the project
    await projectRef.update({
      users: firebase.firestore.FieldValue.arrayUnion(newUserProject),
    })
    // Adding the project to the user
    await userRef.update({
      'projects.invites': firebase.firestore.FieldValue.arrayUnion(projectId),
    })
  }, null)
  return {
    doc: projectId,
    message: 'Invites have been sent!',
  }
}
export const extendUserProject = async (data) => {
  let projectRef = db.collection('projects').doc(data.project.projectId)
  let userRef = db.collection('users').doc(data.user.id)
  let userData = data.user
  let projectData = data.project

  try {
    await projectRef.update({
      users: firebase.firestore.FieldValue.arrayUnion({
        isExtension: true,
        endDate: data.endDate,
        startDate: data.startDate,
        user: data.user.id,
        payments: data.payments,
      }),
    })
    await userRef.update({
      'projects.invites': firebase.firestore.FieldValue.arrayUnion(
        data.project.projectId
      ),
      'projects.extensions': firebase.firestore.FieldValue.arrayUnion(
        data.project.projectId
      ),
    })
    await data.payments.reduce(async (acc, payment, index) => {
      await acc
      let newPaymentRef = db.collection('payments').doc()
      await newPaymentRef.set({
        id: newPaymentRef.id,
        project: projectData.projectId,
        user: userData.id,
        date: payment.date,
        amount: payment.amount,
        paymentNumber: payment.paymentNumber,
        status: 'Pendiente',
        invoice: null,
      })
      // Adding the new payment created to the user
      await userRef.update({
        'payments.pending': firebase.firestore.FieldValue.arrayUnion(
          newPaymentRef.id
        ),
      })
      // eslint-disable-next-line
      let paymentToExtension = `payments.${index}`
      // Adding the new payment created to payments of the project
      await projectRef.update({
        payments: firebase.firestore.FieldValue.arrayUnion(newPaymentRef.id),
      })
    }, null)
    return {
      doc: data.project.projectId,
      message: 'Invites have been sent!',
    }
  } catch (error) {
    throw new Error(error)
  }
}
export const changeProjectStatus = async (id, status) => {
  let projectRef = db.collection('projects').doc(id)
  let projectData = await getProject(id)
  if (status === 'Finalizado') {
    await projectData?.users?.reduce(async (acc, user) => {
      await acc
      let userRef = db.collection('users').doc(user.user)
      let userData = (await getUser(user.user)).user
      if (userData.projects.active.includes(id)) {
        userData.projects.active = userData.projects.active.filter(
          (projectId) => projectId !== id
        )
        userData.projects.finished.push(id)
        await userRef.update({ projects: userData.projects })
      } else if (userData.projects.invites.includes(id)) {
        // Delete project from user projects invites if project is marked as finished
        userData.projects.invites = userData.projects.invites.filter(
          (projectId) => projectId !== id
        )
        await userRef.update({ projects: userData.projects })
        // Delete user from project if it hasn't accept the invitation yet
        projectData.users = projectData.users.filter(
          (user) => user.user !== userRef.id
        )
        await projectRef.update({ users: projectData.users })
        // Delete payments from user, project and payments db
        userData.payments.pending.reduce(async (acc, payment) => {
          await acc
          await deletePayment(payment)
        })
      }
    }, null)
    await projectRef
      .update({
        status: status,
      })
      .then(() => console.log('Project status changed'))
      .catch((err) => console.log('ERROR CHANGING PROJECT STATUS: ', err))
  }
  if (status === 'En desarrollo') {
    await projectData?.users?.reduce(async (acc, user) => {
      await acc
      let userRef = db.collection('users').doc(user.user)
      let userData = (await getUser(user.user)).user
      if (userData.projects.finished.includes(id)) {
        // Change status of project in user data
        userData.projects.finished = userData.projects.finished.filter(
          (projectId) => projectId !== id
        )
        userData.projects.active.push(id)
        await userRef.update({ projects: userData.projects })
      }
    }, null)
    await projectRef
      .update({
        status: status,
      })
      .then(() => console.log('Project status changed'))
      .catch((err) => console.log('ERROR CHANGING PROJECT STATUS: ', err))
  }
  if (status === 'En diseño') {
    await projectRef.update({
      status: status,
    })
  }
}

export const editProjectDetails = async (formData) => {
  return await db
    .collection('projects')
    .doc(formData.projectId)
    .update({
      pname: formData.pname,
      description: formData.description,
      startDate: formData.startDate,
    })
    .then(() => console.log('Project edited successfully!'))
    .catch((err) => console.log(err))
}

export const deletePaymentFull = async (payment) => {
  console.log('🚀 ➡️ Payment: ', payment)
  let paymentRef = db.collection('payments').doc(payment.id)
  let userRef = db.collection('users').doc(payment.user.id)
  let projectRef = db.collection('projects').doc(payment.project.projectId)
  await paymentRef.delete()
  await userRef.update({
    'payments.pending': firebase.firestore.FieldValue.arrayRemove(payment.id),
  })
  //flag
  await projectRef.update({
    payments: firebase.firestore.FieldValue.arrayRemove(payment.id),
  })
}

export const createNewPayment = async (formData, projectId, userId) => {
  let userRef = db.collection('users').doc(userId)
  let projectRef = db.collection('projects').doc(projectId)
  let newPaymentRef = db.collection('payments').doc()
  let newPayment = {
    id: newPaymentRef.id,
    project: projectId,
    user: userId,
    date: formData.date,
    amount: formData.amount,
    currency: formData.currency,
    status: 'Pendiente',
    invoice: null,
    bono: formData.bono === 'true' ? true : false,
  }
  await newPaymentRef.set(newPayment)
  await userRef.update({
    'payments.pending': firebase.firestore.FieldValue.arrayUnion(newPaymentRef.id),
  })
  //flag
  await projectRef.update({
    payments: firebase.firestore.FieldValue.arrayUnion(newPaymentRef.id),
  })
}

export const getProject = async (id) => {
  return await db
    .collection('projects')
    .doc(id)
    .get()
    .then((res) => res.data())
    .catch((err) => ({
      project: null,
      message: 'Error fetching project: ' + err,
    }))
}
export const getUserPaymentsByProject = async (projectId, userId) => {
  return await db
  .collection('payments')
  .where('user', '==', userId)
  .where('project', '==', projectId)
  .get()
  .then((query) => {
    let payments = []
    query.forEach((payment) => {
      let id = payment.id
      let fullPayment = { id: id, ...payment.data() }
      payments.push(fullPayment)
    })
    return payments
  })
  .catch((err) => ({ res: err, message: 'Error getting user payments' }))
}

export const deleteUserFromProject = async (userId, projectId) => {
  console.log('🚀 ➡️ userId: ', userId)
  console.log('🚀 ➡️ projectId: ', projectId)
  let userRef = db.collection('users').doc(userId)
  let userData = (await getUser(userId)).user
  let userPayments = userData.payments.pending
  let projectRef = db.collection('projects').doc(projectId)
  let projectData = await getProject(projectId)
  let paymentsRef = db.collection('payments')

  console.log('🚀 ➡️ userData: ', userData)
  console.log('🚀 ➡️ projectData: ', projectData)

  // Removing user from project
  projectData.users = projectData?.users?.filter(
    (userProject) => userProject.user !== userId
  )
  console.log('🚀 ➡️ projectData.users: ', projectData.users)
  //remove userPayments on project and then on user
  let deletedPayments = []
  if(projectData.payments.length !== 0 && userPayments.length !== 0){
    const filteredProjects = projectData.payments.filter((payment) => {
      let stays = true
      userPayments.map((userPayment) => {
        if (payment === userPayment) {
          stays = false
          deletedPayments.push(payment)
        }
      })
      return stays
    })
    projectData.payments = filteredProjects
    console.log('🚀 ➡️ deletedPayments: ', deletedPayments)
    console.log('🚀 ➡️ projectData.payments: ', projectData.payments)
    userData.payments.pending = userData.payments.pending.filter(
      (userPayment) => !deletedPayments.includes(userPayment)
    )
    console.log('🚀 ➡️ userData.payments.pending: ', userData.payments.pending)
  }
  await projectRef.update({ users: projectData.users, payments: projectData.payments })
  await userRef.update({ 'payments.pending': userData.payments.pending })

  // Removing project from user
  for (const key in userData?.projects) {
    if (userData.projects[key].includes(projectId)) {
      userData.projects[key] = userData.projects[key].filter(
        (projectUser) => projectUser !== projectId
      )
      await userRef.update({ projects: userData.projects })
    }
  }
  
  for (const payment of deletedPayments) {
    await paymentsRef.doc(payment).delete()
  }
}

export const setCuitToPersonalData = async ()=>{
  let users = await fetchUsers()
  let usersWithoutCuit = users.filter(user => !user.personalData.cuit)
  usersWithoutCuit.map(async user => {
    let userRef = db.collection('users').doc(user.id)
    let bankData = user.bankData
    if(!bankData.cuit) {
      console.log('🚀 ➡️ Bank Data: ', bankData,user.id, bankData)
    }
    console.log('🚀 ➡️ Bank Data: ', bankData,user.id, bankData.cuit)
    await userRef.update({ 'personalData.cuit': bankData.cuit })
  })
}

export const finishProjectForUser = async (user, project) => {
  let userRef = db.collection('users').doc(user.id)
  let activeProjects = user.projects.active.filter(
    (projectId) => projectId !== project.projectId
  )
  let finishedProjects = [...user.projects.finished, project.projectId]
  let updatedUserProjects = {
    ...user.projects,
    finished: finishedProjects,
    active: activeProjects,
  }
  let updateUser = new Promise((resolve, reject) => {
    resolve(userRef.update({ projects: updatedUserProjects }))
  })
  return await updateUser
}
// PAYMENTS
export const fetchPayments = async () => {
  return await db
    .collection('payments')
    .get()
    .then((query) => {
      let payments = []
      query.forEach((payment) => {
        let id = payment.id
        let fullPayment = { id: id, ...payment.data() }
        payments.push(fullPayment)
      })
      return payments
    })
    .catch((err) => ({ res: err, message: 'Error getting payments' }))
}
export const getPayment = async (id) => {
  return await db
    .collection('payments')
    .doc(id)
    .get()
    .then((res) => res.data())
    .catch((err) => ({ res: err, message: 'Error getting projects' }))
}
export const deletePayment = async (id) => {
  let paymentRef = db.collection('payments').doc(id)
  let paymentData = await getPayment(id)
  // Delete payment only if status = 'Pendiente'
  if (
    paymentData?.status === 'Pendiente' ||
    paymentData?.status === 'Aprobado' ||
    paymentData?.status === 'Rechazado'
  ) {
    // Delete payment from user
    let userRef = db.collection('users').doc(paymentData.user)
    let userData = (await getUser(paymentData.user)).user
    if (userData) {
      userData.payments.pending = userData.payments.pending.filter(
        (userPayment) => userPayment !== id
      )
      await userRef.update({ 'payments.pending': userData.payments.pending })
    }
    // Delete payment from project
    let projectRef = db.collection('projects').doc(paymentData.project)
    let projectData = await getProject(paymentData.project)
    projectData.payments = projectData.payments.filter(
      (projectPayment) => projectPayment !== id
    )
    await projectRef.update({ payments: projectData.payments })
    // Delete payment from payments db
    await paymentRef
      .delete()
      .then(() => console.log('PAYMENT DELETED: ', id))
      .catch((err) => {
        console.log('ERROR DELETING PAYMENT: ', err)
        return err
      })
  }
}
export const changePaymentStatus = async (payment, action) => {
  let paymentRef = db.collection('payments').doc(payment.id)
  // Delete payment only if status = 'Pendiente'
  return action === 'Rechazado'
    ? paymentRef.update({ status: action, invoice: null })
    : paymentRef.update({ status: action })
}
export const uploadPayment = async (file, payment) => {
  try {
    // UPLOAD FILE
    let res = await uploadPaymentInvoice(file, payment.id)
    // UPDATE PAYMENT WITH INVOICE STORAGE LINK
    let userData = (await getUser(payment.user)).user
    let projectData = await getProject(payment.project)
    await db
      .collection('payments')
      .doc(payment.id)
      .update({ invoice: res, status: 'Pendiente' })
    await db
      .collection('notifications')
      .doc()
      .set({
        type: 'uploadPayment',
        date: new Date(),
        user: payment.user,
        payment: payment.id,
        name: userData.personalData.fullName,
        message: ` ha cargado la factura del proyecto ${projectData.pname}.`,
      })
    console.log('🚀 ➡️ Notification uploaded.')
    return
  } catch (err) {
    if (err) throw new Error('🚀 ➡️ Error uploading payment: ', err)
  } finally {
    return console.log('🚀 ➡️ Payment uploaded successfuly.')
  }
}
export const uploadSettlementPayment = async (data) => {
  const userRef = db.collection('users').doc(data.user.id)
  const paymentRef = db.collection('payments').doc(data.payment.id)
  // UPLOAD FILE
  let res = await uploadPaymentSettlement(
    data.paymentSettlement,
    data.payment.id
  )
  // UPDATE USER PAYMENTS
  let userData = (await getUser(data.user.id)).user
  let userPaymentsPending = userData.payments.pending.filter(
    (userPayment) => userPayment !== data.payment.id
  )
  await userRef.update({
    'payments.pending': userPaymentsPending,
    'payments.received': firebase.firestore.FieldValue.arrayUnion(
      data.payment.id
    ),
  })
  return await paymentRef.update({
    status: 'Realizado',
    paymentSettlement: res,
  })
}

//need to update all users data, add a field called newBankData that is an array with an object that contains bankData
export const updateAllUsersBankData = async () => {
  let users = await fetchUsers()
  users.reduce(async (acc, user) => {
    await acc
    return await updateAllBankData(user)
  }, null)
}

export const updatePersonalAddress = async (user) => {
  let hastAddress = user.personalData.address ? true : false
  if(hastAddress) return
  console.log('🚀 ➡️ No tiene address')
  let address = user.bankData.address
  let userRef = db.collection('users').doc(user.id)
  return await userRef.update({ 'personalData.address': address })
}

export const updateAllUsersAddress = async () => {
  let users = await fetchUsers()
  users.reduce(async (acc, user) => {
    await acc
    return await updatePersonalAddress(user)
  }, null)
}

//need to update all payments data, if payment.amount is lower than 5000 add field currency 'ARS' else 'USD'
export const updateAllPaymentsCurrency = async () => {
  let payments = await fetchPayments()
  payments.reduce(async (acc, payment) => {
    await acc
    let amount = payment.ammount
    let currency = amount < 5000 ? 'USD' : 'ARS'
    return await db.collection('payments').doc(payment.id).update({ currency: currency , amount: amount })
  }, null)
}