import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
// RECOIL
import { useRecoilValue } from 'recoil'
import { usersState } from '../../../atoms'
// UI
import { PrimaryButton } from './Buttons'
import { LoaderComponent } from './Loaders'
import FullRigthArrow from '../Images/FullRigthArrow.png'
import Calendar from '../Images/Calendar.png'
import UserDefault from '../Images/UserDefault.png'
// HELPERS
import { DateTime, Interval } from 'luxon'
import { parseDateFullMonth  } from '../helpers'
import { getUserPaymentsByProject } from '../../../services/db'

export const ProjectCard = ({ project, user }) => {
  const [projectDetails, setProjectDetails] = useState(null)
  const [totalProjectAmount, setTotalProjectAmount] = useState()
  const [payments, setPayments] = useState([])
  const users = useRecoilValue(usersState)

  const getUserProjectPayments = async (project, user) => {
    const payments = await getUserPaymentsByProject(project.projectId, user.id)
    setPayments(payments)
  }

  const getAmount = (payments) => {
    let tpa = 0
    payments.forEach((payment) => (tpa += Number(payment.amount)))
    let curr = payments[0].currency
    return curr + ' ' + tpa
  }

  useEffect(() => {
    getUserProjectPayments(project, user)
  },[])

  useEffect(() => {
    if(payments.length > 0) {
      setTotalProjectAmount(getAmount(payments))
    }
  },[payments])

  return (
    <>
      {project && (
        <div className="p-3 sm:p-4 md:p-6 bg-white border border-gray-300 rounded-md">
          <div className="w-14 h-14 sm:w-20 sm:h-20">
            <img
              src={project.pImage}
              alt="ProjectImage"
              className="rounded-full h-full w-full object-center object-cover border border-gray-300"
            />
          </div>
          <p className="font-poppins text-base sm:text-lg md:text-xl text-gray-800 py-2 sm:py-4">
            <Link to={`/admin/proyectos/${project.projectId}`}>
              {project.pname}
            </Link>
          </p>
          <div className="flex flex-col md:px-2">
            <div className="flex flex-col items-start py-0 sm:py-2 font-poppins">
              <span className="text-base text-gray-500">Monto</span>
              <span className="text-base text-gray-800">
                {(totalProjectAmount)}
              </span>
            </div>
            <div className="flex flex-wrap justify-start py-2 text-sm sm:text-base font-poppins items-center">
              <div className="inline-flex items-center space-x-2 w-auto text-xs mr-2">
                <img src={Calendar} alt="Date" />
                <span className="text-gray-500">
                  {projectDetails &&
                    parseDateFullMonth(projectDetails.startDate)}
                </span>
              </div>
              <div className="inline-flex items-center space-x-2 w-auto text-xs">
                <img src={FullRigthArrow} alt="TO" />
                <span className="text-gray-500">
                  {projectDetails && parseDateFullMonth(projectDetails.endDate)}
                </span>
              </div>
            </div>
            <div className="flex flex-wrap-reverse sm:flex-wrap justify-between items-center space-y-2 pt-2 sm:pt-4">
              <div className="inline-flex h-auto w-auto items-center pt-2 sm:pt-0">
                {project.users
                  .map((user, i) => {
                    let userData = users.find(
                      (detailUser) => detailUser.id === user.user
                    )
                    return (
                      <div className="h-8 w-8" key={i}>
                        <img
                          className="rounded-full h-full w-full object-center object-cover border border-gray-300"
                          alt="UImage"
                          src={
                            userData.profileImage
                              ? userData.profileImage
                              : UserDefault
                          }
                        />
                      </div>
                    )
                  })
                  .slice(0, 3)}
                {project.users.length > 3 ? (
                  <span className="mx-2 text-xs sm:text-sm text-gray-500 font-poppins min-w-max ">
                    + {project.users.length - 3}
                  </span>
                ) : null}
              </div>
              <span
                className={
                  'h-full border px-2 sm:px-4 rounded-full text-sm sm:text-base w-auto min-w-max ' +
                  (project.status !== 'Finalizado'
                    ? 'text-gray-500 border-gray-300'
                    : 'text-green-500 border-green-500')
                }
              >
                {project.status}
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export const ProfileProjectCard = ({ project, user }) => {
  const history = useHistory()
  const [projectDetails, setProjectDetails] = useState(null)
  const [totalProjectAmount, setTotalProjectAmount] = useState()
  const [payments, setPayments] = useState([])

  const getUserProjectPayments = async (project, user) => {
    const payments = await getUserPaymentsByProject(project.projectId, user.id)
    setPayments(payments)
  }

  const getAmount = (payments) => {
    let tpa = 0
    payments.forEach((payment) => (tpa += Number(payment.amount)))
    let curr = payments[0].currency
    return curr + ' ' + tpa
  }

  useEffect(() => {
    getUserProjectPayments(project, user)
    let pDetails = project.users.find(
      (projectUser) => projectUser.user === user.id
    )
    console.log('pDetails', pDetails)
    setProjectDetails(pDetails)
  },[])

  useEffect(() => {
    if(payments.length > 0) {
      setTotalProjectAmount(getAmount(payments))
    }
  },[payments])

  const projectStatus = () => {
    if (user.projects.invites.includes(project.id)) {
      return (
        <div className="min-w-max self-end">
          <PrimaryButton
            type="button"
            size={window.innerWidth > 640 ? 'lg' : 'sm'}
            children="Firmar contrato"
            handler={(e) => history.push(`/user/invite/${project.id}`)}
          />
        </div>
      )
    } else if (user.projects.active.includes(project.id)) {
      let endDate = DateTime.fromSeconds(projectDetails.endDate.seconds)
      let days = Interval.fromDateTimes(DateTime.now(), endDate)
      let remainingDays = Math.round(days.length('days'))
      return (
        <span
          className={
            (project.status !== 'Finalizado'
              ? 'text-gray-500 border-gray-300'
              : 'text-green-500 border-green-300') +
            ' border px-2 sm:px-4 py-1 rounded-full text-sm sm:text-base text-center sm:min-w-max'
          }
        >
          {remainingDays > 0
            ? remainingDays + ' días restantes'
            : project.status}
        </span>
      )
    } else if (user.projects.finished.includes(project.id)) {
      return (
        <span className=" border px-2 sm:px-4 py-1 rounded-full text-sm sm:text-base text-center min-w-max text-green-500 border-green-300">
          Finalizado
        </span>
      )
    }
  }

  if (project && projectDetails) {
    return (
      <>
        {project && projectDetails && (
          <div className="p-4 sm:p-6 bg-white border border-gray-300 rounded-md flex flex-col justify-around">
            <div className="inline-flex justify-between w-full">
              <img
                src={project.pImage}
                alt="ProjectImage"
                className="w-14 h-14 sm:w-20 sm:h-20 border border-gray-300 rounded-full"
              />
            </div>
            <p className="font-poppins text-base sm:text-lg md:text-xl text-gray-800 py-4">
              {project.pname}
            </p>
            <div className="flex flex-col sm:px-2">
              <div className="flex flex-row text-sm sm:text-base sm:flex-col items-start py-2 space-x-2 sm:space-x-0 font-poppins">
                <span className="text-gray-500">Monto</span>
                <span className="text-gray-800">
                  {(totalProjectAmount)}
                </span>
              </div>
              <div className="inline-flex flex-wrap justify-start sm:py-2 space-x-0 sm:space-x-2 space-y-2 sm:space-y-0 font-poppins text-sm sm:text-base items-center w-full">
                <div className="inline-flex items-center space-x-2">
                  <img src={Calendar} alt="Date" className="max-h-6" />
                  <span className="text-gray-500">
                    {projectDetails &&
                      parseDateFullMonth(projectDetails.startDate)}
                  </span>
                </div>
                <div className="inline-flex items-center space-x-2">
                  <img src={FullRigthArrow} alt="TO" className="max-h-6" />
                  <span className="text-gray-500">
                    {projectDetails &&
                      parseDateFullMonth(projectDetails.endDate)}
                  </span>
                </div>
              </div>
              <div className="flex flex-col sm:flex-row flex-wrap justify-between pt-4 sm:pt-6 sm:items-center h-full space-y-2">
                <div className="inline-flex min-w-max pl-4 pr-2">
                  {project.users
                    .map((user, i) => {
                      console.log("ghjg",user)
                      return (
                        <div className="h-8 w-8" key={i}>
                          <img
                            className="rounded-full h-full w-full object-center object-cover border border-gray-300 -ml-3"
                            src={user.profileImage ? user.profileImage : UserDefault}
                            alt="UImage"
                          />
                        </div>
                      )
                    })
                    .slice(0, 3)}
                  {project.users.length > 3 ? (
                    <span className="mx-2 text-sm text-gray-500 font-poppins">
                      + {project.users.length - 3}
                    </span>
                  ) : null}
                </div>
                {projectStatus()}
              </div>
            </div>
          </div>
        )}
      </>
    )
  } else {
    return <LoaderComponent />
  }
}
