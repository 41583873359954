import React, { useState, useEffect } from 'react'
// RECOIL
import { useRecoilState, useSetRecoilState } from 'recoil'
import { projectsLoadingState, projectsState } from '../../../atoms/index'
// COMPONENTS
import ProjectsLayout from './ProjectsLayout'
import ProjectsTable from './ProjectsTable'
// DB
import { removeProject, changeProjectStatus } from '../../../services/db'
import { db } from '../../../services/firebase'

const ProjectsContainer = () => {
  const [modal, setModal] = useState('')
  const [search, setSearch] = useState('')
  const [order, setOrder] = useState('des')
  const [filter, setFilter] = useState('onDevelop')
  const [projectsSelected, setProjectsSelected] = useState([])
  const [projects, setProjects] = useRecoilState(projectsState)
  const setProjectsLoading = useSetRecoilState(projectsLoadingState)
  const [slice, setSlice] = useState({
    start: 0,
    end: projects.length > 10 ? 10 : projects.length,
  })

  console.log('🚀 ➡️ projects', projects)

  const handleModals = async (option) => {
    switch (option) {
      case 'changeToFinished':
        await changeProjectStatus(
          curatedProjects[projectsSelected[0]].projectId,
          'Finalizado'
        )
        break
      case 'changeToOnDevelopment':
        await changeProjectStatus(
          curatedProjects[projectsSelected[0]].projectId,
          'En desarrollo'
        )
        break
      case 'changeToOnDesign':
        await changeProjectStatus(
          curatedProjects[projectsSelected[0]].projectId,
          'En diseño'
        )
        break
      default:
        break
    }
    option ? setModal(option) : setModal('')
  }

  const handleSearch = (e) => setSearch(e.target.value.toLowerCase())

  const sortFunction = (array, key, order) => {
    let copy = [...array]
    order === 'des'
      ? copy.sort((a, b) => (a[key] > b[key] ? 1 : -1))
      : copy.sort((a, b) => (a[key] > b[key] ? -1 : 1))
    return copy
  }

  const handleSort = (e, sort) => {
    setProjects(sortFunction(projects, sort, order))
    order === 'des' ? setOrder('asc') : setOrder('des')
  }

  const selectProjects = (e, i) => {
    if (i === 'all') {
      if (curatedProjects.length === projectsSelected.length) {
        setProjectsSelected([])
      } else {
        curatedProjects.forEach((p, index) =>
          projectsSelected.includes(index) ? null : projectsSelected.push(index)
        )
        setProjectsSelected([...projectsSelected])
      }
    } else {
      if (projectsSelected.includes(i)) {
        projectsSelected.splice(projectsSelected.indexOf(i), 1)
        setProjectsSelected([...projectsSelected])
      } else {
        setProjectsSelected([...projectsSelected, i])
      }
    }
  }

  const deleteProjects = async () => {
    setProjectsLoading(true)
    for (let index of projectsSelected) {
      try {
        await removeProject(curatedProjects[index].projectId)
      } catch (err) {
        console.log('Error: ', err)
      }
    }
    setProjectsSelected([])
    setProjectsLoading(false)
  }

  const sliceProjects = (action) => {
    if (action === 'next') {
      if (slice.start + 10 > projects.length) {
        setSlice({
          start: slice.start,
          end: projects.length,
        })
      } else if (slice.end + 10 > projects.length) {
        setSlice({
          start: projects.length > 10 ? slice.end : 0,
          end: projects.length,
        })
      } else {
        setSlice({ start: slice.start + 10, end: slice.end + 10 })
      }
    }
    if (action === 'prev') {
      if (slice.start - 10 < 0) {
        setSlice({ start: 0, end: projects.length > 10 ? 10 : projects.length })
      } else {
        setSlice({ start: slice.start - 10, end: slice.start })
      }
    }
  }

  const handleFilter = (filter) => {
    console.log('🚀 ➡️ filter', filter)
    filter ? setFilter(filter) : setFilter('all')
    setProjectsSelected([])
  }

  const buildProjects = (projectsArray) => {
    console.log('🚀 ➡️ projectsArray', projectsArray)
    return projectsArray
      .filter((project) => {
        let all = project.pname.toLowerCase().includes(search.toLowerCase())
        if (filter === 'onDevelop') {
          return project.status !== 'Finalizado' && project.status !== 'Eliminado' && all ? true : false
        } else if (filter === 'finished') {
          return project.status === 'Finalizado' && all ? true : false
        } else if (filter === 'onDesign') {
          return project.status === 'En diseño' && all ? true : false
        }else {
          return all
        }
      })
      .slice(slice.start, slice.end)
  }

  let curatedProjects = buildProjects(projects)

  // useEffect(() => {
  //   const getProjects = db.collection('projects').onSnapshot(async (query) => {
  //     setProjectsLoading(true)
  //     let updatedProjects = []
  //     query.forEach((doc) => updatedProjects.push(doc.data()))
  //     setProjects(updatedProjects)
  //     setProjectsLoading(false)
  //   })
  //   return () => getProjects()
  //   // eslint-disable-next-line
  // }, [])

  useEffect(() => {
    search.length || filter !== 'all'
      ? setSlice({
          start: 0,
          end: curatedProjects.length > 10 ? 10 : curatedProjects.length,
        })
      : setSlice({
          start: 0,
          end: projects.length > 10 ? 10 : projects.length,
        })
    // eslint-disable-next-line
  }, [filter, search])

  return (
    <ProjectsLayout
      modal={modal}
      filter={filter}
      handleModals={handleModals}
      handleFilter={handleFilter}
      handleSearch={handleSearch}
      deleteProjects={deleteProjects}
      projectsSelected={projectsSelected}
      project={curatedProjects[projectsSelected[0]]}
    >
      <ProjectsTable
        slice={slice}
        handleSort={handleSort}
        projects={curatedProjects}
        handleModals={handleModals}
        sliceProjects={sliceProjects}
        selectProjects={selectProjects}
        projectsLength={
          search.length || filter !== 'all'
            ? curatedProjects.length
            : projects.length
        }
        projectsSelected={projectsSelected}
      />
    </ProjectsLayout>
  )
}

export default ProjectsContainer
