import React, { useState, useEffect, useCallback } from 'react'
import { DateTime } from 'luxon'
// RECOIL
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  futurePaymentsState,
  pastPaymentsState,
  paymentsLoadingState,
  paymentsState,
  projectsState,
  usersState,
} from '../../../atoms/index'
// DB
import {
  changePaymentStatus,
  //  deletePayment
} from '../../../services/db'

import PaymentsTable from './PaymentsTable.jsx'
import { PaymentDetailMobile } from '../../Utils/UI/Items.js'
import ModalViewPayments from '../../Utils/Modals/ModalViewPayments/ModalViewPayments'
import ModalBankData from '../../Utils/Modals/ModalBankData'
import { donwloadZipFile, JSONDownloadPayments } from '../../Utils/helpers'

const Payments = ({fetchData, handleUploadPayment}) => {
  // STATE
  const [slice, setSlice] = useState({})
  const [search, setSearch] = useState('')
  const [order, setOrder] = useState('des')
  const [filter, setFilter] = useState('all')
  const [modal, setModal] = useState('')
  const [bankData, setBankData] = useState(null)
  const [paymentDetail, setPaymentDetail] = useState({
    open: false,
    payment: null,
  })
  const [extendedView, setExtendedView] = useState(false)
  const [sliceRange, setSliceRange] = useState(
    window.innerWidth > 1048 ? 10 : 5
  )
  const [invoiceZipPayments, setInvoiceZipPayments] = useState([])
  const [approvedPayments, setApprovedPayments] = useState([])
  const [curatedPayments, setCuratedPayments] = useState([])
  const [paymentsSelected, setPaymentsSelected] = useState([])
  const [downloadLoading, setDownloadLoading] = useState(false)
  // RECOIL
  const users = useRecoilValue(usersState)
  const payments = useRecoilValue(paymentsState)
  const pastPayments = useRecoilValue(pastPaymentsState)
  const futurePayments = useRecoilValue(futurePaymentsState)
  const projects = useRecoilValue(projectsState)
  const [paymentsRange, setPaymentsRange] = useState(payments || [])
  const [paymentsRangeSelected, setPaymentsRangeSelected] = useState('all')
  const [paymentsLoading, setPaymentsLoading] =
    useRecoilState(paymentsLoadingState)

  // Handles the selected filter
  const handleFilter = (filter) => {
    filter ? setFilter(filter) : setFilter('all')
  }
  console.log(payments)
  // Filter the payments by past or future payments
  const handlePaymentsRange = (option) => {
    setPaymentsRangeSelected(option)
    switch (option) {
      case 'all':
        setPaymentsRange(payments)
        break
      case 'past':
        setPaymentsRange(pastPayments)
        break
      case 'future':
        setPaymentsRange(futurePayments)
        break
      default:
        break
    }
  }

  // Build the payments if there is search value and aggregates user and project data to the payment
  const buildPayments = useCallback(() => {
    const validatePaymentByFilter = (payment) => {
      if (filter === 'pending') {
        return payment.status === 'Pendiente'
      } else if (filter === 'approved') {
        return payment.status === 'Aprobado'
      } else if (filter === 'settled') {
        return payment.status === 'Realizado'
      } else if (filter === 'rejected') {
        return payment.status === 'Rechazado'
      } else {
        return true
      }
    }
    console.log('🚀 ➡️ payments: ', payments)
    console.log('projects: ', projects)
    let updatedPayments = payments
      .filter((payment) => validatePaymentByFilter(payment))
      .reduce((filteredPayments, payment) => {
        payment = {
          ...payment,
          project:
            projects?.find(
              (project) => payment.project === project.projectId
            ) || null,
          // user: users?.find((user) => payment.user === user.id) || null,
        }
        if (!payment.user) console.log('🚀 ➡️ payment', payment)

        if (search) {
          if (
            payment.user.personalData.fullName
              .toLowerCase()
              .includes(search.toLowerCase())
          )
            filteredPayments.push(payment)
        } else {
          filteredPayments.push(payment)
        }
        return filteredPayments
      }, [])
    setCuratedPayments(sortFunction(updatedPayments, 'date', 'des'))
  }, [payments, projects, search, users, filter])

  useEffect(() => {
    setPaymentsLoading(true)
    buildPayments()
    setPaymentsLoading(false)
    // eslint-disable-next-line
  }, [filter, search, paymentsRange, projects])

  useEffect(() => {
    setPaymentsLoading(true)
    setSlice({
      start: 0,
      end:
        curatedPayments.length > sliceRange
          ? sliceRange
          : curatedPayments.length,
    })
    setPaymentsLoading(false)
    // eslint-disable-next-line
  }, [extendedView, curatedPayments])

  useEffect(() => {
    console.log('🚀 ➡️ payments', payments)
    buildPayments()
    if (users?.length) {
      console.log('🚀 ➡️ users', users)
      let updateApprovedPayments = payments.reduce((acc, payment) => {
        let userData = users.find((user) => user.id === payment.user)
        if (userData && payment.user === userData.id) {
          payment = { ...payment, user: userData }
          if (payment.status === 'Aprobado') acc.push(payment)
        }
        return acc
      }, [])
      setApprovedPayments(updateApprovedPayments)
      let invoiceZipPayments = payments.reduce(
        (paymentUrlsInvoices, payment) => {
          let now = new Date()
          let paymentDate = DateTime.fromSeconds(payment.date.seconds)
          let isPaymentFromThisMonth = paymentDate.month === now.getMonth() + 1
          if (isPaymentFromThisMonth && payment.status === 'Aprobado')
            paymentUrlsInvoices.push({ id: payment.id, url: payment.invoice })
          return paymentUrlsInvoices
        },
        []
      )
      if (invoiceZipPayments.length) setInvoiceZipPayments(invoiceZipPayments)
    }
    // eslint-disable-next-line
  }, [payments, users])

  // Handles the extended view of payments in mobile version
  const handleExtendedView = () => {
    if (extendedView) {
      setExtendedView(false)
      setSliceRange(5)
    } else {
      setExtendedView(true)
      setSliceRange(10)
    }
  }
  // Mobile dropdown to see payments options
  const SinglePaymentDropdown = (option, payment) => {
    if (option === 'invoice') {
      window.open(payment.invoice, '_blank')
    }
    if (option === 'paymentSettlement') {
      window.open(payment.paymentSettlement, '_blank')
    }
    if (option === 'details') {
      setPaymentDetail({
        open: true,
        payment: payment,
      })
    }
    if (option === 'delete') {
      console.log('Delete payment - ', payment)
    }
  }

  // Aux function to sort the payments table
  const sortFunction = (array, key, order) => {
    let copy = [...array]
    if (key === 'amount') {
      order === 'des'
        ? copy.sort((a, b) => a[key] - b[key])
        : copy.sort((a, b) => b[key] - a[key])
    } else if (key === 'user') {
      order === 'des'
        ? copy.sort((a, b) =>
            a[key].personalData.fullName > b[key].personalData.fullName ? 1 : -1
          )
        : copy.sort((a, b) =>
            a[key].personalData.fullName > b[key].personalData.fullName ? -1 : 1
          )
    } else if (key === 'project') {
      order === 'des'
        ? copy.sort((a, b) => (a[key]?.pname > b[key]?.pname ? 1 : -1))
        : copy.sort((a, b) => (a[key]?.pname > b[key]?.pname ? -1 : 1))
    } else {
      order !== 'des'
        ? copy.sort((a, b) => (a[key] > b[key] ? 1 : -1))
        : copy.sort((a, b) => (a[key] > b[key] ? -1 : 1))
    }
    return copy
  }
  // Handles the sort actions for payments table
  const handleSort = (e, sort) => {
    setCuratedPayments(sortFunction(curatedPayments, sort, order))
    order === 'des' ? setOrder('asc') : setOrder('des')
  }
  // Selects payments on clicked payment or selects all payments
  const selectPayments = (e, i) => {
    if (i === 'all') {
      if (curatedPayments.length === paymentsSelected.length) {
        setPaymentsSelected([])
      } else {
        curatedPayments.forEach((p, index) =>
          paymentsSelected.includes(index) ? null : paymentsSelected.push(index)
        )
        setPaymentsSelected([...paymentsSelected])
      }
    } else {
      if (paymentsSelected.includes(i)) {
        paymentsSelected.splice(paymentsSelected.indexOf(i), 1)
        setPaymentsSelected([...paymentsSelected])
      } else {
        setPaymentsSelected([...paymentsSelected, i])
      }
    }
  }
  // Slice the payments array for pagination of table
  const slicePayments = (action) => {
    if (action === 'next') {
      if (slice.start + sliceRange > curatedPayments.length) {
        setSlice({
          start: slice.start,
          end: curatedPayments.length,
        })
      } else if (slice.end + sliceRange > curatedPayments.length) {
        setSlice({
          start: curatedPayments.length > sliceRange ? slice.end : 0,
          end: curatedPayments.length,
        })
      } else {
        setSlice({
          start: slice.start + sliceRange,
          end: slice.end + sliceRange,
        })
      }
    }
    if (action === 'prev') {
      if (slice.start - sliceRange < 0) {
        setSlice({
          start: 0,
          end:
            curatedPayments.length > sliceRange
              ? sliceRange
              : curatedPayments.length,
        })
      } else {
        setSlice({ start: slice.start - sliceRange, end: slice.start })
      }
    }
  }
  // Deletes the selected payments
  const deletePayments = async () => {
    setPaymentsLoading(true)
    setPaymentsSelected([])
    for (let index of paymentsSelected) {
      console.log('🚀 ➡️ payment', curatedPayments[index])
      // await deletePayment(curatedPayments[index].id)
    }
    setPaymentsLoading(false)
  }
  // Approve or reject payment
  const handleChangePaymentStatus = async (payment, action) => {
    setPaymentsLoading(true)
    await changePaymentStatus(payment, action).then(async() => {
      await fetchData()
      setPaymentsLoading(false)
    })
  }

  // Handle download buttons
  const handleDownloads = async (option) => {
    console.log('🚀 ➡️ option', option,approvedPayments)
    setDownloadLoading(true)
    switch (option) {
      case 'all':
        if (invoiceZipPayments.length) await donwloadZipFile(invoiceZipPayments)
        setDownloadLoading(false)
        break
      case 'approved':
        if (approvedPayments.length)
          await JSONDownloadPayments(approvedPayments)
        setDownloadLoading(false)
        break
      default:
        break
    }
  }

  return (
    <>
      {paymentDetail.open && (
        <PaymentDetailMobile
          paymentDetail={paymentDetail}
          onClose={setPaymentDetail}
        />
      )}
      {extendedView && (
        <ModalViewPayments
          admin
          slice={slice}
          filter={filter}
          setSearch={setSearch}
          handleFilter={handleFilter}
          approvedPayments={approvedPayments}
          slicePayments={slicePayments}
          handleModals={handleExtendedView}
          handleDownloads={handleDownloads}
          handlePaymentsRange={handlePaymentsRange}
          paymentsRangeSelected={paymentsRangeSelected}
          payments={curatedPayments.slice(slice.start, slice.end)}
          paymentsLength={
            search || filter ? curatedPayments.length : payments.length
          }
        />
      )}
      <PaymentsTable
        users={users}
        slice={slice}
        filter={filter}
        setModal={setModal}
        setBankData={setBankData}
        projects={projects}
        setSearch={setSearch}
        handleSort={handleSort}
        handleFilter={handleFilter}
        slicePayments={slicePayments}
        deletePayments={deletePayments}
        selectPayments={selectPayments}
        paymentsLoading={paymentsLoading}
        downloadLoading={downloadLoading}
        handleDownloads={handleDownloads}
        paymentsSelected={paymentsSelected}
        approvedPayments={approvedPayments}
        invoiceZipPayments={invoiceZipPayments}
        handleExtendedView={handleExtendedView}
        handlePaymentsRange={handlePaymentsRange}
        SinglePaymentDropdown={SinglePaymentDropdown}
        paymentsRangeSelected={paymentsRangeSelected}
        handleChangePaymentStatus={handleChangePaymentStatus}
        payments={curatedPayments.slice(slice.start, slice.end)}
        paymentsLength={
          search || filter !== 'all' ? curatedPayments.length : payments.length
        }
        handleUploadPayment={handleUploadPayment}
      />
      {modal === 'openBankData' && (
        <ModalBankData
          handleModals={() => {
            setModal('')
            setPaymentsSelected([])
          }}
          data={bankData}
        />
      )}
    </>
  )
}

export default Payments
