import React, { useEffect, useState, useCallback } from 'react'
import { Link, useLocation } from 'react-router-dom'
// RECOIL
import { useRecoilValue } from 'recoil'
import { layoutState, sessionUserState } from '../../../atoms/index'
import { logOut } from '../../../services/auth'
// UI
import SidebarButton from './SidebarButtons'
import Logo from '../Images/Logo.png'
import UserDefault from '../Images/UserDefault.png'
import { monthsShort } from '../helpers'
import { Dropdown } from '../UI/Items'
import NotificationsPanel from '../../Admin/NotificationsPanel'

const DashboardLayout = ({ children }) => {
  const today = new Date()
  const location = useLocation()
  const [title, setTitle] = useState()
  const [selected, setselected] = useState(0)
  const [typeOfUser, setTypeOfUser] = useState('')
  const [notificationPanel, setNotificationPanel] = useState(false)
  const options = useRecoilValue(layoutState)
  const sessionUser = useRecoilValue(sessionUserState)
  const handleSelect = (i) => setselected(i)


  const changeTitle = useCallback(() => {
    let url = location.pathname
    if (url === `/${typeOfUser}/home`)
      typeOfUser === 'user'
        ? sessionUser &&
          setTitle(`Bienvenido ${sessionUser.personalData.fname}!🙂`)
        : setTitle('Bienvenido!🙂')
    if (url === `/${typeOfUser}/proyectos`) setTitle('Proyectos')
    if (url === `/${typeOfUser}/comunidad`) setTitle('Comunidad')
    if (url === `/${typeOfUser}/perfil`) setTitle('Perfil')
    if (url === `/${typeOfUser}/pagos`) setTitle('Pagos')
  }, [location.pathname, typeOfUser, sessionUser])

  useEffect(() => {
    changeTitle()
    sessionUser && sessionUser.isAdmin
      ? setTypeOfUser('admin')
      : setTypeOfUser('user')
  }, [changeTitle, sessionUser, location])

  const isUserLogged = Object.keys(sessionUser).length

  return (
    <>
      {typeOfUser === 'admin' && (
        <NotificationsPanel
          closePanel={() => setNotificationPanel(false)}
          notificationPanel={notificationPanel}
        />
      )}
      {isUserLogged && (
        <div className="relative min-h-screen sm:h-screen flex overflow-auto bg-white sm:bg-gray-100">
          {/* NAVBAR AND HEADER */}
          <div className="w-full absolute sm:relative sm:w-24 sm:min-h-screen z-30 sm:z-0">
            <div className="sm:bg-white absolute flex flex-col items-center sm:py-8 w-full sm:w-24 sm:min-h-screen h-full">
              <img src={Logo} alt="ITESA" className="hidden sm:block" />
              <nav
                className={
                  (location.pathname.includes('invite') ? 'hidden' : '') +
                  ' sm:mt-8 bg-gray-100 sm:bg-white fixed bottom-0 sm:relative flex sm:flex-col sm:items-center sm:justify-between w-full sm:h-full'
                }
              >
                <div className="w-4/5 sm:w-full flex sm:block">
                  {(typeOfUser === 'user' ? options.user : options.admin).map(
                    (button, i) => (
                      <Link
                        key={i}
                        to={`/${typeOfUser}/${button}`}
                        className="w-full"
                        onClick={() => handleSelect(i)}
                      >
                        <SidebarButton
                          button={button}
                          profileImage={
                            sessionUser.profileImage
                              ? sessionUser.profileImage
                              : UserDefault
                          }
                          active={selected === i ? true : false}
                        />
                      </Link>
                    )
                  )}
                </div>
                <div
                  className="hidden sm:block w-full cursor-pointer"
                  onClick={logOut}
                >
                  <SidebarButton button="logout" active={false} />
                </div>
                <div className="sm:hidden w-1/5 cursor-pointer">
                  <Dropdown
                    handler={logOut}
                    options={[{ value: 'logout', label: 'Cerrar sesion' }]}
                    button={<SidebarButton button="logout" active={false} />}
                  />
                </div>
              </nav>
            </div>
          </div>
          {/* LAYOUT */}
          <main className="flex-1 relative z-20 overflow-y-auto focus:outline-none h-full w-full">
            <div className="relative py-10 px-4 sm:px-12 h-full">
              <div className="w-full flex flex-col sm:flex-row items-baseline sm:items-center">
                <h1 className="dm-h3 md:dm-h2 min-w-max pr-4">{title}</h1>
                <div
                  className={
                    (title === 'Perfil'
                      ? 'hidden sm:inline-flex'
                      : 'inline-flex') +
                    ' relative items-center sm:justify-end space-y-4 sm:space-y-0 sm:space-x-6 lg:space-x-14 w-full'
                  }
                >
                  {typeOfUser === 'admin' && (
                    <span
                      className={
                        (title === 'Bienvenido!🙂'
                          ? 'block'
                          : 'hidden sm:block') +
                        ' absolute right-0 bottom-1 sm:bottom-0 sm:relative text-purple-light cursor-pointer'
                      }
                      onClick={() => setNotificationPanel(true)}
                    >
                      <svg
                        className="w-7 h-7"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
                      </svg>
                    </span>
                  )}
                  <div className="hidden sm:block">
                    <p className="font-poppins text-sm text-gray-800">Hoy</p>
                    <p className="font-poppins text-lg text-gray-800 min-w-max">
                      {monthsShort[today.getMonth()]} {today.getDate()},{' '}
                      {today.getFullYear()}
                    </p>
                  </div>
                  <div
                    className={
                      (sessionUser.isAdmin ? 'hidden' : '') +
                      ' inline-flex items-start sm:items-center space-x-4 min-w-max'
                    }
                  >
                    <div className="h-24 w-24 sm:h-16 sm:w-16">
                      <img
                        src={
                          sessionUser.profileImage
                            ? sessionUser.profileImage
                            : UserDefault
                        }
                        alt="USER"
                        className="rounded-full h-full w-full object-center object-cover border border-gray-300"
                      />
                    </div>
                    <div className="space-y-1 sm:space-y-0">
                      <p className="font-poppins text-base text-gray-800 min-w-max">
                        {sessionUser && sessionUser
                          ? sessionUser.personalData.fullName
                          : null}
                      </p>
                      <p className="font-poppins text-sm text-gray-800 min-w-max">
                        {sessionUser ? sessionUser.personalData.role : null}
                      </p>
                      <div className="sm:hidden h-4 inline-flex justify-center items-center min-w-max">
                        <span
                          className={
                            (!sessionUser.isAdmin &&
                            sessionUser.projects.active.length
                              ? 'bg-red-500'
                              : 'bg-green-500') + ' h-3 w-3 mr-2 rounded-full'
                          }
                        />
                        <span className="sm:hidden font-poppins text-sm text-gray-800 min-w-max">
                          {!sessionUser.isAdmin &&
                          sessionUser.projects.active.length
                            ? 'En proyecto'
                            : 'Libre'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative w-full flex items-center pt-4 pb-8 md:py-10">
                {children}
              </div>
            </div>
          </main>
        </div>
      )}
    </>
  )
}

export default DashboardLayout
