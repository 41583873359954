import { useEffect, useState } from 'react'
import EditProfile from './EditProfile.jsx'
// DB
import { updateProfile } from '../../../services/db'
// RECOIL
import { sessionUserState } from '../../../atoms'
import { useRecoilValue } from 'recoil'
import { fileToUrl, PdfToArrayBuffer } from '../../Utils/helpers'
import { db } from '../../../services/firebase.js'

const EditProfileContainer = ({ handleModal }) => {
  const sessionUser = useRecoilValue(sessionUserState)
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState('profileDetails')
  const [errorForm, setErrorForm] = useState(false)
  const [skillsList, setSkillsList] = useState([])
  const [previewProfileImage, setPreviewProfileImage] = useState(null)
  const [formData, setFormData] = useState({
    profileImage: sessionUser.profileImage || '',
    portfolio: sessionUser.portfolio || '',
    cv: sessionUser.cv || '',
    skills: sessionUser.skills || [],
  })

  useEffect(() => {
    let unsubscribe = db.collection('skills').onSnapshot((snapshot) => {
      if (snapshot.size) {
        let updatedSkillsList = []
        snapshot.forEach((doc) => {
          updatedSkillsList.push(doc.data().name)
        })
        setSkillsList(updatedSkillsList)
      }
    })
    return () => {
      unsubscribe()
    }
  }, [])

  const handleFiles = async (inputFile, name) => {
    let key = name
    let file = inputFile ? inputFile : null
    let converted
    if (key === 'cv') {
      converted = file ? await PdfToArrayBuffer(file) : null
    } else {
      setPreviewProfileImage(file ? await fileToUrl(file) : null)
      converted = file
    }
    setFormData({ ...formData, [key]: converted })
  }
  const handleStep = (e) => {
    e.preventDefault()
    let errors = validateData()
    if (errors) {
      setErrorForm(true)
    } else {
      setErrorForm(false)
      return step === 'profileDetails' &&
        sessionUser.personalData.role === 'Developer'
        ? setStep('selectSkills')
        : handleSubmit()
    }
  }
  const validateData = () => {
    let errors = 0
    for (const key in formData) {
      if (!formData[key]) {
        errors++
      }
    }
    return errors
  }
  const handleSkillSelection = (option) => {
    setFormData({ ...formData, skills: [...formData.skills, option] })
  }
  const removeSkill = (i) => {
    let skillsUpdated = formData.skills.filter((skill, index) =>
      index !== i ? true : false
    )
    setFormData({ ...formData, skills: skillsUpdated })
  }
  const handleSubmit = async (e) => {
    setLoading(true)
    try {
      await updateProfile(sessionUser.id, formData)
    } catch(err) {
      throw new Error(err)
    } finally {
      setLoading(false)
      setStep('success')
    }
  }

  return (
    <EditProfile
      step={step}
      loading={loading}
      formData={formData}
      errorForm={errorForm}
      handleStep={handleStep}
      handleFiles={handleFiles}
      removeSkill={removeSkill}
      setFormData={setFormData}
      handleModal={handleModal}
      handleSubmit={handleSubmit}
      skillsList={skillsList.slice(0, 20)}
      previewProfileImage={previewProfileImage}
      handleSkillSelection={handleSkillSelection}
    />
  )
}

export default EditProfileContainer
