import { useState } from 'react'
// RECOIL
import { useRecoilValue } from 'recoil'
import { sessionUserState } from '../../../../atoms'
// HELPERS
import { cbu, cuit } from 'arg.js'
// DB
import { updateBankDetails } from '../../../../services/db'
//
import ModalEditBankDetails from './ModalEditBankDetails'

const ModalEditBankDetailsContainer = ({ handleModals, fetchUserData }) => {
  const sessionUser = useRecoilValue(sessionUserState)
  console.log('sessionUser', sessionUser)
  const bd = sessionUser.bankData
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [invalidCuit, setInvalidCuit] = useState(false)
  const [invalidCbu, setInvalidCbu] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    bank: bd.bank,
    accountOwner: bd.accountOwner,
    cuit: bd.cuit,
    cbu: bd.cbu,
    address: bd.address,
    invoice: bd.invoice,
  })

  const handleInputs = (e, option, data) => {
    let key = data ? data : e.target.name
    let value = option ? option : e.target.value
    if (key === 'cuit' && cuit.isValid(value)) {
      setInvalidCuit(false)
    }
    if (key === 'cbu' && cbu.isValid(value)) setInvalidCbu(false)
    setFormData({ ...formData, [key]: value })
  }

  const validateData = (data, setError) => {
    let errors = 0
    for (const key in data) {
      if (!data[key]) errors++
      if (key === 'cuit' && !cuit.isValid(data[key].replace(/-/g, ''))) {
        errors++
        setInvalidCuit(true)
      } else if (key === 'cbu' && !cbu.isValid(data[key])) {
        errors++
        setInvalidCbu(true)
      }
    }
    errors ? setError(true) : setError(false)
    return errors
  }

  const validateForm = async (e) => {
    e.preventDefault()
    let errors = validateData(formData, setError)
    if (!errors) {
      setLoading(true)
      await updateBankDetails(sessionUser.id, formData)
      setLoading(false)
      setSuccess(true)
    }
  }

  return (
    <ModalEditBankDetails
      error={error}
      loading={loading}
      success={success}
      formData={formData}
      invalidCbu={invalidCbu}
      invalidCuit={invalidCuit}
      validateForm={validateForm}
      handleModals={handleModals}
      handleInputs={handleInputs}
      sessionUser={sessionUser}
      fetchUserData={fetchUserData}
    />
  )
}

export default ModalEditBankDetailsContainer
