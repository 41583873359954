import React from 'react'
// UI
import ModalLayout from '../../UI/ModalLayout'
import { PrimaryButton } from '../../UI/Buttons'
import Success from '../../Images/InviteSent.png'
import { Input } from '../../UI/Inputs'

const ModalInvite = ({
  error,
  success,
  loading,
  handleModal,
  emailInvite,
  handleValue,
  inviteProfile,
}) => (
  <ModalLayout
    modal="invite"
    handler={handleModal}
    goBackText="Community"
    title={success ? '¡Solicitud enviada!' : 'Invitar nuevo miembro'}
    regular
  >
    {!success ? (
      <form
        onSubmit={inviteProfile}
        className="w-full flex flex-col items-baseline space-y-8"
      >
        <div className="w-full flex flex-col items-baseline space-y-2">
          <Input
            type="email"
            label="Mail del perfil"
            name="cbu"
            value={emailInvite}
            handler={handleValue}
            error={error}
          />
          {error && !emailInvite ? (
            <p className="text-sm font-medium font-poppins text-red-600 mt-2">
              Ingrese un email válido
            </p>
          ) : null}
        </div>
        <div className="self-center sm:self-end">
          <PrimaryButton
            type="submit"
            size="lg"
            children="Invitar"
            loading={loading}
          />
        </div>
      </form>
    ) : (
      <div className="w-full flex flex-col items-center space-y-8">
        <img src={Success} alt="Success!" />
        <p className="text-sm sm:text-base md:text-lg text-center font-poppins text-gray-500">
          El perfil podrá ingresar a crear su cuenta desde su email.
        </p>
      </div>
    )}
  </ModalLayout>
)

export default ModalInvite
