import React, { useState, useEffect } from 'react'
// RECOIL
import { useRecoilState, useSetRecoilState } from 'recoil'
import { usersLoadingState, usersState } from '../../../atoms/index'
// COMPONENTS
import CommunityList from './List'
import Grid from './Grid'
import CommunityLayout from './CommunityLayout'
import ModalInviteUserToProject from '../../Utils/Modals/ModalInviteUserToProject'
// DB
import { db } from '../../../services/firebase'
// import { removeUser } from '../../../services/db'

const CommunityContainer = () => {
  const [modal, setModal] = useState('')
  const [search, setSearch] = useState('')
  const [order, setOrder] = useState('des')
  const [filter, setFilter] = useState('all')
  const [listView, setListView] = useState(true)
  const [users, setUsers] = useRecoilState(usersState)
  const [skillsFilters, setSkillsFilters] = useState([])
  const [usersSelected, setUsersSelected] = useState([])
  const setUsersLoading = useSetRecoilState(usersLoadingState)
  const [selectedUserPayments, setSelectedUserPayments] = useState([])
  const [slice, setSlice] = useState({
    start: 0,
    end: users.length > 10 ? 10 : users.length,
  })
  const [availabilitySelected, setAvailabilitySelected] = useState('allAvailability')

  const handleModals = (option) => {
    if(option === 'openCv') {
      window.open(curatedUsers[usersSelected[0]].cv)
    }
    option ? setModal(option) : setModal('')
  }

  const handleSearch = (e) => setSearch(e.target.value.toLowerCase())

  const toggleView = () => {
    setUsersSelected([])
    listView ? setListView(false) : setListView(true)
  }

  const sortFunction = (array, key, order) => {
    let copy = [...array]
    order === 'des'
      ? copy.sort((a, b) =>
          a.personalData[key] > b.personalData[key] ? 1 : -1
        )
      : copy.sort((a, b) =>
          a.personalData[key] > b.personalData[key] ? -1 : 1
        )
    return copy
  }

  const handleSort = (e, sort) => {
    setUsers(sortFunction(users, sort, order))
    order === 'des' ? setOrder('asc') : setOrder('des')
  }

  const selectUsers = (e, i) => {
    if (i === 'all') {
      if (users.length === usersSelected.length) {
        setUsersSelected([])
      } else {
        users.forEach((user, index) =>
          usersSelected.includes(index) ? null : usersSelected.push(index)
        )
        setUsersSelected([...usersSelected])
      }
    } else {
      if (usersSelected.includes(i)) {
        usersSelected.splice(usersSelected.indexOf(i), 1)
        setUsersSelected([...usersSelected])
      } else {
        setUsersSelected([...usersSelected, i])
      }
    }
  }

  const deleteUsers = async () => {
    console.log('🚀 ➡️ usersSelected', usersSelected)
    let actualUsers = buildUsers(users)
    usersSelected.forEach(async (i) => {
      console.log('🚀 ➡️ users[i]', actualUsers[i])
      // await removeUser(users[i].id)
    })
    setUsersSelected([])
  }

  const sliceUsers = (action) => {
    if (action === 'next') {
      if (slice.start + 10 > users.length) {
        setSlice({
          start: slice.start,
          end: users.length,
        })
      } else if (slice.end + 10 > users.length) {
        setSlice({
          start: users.length > 10 ? slice.end : 0,
          end: users.length,
        })
      } else {
        setSlice({ start: slice.start + 10, end: slice.end + 10 })
      }
    }
    if (action === 'prev') {
      if (slice.start - 10 < 0) {
        setSlice({ start: 0, end: users.length > 10 ? 10 : users.length })
      } else {
        setSlice({ start: slice.start - 10, end: slice.start })
      }
    }
  }

  const singleUserSelection = (e, i) => {
    setUsersSelected([i])
  }

  const handleFilter = (filter) =>{
    if(filter === 'Full time' || filter === 'Part time' || filter === 'No disponible' || filter === 'allAvailability') {
      handleUsersByAvailability(filter)
    } else {
      filter ? setFilter(filter) : setFilter('all')
    }
  }
  /* */

  const handleSkillFilter = (skill) => {
    if (skillsFilters.includes(skill)) {
      setSkillsFilters(skillsFilters.filter((prevSkill) => prevSkill !== skill))
    } else {
      setSkillsFilters([...skillsFilters, skill])
    }
  }

  const buildUsers = (usersArray) => {
    return usersArray
      .filter((user) => {
        let validUser = false
        let includesName = user.personalData.fullName
          .toLowerCase()
          .includes(search.toLowerCase())
        let hasSkills = false
        if (skillsFilters.length) {
          let userSkillsFiltered = []
          userSkillsFiltered = skillsFilters.reduce((acc, skill) => {
            if (user.skills?.includes(skill)) acc.push(skill)
            return acc
          }, [])
          hasSkills = userSkillsFiltered.length === skillsFilters.length
        }
        validUser = includesName && (skillsFilters.length ? hasSkills : true)
        
        if (filter === 'onProject') {
          return user.projects.active.length >= 1 && validUser
        } else if (filter === 'free') {
          return !user.projects.active.length && validUser
        } else if (availabilitySelected === 'Full time') {
          return user.availability === 'Full time' && validUser
        } else if (availabilitySelected === 'Part time') {
          return user.availability === 'Part time' && validUser
        } else if (availabilitySelected === 'No disponible') {
          return user.availability === 'No disponible' && validUser
        } else {
          return validUser
        }
      })
      .slice(slice.start, slice.end)
  }

  let curatedUsers = buildUsers(users)
  /* */

  const setUserSelectedPayments = () => {
    let userPayments = []
    if (usersSelected.length === 1) {
      let user = curatedUsers[usersSelected[0]]
      for (const key in user.payments) {
        userPayments = [...userPayments, ...user.payments[key]]
      }
      setSelectedUserPayments(userPayments)
    } else {
      setSelectedUserPayments([])
    }
  }

  const handleUsersByAvailability = (option) => {
    setAvailabilitySelected(option)
  }

  useEffect(() => {
    const getUsers = db.collection('users').onSnapshot((query) => {
      setUsersLoading(true)
      let updatedUsers = []
      query.forEach((doc) => updatedUsers.push(doc.data()))
      setUsers(updatedUsers)
      setUsersLoading(false)
    })
    return () => getUsers()
    // eslint-disable-next-line
  }, [])

  // eslint-disable-next-line
  useEffect(() => setUserSelectedPayments(), [usersSelected])

  return (
    <CommunityLayout
      modal={modal}
      filter={filter}
      listView={listView}
      users={curatedUsers}
      toggleView={toggleView}
      deleteUsers={deleteUsers}
      handleModals={handleModals}
      handleFilter={handleFilter}
      handleSearch={handleSearch}
      skillsFilters={skillsFilters}
      usersSelected={usersSelected}
      handleSkillFilter={handleSkillFilter}
      availabilitySelected={availabilitySelected}
      selectedUserPayments={selectedUserPayments}
      selectedUser={curatedUsers[usersSelected[0]]}
    >
      {listView ? (
        <CommunityList
          users={users}
          curatedUsers={curatedUsers}
          handleSort={handleSort}
          usersSelected={usersSelected}
          selectUsers={selectUsers}
          handleModals={handleModals}
          sliceUsers={sliceUsers}
          slice={slice}
          setSearch={setSearch}
          singleUserSelection={singleUserSelection}
        />
      ) : (
        <Grid
          slice={slice}
          users={curatedUsers}
          sliceUsers={sliceUsers}
          handleModals={handleModals}
          singleUserSelection={singleUserSelection}
        />
      )}
      {modal === 'inviteToProject' && (
        <ModalInviteUserToProject
          handleModals={handleModals}
          user={users[usersSelected[0]]}
          userId={users[usersSelected[0]].id}
        />
      )}
    </CommunityLayout>
  )
}

export default CommunityContainer
