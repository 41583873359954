import React, { useEffect, useState } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
// FIREBASE
import {
  fetchUserProjects,
  fetchUserPayments,
  getUser,
  updateAllBankData,
} from '../../services/db'
import { useAuth } from '../../services/firebase-auth-context'
// RECOIL
import { useSetRecoilState, useRecoilState } from 'recoil'
import {
  sessionUserState,
  userPaymentsState,
  userProjectsState,
  projectsLoadingState,
  paymentsLoadingState,
} from '../../atoms/index'
// UI
import DashboardLayout from '../Utils/UI/DashboardLayout'
import { FullPageLoaderComponent } from '../Utils/UI/Loaders'
// COMPONENTS
import UserHome from './UserHome'
import ProfileContainer from './Profile'
import ModalUploadPayment from './UploadPayment'
import EditProfileContainer from './EditProfile'
import ModalItesaDetails from '../Utils/Modals/ModalItesaDetails.jsx'
import AcceptProjectInviteContainer from './AcceptProjectInvite'
import ModalEditBankDetailsContainer from '../Utils/Modals/ModalEditBankDetails'

const UserContainer = () => {
  const user = useAuth()
  const history = useHistory()
  const [userLoading, setUserLoading] = useState(true)
  const setUserPayments = useSetRecoilState(userPaymentsState)
  const setUserProjects = useSetRecoilState(userProjectsState)
  const setProjectsLoading = useSetRecoilState(projectsLoadingState)
  const setPaymentsLoading = useSetRecoilState(paymentsLoadingState)
  const [sessionUser, setSessionUser] = useRecoilState(sessionUserState)
  const [data,setData] = useState({})
  const [pLoading, setPLoading] = useState(true)

  const userProjects = useRecoilState(userProjectsState)

  const getUserProjects = async () => {
    setProjectsLoading(true)
    setPLoading(true)
    if(!user.uid) return
    await fetchUserProjects(user.uid).then((res) => {
      if(res.error) return 
      setUserProjects(res)
      setData(res)
      setPLoading(false)
      return setProjectsLoading(false)
    }).catch((err) => console.log(err))
  }

  const addPaymentNumber = (payments) => {
    // Group payments by user ID and project
    if(!payments) return 
    const groupedPayments = payments.reduce((acc, payment) => {
      const key = `${payment.user.id}-${payment.project}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(payment);
      return acc;
    }, {});
  
    // Sort each group by date and assign paymentNumber
    Object.values(groupedPayments).forEach(group => {
      group.sort((a, b) => a.date.seconds - b.date.seconds); // Sort by date
      group.forEach((payment, index) => {
        payment.paymentNumber = index + 1; // Assign paymentNumber
      });
    });
  
    return payments;
  };

  const getUserPayments = async () => {
    setPaymentsLoading(true)
    return await fetchUserPayments(user.uid).then((res) => {
      setUserPayments(addPaymentNumber(res))
      return setPaymentsLoading(false)
    })
  }
  // const checkBankData = async (userInfo) => {
  //   if (!userInfo.allBankData) {
  //     console.log('no bank data',userInfo)
  //     const userData = await updateAllBankData(userInfo)
  //     return userData
  //   }else{
  //     return userInfo
  //   }
  // }
  const fetchUserData = async () => {
    if (user) {
      return getUser(user.uid).then(async(res) => {
        if (res.user) {
          const userData = res.user
          setSessionUser(userData)
          setUserLoading(false)

        } else {
          console.log('no user')
          return history.push('/login')
        }
      })
    }
  }

  useEffect(() => {
    if (!user){
      history.push('/login')
      }
    // else fetchUserData()
    // eslint-disable-next-line
  }, [user])

  useEffect(() => {
    getUserProjects() && getUserPayments() && fetchUserData()
    // eslint-disable-next-line
  },[])

  if (userLoading || pLoading || userLoading) {
    return <FullPageLoaderComponent />
  } else if (!sessionUser.profileImage && !sessionUser.cv) {
    return <EditProfileContainer handleModal={() => history.push('/user/home')} />
  } else {
    return (
      <Switch>
        <DashboardLayout>
          <Route path="/user/home">
            <UserHome fetchUserData={fetchUserData}/>
          </Route>
          <Route exact path="/user/perfil">
            <ProfileContainer />
          </Route>
          <Route exact path="/user/invite/:projectId">
            <AcceptProjectInviteContainer signature={sessionUser.signature} data={userProjects}/>
          </Route>
          <Route exact path="/user/upload/:paymentId">
            <ModalUploadPayment />
          </Route>
          <Route exact path="/user/editar-datos-bancarios">
            <ModalEditBankDetailsContainer
              handleModals={() => history.push('/user/home')}
            />
          </Route>
          <Route exact path="/user/detalles-itesa">
            <ModalItesaDetails
              handleModals={() => history.push('/user/home')}
            />
          </Route>
          <Route exact path="/">
            <Redirect to="/user/home" />
          </Route>
        </DashboardLayout>
      </Switch>
    )
  }
}

export default UserContainer
