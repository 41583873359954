import { useState } from 'react'
import ModalLayout from '../UI/ModalLayout'
import BankAccountsContainer from '../../User/BankAccounts'

const ModalBankData = ({ handleModals, data, allBankData }) => {
  let type = allBankData ? null : data.type

  const BankCard = ({ bankData }) => {
    const isDefault = bankData.default;
    const [open, setOpen] = useState(false);
    let image 
    let dataToShow
    if(bankData.type === 'arg'){
      image = '/images/arg.png'
      dataToShow = '...' + bankData.cbu.slice(-16)
    }
    if(bankData.type === 'wise'){
      image = '/images/wise.png'
      dataToShow = bankData.email
    }
    if(bankData.type === 'zelle'){
      image = '/images/zelle.png'
      dataToShow = bankData.email
    }
    if(bankData.type === 'ach'){
      image = '/images/ach.svg'
      dataToShow = bankData.routingNumber
    }
    if(bankData.type === 'usdt'){
      image = '/images/usdt.svg'
      let address = bankData.walletAddress
      dataToShow = address.slice(0, 6) + '...' + address.slice(-6)
    }
    return (
      // Create cards for each bank account only with the bank name
      <div className={`flex flex-row items-center flex-start p-3 border border-gray-500 rounded-lg cursor-pointer gap-3 ${!isDefault ? 'bg-gray-100' : 'bg-green-200'}`} onClick={() =>setOpen(true)}>
        <div className=''>
           <img src={image} alt='Arg' className='w-14 h-auto' />
        </div>
        <div className={`flex flex-col justify-between w-full`}>
          <div className='flex flex-row w-full align-center justify-between' >
            <p>{bankData.name}</p>
            {open && <BankAccountsContainer bankData={bankData} edit={true} setOpen={setOpen} fetchUserData={null} onlyView={true}/>}
          </div>
            <span className="text-xs text-gray-500">{dataToShow}</span> 
          {isDefault && <p className="text-md p-0 m-0 text-green-600">Default</p>}
        </div>
      </div>
    );
  }

  return(
    <ModalLayout
      handler={handleModals}
      modal="profileBankDetails"
      title="Datos de cuenta bancaria"
      goBackText="Perfil"
    >
      <div className="w-full flex items-center justify-center mb-5">
        <div className="min-w-max">
          <div className="h-8 w-8">
            <img
              className="rounded-full h-full w-full object-center object-cover border border-gray-300"
              src={
                data.user.profileImage
                  ? data.user.profileImage
                  : null
              }
              alt="UImage"
            />
          </div>
        </div>
        <span className='ml-2'>{data.user.personalData.fullName}</span>
      </div>
      {type === 'arg' && (
        <div className="w-full flex flex-col space-y-4">
          <p className="text-lg text-gray-800">{data.bank}</p>
          <div>
            <p className="text-sm text-gray-500">CBU</p>
            <p className="text-lg text-gray-800 break-all">{data.cbu}</p>
          </div>
          <div>
            <p className="text-sm text-gray-500">TITULAR</p>
            <p className="text-lg text-gray-800">{data.accountOwner}</p>
          </div>
          <div>
            <p className="text-sm text-gray-500">CUIT</p>
            <p className="text-lg text-gray-800">{data.cuit}</p>
          </div>
        </div>
      )}
      {type === 'wise' && (
         <div className="w-full flex flex-col space-y-4">
          <p className="text-lg text-gray-800">{data.type.toUpperCase()}</p>
          <div>
            <p className="text-sm text-gray-500">Email</p>
            <p className="text-lg text-gray-800 break-all">{data.email}</p>
          </div>
        </div>
      )}
      {type === 'zelle' && (
         <div className="w-full flex flex-col space-y-4">
          <p className="text-lg text-gray-800">{data.type.toUpperCase()}</p>
          <div>
            <p className="text-sm text-gray-500">Email</p>
            <p className="text-lg text-gray-800 break-all">{data.email}</p>
          </div>
        </div>
      )}
      {type === 'ach' && (
         <div className="w-full flex flex-col space-y-4">
          <p className="text-lg text-gray-800">{data.type.toUpperCase()}</p>
          <div>
            <p className="text-sm text-gray-500">Account Number</p>
            <p className="text-lg text-gray-800 break-all">{data.accountNumber}</p>
          </div>
          <div>
            <p className="text-sm text-gray-500">Routing Number</p>
            <p className="text-lg text-gray-800 break-all">{data.routingNumber}</p>
          </div>
          <div>
            <p className="text-sm text-gray-500">Account Type</p>
            <p className="text-lg text-gray-800 break-all">{data.accountType}</p>
          </div>
          <div>
            <p className="text-sm text-gray-500">Bank Name</p>
            <p className="text-lg text-gray-800 break-all">{data.bankName}</p>
          </div>
          <div>
            <p className="text-sm text-gray-500">Bank Address</p>
            <p className="text-lg text-gray-800 break-all">{data.bankAddress}</p>
          </div>
          <div>
            <p className="text-sm text-gray-500">Nombre del beneficiario</p>
            <p className="text-lg text-gray-800 break-all">{data.beneficiaryName}</p>
          </div>
        </div>
      )}
      {type === 'usdt' && (
         <div className="w-full flex flex-col space-y-4">
          <p className="text-lg text-gray-800">{data.type.toUpperCase()}</p>
          <div>
            <p className="text-sm text-gray-500">Wallet Address</p>
            <p className="text-lg text-gray-800 break-all">{data.walletAddress}</p>
          </div>
        </div>
      )}
      {allBankData && (
     
              <div className="flex flex-col space-y-10 h-full w-full">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                       {data.user.allBankData?.map((bankData, i) => {
                            return( 
                              <BankCard key={i} bankData={bankData}/>
                            )
                          }
                        )}
                    </div>
              </div>
      )}
    </ModalLayout>

  )
}

export default ModalBankData
