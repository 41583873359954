import React, { useEffect } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { lastProjectId, } from '../../../../services/db'
// UI
import { PrimaryButton } from '../../UI/Buttons'
import { InputImg } from '../../UI/Inputs'

const inputStyle = (error) =>
  (error
    ? 'border-red-500 focus:ring-red-500 focus:border-red-500 '
    : 'border-gray-300 focus:ring-purple focus:border-purple ') +
  'appearance-none block w-full px-3 py-2 border rounded-md sm:placeholder-transparent sm:bg-transparent font-poppins focus:outline-none bg-gray-50 sm:text-sm placeholder-gray-400'

const labelStyle = (error) =>
  error
    ? 'text-sm text-red-500 font-poppins'
    : 'text-sm text-gray-800 font-poppins'

const ModalProjectDetails = ({
  error,
  formData,
  setFormData,
  handleFile,
  handleValue,
  handleDates,
  createProject,
  confirmDetails,
}) => {
  
  useEffect(() => {
    lastProjectId().then((res) => {
      let data = res.data()
      console.log('Ressss', res.data())
      if(!data) data = { number: 0 }
      const strDate = new Date() // By default Date empty constructor give you Date.now
      const shortYear = strDate.getFullYear()
      // Add this line
      const twoDigitYear = shortYear.toString().substr(-2)
      // setProjectId(data.number + 1)
      setFormData({
        ...formData,
        'projectId':
          `ITS${twoDigitYear}${data.number > 99 ? '' : 0}` + (data.number + 1),
      })
    })
  }, [])

  return (
    <form className="w-full flex flex-col items-baseline px-1 space-y-8 overflow-y-auto">
      <div className="w-full flex flex-col items-baseline space-y-2">
        <label
          htmlFor="pname"
          className={labelStyle(error && !formData.pname ? true : false)}
        >
          Nombre del proyecto
        </label>
        <input
          id="pname"
          name="pname"
          type="text"
          value={formData.pname}
          onChange={handleValue}
          className={inputStyle(error && !formData.pname ? true : false)}
        ></input>
      </div>
      <div className="w-full flex flex-col items-baseline space-y-1">
        <label
          htmlFor="description"
          className={labelStyle(error && !formData.description ? true : false)}
        >
          Descripción
        </label>
        <textarea
          id="description"
          name="description"
          type="text"
          value={formData.description}
          onChange={handleValue}
          className={inputStyle(error && !formData.description ? true : false)}
        ></textarea>
      </div>
      <div className="w-full flex flex-col items-baseline space-y-1">
        <label
          htmlFor="projectId"
          className={labelStyle(error && !formData.projectId ? true : false)}
        >
          ID del proyecto
        </label>
        <input
          id="projectId"
          name="projectId"
          type="text"
          value={formData.projectId}
          // onChange={handleValue}
          className={inputStyle(error && !formData.projectId ? true : false)}
        ></input>
      </div>
      <div className="w-full flex flex-col items-baseline space-y-1">
        <label
          htmlFor="pImage"
          className={labelStyle(error && !formData.pImage ? true : false)}
        >
          Imagen del proyecto
        </label>
        <InputImg
          name="pImage"
          handleChange={handleFile}
          img={formData.pImage}
          error={error}
        />
      </div>
      <div className="w-full flex flex-col items-baseline space-y-1">
        <label
          htmlFor="dates"
          className={labelStyle(error && !formData.startDate ? true : false)}
        >
          Fecha de inicio
        </label>
        <DatePicker
          dateFormat="dd/MM/yyyy"
          selected={formData.startDate}
          startDate={formData.startDate}
          readonly
          popperPlacement="top-start"
          onChange={(date) => handleDates(date, 'startDate')}
          className={inputStyle(false)}
        />
      </div>
      <div className="w-full inline-flex justify-between space-x-8">
        <PrimaryButton
          type="button"
          size="lg"
          children="Crear proyecto"
          handler={createProject}
        />
        <PrimaryButton
          type="button"
          size="lg"
          children="Asignar perfiles"
          handler={() => confirmDetails(false)}
        />
      </div>
    </form>
  )
}
export default ModalProjectDetails
