import React from 'react'
import { Link } from 'react-router-dom'
//RECOIL
import { useRecoilValue } from 'recoil'
import { projectsLoadingState, usersState } from '../../../atoms/index'
// UI
import { ProjectsOptionsDropdown } from '../../Utils/UI/Selects'
// IMAGES
import Down from '../../Utils/Images/Down.png'
import Trash from '../../Utils/Images/Trash.png'
import UserDefault from '../../Utils/Images/UserDefault.png'
import SelectedCheck from '../../Utils/Images/SelectedCheck.png'
import ArrowDownViolet from '../../Utils/Images/ArrowDownViolet.png'
import ArrowControlLeft from '../../Utils/Images/ArrowControlLeft.png'
import SelectAllDefault from '../../Utils/Images/SelectAllDefault.png'
import SelectAllSelected from '../../Utils/Images/SelectAllSelected.png'
import ArrowControlRight from '../../Utils/Images/ArrowControlRight.png'
// HELPERS
import { LoaderComponent } from '../../Utils/UI/Loaders'
import { parseDate } from '../../Utils/helpers'

const ProjectsTable = ({
  slice,
  projects,
  handleSort,
  handleModals,
  sliceProjects,
  projectsLength,
  selectProjects,
  projectsSelected,
}) => {
  const users = useRecoilValue(usersState)
  const projectsLoading = useRecoilValue(projectsLoadingState)

  if (projectsLoading && !projects) {
    return <LoaderComponent />
  } else {
    return (
      <>
        {/* PROJECTS DESKTOP TABLE */}
        <div className="hidden md:block w-full h-full rounded-md bg-white pt-8 border border-gray-300 px-6 py-4">
          {/* TABLE CONTROL ACTIONS */}
          <div className="inline-flex justify-between items-center w-full px-4 py-4 border-b border-gray-300">
            {/* PROJECTS SELECTION OPTIONS */}
            <div className="inline-flex items-center space-x-6">
              <div
                className="min-h-max inline-flex items-center space-x-2"
                id="selectAll"
              >
                <button
                  onClick={(e) => selectProjects(e, 'all')}
                  type="button"
                  className="h-full focus:outline-none"
                >
                  <img
                    src={
                      projectsSelected.length === projects.length
                        ? SelectAllSelected
                        : SelectAllDefault
                    }
                    alt="SelectAll"
                  />
                </button>
                <img src={Down} alt="" />
              </div>
              {projectsSelected.length >= 1 ? (
                <button
                  onClick={() => handleModals('delete')}
                  id="delete"
                  type="button"
                  className="h-full p-1 focus:outline-none"
                >
                  <img src={Trash} alt="Delete" />
                </button>
              ) : null}
              {projectsSelected && projectsSelected.length === 1 ? (
                <ProjectsOptionsDropdown handler={handleModals} />
              ) : null}
            </div>
            {/* PAGINATION */}
            <div className="inline-flex items-center space-x-4">
              <p className="text-gray-800 font-poppins text-sm">
                {slice.start + 1}-{slice.end} de {projectsLength}
              </p>
              <button
                onClick={() => sliceProjects('prev')}
                type="button"
                className="h-full p-2 focus:outline-none"
              >
                <img src={ArrowControlLeft} alt="Previous" />
              </button>
              <button
                onClick={() => sliceProjects('next')}
                type="button"
                className="h-full p-2 focus:outline-none"
              >
                <img src={ArrowControlRight} alt="Previous" />
              </button>
            </div>
          </div>

          {/* TABLE */}
          <table className="w-full mb-4">
            <thead>
              <tr>
                <th scope="col" className="px-0 py-6 w-20"></th>
                <th scope="col" className="px-2 py-6">
                  <button className="w-full inline-flex items-center space-x-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider focus:outline-none cursor-default">
                    <span>Equipo</span>
                  </button>
                </th>
                <th scope="col" className="px-2 py-6">
                  <button
                    onClick={(e) => handleSort(e, 'pname')}
                    className="w-full inline-flex items-center space-x-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                  >
                    <span>Proyecto</span>
                    <img src={ArrowDownViolet} alt="Sort" />
                  </button>
                </th>
                <th scope="col" className="px-2 py-6">
                  <button
                    onClick={(e) => handleSort(e, 'id')}
                    className="w-full inline-flex items-center space-x-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                  >
                    <span>ID</span>
                    <img src={ArrowDownViolet} alt="Sort" />
                  </button>
                </th>
                <th scope="col" className="px-2 py-6">
                  <button
                    onClick={(e) => handleSort(e, 'startDate')}
                    className="w-full inline-flex items-center space-x-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                  >
                    <span>Fecha de Inicio</span>
                    <img src={ArrowDownViolet} alt="Sort" />
                  </button>
                </th>
                <th scope="col" className="px-2 py-6">
                  <button
                    onClick={(e) => handleSort(e, 'status')}
                    className="w-full inline-flex items-center space-x-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider focus:outline-none cursor-default"
                  >
                    <span>Estado</span>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 w-full">
              {projects.length ? (
                projects.map((project, i) => {
                  console.log('project', project.users)
                  return (
                  <tr key={i}>
                    <td className="px-4 py-2 font-poppins">
                      <button
                        onClick={(e) => selectProjects(e, i)}
                        type="button"
                        className="focus:outline-none h-full"
                      >
                        <img
                          src={
                            projectsSelected.includes(i)
                              ? SelectedCheck
                              : SelectAllDefault
                          }
                          alt="SelectAll"
                        />
                      </button>
                    </td>
                    <td className="px-2 py-2 font-poppins">
                      <div className="flex items-center">
                        {project.users &&
                          project.users
                            .map((user, i) => {
                              // let userData = users.find(
                              //   (detailUser) => detailUser.id === user.user
                              // )
                              // if (user.isExtension) return null
                              return (
                                <div className="h-8 w-8" key={i}>
                                  <img
                                    key={i}
                                    className="rounded-full h-full w-full object-center object-cover border border-gray-300"
                                    src={
                                      user.profileImage
                                        ? user.profileImage
                                        : UserDefault
                                    }
                                    alt="UImage"
                                  />
                                </div>
                              )
                            })
                            .slice(0, 4)}
                        {project.users && project.users.length > 4 ? (
                          <span className="mx-2 text-sm text-gray-500 font-poppins">
                            + {project.users.length - 4}{' '}
                          </span>
                        ) : null}
                      </div>
                    </td>
                    <td className="px-2 py-2 text-sm text-gray-800 font-poppins">
                      <Link to={`/admin/proyectos/${project.projectId}`}>
                        {project.pname}
                      </Link>
                    </td>
                    <td className="px-2 py-2 text-sm text-gray-800 font-poppins">
                      <Link to={`/admin/proyectos/${project.projectId}`}>
                        {project.projectId}
                      </Link>
                    </td>
                    <td className="px-2 py-2 text-sm text-gray-800 font-poppins">
                      {parseDate(project.startDate)}
                    </td>
                    <td className="px-2 py-2 text-sm text-gray-800 font-poppins min-w-max">
                      <div
                        className={
                          'px-4 py-1 rounded-full text-center w-36 ' +
                          (project.status !== 'Finalizado'
                            ? 'bg-gray-100'
                            : 'bg-green-400 text-white')
                        }
                      >
                        {project.status}
                      </div>
                    </td>
                  </tr>
                )})
              ) : (
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="text-center px-2 py-6 text-sm text-gray-500 font-poppins w-max">
                    No se encontraron proyectos
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* PROJECTS MOBILE TABLE */}
        <div className="block md:hidden w-full h-full">
          {/* PAGINATION */}
          <div className="inline-flex justify-end items-center w-full py-2 space-x-4">
            <button
              onClick={() => sliceProjects('prev')}
              type="button"
              className="h-full p-2 focus:outline-none"
            >
              <img src={ArrowControlLeft} alt="Previous" />
            </button>
            <p className="text-gray-800 font-poppins text-sm">
              {slice.start + 1}-{slice.end} de {projects.length}
            </p>
            <button
              onClick={() => sliceProjects('next')}
              type="button"
              className="h-full p-2 focus:outline-none"
            >
              <img src={ArrowControlRight} alt="Previous" />
            </button>
          </div>
          {/* TABLE */}
          <table className="w-full">
            <tbody>
              {projects.length ? (
                projects.map((project, i) => (
                  <tr
                    key={i}
                    className="table-row w-full border-b border-t border-gray-300"
                  >
                    <td className="w-full h-full pt-2">
                      <div className="w-full h-full inline-flex justify-between items-center">
                        <div className="w-full space-x-2 flex flex-1 items-start">
                          <div className="min-w-max">
                            <div className="relative max-h-14 h-14 w-14">
                              <img
                                src={project.pImage}
                                alt="USER"
                                className="rounded-full h-full w-full object-center object-cover border border-gray-300"
                              />
                            </div>
                          </div>
                          <div className="table-cell text-sm text-gray-800 font-poppins">
                            <div className="flex flex-col justify-center text-left h-full min-w-0">
                              <Link
                                to={`/admin/proyectos/${project.projectId}`}
                              >
                                {project.pname}
                              </Link>
                              <p className="text-gray-500 text-xs">
                                {/* {(project.startDate)} */}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="w-auto flex flex-col items-end space-y-1">
                          <div className="flex items-center">
                            {project.users
                              ?.map((user, i) => {
                                let userData = users.find(
                                  (detailUser) => detailUser.id === user.user
                                )
                                if (user.isExtension) return null
                                return (
                                  <div className="h-7 w-7" key={i}>
                                    <img
                                      className="rounded-full h-full w-full object-center object-cover border border-gray-300"
                                      src={
                                        userData.profileImage
                                          ? userData.profileImage
                                          : UserDefault
                                      }
                                      alt="UImage"
                                    />
                                  </div>
                                )
                              })
                              .slice(0, 3)}
                            {project.users && project.users.length > 3 ? (
                              <span className="mx-2 text-sm text-gray-500 font-poppins">
                                + {project.users.length - 3}{' '}
                              </span>
                            ) : null}
                          </div>
                          <div
                            className={
                              'px-2 rounded-full text-xs text-gray-800 font-poppins border ' +
                              (project.status !== 'Finalizado'
                                ? 'bg-gray-100 border-gray-300'
                                : 'border-green-500 bg-green-400 text-white')
                            }
                          >
                            {project.status}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td></td>
                  <td></td>
                  <td className="text-center px-2 py-6 text-sm text-gray-500 font-poppins w-max">
                    No se encontraron freelancers
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </>
    )
  }
}

export default ProjectsTable
