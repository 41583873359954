import ModalLayout from '../UI/ModalLayout'

const ModalContactData = ({ handleModals, data, address }) => (
  <ModalLayout
    handler={handleModals}
    modal="contactDetails"
    title="Datos de contacto"
    goBackText="Perfil"
  >
    <div className="w-full flex flex-col space-y-4">
      <div>
        <p className="text-sm text-gray-500">Mail</p>
        <p className="text-lg text-gray-800">{data.email}</p>
      </div>
      <div>
        <p className="text-sm text-gray-500">Teléfono</p>
        <p className="text-lg text-gray-800">{data.phone}</p>
      </div>
      <div>
        <p className="text-sm text-gray-500">Direccion</p>
        <p className="text-lg text-gray-800">{address}</p>
      </div>
      <div>
        <p className="text-sm text-gray-500">Cumpleaños</p>
        <p className="text-lg text-gray-800">{data.birthdate}</p>
      </div>
    </div>
  </ModalLayout>
)

export default ModalContactData
