import React from 'react'

// COMPONENTS
import ModalBankData from '../../Utils/Modals/ModalBankData'
import ModalInviteContainer from '../../Utils/Modals/ModalInvite'
import ModalViewPaymentsContainer from '../../Utils/Modals/ModalViewPayments'
import ModalContactData from '../../Utils/Modals/ModalContactDetails'
import ModalConfirmDelete from '../../Utils/Modals/ModalConfirmDelete'
import ModalInviteUserToProject from '../../Utils/Modals/ModalInviteUserToProject'
// UI
import { ProjectCard } from '../../Utils/UI/ProjectCard'
import { SingleUserDropdown } from '../../Utils/UI/Selects'
import UserDefault from '../../Utils/Images/UserDefault.png'
import { PrimaryButton, SecondaryButton } from '../../Utils/UI/Buttons'

const SingleUser = ({
  user,
  modal,
  userId,
  projects,
  setModal,
  deleteUser,
  userPayments,
  handleModals,
}) => {
  return (
    <>
      {user && (
        <div className="w-full">
          <div className="w-full flex space-x-4 md:space-x-10 mb-10">
            {/* PROFILE IMAGE */}
            <div className="w-auto">
              <div className="h-24 w-24 md:h-56 md:w-56">
                <img
                  src={user.profileImage ? user.profileImage : UserDefault}
                  alt="ProfileImage"
                  className="rounded-full h-full w-full object-center object-cover border border-gray-300"
                />
              </div>
            </div>
            <div className="relative w-full sm:w-auto flex flex-col md:flex-row space-x-0 space-y-4 md:space-y-0 md:space-x-10">
              {/* PROFILE DATA */}
              <div className="flex flex-col justify-between w-full md:min-w-max">
                <div>
                  <div className="inline-flex space-x-4 items-center">
                    <p className="font-poppins text-base sm:text-lg md:text-xl text-gray-800">
                      {user.personalData.fullName}
                    </p>
                    <div className="hidden sm:block">
                      {user.projects.active.length ? (
                        <span className="font-poppins text-sm md:text-base text-gray-800 rounded-full bg-gray-300 px-2 py-1">
                          En proyecto
                        </span>
                      ) : (
                        <span className="font-poppins text-sm md:text-base text-white rounded-full bg-green-500 px-2 py-1">
                          Libre
                        </span>
                      )}
                    </div>
                  </div>
                  <p className="font-poppins text-sm sm:text-base md:text-lg text-gray-500 m-0">
                    {user.personalData.role}
                  </p>
                  <div className="sm:hidden h-4 inline-flex justify-center items-center min-w-max">
                    <span
                      className={
                        (user.projects.active.length
                          ? 'bg-red-500'
                          : 'bg-green-500') + ' h-3 w-3 mr-2 rounded-full'
                      }
                    />
                    <span className="sm:hidden font-poppins text-sm text-gray-800 min-w-max">
                      {user.projects.active.length ? 'En proyecto' : 'Libre'}
                    </span>
                  </div>
                  <div className="break-all my-2 text-sm sm:text-base md:text-lg text-purple">
                    <a href={user.portfolio} rel="noreferrer" target="_blank">
                      {user.portfolio}
                    </a>
                  </div>
                  {user.availability ? (
                    <p className="font-poppins text-xs sm:text-sm md:text-base text-gray-800 m-0">
                      <span className="text-gray-500">Disponibilidad:</span>{' '}
                      {user.availability}
                    </p>
                  ) : null}
                </div>
                <div className="font-poppins text-sm sm:text-base md:text-lg text-gray-800 inline-flex items-center">
                  <span className="text-base sm:text-lg md:text-xl mr-2">
                    {user.projects.finished.length}
                  </span>{' '}
                  proyectos realizados
                </div>
              </div>
              {/* PROFILE ACTIONS */}
              <div className="inline-flex justify-end sm:justify-start space-x-0 sm:space-x-4 w-full">
                <div className="hidden sm:inline-flex w-full items-baseline space-x-4">
                  <SecondaryButton
                    type="button"
                    size="lg"
                    children="Invitar"
                    handler={() => setModal('inviteToProject')}
                  />
                  <SecondaryButton
                    type="button"
                    size="lg"
                    children={<a href={user.cv}>CV</a>}
                  />
                </div>
                <div className="inline-flex sm:hidden w-full items-baseline space-x-4">
                  <PrimaryButton
                    type="button"
                    size="sm"
                    children="Invitar"
                    handler={() => setModal('inviteToProject')}
                  />
                  <SecondaryButton
                    type="button"
                    size="sm"
                    children={<a href={user.cv ? user.cv : null}>CV</a>}
                    handler={() => null}
                  />
                </div>
                <div className="absolute top-0 right-0 sm:relative pt-0 sm:pt-2">
                  <SingleUserDropdown handler={handleModals} admin={true} />
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
            {user &&
              [
                ...user.projects.active,
                ...user.projects.invites,
                ...user.projects.finished,
              ].map((project) => (
                <ProjectCard
                  key={project}
                  project={projects.find((p) => p.projectId === project)}
                  user={user}
                />
              ))}
          </div>
          {modal && modal === 'openBankData' ? (
            <ModalBankData handleModals={handleModals} data={{user}} allBankData={true}/>
          ) : null}
          {modal && modal === 'invite' ? (
            <ModalInviteContainer handleModal={handleModals} />
          ) : null}
          {modal && modal === 'openUserData' ? (
            <ModalContactData
              handleModals={handleModals}
              data={user.personalData}
              address={user.bankData.address}
            />
          ) : null}
          {modal && modal === 'delete' ? (
            <ModalConfirmDelete
              handleModals={handleModals}
              handleDelete={deleteUser}
              multiple={false}
              children="usuario"
            />
          ) : null}
          {modal && modal === 'inviteToProject' ? (
            <ModalInviteUserToProject
              handleModals={handleModals}
              user={user}
              userId={userId}
            />
          ) : null}
          {modal && modal === 'openPayments' ? (
            <ModalViewPaymentsContainer
              handleModals={handleModals}
              payments={userPayments()}
              userSelected={user}
            />
          ) : null}
        </div>
      )}
    </>
  )
}

export default SingleUser
