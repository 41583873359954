// PROD
// const firebaseConfig = {
//   apiKey: "AIzaSyAkB_qOCMWX2AVUZMxoLtx5ndT-tHnFpoA",
//   authDomain: "talentos-gus-stage.firebaseapp.com",
//   projectId: "talentos-gus-stage",
//   storageBucket: "talentos-gus-stage.appspot.com",
//   messagingSenderId: "797866285192",
//   appId: "1:797866285192:web:006041da78b6c68940673d"
// };
var firebaseConfig = {
  apiKey: 'AIzaSyC4V2lw15ewK6oiW9796NhmxV8SbfgO8go',
  authDomain: 'gus.itesa.ar',
  projectId: 'talentositesa',
  storageBucket: 'talentositesa.appspot.com',
  messagingSenderId: '570883200277',
  appId: '1:570883200277:web:d8f98ae97b48c9f787051c'
}
export default firebaseConfig
