import React, { useState, useEffect } from 'react'
// RECOIL
import { useRecoilValue } from 'recoil'
import { sessionUserState, userProjectsState } from '../../../atoms'
import { changeAvailability } from '../../../services/db'
import Profile from './Profile.jsx'

const ProfileContainer = () => {
  const [modal, setModal] = useState('')
  const [searchBar, setSearchBar] = useState(false)
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState('all')
  const [curatedProjects, setCuratedProjects] = useState([])
  const user = useRecoilValue(sessionUserState)
  const userProjects = useRecoilValue(userProjectsState)

  const handleModals = async (option) =>
    option ? setModal(option) : setModal('')

  const handleFilter = (filter) =>
    filter ? setFilter(filter) : setFilter('all')

  const handleChangeAvailability = async (option) => {
    await changeAvailability(user, option)
  }

  useEffect(() => {
    console.log('userProjects', userProjects)
    if(!userProjects) return
    setCuratedProjects(
      [
        ...userProjects?.active,
        ...userProjects?.invites,
        ...userProjects?.finished,
      ].filter((project) => {
        console.log('project', project)
        let all = project.pname.toLowerCase().includes(search.toLowerCase())
        if (filter === 'onDevelop') {
          return (project.status === 'En desarrollo' ||
            project.status === 'Iniciado') &&
            !userProjects.invites.includes(project) &&
            all
            ? true
            : false
        } else if (filter === 'finished') {
          return project.status === 'Finalizado' && all ? true : false
        } else if (filter === 'onDesign') {
          return project.status === 'En diseño' && all ? true : false
        } else if (filter === 'invitations') {
          return userProjects.invites.includes(project) && all
        } else {
          return all
        }
      })
    )
    // eslint-disable-next-line
  }, [filter, search])

  return (
    <Profile
      user={user}
      modal={modal}
      filter={filter}
      setModal={setModal}
      setSearch={setSearch}
      searchBar={searchBar}
      setSearchBar={setSearchBar}
      handleModals={handleModals}
      handleFilter={handleFilter}
      curatedProjects={curatedProjects}
      handleAvailability={handleChangeAvailability}
    />
  )
}

export default ProfileContainer
