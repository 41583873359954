import React from 'react'
import InviteP from '../Images/InviteP.png'
import CProject from '../Images/CProject.png'
import AddPayment from '../Images/AddPayment.png'
import { LoaderSmall } from './Loaders'

export const ButtonSlector = ({ type, size, children, handler, loading, isDisabled }) => (
  <button
    type={type}
    onClick={handler}
    className={
      (size === 'xs' ? 'px-2.5 py-1.5 ' : '') +
      (size === 'sm' ? 'px-3 py-2' : '') +
      (size === 'md' ? 'px-4 py-2' : '') +
      (size === 'lg' ? 'px-4 py-2' : '') +
      (size === 'xl' ? 'px-6 py-3' : '') +
      ' w-full text-center text-sm sm:text-base font-medium rounded text-white focus:outline-none font-poppins' +
      (isDisabled ? ' bg-gray-300 ' : ' bg-purple hover:bg-purple-dark focus:bg-purple-dark active:bg-purple-light')
    }
  >
    {loading ? <LoaderSmall /> : children}
  </button>
)

export const PrimaryButton = ({ type, size, children, handler, loading }) => (
  <button
    type={type}
    onClick={handler}
    disabled={loading}
    className={
      (size === 'xs' ? 'px-2.5 py-1.5 ' : '') +
      (size === 'sm' ? 'px-3 py-2' : '') +
      (size === 'md' ? 'px-4 py-2' : '') +
      (size === 'lg' ? 'px-4 py-2' : '') +
      (size === 'xl' ? 'px-6 py-3' : '') +
      ' w-full text-center text-sm sm:text-base font-medium rounded text-white bg-purple hover:bg-purple-dark focus:bg-purple-dark active:bg-purple-light focus:outline-none font-poppins'
    }
  >
    {loading ? <LoaderSmall /> : children}
  </button>
)

export const SecondaryButton = ({
  type,
  size,
  children,
  handler,
  error,
  loading,
}) => (
  <button
    type={type}
    onClick={handler}
    disabled={loading}
    className={
      (error
        ? 'border-red-500 text-red-500 '
        : 'border-gray-300 text-gray-800 ') +
      (size === 'xs' ? ' px-2.5 py-1.5 ' : '') +
      (size === 'sm' ? ' px-3 py-2 ' : '') +
      (size === 'md' ? ' px-4 py-2 ' : '') +
      (size === 'lg' ? ' px-4 py-2 ' : '') +
      (size === 'xl' ? ' px-6 py-3 ' : '') +
      ' w-full text-center text-sm sm:text-base font-medium rounded bg-transparent border border-gray-300 hover:bg-purple-light focus:bg-purple-light hover:text-white focus:text-white focus:outline-none font-poppins'
    }
  >
    {loading ? <LoaderSmall /> : children}
  </button>
)

export const AdminHomeButton = ({ img, children, handler }) => (
  <div
    className="h-28 md:h-32 w-full lg:min-w-max p-2 lg:p-0 px-4 md:px-6 bg-white rounded-md border border-gray-300 text-xs md:text-sm lg:text-base font-poppins font-medium flex flex-col items-center justify-center space-y-2 lg:space-y-4 focus:outline-none focus:ring-2 focus:ring-purple cursor-pointer hover:bg-gray-50"
    onClick={(e) => handler(e, img)}
  >
    {img === 'invite' ? (
      <img src={InviteP} alt="InviteP" className="h-10 sm:h-auto w-auto" />
    ) : null}
    {img === 'cproject' ? (
      <img src={CProject} alt="CProject" className="h-10 sm:h-auto w-auto" />
    ) : null}
    {img === 'addPayment' ? (
      <img
        src={AddPayment}
        alt="AddPayment"
        className="h-10 sm:h-auto w-auto"
      />
    ) : null}
    <span>{children}</span>
  </div>
)
