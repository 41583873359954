import ModalLayout from '../UI/ModalLayout'

const ModalItesaDetails = ({ handleModals }) => (
  <ModalLayout
    handler={handleModals}
    modal="itesaBankDetails"
    title="Datos de facturación Itesa"
    goBackText="Home"
  >
    <div className="w-full flex flex-col space-y-4">
      <div>
        <p className="text-sm text-gray-500">Titular</p>
        <p className="text-lg text-gray-800">Itesa Innovation Company SAS</p>
      </div>
      <div>
        <p className="text-sm text-gray-500">CUIT</p>
        <p className="text-lg text-gray-800">30-71664275-1</p>
      </div>
    </div>
  </ModalLayout>
)

export default ModalItesaDetails
