import React, { useState } from 'react'
// DB
import { createNewPayment, deletePaymentFull, editProjectDetails, updatePayment } from '../../../../services/db'
// COMPONENTS
import ModalEditProject from './ModalEditProject.jsx'
// UI
import ModalLayout from '../../UI/ModalLayout'

const ModalEditProjectContainer = ({ handleModals, project, payment, closeEdit, getProjects, getPayments,fetchProject, refreshData }) => {

  const [formData, setFormData] = useState(!payment ? ({
    pname: project.pname,
    description: project.description,
    projectId: project.projectId,
    startDate: project.startDate.toDate(),
  }) : ( payment.new ? {
    amount: '',
    date: '',
    currency: '',
    bono: 'false'
  } : {
    amount: payment.amount,
    id: payment.id,
    date: payment.date.toDate(),
    currency: payment.currency,
    bono: payment.bono?.toString()
  }))
  const [error, setError] = useState(false)
  const [step, setStep] = useState('editDetails')
  const [loading, setLoading] = useState(false)

  const handleValue = async (e, field, data) => {
    let name = field ? field : e.target.name
    let value = data ? data : e.target.value
    if(value === 'on' || value === 'off') value = e.target.checked.toString()
    setFormData({ ...formData, [name]: value })
  }

  const handleDates = (date, name) => {
    setFormData({
      ...formData,
      [name]: date == null ? '' : date,
    })
  }

  const confirmDetails = async (e) => {
    setLoading(true)
    e.preventDefault()
    let errors = 0
    for (const key in formData) {
      if (!formData[key]){
          console.log('error',formData[key])  
         errors++
        }
    }
    console.log(errors)
    if (errors > 0) {
      setError(true)
      setLoading(false)
    } else {
      setError(false)
      if(payment){
        console.log('edit payment',formData)
        if(payment.new){
          console.log('create payment',payment)
          await createNewPayment(formData, payment.project, payment.user)
        }else{
          await updatePayment(formData)
        }
      }else{
        await editProjectDetails(formData)
      }
      await refreshData()
      setStep('success')
      setLoading(false)
      
    }
  }

  const deletePayment = async () => {
    await deletePaymentFull(payment)
    await getPayments()
    setStep('success')
  }

  return (
    <ModalLayout
      handler={payment ? closeEdit : handleModals}
      modal="editProject"
      goBackText="Proyecto"
      title={step === 'editDetails' ? (payment ? (payment.new ? 'Crear pago' : 'Editar pago') :'Editar proyecto') : (payment ? (payment.new ? '¡Pago creado!' : '¡Pago editado!') :'¡Proyecto editado!')}
      regular={step === 'success'}
    >
      {step === 'editDetails' ? (
        <ModalEditProject
          formData={formData}
          handleValue={handleValue}
          handleDates={handleDates}
          confirmDetails={confirmDetails}
          error={error}
          payment={payment}
          deletePayment={deletePayment}
          loading={loading}
        />
      ) : null}
      {step === 'success' ? (
        <div className="w-full flex flex-col items-center">
          <p className="text-sm sm:text-base md:text-lg text-center font-poppins text-gray-500">
            El proyecto ha sido editado correectamente.
          </p>
        </div>
      ) : null}
    </ModalLayout>
  )
}

export default ModalEditProjectContainer
